import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

const fetchDownloadedFiles = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetails);

  const response = await axios.get(
    `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=downloadedFile&companyid=${user.companyid}&token=${user.token}&tcid=${id}`,
  );

  return response.data;
};

const useDownloadedFileQuery = (id) => {
  return useQuery({
    queryKey: ["downloadedFiles", id],
    queryFn: () => fetchDownloadedFiles(id),
    retry: true,
    cacheTime: 90000,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    staleTime: Infinity,
  });
};

export default useDownloadedFileQuery;
