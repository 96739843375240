import { Box, Typography, Button, IconButton, styled } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconContainerCircle } from "../../components-new/common";
import {
    BookmarkIcon,
    ContextIcon,
    PlusIcon,
    RightArrowIcon,
    LeftArrowIcon,
} from "../../components-new/icons";
import { useDispatch, useSelector } from "react-redux";
import { setEditItemIndex } from "../../Redux/TestAuthoring/TestAuthoring";
import { useEffect, useState } from "react";
import CustomMenu from "../../components-new/common/CustomMenu";

const StyledIconBtn = styled(IconButton)`
  border-radius: 0;
  padding: 10px;

  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
`;

const StepText = styled("span")`
  padding-left: 30px;
  padding-right: 30px;
`;

const EditHeader = ({ editItem, editItemIndex: currentEditItemIndex, testCasesList, setEditItemIndex }) => {
    const dispatch = useDispatch();
    const stepDetails = useSelector((state) => state.authoringSlice.editStep);

    const [disableIncrement, setDisableIncrement] = useState(false);
    const [disableDecrement, setDisableDecrement] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        updateButtonDisableStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEditItemIndex, testCasesList]);

    const updateButtonDisableStatus = () => {
        if (currentEditItemIndex !== -1) {
            setDisableIncrement(currentEditItemIndex === testCasesList?.length - 1);
            setDisableDecrement(currentEditItemIndex === 0);
        } else {
            setDisableIncrement(true);
            setDisableDecrement(true);
        }
    };

    const handleIncrementStep = () => {
        if (currentEditItemIndex !== -1) {
            const currenItemIndex = testCasesList.findIndex((testCaseItem) => testCaseItem.Step_Number === editItem.Step_Number)
            if (currentEditItemIndex + 1 < testCasesList.length) {
                setEditItemIndex(currenItemIndex + 1)
            } else {
                console.log("End of test cases list reached");
            }
        }
    };

    const handleDecrementStep = () => {
        if (currentEditItemIndex !== -1) {
            const currentItemIndex = testCasesList.findIndex((testCaseItem) => testCaseItem.Step_Number === editItem.Step_Number)
            if (currentEditItemIndex - 1 >= 0) {
                setEditItemIndex(currentItemIndex - 1);
            } else {
                console.log("Beginning of test cases list reached");
            }
        }
    };

    const handleBack = () => {
        setEditItemIndex(null);
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (event) => {
        setAnchorEl(null);
    };

    const handleExitEdit = () => {
        handleBack();
    };

    return (
        <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box>
                <Typography color="#000000" fontSize="25px" fontWeight={600}>
                    <StyledIconBtn
                        variant="text"
                        aria-label="Previous step"
                        disabled={disableDecrement}
                        onClick={handleDecrementStep}
                    >
                        <LeftArrowIcon />
                    </StyledIconBtn>
                    <StepText>Step {editItem.Step_Number}</StepText>
                    <StyledIconBtn
                        aria-label="Next step"
                        disabled={disableIncrement}
                        onClick={handleIncrementStep}
                    >
                        <RightArrowIcon />
                    </StyledIconBtn>
                </Typography>
            </Box>
            <Box display="flex" alignItems="center" columnGap="12px" textAlign="right">
        <Button
          variant="outlined"
          sx={{
            textTransform: "capitalize",
            padding: "15px 28px",
            borderRadius: "8px",
            fontSize: "16px",
          }}
          startIcon={<ArrowBackIcon />}
          onClick={handleBack}
        >
          Exit Edit
        </Button>
        {/* <Button
          color="primary"
          sx={{
            backgroundColor: "#2550d1",
            boxShadow: "0px 16px 30.6px 0px rgba(37, 80, 209, 0.27)",
            width: "152px",
            textTransform: "capitalize",
            padding: "15px 28px",
            borderRadius: "8px",
            fontSize: "16px",
          }}
          variant="contained"
          endIcon={<PlusIcon />}
          onClick={handleAddStepClick}
        >
          Add Step
        </Button>
        <Button
          color="warning"
          sx={{
            backgroundColor: "#ff5336",
            boxShadow: "0px 16px 30.6px 0px rgba(255, 83, 54, 0.27)",
            textTransform: "capitalize",
            padding: "15px 28px",
            borderRadius: "8px",
            fontSize: "16px",
            width: "190px",
          }}
          variant="contained"
          endIcon={<BookmarkIcon />}
          onClick={handleSaveStepClick}
        >
          Save Changes
        </Button>
        <Box onClick={handleMenuClick}>
          <IconContainerCircle>
            <ContextIcon />
          </IconContainerCircle>
        </Box>
        <CustomMenu
          anchorEl={anchorEl}
          handleClose={handleMenuClose}
          handleExitEdit={handleExitEdit}
        /> */}
      </Box>
        </Box>
    );
};
export default EditHeader;
