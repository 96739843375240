import React from "react";
import { AccessTime } from "@mui/icons-material";
import { Box, Typography, Card } from "@mui/material";
import { colorExport } from "../common/common.Component";
import { PrimaryColor, SecondaryColor } from "../common/common.Component";

const GraphCard = (props) => {
  const colors = colorExport();
  return (
    <Card
      sx={{
        display: "flex",
        width: "19rem",
        justifyContent: "center",
        flexDirection: "column",
        color: colors?.primaryColor,
        backgroundColor: colors?.secondaryColor,
        padding: "1rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          alignItem: "space-between",
          justifyContent: "space-between",
        }}
      >
        <Typography paddingBottom={0} variant="subtitle1" component="h2">
          {props.title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItem: "center",
            justifyContent: "flex-start",
          }}
        >
          <Typography variant="subtitle1" component="p" marginRight={0.5}>
            {props.count}
          </Typography>
          <AccessTime style={{ width: "1rem" }} />
        </Box>
      </Box>
    </Card>
  );
};
export default GraphCard;
