// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.testdat_header {
  width: 100% !important;
}

.subheads {
  margin-left: 26px !important;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.alert {
  width: 20%;
  position: fixed;
  top: 45%;
  right: 40%;
  left: 40%;
  bottom: 45%;
  z-index: 999;
  background-color: #0FC6EE !important;
  color: black !important;
  font-weight: 800 !important;
  font-size: 18px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/TestData/TestData.scss"],"names":[],"mappings":"AAAA;EAEE,sBAAA;AAAF;;AAIA;EACE,4BAAA;EACA,aAAA;EACA,mBAAA;EACA,oCAAA;AADF;;AAGA;EACI,UAAA;EACA,eAAA;EACA,QAAA;EACA,UAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,oCAAA;EACA,uBAAA;EACA,2BAAA;EACA,0BAAA;AAAJ","sourcesContent":[".testdat_header {\n  // margin-top: 0px !important;\n  width: 100% !important;\n  // margin: 0px 0px 0px -16px !important;\n  \n}\n.subheads {\n  margin-left: 26px !important;\n  display: flex;\n  align-items: center;\n  /* justify-content: space-between; */\n}\n.alert{\n    width: 20%;\n    position: fixed;\n    top: 45%;\n    right: 40%;\n    left: 40%;\n    bottom: 45%;\n    z-index: 999;\n    background-color: #0FC6EE !important;\n    color: black !important;\n    font-weight: 800 !important;\n    font-size: 18px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
