import { Box, Button, MenuItem, Select, Typography, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedComponent,
  setSelectedTestCase,
  setSelectedProduct,
  resetAuthorings,
} from "../../Redux/TestAuthoring/TestAuthoring";

import AutocompleteComponent from "../../components-new/common/AutoCompleteComponent";
import { DeleteIcon } from "../../components-new/icons";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "300px",
    },
  },
};

const StyledSelect = styled(Select)`
  border-radius: 5px;
  border: 1px solid #d1dbff;
  font-size: 16px;
  color: #2b3539;
  min-width: 200px;
  max-height: 300px;
  background-color: #ffffff;
  .MuiSelect-select {
    padding: 13px 15px;
  }
`;

const StudioSelection = ({
  refetchTestCases,
  setRefreshFlag,
  products,
  modules,
  testCases,
  isLoadingTestcase,
  testCaseSearchKeyword,
  setTestCaseSearchKeyword,
}) => {
  const dispatch = useDispatch();

  const selectedProduct = useSelector((state) => state.authoringSlice.selectedProduct);
  const selectedModule = useSelector((state) => state.authoringSlice.selectedComponent);
  const selectedTestCase = useSelector((state) => state.authoringSlice.selectedTestCase);

  const handleProductChange = (event) => {
    setRefreshFlag(false);
    Promise.resolve().then(() => {
      dispatch(setSelectedProduct(event.target.value));
    });
  };

  const handleSearchClick = async () => {
    refetchTestCases();
  };

  const handleOptionChange = (event, value) => {
    setTestCaseSearchKeyword(value);
  };

  const handleTestCaseChange = (event, newValue) => {
    if (newValue) dispatch(setSelectedTestCase(newValue.idtest_case));
    else {
      dispatch(setSelectedTestCase(null));
    }
  };

  const handleClearFilter = () => {
    setRefreshFlag(false);
    dispatch(resetAuthorings());
  };

  return (
    <>
      <Box>
        <Box>
          <Typography color="#8089A8" fontSize="13px" fontWeight={500}>
            Application
          </Typography>
        </Box>
        <StyledSelect
          MenuProps={MenuProps}
          value={selectedProduct}
          onChange={handleProductChange}
          displayEmpty
        >
          <MenuItem disabled value="">
            Application
          </MenuItem>
          {products &&
            products.length > 0 &&
            (products ?? [{ label: "", value: "" }]).map((product) => (
              <MenuItem value={product.idproducts} key={product.idproducts}>
                {product.productname}
              </MenuItem>
            ))}
        </StyledSelect>
      </Box>
      <Box>
        <Box>
          <Typography color="#8089A8" fontSize="13px" fontWeight={500}>
            Component
          </Typography>
        </Box>
        <StyledSelect
          MenuProps={MenuProps}
          value={selectedModule}
          displayEmpty
          onChange={(event) => {
            setRefreshFlag(false);
            dispatch(setSelectedComponent(event.target.value));
          }}
          disabled={!modules}
        >
          <MenuItem disabled value="">
            Component
          </MenuItem>
          {modules &&
            modules.length > 0 &&
            modules.map((module) => (
              <MenuItem key={module.idmodules} value={module.idmodules}>
                {module.modulename}
              </MenuItem>
            ))}
        </StyledSelect>
      </Box>
      <Box>
        <Box>
          <Typography color="#8089A8" fontSize="13px" fontWeight={500}>
            Test Scenario
          </Typography>
        </Box>
        <AutocompleteComponent
          options={testCases?.length ? testCases : []}
          value={
            selectedTestCase && testCases?.length
              ? (testCases ?? []).find((testCase) => testCase.idtest_case === selectedTestCase) ||
                null
              : ""
          }
          onChange={handleTestCaseChange}
          isLoading={isLoadingTestcase}
          onInputChange={handleOptionChange}
          handleSearchClick={handleSearchClick}
          getOptionLabel={(testCase) => testCase.Test_Case ?? ""}
          placeholder="Search Test Cases"
        />
      </Box>
      <Button
        variant="outlined"
        startIcon={<DeleteIcon />}
        size="medium"
        onClick={handleClearFilter}
        sx={{
          color: "#828286",
          background: "#ffffff",
          height: "44px",
          border: "1px solid #E2E6F3",
          outline: 0,
          textTransform: "capitalize",
          mt: 2.5,
        }}
      >
        Clear filter
      </Button>
    </>
  );
};

export default StudioSelection;
