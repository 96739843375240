import React from "react";

const SynopsisIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
    <path
      d="M13.6 16.5H2.4C1.76348 16.5 1.15303 16.2471 0.702944 15.7971C0.252856 15.347 0 14.7365 0 14.1V1.3C0 1.08783 0.0842854 0.884344 0.234315 0.734315C0.384344 0.584285 0.587827 0.5 0.8 0.5H12C12.2122 0.5 12.4157 0.584285 12.5657 0.734315C12.7157 0.884344 12.8 1.08783 12.8 1.3V10.9H16V14.1C16 14.7365 15.7471 15.347 15.2971 15.7971C14.847 16.2471 14.2365 16.5 13.6 16.5ZM12.8 12.5V14.1C12.8 14.3122 12.8843 14.5157 13.0343 14.6657C13.1843 14.8157 13.3878 14.9 13.6 14.9C13.8122 14.9 14.0157 14.8157 14.1657 14.6657C14.3157 14.5157 14.4 14.3122 14.4 14.1V12.5H12.8ZM11.2 14.9V2.1H1.6V14.1C1.6 14.3122 1.68429 14.5157 1.83431 14.6657C1.98434 14.8157 2.18783 14.9 2.4 14.9H11.2ZM3.2 4.5H9.6V6.1H3.2V4.5ZM3.2 7.7H9.6V9.3H3.2V7.7ZM3.2 10.9H7.2V12.5H3.2V10.9Z"
      fill="#03053D"
    />
  </svg>
);

export default SynopsisIcon;
