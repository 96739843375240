import React from "react";
import { validateDetailsBySearch } from "../../API/ValidateAPI";
import { InputLabel, FormControl, Button, TextField } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { ColorButton, colorExport } from "./../common/common.Component";
import { CommonMethods } from "./../common/CommonMethods";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_PATH, API_BASE_RELATIVE_PATH } from "./../../Config/Config";
import { useNavigate } from "react-router-dom";
import "./RecordingTable.style.scss";
import { handleTDChange } from "./../../API/RecordingTableAPI";
import { getFileCustomData, scriptFileUpdated } from "../../API/TestDataApi";

const Script = ({
  setTestDataDetails,
  TestDataDetails,
  handleClose,
  actionValue,
  newTestStep,
  setNewTestStep,
}) => {
  const scriptListData = [
    { value: "selectScript", name: "Select Script" },
    { value: "uploadScript", name: "Upload Script" },
  ];

  const colors = colorExport();
  const navigate = useNavigate();
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const handleFileChange = (e) => {
    if (e.target.files) {
      setTestDataDetails((prevState) => ({
        ...prevState,
        file: e.target.files,
        filename: e.target.files[0].name,
      }));
    }
  };
  const [uploadFileName, setUploadFileName] = useState([]);

  useEffect(() => {
    getFileNewCustomData();
  }, []);

  const getFileNewCustomData = async () => {
    await getFileCustomData()
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
          setUploadFileName(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getScriptFileUpdated = async (input) => {
    await scriptFileUpdated(input)
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
          console.log("response.data.", response.data);
          setUploadFileName(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUploadClick = (fromtestdatasource) => {
    if (!TestDataDetails.file) {
      return;
    }

    fetch(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=addscript&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${newTestStep.Test_Case_Id}&teststepid=${newTestStep.tsid}&name=${TestDataDetails.file[0].name}&script=uploadScript`,
      {
        method: "POST",
        body: TestDataDetails.file[0],
        headers: {
          "content-type": TestDataDetails.file[0].type,
          "content-length": `${TestDataDetails.file[0].size}`, // 👈 Headers need to be a string
        },
      },
    )
      .then((res) => res.json())
      .then((data) => {
        if (fromtestdatasource) {
          ChangeTableRowData(TestDataDetails);
        }
      })
      .catch((err) => console.error(err));
  };

  const ChangeTableRowData = async (api_input) => {
    await handleTDChange(api_input)
      .then((response) => {
        if (response.status === 200) {
          // setloader(false);
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <FormControl>
        <InputLabel id={`Script Option`}>Script Options</InputLabel>
        <Select
          labelId={`Script Option`}
          id={`Script Option`}
          value={newTestStep.script}
          label="Script Option"
          name="Script Option"
          variant="standard"
          onChange={(event) => {
            setNewTestStep((prev) => {
              return {
                ...prev,
                script: event.target.value,
              };
            });
            if (event.target.value === "selectScript") {
              getFileNewCustomData();
            }
          }}
          style={{ minWidth: 168, marginRight: "2rem", alignItems: "center" }}
        >
          {scriptListData.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {newTestStep.script === "uploadScript" && (
        <>
          {!TestDataDetails.file && (
            <TextField
              sx={{ marginX: "2rem", width: "15%" }}
              label="File Name"
              variant="standard"
              value={newTestStep.scriptfile}
              disabled={true}
            />
          )}
          <TextField
            type="file"
            id="recording-file-upload"
            onChange={(event) => {
              handleFileChange(event);
            }}
            variant="standard"
            sx={{ width: "20%", marginTop: "1rem" }}
          />
          {/* <TextField
                        sx={{ width: "20%", marginX: "2rem" }}
                        label="Script"
                        variant="standard"
                        defaultValue={TestDataDetails.scriptfile}
                        onChange={(event) => {
                            event.preventDefault();
                            setTestDataDetails((prev) => {
                                return {
                                    ...prev,
                                    scriptfile: event.target.value,
                                };
                            });
                        }}
                    /> */}

          <Button
            component="label"
            size="medium"
            onClick={() => {
              handleUploadClick(true);
              handleClose("Save");
            }}
            sx={{ marginLeft: 4, marginTop: 2 }}
          >
            Upload
          </Button>
        </>
      )}

      {newTestStep.script === "selectScript" && (
        <>
          <FormControl>
            <InputLabel id={`Select Script`}>Select File</InputLabel>
            <Select
              variant="standard"
              labelId={`Select Script`}
              id={`Select Script`}
              label="Select Script"
              value={newTestStep.scriptfile}
              onChange={(event) => {
                setNewTestStep((prev) => {
                  return {
                    ...prev,
                    scriptfile: event.target.value,
                  };
                });
              }}
              sx={{ width: 180 }}
            >
              {uploadFileName.map((item, index) => (
                <MenuItem key={index} value={item.FileName}>
                  {item.FileName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            component="label"
            size="medium"
            onClick={() => {
              getScriptFileUpdated({
                teststepid: newTestStep.tsid,
                testCaseId: newTestStep.Test_Case_Id,
                script: "selectScript",
                scriptfile: TestDataDetails.scriptfile,
              });
              handleClose("Save");
            }}
            sx={{ marginLeft: 4, marginTop: 2 }}
          >
            Select
          </Button>
        </>
      )}
    </>
  );
};

export default Script;
