import React from "react";
import { Typography, Tooltip } from "@mui/material";
import { styled } from "@mui/system";

const EllipsisText = styled(Typography)({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "100%",
  cursor: "pointer",
  fontSize: "16px",
  fontWeight: 500,
  color: "#2B3539",
});

const EllipsisTooltipText = ({ text, maxChars = 0, maxWidth = "100%", mode = "width" }) => {
  let displayText = text;

  if (mode === "chars" && maxChars) {
    displayText = text.length > maxChars ? `${text.substring(0, maxChars)}...` : text;
  }

  return (
    <Tooltip title={text} arrow>
      {mode === "chars" ? (
        <Typography style={{ cursor: "pointer", overflowWrap: "anywhere" }}>
          {displayText}
        </Typography>
      ) : (
        <EllipsisText maxWidth={maxWidth}>{text}</EllipsisText>
      )}
    </Tooltip>
  );
};

export default EllipsisTooltipText;
