import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_BASE_PATH, API_BASE_RELATIVE_PATH } from "../Config/Config";
import axios from "axios";

const buildQueryParams = (params) => {
  return Object.entries(params)
    .filter(([key, value]) => value !== undefined && value !== null)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("&");
};

const executeTC = async ({
  id,
  SelectEnvironmentName,
  SelectEnvironmentUrl,
  SelectBrowserName,
  SelectResolution,
  SelectRunonce,
  TcThreshold,
  testcase,
  proxyurl,
  baseline,
}) => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetails);

  const queryParams = buildQueryParams({
    action: "execute",
    companyid: user.companyid,
    token: user.token,
    testcaseid: id,
    testcase,
    envName: SelectEnvironmentName,
    envUrl: SelectEnvironmentUrl,
    browsername: SelectBrowserName,
    resolution: SelectResolution,
    runonce: SelectRunonce,
    threshold: TcThreshold,
    proxyurl,
    baseline,
  });

  try {
    const response = await axios.get(`${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?${queryParams}`);
    return response;
  } catch (error) {
    console.log(error.response.data.error);
    throw error;
  }
};

const useExecuteTCQuery = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: executeTC,
    onSuccess: () => {
      queryClient.invalidateQueries([]);
    },
    onError: (error) => {
      console.log("Error executing test case", error.message);
    },
  });
};

export default useExecuteTCQuery;
