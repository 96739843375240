// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#input-file-upload {
  color: #0FC6EE;
  font-weight: 700;
  font-size: large;
  border: none;
  background-color: transparent;
}
#input-file-upload:hover {
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/components/FileSelect/FileSelect.style.scss"],"names":[],"mappings":"AACA;EACA,cAAA;EACA,gBAAA;EACA,gBAAA;EACA,YAAA;EACA,6BAAA;AAAA;AACA;EACI,YAAA;AACJ","sourcesContent":["\n#input-file-upload{\ncolor: #0FC6EE;\nfont-weight: 700;\nfont-size: large;\nborder: none;\nbackground-color: transparent;\n&:hover{\n    border: none;\n}\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
