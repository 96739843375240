import React from "react";

const LogFileIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="currentColor"
  >
    <path
      d="M9.56167 0L13.0617 3.5V13.2951C13.0617 13.6843 12.7502 14 12.3666 14H1.15677C0.77317 14 0.46167 13.6815 0.46167 13.3056V0.6944C0.46167 0.3108 0.77457 0 1.16097 0H9.56167ZM8.86167 1.4H1.86167V12.6H11.6617V4.2H8.86167V1.4ZM7.46167 4.9V7.7H9.56167V9.1H6.06167V4.9H7.46167Z"
      fill="currentColor"
    />
  </svg>
);

export default LogFileIcon;
