import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

const fetchTestCaseResults = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetails);

  const response = await axios.get(
    `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=search&companyid=${user.companyid}&token=${user.token}&testcaseid=${id}`,
  );

  return response.data;
};

const useTestCaseResultsQuery = (id, options) => {
  return useQuery({
    queryKey: ["testCaseResults", id],
    queryFn: () => fetchTestCaseResults(id),
    enabled: options.enabled,
    retry: true,
    cacheTime: 90000,
    refetchInterval: options.refetchInterval,
    refetchIntervalInBackground: false,
    staleTime: Infinity,
    select: (data) => {
      return {
        ...data,
        data: Object.values(data.data),
      };
    },
  });
};

export default useTestCaseResultsQuery;
