import { Typography } from "@mui/material";

const PageTitle = (props) => {
  const {title, variant='h4'} = props
  return (
    <Typography variant={variant} component={variant} gutterBottom fontWeight={600} m={0} {...props}>
      {title}
    </Typography>
  );
};

export default PageTitle;
