import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedProduct: "",
  selectedComponent: "",
  selectedTestSuite: "",
  selectedTestCase: null,
  testCaseResults: [],
  selectedTestSuiteData:[]
};

const executionSlice = createSlice({
  name: "executionReport",
  initialState,
  reducers: {
    setSelectedProduct(state, action) {
      state.selectedProduct = action.payload;
      state.selectedComponent = initialState.selectedComponent;
    },
    setSelectedComponent(state, action) {
      state.selectedComponent = action.payload;
      state.selectedTestCase = initialState.selectedTestCase;
    },
    setSelectedTestSuite(state, action) {
      state.selectedTestSuite = action.payload;
    },
    setSelectedTestCase(state, action) {
      state.selectedTestCase = action.payload;
    },
    setTestCaseResults(state, action) {
      state.testCaseResults = action.payload;
    },
    setSelectedTestSuiteData(state, action) {
      state.selectedTestSuiteData = action.payload;
    },
    resetExecutionReport() {
      return { ...initialState }
    },
  },
});

export const {
  setTestCaseResults,
  setSelectedProduct,
  setSelectedComponent,
  setSelectedTestCase,
  setSelectedTestSuite,
  setSelectedTestSuiteData,
  resetExecutionReport

} = executionSlice.actions;

export default executionSlice.reducer;
