import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

const getAllAuthors = async () => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetails);

  const response = await axios.get(
    `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getAuthors&companyid=${user.companyid}&token=${user.token}`,
  );

  return response.data;
};

const useGetAllAuthorsQuery = () => {
  return useQuery({
    queryKey: ["getAuthor"],
    queryFn: () => getAllAuthors(),
    retry: true,
    enabled: true,
    cacheTime: 0,
    refetchIntervalInBackground: false,
    staleTime: 0,
  });
};

export default useGetAllAuthorsQuery;
