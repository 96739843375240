import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  issidebaropen: false,
};

export const SiteSettingsSlice = createSlice({
  name: "SiteSettings",
  initialState,
  reducers: {
    ChangeSideBar: (state, action) => {
      state.issidebaropen = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { ChangeSideBar } = SiteSettingsSlice.actions;

export default SiteSettingsSlice.reducer;
