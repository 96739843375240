import React from "react";

const DesignIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.4 6.8L7.2304 3.892C7.27793 3.72453 7.3788 3.57713 7.51769 3.47218C7.65658 3.36722 7.82591 3.31044 8 3.31044C8.17409 3.31044 8.34342 3.36722 8.48231 3.47218C8.6212 3.57713 8.72207 3.72453 8.7696 3.892L9.6 6.8V8H10.176C10.3543 8 10.5276 8.0596 10.6682 8.16932C10.8088 8.27905 10.9087 8.4326 10.952 8.6056L12.0408 12.9632C13.0656 12.1288 13.807 10.9977 14.1631 9.72513C14.5193 8.45255 14.4728 7.1009 14.0301 5.8558C13.5873 4.61069 12.77 3.53317 11.6903 2.77119C10.6106 2.00922 9.32149 1.60014 8 1.60014C6.67851 1.60014 5.38942 2.00922 4.30973 2.77119C3.23004 3.53317 2.4127 4.61069 1.96993 5.8558C1.52716 7.1009 1.48067 8.45255 1.83685 9.72513C2.19303 10.9977 2.93441 12.1288 3.9592 12.9632L5.0488 8.6056C5.0921 8.43274 5.19189 8.27928 5.33232 8.16957C5.47275 8.05986 5.64579 8.00018 5.824 8H6.4V6.8ZM8 14.4C8.90014 14.4018 9.79042 14.2126 10.612 13.8448L9.5504 9.6H6.4496L5.388 13.8448C6.20957 14.2126 7.09985 14.4019 8 14.4ZM8 16C3.5816 16 0 12.4184 0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8C16 12.4184 12.4184 16 8 16Z" fill="#03053D"/>
    </svg>
    
);

export default DesignIcon;
