import React from "react";

const EditIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path
      d="M9.70739 5.31549L8.68451 4.29261L1.94679 11.0303V12.0532H2.96967L9.70739 5.31549ZM10.7303 4.29261L11.7532 3.26973L10.7303 2.24685L9.70739 3.26973L10.7303 4.29261ZM3.56865 13.5H0.5V10.4306L10.2188 0.711801C10.3545 0.576185 10.5385 0.5 10.7303 0.5C10.9221 0.5 11.1061 0.576185 11.2417 0.711801L13.2882 2.75829C13.4238 2.89395 13.5 3.07791 13.5 3.26973C13.5 3.46155 13.4238 3.64551 13.2882 3.78117L3.56937 13.5H3.56865Z"
      fill="currentColor"
    />
  </svg>
);

export default EditIcon;
