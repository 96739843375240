import React from "react";

const FileDownloadIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_121_7512)">
      <path
        d="M10 9H12.1L9.3 11.8L6.5 9H8.6V6.2H10V9ZM11.4 3.4H4.4V14.6H14.2V6.2H11.4V3.4ZM3 2.6944C3 2.3108 3.3129 2 3.6993 2H12.1L15.6 5.5V15.2951C15.6006 15.387 15.5832 15.4782 15.5486 15.5634C15.514 15.6485 15.463 15.7261 15.3984 15.7915C15.3339 15.857 15.2571 15.9091 15.1724 15.9448C15.0877 15.9806 14.9968 15.9994 14.9049 16H3.6951C3.51126 15.9987 3.33531 15.9252 3.20525 15.7952C3.07519 15.6653 3.00147 15.4894 3 15.3056V2.6944Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_121_7512">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default FileDownloadIcon;
