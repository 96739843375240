import React, {  } from "react";
import {
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
  "&.MuiButtonBase-root.MuiMenuItem-root": {
    display: "flex",
    padding: "12px 25px",
  },
  "& .MuiListItemIcon-root": {
    minWidth: "36px",
    textAlign: "center",
    color: "#242F3F",
    "& svg": {
      width: "20px",
      height: "20px",
    },
  },
  "&:last-child .MuiListItemIcon-root": {
    color: "#FF310E",
  },
  "& .MuiTypography-root": {
    fontSize: "14px",
    fontWeight: 500,
    color: "#2B3539",
  },
  "&:last-child .MuiTypography-root": {
    color: "#FF310E",
  },
}));

const CustomMenu = ({ anchorEl, handleClose,editItem }) => {
  return (
    <Menu
      id="customized-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <CustomMenuItem
        onClick={() => {
          window.open(
            `/new/dashboard/ResultStepPage/${editItem.idtest_step_result}`,
            "_blank",
          );
        }}
      >
        <Typography>Step history</Typography>
      </CustomMenuItem>
    </Menu>
  );
};

export default CustomMenu;
