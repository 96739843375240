import React from "react";

const CopyIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="currentColor"
  >
    <path
      d="M3.96167 3.5V0.7C3.96167 0.514348 4.03542 0.336301 4.1667 0.205025C4.29797 0.0737498 4.47602 0 4.66167 0H13.7617C13.9473 0 14.1254 0.0737498 14.2566 0.205025C14.3879 0.336301 14.4617 0.514348 14.4617 0.7V9.8C14.4617 9.98565 14.3879 10.1637 14.2566 10.295C14.1254 10.4263 13.9473 10.5 13.7617 10.5H10.9617V13.2951C10.9617 13.6843 10.6474 14 10.2568 14H1.16657C1.07398 14.0001 0.982272 13.9819 0.896708 13.9465C0.811144 13.9111 0.7334 13.8592 0.667925 13.7937C0.602451 13.7283 0.550532 13.6505 0.51514 13.565C0.479748 13.4794 0.461578 13.3877 0.46167 13.2951L0.46377 4.2049C0.46377 3.8157 0.77807 3.5 1.16867 3.5H3.96167ZM5.36167 3.5H10.2568C10.646 3.5 10.9617 3.8143 10.9617 4.2049V9.1H13.0617V1.4H5.36167V3.5ZM1.86377 4.9L1.86167 12.6H9.56167V4.9H1.86377Z"
      fill="currentColor"
    />
  </svg>
);

export default CopyIcon;
