import React from "react";

const ReportsIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 8.42105H3.36842V16H0V8.42105ZM11.7895 5.05263H15.1579V16H11.7895V5.05263ZM5.89474 0H9.26316V16H5.89474V0Z"
      fill="currentColor"
    />
  </svg>
);

export default ReportsIcon;
