import { Box } from "@mui/material";
import { StatusCard } from "../../components-new/common";
import { CalendarIcon, InfoIcon, TimeIcon } from "../../components-new/icons";
import ChromeIcon from "../../components-new/icons/ChromeIcon";

const StatusBar = () => {
  return (
    <Box display="flex" alignItems="center">
        <Box display="flex" columnGap={5} flex={2}>
          <StatusCard
            icon={<CalendarIcon />}
            title={"Run Date"}
            value={"2024-05-08 10:21:49.213"}
          />
          <StatusCard icon={<TimeIcon />} title={"Run Time"} value={"27.12s"} />
          <StatusCard icon={<InfoIcon />} title={"Status"} value={"Pass"} />
          <StatusCard icon={<ChromeIcon />} title={"Browser Type"} value={"Chrome"} />
        </Box>
    </Box>
  );
};

export default StatusBar;
