import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Button from "@mui/material/Button";

import TableRow from "@mui/material/TableRow";

import  Tooltip,{ tooltipClasses } from '@mui/material/Tooltip';
import { useEffect } from "react";

const userDetails = localStorage.getItem("userDetail");
const temp = userDetails ? JSON.parse(userDetails) : {};

export const colorExport = () => {
  const colorCode = localStorage.getItem(
    "colorcode",
  );
  return JSON.parse(colorCode);
}

export const PrimaryColor = temp.primaryColor 
export const SecondaryColor = temp.secondaryColor 
export const TertiaryColor = temp.tertiaryColor 

export const StyledTableCell = styled(TableCell)(({ theme }) => {
  const colors = colorExport();
 return({ [`&.${tableCellClasses.head}`]: {
  
    color: colors?.primaryColor,
    backgroundColor: colors?.secondaryColor,
    // textTransform: "uppercase",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
})
});

export const StyledTableRow = styled(TableRow)(({ theme }) => {
  const colors = colorExport();
  return({ [`&.${tableCellClasses.head}`]: {
  
    color: colors?.primaryColor,
    backgroundColor: colors?.secondaryColor,
    // textTransform: "uppercase",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
})});

export const ColorButton = styled(Button)(({ theme }) => {
  const colors = colorExport()
  return({ [`&.${tableCellClasses.head}`]: {
  
    color: colors?.primaryColor,
    backgroundColor: colors?.secondaryColor,
  },
    // textTransform: "uppercase",
    "&:hover": {
      color: colors?.primaryColor,
      backgroundColor: colors?.secondaryColor,
      fontWeight: 700,
      fontSize: "medium",
  },
})});


export const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className||"" }} />
))(({ theme }) => {
  const colors = colorExport()
  return({[`& .${tooltipClasses.tooltip}`]: {
    color: colors?.primaryColor,
    backgroundColor: colors?.secondaryColor,
  maxWidth: 220,
  fontSize: theme.typography.pxToRem(8),
  // border: '1px solid #dadde9',
},
  [`& .${tooltipClasses.arrow}`]: {
    color: colors?.primaryColor,    
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colors?.secondaryColor,
  },
})});