import { ReportsIcon, TestSuiteIcon, TestStudioIcon, VisiblityIcon, DashboardIcon } from "../icons";

export const navItems = [
  { text: "Dashboard", icon: <DashboardIcon />, path: "/new/dashboard" },
  { text: "Test Studio", icon: <TestStudioIcon />, path: "/new/test-studio" },
  { text: "Execution Reports", icon: <ReportsIcon />, path: "/new/reports" },
  { text: "Test Suites", icon: <TestSuiteIcon />, path: "/new/test-suites" },
  { text: "Analytics", icon: <VisiblityIcon />, path: "/new/analytics" },
];

export const ExeReportHistoryStatus = {
  PASS: 'Pass',
  STARTED:'STARTED',
  FAIL: 'Fail',
  ABEND: 'Abort',
  STOPP: 'Stop',
  ABORTED: 'ABORTED',
  STOP: 'Stop',
  STOPPED: 'Stop',
  INCOMPLETE: "INCOMPLETE"
}

export const TestSuiteStatusOptions = [
    { label: "All", value: "ALL" },
    { label: "Only Fail", value: "FAIL" },
    { label: "InComplete", value: "STARTED" },
    { label: "Both Failed and Incomplete", value: "BOTH" },
  ];

export const testReportPathStrings = [
    "/new/reports/history",
    "/new/reports",
];

export const dummyExeData = {
  "2": {
        "Test_Case_Results_Id": 77718,
        "Status": "PASS",
        "Failure_Screenshot_Location": "https://file.nogrunt.com/KeyLogging/ReactApp?action=downloadFile&token=83087910&fileName=_59_5927_232640_77718_Step0.png",
        "Step_Number": 1,
        "Action": "Get",
        "test_step_status": "PASS",
        "Keyword": "URL",
        "TestData": "https://qa2.enphaseenergy.com/",
        "Executed_Date": "2024-07-19 07:32:20.491",
        "screenshot1": "",
        "Executed_By": "",
        "Object_Xpath": "",
        "Duration": "0.93",
        "issues": "",
        "Flow": "Positive",
        "Page_Name": "",
        "idtest_step_result": 1304944,
        "Test_Step": 232640,
        "ValDevice": "On Screen",
        "test_step_duration": "0.93",
        "VarName": "",
        "Page_Description": ""
    },
    "3": {
        "Test_Case_Results_Id": 77718,
        "Status": "PASS",
        "Failure_Screenshot_Location": "https://file.nogrunt.com/KeyLogging/ReactApp?action=downloadFile&token=83087910&fileName=_59_5927_232643_77718_Step4_afterAction.png",
        "Step_Number": 4,
        "Action": "Enter",
        "test_step_status": "PASS",
        "Keyword": "EditBox",
        "TestData": "dvttestautomation1@yopmail.com",
        "Executed_Date": "2024-07-19 07:32:21.098",
        "screenshot1": "https://file.nogrunt.com/KeyLogging/ReactApp?action=downloadFile&token=83087910&fileName=_59_5927_232643_77718_Step4.png",
        "Executed_By": "",
        "Object_Xpath": "//*[@id=\"user_email\"] | HTML/BODY/DIV[4]/DIV/FORM/DIV/INPUT",
        "Duration": "0.44",
        "issues": "",
        "Flow": "Positive",
        "Page_Name": "",
        "idtest_step_result": 1304945,
        "Test_Step": 232643,
        "ValDevice": "On Screen",
        "test_step_duration": "0.44",
        "VarName": "",
        "Page_Description": "Email:"
    },
    "4": {
        "Test_Case_Results_Id": 77718,
        "Status": "PASS",
        "Failure_Screenshot_Location": "https://file.nogrunt.com/KeyLogging/ReactApp?action=downloadFile&token=83087910&fileName=_59_5927_232646_77718_Step8_afterAction.png",
        "Step_Number": 8,
        "Action": "Enter",
        "test_step_status": "PASS",
        "Keyword": "EditBox",
        "TestData": "********",
        "Executed_Date": "2024-07-19 07:32:21.646",
        "screenshot1": "https://file.nogrunt.com/KeyLogging/ReactApp?action=downloadFile&token=83087910&fileName=_59_5927_232646_77718_Step8.png",
        "Executed_By": "",
        "Object_Xpath": "//*[@id=\"user_password\"] | HTML/BODY/DIV[4]/DIV/FORM/DIV/INPUT[2]",
        "Duration": "0.36",
        "issues": "",
        "Flow": "Positive",
        "Page_Name": "",
        "idtest_step_result": 1304946,
        "Test_Step": 232646,
        "ValDevice": "On Screen",
        "test_step_duration": "0.36",
        "VarName": "",
        "Page_Description": "Email:"
    },
    "5": {
        "Test_Case_Results_Id": 77718,
        "Status": "PASS",
        "Failure_Screenshot_Location": "https://file.nogrunt.com/KeyLogging/ReactApp?action=downloadFile&token=83087910&fileName=_59_5927_232647_77718_Step17_afterAction.png",
        "Step_Number": 17,
        "Action": "Click",
        "test_step_status": "PASS",
        "Keyword": "Button",
        "TestData": "",
        "Executed_Date": "2024-07-19 07:32:24.523",
        "screenshot1": "https://file.nogrunt.com/KeyLogging/ReactApp?action=downloadFile&token=83087910&fileName=_59_5927_232647_77718_Step17.png",
        "Executed_By": "",
        "Object_Xpath": "//*[@id=\"submit\"] | HTML/BODY/DIV[4]/DIV/FORM/DIV/LABEL[3]/INPUT",
        "Duration": "2.71",
        "issues": "",
        "Flow": "Positive",
        "Page_Name": "",
        "idtest_step_result": 1304947,
        "Test_Step": 232647,
        "ValDevice": "On Screen",
        "test_step_duration": "2.71",
        "VarName": "",
        "Page_Description": "Sign In"
    }
  }

  export const dummyExeHistoryData = [
    {
        "Status": "PASS",
        "ModuleId": 586,
        "Executed_Date": "2024-07-19 07:32:18.048",
        "logfile": "https://file.nogrunt.com/KeyLogging/ReactApp?action=executionlog&token=83087910&companyid=59&tcid=5927&tcrid=77718",
        "Executed_By": "Deva",
        "Duration": 109.134,
        "envurl": null,
        "Test_Case_Id": 5927,
        "testcase": "C148340",
        "idtest_case_results": 77718,
        "Browser": "Chrome"
    },
    {
        "Status": "PASS",
        "ModuleId": 586,
        "Executed_Date": "2024-07-18 16:33:25.929",
        "logfile": "https://file.nogrunt.com/KeyLogging/ReactApp?action=executionlog&token=83087910&companyid=59&tcid=5927&tcrid=77578",
        "Executed_By": "ashitha@nogrunt.com",
        "Duration": 354.659,
        "envurl": null,
        "Test_Case_Id": 5927,
        "testcase": "C148340",
        "idtest_case_results": 77578,
        "Browser": "Chrome"
    },
    {
        "Status": "PASS",
        "ModuleId": 586,
        "Executed_Date": "2024-07-18 16:20:55.007",
        "logfile": "https://file.nogrunt.com/KeyLogging/ReactApp?action=executionlog&token=83087910&companyid=59&tcid=5927&tcrid=77577",
        "Executed_By": "ashitha@nogrunt.com",
        "Duration": 78.711,
        "envurl": null,
        "Test_Case_Id": 5927,
        "testcase": "C148340",
        "idtest_case_results": 77577,
        "Browser": "Chrome"
    },
    {
        "Status": "STOPP",
        "ModuleId": 586,
        "Executed_Date": "2024-07-18 16:19:47.869",
        "logfile": "https://file.nogrunt.com/KeyLogging/ReactApp?action=executionlog&token=83087910&companyid=59&tcid=5927&tcrid=77576",
        "Executed_By": "ashitha@nogrunt.com",
        "Duration": 181.665,
        "envurl": null,
        "Test_Case_Id": 5927,
        "testcase": "C148340",
        "idtest_case_results": 77576,
        "Browser": "Chrome"
    },
    {
        "Status": "ABEND",
        "ModuleId": 586,
        "Executed_Date": "2024-07-18 16:09:55.992",
        "logfile": "https://file.nogrunt.com/KeyLogging/ReactApp?action=executionlog&token=83087910&companyid=59&tcid=5927&tcrid=77575",
        "Executed_By": "Deva",
        "Duration": 401.437,
        "envurl": null,
        "Test_Case_Id": 5927,
        "testcase": "Analysis",
        "idtest_case_results": 77575,
        "Browser": "Chrome"
    },
    {
        "Status": "ABEND",
        "ModuleId": 586,
        "Executed_Date": "2024-07-18 15:54:21.698",
        "logfile": "https://file.nogrunt.com/KeyLogging/ReactApp?action=executionlog&token=83087910&companyid=59&tcid=5927&tcrid=77574",
        "Executed_By": "Deva",
        "Duration": 228.67,
        "envurl": null,
        "Test_Case_Id": 5927,
        "testcase": "Analysis",
        "idtest_case_results": 77574,
        "Browser": "Chrome"
    },
    {
        "Status": "FAIL",
        "ModuleId": 586,
        "Executed_Date": "2024-07-18 15:31:25.252",
        "logfile": "https://file.nogrunt.com/KeyLogging/ReactApp?action=executionlog&token=83087910&companyid=59&tcid=5927&tcrid=77573",
        "Executed_By": "Deva",
        "Duration": 855.811,
        "envurl": null,
        "Test_Case_Id": 5927,
        "testcase": "Analysis",
        "idtest_case_results": 77573,
        "Browser": "Chrome"
    },
    {
        "Status": "PASS",
        "ModuleId": 586,
        "Executed_Date": "2024-07-10 12:28:28.071",
        "logfile": "https://file.nogrunt.com/KeyLogging/ReactApp?action=executionlog&token=83087910&companyid=59&tcid=5927&tcrid=77045",
        "Executed_By": "ashitha@nogrunt.com",
        "Duration": 102.623,
        "envurl": null,
        "Test_Case_Id": 5927,
        "testcase": "C148340",
        "idtest_case_results": 77045,
        "Browser": "Chrome"
    },
    {
        "Status": "PASS",
        "ModuleId": 586,
        "Executed_Date": "2024-07-10 09:48:38.386",
        "logfile": "https://file.nogrunt.com/KeyLogging/ReactApp?action=executionlog&token=83087910&companyid=59&tcid=5927&tcrid=77012",
        "Executed_By": "ashitha@nogrunt.com",
        "Duration": 87.852,
        "envurl": null,
        "Test_Case_Id": 5927,
        "testcase": "C148340 - 3",
        "idtest_case_results": 77012,
        "Browser": null
    },
    {
        "Status": "FAIL",
        "ModuleId": 586,
        "Executed_Date": "2024-07-10 09:35:13.288",
        "logfile": "https://file.nogrunt.com/KeyLogging/ReactApp?action=executionlog&token=83087910&companyid=59&tcid=5927&tcrid=76995",
        "Executed_By": "ashitha@nogrunt.com",
        "Duration": 17.489,
        "envurl": null,
        "Test_Case_Id": 5927,
        "testcase": "C148340 - 3",
        "idtest_case_results": 76995,
        "Browser": "Chrome"
    },
    {
        "Status": "PASS",
        "ModuleId": 586,
        "Executed_Date": "2024-07-09 19:24:12.351",
        "logfile": "https://file.nogrunt.com/KeyLogging/ReactApp?action=executionlog&token=83087910&companyid=59&tcid=5927&tcrid=76849",
        "Executed_By": "kishore",
        "Duration": 150.423,
        "envurl": null,
        "Test_Case_Id": 5927,
        "testcase": "C148340 - 3",
        "idtest_case_results": 76849,
        "Browser": null
    },
    {
        "Status": "FAIL",
        "ModuleId": 586,
        "Executed_Date": "2024-07-09 19:06:53.596",
        "logfile": "https://file.nogrunt.com/KeyLogging/ReactApp?action=executionlog&token=83087910&companyid=59&tcid=5927&tcrid=76832",
        "Executed_By": "kishore",
        "Duration": 18.51,
        "envurl": null,
        "Test_Case_Id": 5927,
        "testcase": "C148340 - 3",
        "idtest_case_results": 76832,
        "Browser": "Chrome"
    },
    {
        "Status": "FAIL",
        "ModuleId": 586,
        "Executed_Date": "2024-07-09 18:43:07.077",
        "logfile": "https://file.nogrunt.com/KeyLogging/ReactApp?action=executionlog&token=83087910&companyid=59&tcid=5927&tcrid=76824",
        "Executed_By": "kishore",
        "Duration": 49.02,
        "envurl": null,
        "Test_Case_Id": 5927,
        "testcase": "C148340 - 3",
        "idtest_case_results": 76824,
        "Browser": null
    },
    {
        "Status": "FAIL",
        "ModuleId": 586,
        "Executed_Date": "2024-07-09 18:23:27.224",
        "logfile": "https://file.nogrunt.com/KeyLogging/ReactApp?action=executionlog&token=83087910&companyid=59&tcid=5927&tcrid=76808",
        "Executed_By": "kishore",
        "Duration": 18.543,
        "envurl": null,
        "Test_Case_Id": 5927,
        "testcase": "C148340 - 3",
        "idtest_case_results": 76808,
        "Browser": "Chrome"
    },
    {
        "Status": "PASS",
        "ModuleId": 586,
        "Executed_Date": "2024-07-09 17:57:06.411",
        "logfile": "https://file.nogrunt.com/KeyLogging/ReactApp?action=executionlog&token=83087910&companyid=59&tcid=5927&tcrid=76790",
        "Executed_By": "kishore",
        "Duration": 135.39,
        "envurl": null,
        "Test_Case_Id": 5927,
        "testcase": "C148340 - 3",
        "idtest_case_results": 76790,
        "Browser": null
    },
    {
        "Status": "FAIL",
        "ModuleId": 586,
        "Executed_Date": "2024-07-09 16:39:55.228",
        "logfile": "https://file.nogrunt.com/KeyLogging/ReactApp?action=executionlog&token=83087910&companyid=59&tcid=5927&tcrid=76757",
        "Executed_By": "kishore",
        "Duration": 75.569,
        "envurl": null,
        "Test_Case_Id": 5927,
        "testcase": "C148340 - 3",
        "idtest_case_results": 76757,
        "Browser": null
    },
    {
        "Status": "FAIL",
        "ModuleId": 586,
        "Executed_Date": "2024-07-09 16:20:52.122",
        "logfile": "https://file.nogrunt.com/KeyLogging/ReactApp?action=executionlog&token=83087910&companyid=59&tcid=5927&tcrid=76742",
        "Executed_By": "kishore",
        "Duration": 79.798,
        "envurl": null,
        "Test_Case_Id": 5927,
        "testcase": "C148340 - 3",
        "idtest_case_results": 76742,
        "Browser": null
    },
    {
        "Status": "FAIL",
        "ModuleId": 586,
        "Executed_Date": "2024-07-09 15:38:15.17",
        "logfile": "https://file.nogrunt.com/KeyLogging/ReactApp?action=executionlog&token=83087910&companyid=59&tcid=5927&tcrid=76725",
        "Executed_By": "kishore",
        "Duration": 17.872,
        "envurl": null,
        "Test_Case_Id": 5927,
        "testcase": "C148340 - 3",
        "idtest_case_results": 76725,
        "Browser": "Chrome"
    },
    {
        "Status": "PASS",
        "ModuleId": 586,
        "Executed_Date": "2024-07-09 12:31:00.711",
        "logfile": "https://file.nogrunt.com/KeyLogging/ReactApp?action=executionlog&token=83087910&companyid=59&tcid=5927&tcrid=76684",
        "Executed_By": "ashitha@nogrunt.com",
        "Duration": 121.374,
        "envurl": null,
        "Test_Case_Id": 5927,
        "testcase": "C148340 - 3",
        "idtest_case_results": 76684,
        "Browser": "Chrome"
    },
    {
        "Status": "FAIL",
        "ModuleId": 586,
        "Executed_Date": "2024-07-09 12:12:11.893",
        "logfile": "https://file.nogrunt.com/KeyLogging/ReactApp?action=executionlog&token=83087910&companyid=59&tcid=5927&tcrid=76668",
        "Executed_By": "kishore",
        "Duration": 69.519,
        "envurl": null,
        "Test_Case_Id": 5927,
        "testcase": "C148340 - 3",
        "idtest_case_results": 76668,
        "Browser": null
    },
    {
        "Status": "PASS",
        "ModuleId": 586,
        "Executed_Date": "2024-07-09 10:28:55.511",
        "logfile": "https://file.nogrunt.com/KeyLogging/ReactApp?action=executionlog&token=83087910&companyid=59&tcid=5927&tcrid=76666",
        "Executed_By": "ashitha@nogrunt.com",
        "Duration": 120.751,
        "envurl": null,
        "Test_Case_Id": 5927,
        "testcase": "C148340 - 3",
        "idtest_case_results": 76666,
        "Browser": "Chrome"
    },
    {
        "Status": "PASS",
        "ModuleId": 586,
        "Executed_Date": "2024-07-09 10:22:29.011",
        "logfile": "https://file.nogrunt.com/KeyLogging/ReactApp?action=executionlog&token=83087910&companyid=59&tcid=5927&tcrid=76665",
        "Executed_By": "ashitha@nogrunt.com",
        "Duration": 117.091,
        "envurl": null,
        "Test_Case_Id": 5927,
        "testcase": "C148340 - 3",
        "idtest_case_results": 76665,
        "Browser": "Chrome"
    },
    {
        "Status": "FAIL",
        "ModuleId": 586,
        "Executed_Date": "2024-07-09 10:18:14.793",
        "logfile": "https://file.nogrunt.com/KeyLogging/ReactApp?action=executionlog&token=83087910&companyid=59&tcid=5927&tcrid=76664",
        "Executed_By": "ashitha@nogrunt.com",
        "Duration": 131.355,
        "envurl": null,
        "Test_Case_Id": 5927,
        "testcase": "C148340 - 3",
        "idtest_case_results": 76664,
        "Browser": "Chrome"
    },
    {
        "Status": "FAIL",
        "ModuleId": 586,
        "Executed_Date": "2024-07-09 10:13:39.919",
        "logfile": "https://file.nogrunt.com/KeyLogging/ReactApp?action=executionlog&token=83087910&companyid=59&tcid=5927&tcrid=76663",
        "Executed_By": "ashitha@nogrunt.com",
        "Duration": 95.754,
        "envurl": null,
        "Test_Case_Id": 5927,
        "testcase": "C148340 - 3",
        "idtest_case_results": 76663,
        "Browser": "Chrome"
    },
    {
        "Status": "FAIL",
        "ModuleId": 586,
        "Executed_Date": "2024-07-09 10:11:01.382",
        "logfile": "https://file.nogrunt.com/KeyLogging/ReactApp?action=executionlog&token=83087910&companyid=59&tcid=5927&tcrid=76662",
        "Executed_By": "ashitha@nogrunt.com",
        "Duration": 102.611,
        "envurl": null,
        "Test_Case_Id": 5927,
        "testcase": "C148340 - 3",
        "idtest_case_results": 76662,
        "Browser": "Chrome"
    },
    {
        "Status": "FAIL",
        "ModuleId": 586,
        "Executed_Date": "2024-07-09 10:06:42.254",
        "logfile": "https://file.nogrunt.com/KeyLogging/ReactApp?action=executionlog&token=83087910&companyid=59&tcid=5927&tcrid=76661",
        "Executed_By": "ashitha@nogrunt.com",
        "Duration": 90.276,
        "envurl": null,
        "Test_Case_Id": 5927,
        "testcase": "C148340 - 3",
        "idtest_case_results": 76661,
        "Browser": "Chrome"
    },
    {
        "Status": "FAIL",
        "ModuleId": 586,
        "Executed_Date": "2024-07-09 10:01:40.269",
        "logfile": "https://file.nogrunt.com/KeyLogging/ReactApp?action=executionlog&token=83087910&companyid=59&tcid=5927&tcrid=76660",
        "Executed_By": "ashitha@nogrunt.com",
        "Duration": 88.209,
        "envurl": null,
        "Test_Case_Id": 5927,
        "testcase": "C148340 - 3",
        "idtest_case_results": 76660,
        "Browser": "Chrome"
    },
    {
        "Status": "PASS",
        "ModuleId": 586,
        "Executed_Date": "2024-07-08 20:19:16.528",
        "logfile": "https://file.nogrunt.com/KeyLogging/ReactApp?action=executionlog&token=83087910&companyid=59&tcid=5927&tcrid=76590",
        "Executed_By": "ashitha@nogrunt.com",
        "Duration": 36.146,
        "envurl": null,
        "Test_Case_Id": 5927,
        "testcase": "C148340 - 3",
        "idtest_case_results": 76590,
        "Browser": "Chrome"
    },
    {
        "Status": "FAIL",
        "ModuleId": 586,
        "Executed_Date": "2024-07-08 20:10:08.158",
        "logfile": "https://file.nogrunt.com/KeyLogging/ReactApp?action=executionlog&token=83087910&companyid=59&tcid=5927&tcrid=76588",
        "Executed_By": "ashitha@nogrunt.com",
        "Duration": 58.879,
        "envurl": null,
        "Test_Case_Id": 5927,
        "testcase": "C148340 - 3",
        "idtest_case_results": 76588,
        "Browser": "Chrome"
    },
    {
        "Status": "FAIL",
        "ModuleId": 586,
        "Executed_Date": "2024-07-08 20:08:02.288",
        "logfile": "https://file.nogrunt.com/KeyLogging/ReactApp?action=executionlog&token=83087910&companyid=59&tcid=5927&tcrid=76587",
        "Executed_By": "ashitha@nogrunt.com",
        "Duration": 59.779,
        "envurl": null,
        "Test_Case_Id": 5927,
        "testcase": "C148340 - 3",
        "idtest_case_results": 76587,
        "Browser": "Chrome"
    },
    {
        "Status": "FAIL",
        "ModuleId": 586,
        "Executed_Date": "2024-07-08 20:01:40.869",
        "logfile": "https://file.nogrunt.com/KeyLogging/ReactApp?action=executionlog&token=83087910&companyid=59&tcid=5927&tcrid=76586",
        "Executed_By": "ashitha@nogrunt.com",
        "Duration": 50.803,
        "envurl": null,
        "Test_Case_Id": 5927,
        "testcase": "C148340 - 3",
        "idtest_case_results": 76586,
        "Browser": "Chrome"
    }
]