import { Box, MenuItem, Select, styled, Typography } from "@mui/material";
import GaugeComponent from 'react-gauge-component'
import { useFlakinessIndicatorChartQuery, useProductQuery, useTestSuiteQuery } from "../../useQuery";
import { useEffect, useState } from "react";

const Wrapper = styled(Box)`
    display: flex;
    padding: 30px;
    flex-direction: column;
    gap: 26px;
    border-radius: 8px;
    background: var(--surface-bg, #FFF);
    box-shadow: 0px -3px 17.1px 0px rgba(0, 0, 0, 0.07);
    flex: 1;
`

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: "300px",
        },
    },
};

const StyledSelect = styled(Select)`
    border-radius: 5px;
    border: 1px solid #d1dbff;
    font-size: 16px;
    color: #2b3539;
    width: 100%;
    max-height: 300px;
    background-color: #ffffff;
    .MuiSelect-select {
      padding: 13px 15px;
    }
  `;

const ExecutionGuageChart = () => {
    const [selectedProduct, setSelectedProduct] = useState('')
    const [testSuiteData, setTestSuiteData] = useState([]);
    const [selectedTestSuite, setSelectedTestSuite] = useState(null)

    const { data: products } = useProductQuery();

    const {
        data: testSuites,
        isSuccess: isSuccessTestSuiteList,
    } = useTestSuiteQuery(selectedProduct, {
        enabled: selectedProduct !== "",
    });

    const { data } = useFlakinessIndicatorChartQuery(selectedTestSuite, { enabled: selectedTestSuite !== null })

    useEffect(() => {
        if (selectedProduct && isSuccessTestSuiteList) {
            let flattened = testSuites.flat();
            setTestSuiteData(flattened);
        }
    }, [isSuccessTestSuiteList, selectedProduct, selectedTestSuite, testSuites]);

    const handleProductChange = (e) => {
        setSelectedTestSuite(null)
        setSelectedProduct(e.target.value)
    }
    return (
        <Wrapper>
            <Typography fontSize={'18px'} fontWeight={600} color={'#2b3529'}>Flakiness Indicator (User Scenarios Test Suite) </Typography>
            <Box display={"flex"} columnGap={5}>
                <Box display={'flex'} flexDirection={'column'} rowGap={2} flex={1}>
                    <Box>
                        <Box>
                            <Typography color="#8089A8" fontSize="13px" fontWeight={500}>
                                Application
                            </Typography>
                        </Box>
                        <StyledSelect
                            MenuProps={MenuProps}
                            value={selectedProduct}
                            onChange={handleProductChange}
                            displayEmpty
                        >
                            <MenuItem disabled value="">
                                Application
                            </MenuItem>
                            {products &&
                                products.length > 0 &&
                                (products ?? [{ label: "", value: "" }]).map((product) => (
                                    <MenuItem value={product.idproducts} key={product.idproducts}>
                                        {product.productname}
                                    </MenuItem>
                                ))}
                        </StyledSelect>
                    </Box>
                    <Box>
                        <Box>
                            <Typography color="#8089A8" fontSize="13px" fontWeight={500}>
                                Test Suite
                            </Typography>
                        </Box>
                        <StyledSelect
                            MenuProps={MenuProps}
                            value={selectedTestSuite}
                            onChange={(event) => {
                                setSelectedTestSuite(event.target.value);
                            }}
                            disabled={!testSuites}
                        >
                            <MenuItem disabled value="">
                                Test Suite
                            </MenuItem>
                            {testSuiteData &&
                                testSuiteData.length > 0 &&
                                testSuiteData.map((testSuite) => (
                                    <MenuItem
                                        key={`${testSuite.Test_Suite}-${testSuite.idtest_suite}`}
                                        value={testSuite.idtest_suite}
                                    >
                                        {testSuite.Test_Suite}
                                    </MenuItem>
                                ))}
                        </StyledSelect>
                    </Box>
                    {/* <Box><ResetBtn variant="text">Reset</ResetBtn></Box> */}
                </Box>
                <Box flex={1} display={'flex'} alignItems={'center'} justifyItems={'center'}>
                    <GaugeComponent
                        value={data?.flakiness || 0}
                        type="radial"
                        labels={{
                            valueLabel: {
                                style: {
                                    fill: '#1D4ED8',
                                    textShadow: 'none',
                                    fontSize: '35px',
                                    fontWeight: '700'
                                }
                            },
                            tickLabels: {
                                defaultTickValueConfig: {
                                    style: {
                                        fill: '#8089A8',
                                        textShadow: 'none',
                                        fontWeight: '800'
                                    }
                                },
                            }
                        }}
                        arc={{
                            subArcs: [
                                { limit: 10, color: '#5BE12C', showTick: true }, 
                                { limit: 20, color: '#F5CD19', showTick: true }, 
                                { limit: 30, color: '#EA4228', showTick: true }, 
                                { limit: 40, color: '#EA4228', showTick: true }, 
                                { limit: 50, color: '#EA4228', showTick: true }, 
                                { limit: 60, color: '#EA4228', showTick: true }, 
                                { limit: 70, color: '#EA4228', showTick: true }, 
                                { limit: 80, color: '#EA4228', showTick: true },
                                { limit: 90, color: '#EA4228', showTick: true }, 
                                { limit: 100, color: '#EA4228', showTick: true } 
                            ],
                            padding: 0.02,
                            width: 0.3
                        }}
                        pointer={{
                            elastic: true,
                            animationDelay: 0
                        }}
                    />
                </Box>
            </Box>
        </Wrapper>)
}

export default ExecutionGuageChart;