import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  css,
  Link,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { ExeReportHistoryStatus, TestSuiteStatusOptions } from "../../components-new/layout/constants";
import { CustomSelect, IconContainer } from "../../components-new/common";
import { CurrentExecutionIcon, DownloadIcon, HistoryIcon } from "../../components-new/icons";
import { useDownloadTestSuiteQuery, useStopTestSuiteQuery } from "../../useQuery";
import TestSuiteExecuteDialog from "./TestSuiteExecuteDialog";
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import CustomDialog from "../../components-new/common/CustomDialog";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)`
    color: #232D3D;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid #E2E6F3;
`;

const StyledTableBodyCell = styled(TableCell)`
    padding: 8px 20px;
    border-bottom: 1px solid #eee;
    p {
        color: #2B3539;
        font-size: 16px;
        font-weight: 500;
        padding-right: 15px;
        min-width: 60px;
    }
`;

const typeStyles = {
    PASS: css`
    border-radius: 41px;
    border: 0px solid #30ca17;
    background: #e7fbe4;
  `,
    FAIL: css`
    border-radius: 41px;
    border: 0px solid #e15151;
    background: #fbe7e7;
  `,
    STOP: css`
  border-radius: 41px;
  border: 0px solid #e15151;
  background: #fbe7e7;
`,
    STOPP: css`
  border-radius: 41px;
  border: 0px solid #e15151;
  background: #fbe7e7;
`,
    STOPPED: css`
border-radius: 41px;
border: 0px solid #e15151;
background: #fbe7e7;
`,
    ABORTED: css`
border-radius: 41px;
border: 0px solid #e15151;
background: #fbe7e7;
`,
    INCOMPLETE: css`
border-radius: 41px;
border: 0px solid #e15151;
background: #fbe7e7;
`,
};

const StatusPill = styled(Chip)`
  min-width: 82px;
  padding: 8px 10px;
  gap: 10px;
  ${(props) => typeStyles[props.type]}
  span {
    text-transform: capitalize;
  }
`;

const ExecutionReportsTestSuiteTable = ({ data, isSuccess, testSuiteName }) => {
    const navigate = useNavigate();
    const [statusType, setStatusType] = useState({});
    const [testSuiteId, setTestSuiteId] = useState('');
    const [enableDownload, setEnableDownload] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('ALL');
    const [selectedItemForExecute, setSelectedItemForExecute] = useState(null);
    const [openStopDialog, setOpenStopDialog] = useState(false);  // State for stop execution dialog

    const selectedTestSuite = useSelector((state) => state.executionSlice.selectedTestSuite);
    const productId = useSelector((state) => state.executionSlice.selectedProduct);
    const { refetch: refetchDownloadTestSuite } = useDownloadTestSuiteQuery(testSuiteId);
    const {refetch: handleStopTSExecution, isSuccess: isStopExecutionSuccess} = useStopTestSuiteQuery(selectedTestSuite, 'suite')
    
    useEffect(() => {
        if (enableDownload && testSuiteId) refetchDownloadTestSuite();
        setTestSuiteId('');
    }, [enableDownload, refetchDownloadTestSuite, testSuiteId]);

    const handleTestCaseHistory = (item, criteriaData) => {
        setTestSuiteId(item.idtest_suite_results);
        const url = `/new/reports/history?tsId=${item.idtest_suite_results}&criteria=${criteriaData}&name=${testSuiteName}&date=${item.Executed_Date}`;
        window.open(url, '_blank');  // Open the link in a new tab
    };

    const handleOpenDialog = (item) => {
        setSelectedItemForExecute(item);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleStopExecution = (item) => {
        setSelectedItemForExecute(item);
        setOpenStopDialog(true);  // Open the stop execution dialog
    };

    const handleConfirmStopExecution = () => {
        // Add logic to stop execution
        handleStopTSExecution()
        setOpenStopDialog(false);
        navigate("/new/reports", { state: { isExecuteClick: true,selectedSuit: selectedTestSuite, product: productId, fetchTestSuite: true } });
    };

    const handleCancelStopExecution = () => {
        setOpenStopDialog(false);
    };

    return (
        <Box flex={1}>
            {isSuccess && <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Run Date</StyledTableCell>
                            <StyledTableCell>Run By</StyledTableCell>
                            <StyledTableCell>Run Duration</StyledTableCell>
                            <StyledTableCell>Log File</StyledTableCell>
                            <StyledTableCell>Status</StyledTableCell>
                            <StyledTableCell>Total/Pass/Fail Count</StyledTableCell>
                            <StyledTableCell>Status Type</StyledTableCell>
                            <StyledTableCell>Actions</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.length ?
                            data.map((item, index) => (
                                <TableRow key={index}>
                                    <StyledTableBodyCell>{item.Executed_Date}</StyledTableBodyCell>
                                    <StyledTableBodyCell>{item.Executed_By}</StyledTableBodyCell>
                                    <StyledTableBodyCell>{item.Duration}</StyledTableBodyCell>
                                    <StyledTableBodyCell><Link target="_blank" href={item.logfile}>Log File</Link></StyledTableBodyCell>
                                    <StyledTableBodyCell><StatusPill label={ExeReportHistoryStatus[item.Status]} type={item.Status} /></StyledTableBodyCell>
                                    <StyledTableBodyCell>{item.totalCount}/{item.passCount}/{item.failCount}</StyledTableBodyCell>
                                    <StyledTableBodyCell>
                                        <CustomSelect
                                            options={TestSuiteStatusOptions}
                                            width={"100%"}
                                            background={"#ffffff"}
                                            value={statusType[index] || 'ALL'}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                setStatusType(((prev) => ({ ...prev, [index]: value })));
                                                setSelectedStatus(value);
                                            }}
                                        />
                                    </StyledTableBodyCell>
                                    <StyledTableBodyCell>
                                        <Box display={'flex'} columnGap={1}>
                                            {(item.Status !== "STARTED" || item.Status !== 'RUNNING') && item.testSuiteId !== '' ?
                                                (<IconContainer tooltipText={"Execute"} handleClick={() => handleOpenDialog(item)}><CurrentExecutionIcon /></IconContainer>) :
                                                (<IconContainer tooltipText={"Stop"} handleClick={() => handleStopExecution(item)}><DoNotDisturbAltIcon /></IconContainer>)}
                                            <IconContainer tooltipText={"Download Test suite"} handleClick={() => { setTestSuiteId(item.idtest_suite_results); setEnableDownload(true); }}><DownloadIcon /></IconContainer>
                                            <IconContainer tooltipText={"View Testcase History"}><Link target="_blank" sx={{ cursor: 'pointer' }} onClick={(e) => handleTestCaseHistory(item, statusType[index] || 'ALL')}><HistoryIcon /></Link></IconContainer>
                                        </Box>
                                    </StyledTableBodyCell>
                                </TableRow>
                            )) : <TableRow> <StyledTableBodyCell align="center" colSpan={8}>No data available</StyledTableBodyCell></TableRow>}
                    </TableBody>
                </Table>
            </TableContainer>}
            {selectedItemForExecute && openDialog && <TestSuiteExecuteDialog item={selectedItemForExecute} openDialog={openDialog} handleCloseDialog={handleCloseDialog} statusType={selectedStatus} />}
            <CustomDialog
                open={openStopDialog}
                onClose={handleCancelStopExecution}
                title="Stop Execution"
                content={<Typography>Are you sure you want to stop execution of this Suite?</Typography>}
                actions={
                    <>
                        <Button onClick={handleConfirmStopExecution} >Yes</Button>
                        <Button onClick={handleCancelStopExecution} >No</Button>
                    </>
                }
            />
        </Box>
    );
};

export default ExecutionReportsTestSuiteTable;
