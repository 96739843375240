import React, { useState } from "react";
import "./FileSelect.style.scss";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { Paper, Typography, TextField } from "@mui/material";

import { API_BASE_PATH, API_BASE_RELATIVE_PATH } from "./../../Config/Config";
import FormControl from "@mui/material/FormControl";
import Search from "../Search/Search.component";

import {
  ColorButton,
  StyledTableRow,
  StyledTableCell,
} from "./../common/common.Component";

const FileSelect = () => {
  const [state, setState] = useState({
    file: "",
    xls: [],
    tcs: {},
  });

  const userDetails = localStorage.getItem("userDetail");    
  const temp = JSON.parse(userDetails);
  const [SearchString, setSearchString] = useState();

  const handleUploadClick = () => {
    if (!state.file) {
      return;
    }
    let apicall = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=file&name=${state.file.name}&companyid=${temp.companyid}&token=${temp.token}`;
    fetch(apicall, {
      method: "POST",
      body: state.file,
      headers: {
        "content-type": state.file.type,
        "content-length": `${state.file.size}`, // 👈 Headers need to be a string
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setState((prev) => {
          return {
            ...prev,
            xls: [...Object.values(data.data)],
          };
        });
      })
      .catch((err) => console.error(err));
  };

  const handleFileChange = (e) => {
    if (e.target.files) {
      setState({
        file: e.target.files[0],
      });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        alignItem: "center",
        justifyContent: "space-evenly",
        paddingTop: "2rem",
        paddingX: "2rem",
      }}
    >
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell colSpan={10}>
                <Box
                  sx={{
                    display: "flex",
                    ml: "5rem",
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                    paddingY: "1.5rem",
                  }}
                >
                  <Box></Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      // backgroundColor: "white",
                      // color: "black",
                    }}
                  >
                    <Typography paddingBottom={1} variant="h6" component="h2">
                      File Upload
                    </Typography>
                    <FormControl variant="standard">
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <TextField
                          type="file"
                          id="input-file-upload"
                          onChange={handleFileChange}
                          variant="standard"
                          // color="inherit"
                        />
                        <ColorButton size='small'
                          component="label"
                          onClick={handleUploadClick}
                          sx={{ marginLeft: 4 }}
                        >
                          Upload
                        </ColorButton>
                      </Box>
                    </FormControl>
                  </Box>
                  <Search
                    SearchString={SearchString}
                    onChange={setSearchString}
                  />
                </Box>
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell
                sx={{ color: "white", backgroundColor: "black" }}
              >
                Page Element
              </StyledTableCell>
              <StyledTableCell
                sx={{ color: "white", backgroundColor: "black" }}
                align="right"
              >
                Keyword
              </StyledTableCell>
              <StyledTableCell
                sx={{ color: "white", backgroundColor: "black" }}
                align="right"
              >
                Action
              </StyledTableCell>
              <StyledTableCell
                sx={{ color: "white", backgroundColor: "black" }}
                align="right"
              >
                Flow
              </StyledTableCell>
              <StyledTableCell
                sx={{ color: "white", backgroundColor: "black" }}
                align="right"
              >
                TestData
              </StyledTableCell>
              <StyledTableCell
                sx={{ color: "white", backgroundColor: "black" }}
                align="right"
              >
                Variable
              </StyledTableCell>
              <StyledTableCell
                sx={{ color: "white", backgroundColor: "black" }}
                align="right"
              >
                Screenshot
              </StyledTableCell>
              <StyledTableCell
                sx={{ color: "white", backgroundColor: "black" }}
                align="right"
              >
                Validate In
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {state?.xls?.map((row) => (
              <StyledTableRow
                key={row.action}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <StyledTableCell component="th" scope="row">
                  {row.Page_Description}
                </StyledTableCell>
                <StyledTableCell align="right">{row.Keyword}</StyledTableCell>
                <StyledTableCell align="right">{row.Action}</StyledTableCell>
                <StyledTableCell align="right">{row.Flow}</StyledTableCell>
                <StyledTableCell align="right">{row.TestData}</StyledTableCell>
                <StyledTableCell align="right">{row.VarName}</StyledTableCell>
                <StyledTableCell align="right">{row.Status}</StyledTableCell>
                <StyledTableCell align="right">
                  {row.Failure_Screenshot_Location}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row.Duration} (s)
                </StyledTableCell>
                <StyledTableCell align="right">{row.ValDevice}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default FileSelect;
