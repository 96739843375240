import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { CircleTickIcon, EditIcon, CircleWarningIcon } from "../icons";
import ImageNotSupportedOutlinedIcon from "@mui/icons-material/ImageNotSupportedOutlined";
import { useSelector } from "react-redux";
import { EllipsisTooltipText } from ".";
import { AssertionTargetOptions, TestDataOptions } from "../../pages-new/Studio/constants";

const ActionButton = styled(Box)`
  width: 40px;
  height: 40px;
  border-radius: 88px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const styles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const getSource = (options, value) => {
  return options.find((option) => option.value === value)?.label || "-";
};

const TestItemCard = ({ item, handleEdit, index, width }) => {
  const tsrData = useSelector((state) => state.authoringSlice.tsrData);
  const screenshotStyle = tsrData && tsrData[index]?.screenshot1 ? {} : styles;

  return (
    <Box
      flex={1}
      minWidth={`${width}px`}
      maxWidth={`${width}px`}
      gap={"12px"}
      alignItems={"flex-start"}
      padding={"20px"}
      display={"flex"}
      borderRadius={"6px"}
      bgcolor={"#ffffff"}
      boxShadow={"0px 43px 62.4px 0px rgba(0, 0, 0, 0.09)"}
      flexDirection={"column"}
    >
      {tsrData && (
        <Box
          borderRadius={"4px"}
          height={200}
          width={"100%"}
          border={"1px solid rgba(0, 0, 0, 0.30)"}
          sx={screenshotStyle}
        >
          {tsrData[index]?.screenshot1 ? (
            <img
              style={{ height: "100%", width: "100%" }}
              src={tsrData[index].screenshot1 || `https://via.placeholder.com/900?text=No+Image`}
              alt="testImage"
            />
          ) : (
            <ImageNotSupportedOutlinedIcon sx={{ color: "#8089A8" }} fontSize="large" />
          )}
        </Box>
      )}
      <Box width={"100%"} display={"flex"} justifyContent="space-between" alignItems={"center"}>
        <Box display={"flex"} columnGap={"8px"}>
          <Typography>{item.Step_Number}</Typography>
          {item.Issues ? <CircleWarningIcon /> : <CircleTickIcon />}
        </Box>
        <Box display={"flex"} columnGap={"15px"}>
          <ActionButton border={"2px solid #547de8"} onClick={() => handleEdit(item)}>
            <EditIcon />
          </ActionButton>
          {/* <ActionButton border={"2px solid #D7DBE8"}>
            <ContextIcon />
          </ActionButton> */}
        </Box>
      </Box>
      <Box
        width={"100%"}
        rowGap={2}
        columnGap={2}
        display={"flex"}
        flexWrap={"wrap"}
        justifyContent={"space-between"}
      >
        <Box>
          <Typography fontWeight={"bold"}>Keyword</Typography>
          <Typography>{item.Keyword ? item.Keyword : "-"}</Typography>
        </Box>
        <Box>
          <Typography fontWeight={"bold"}>Action</Typography>
          <Typography>{item.Action}</Typography>
        </Box>
      </Box>
      {item.Keyword !== "Assertion" && item.Keyword !== "Validation" ? (
        <Box>
          <Box flex={1}>
            <Typography fontWeight={"bold"}>Source</Typography>
            <EllipsisTooltipText
              text={
                item.testdata_source
                  ? getSource([...AssertionTargetOptions, ...TestDataOptions], item.testdata_source)
                  : "-"
              }
              maxChars={50}
              mode="chars"
            />
          </Box>
        </Box>
      ) : (
        <Box>
          <Box flex={1}>
            <Typography fontWeight={"bold"}>Source</Typography>
            <EllipsisTooltipText
              text={
                item.ValDevice
                  ? getSource([...AssertionTargetOptions, ...TestDataOptions], item.ValDevice)
                  : "-"
              }
              maxChars={50}
              mode="chars"
            />
          </Box>
        </Box>
      )}
      <Box>
        <Box flex={1}>
          <Typography fontWeight={"bold"}>Test Data</Typography>
          <EllipsisTooltipText
            text={item.TestData ? item.TestData : "-"}
            maxChars={50}
            mode="chars"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TestItemCard;
