import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

const fetchTestSuite = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found");
  const user = JSON.parse(userDetails);

  const response = await axios.get(
    `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getTestSuites&companyid=${user.companyid}&token=${user.token}&productid=${id}`,
  );

  return response.data;
};

const useTestSuiteQuery = (id, options) => {
  return useQuery({
    queryKey: ["testSuite", id],
    queryFn: () => fetchTestSuite(id),
    enabled: options.enabled,
    retry: true,
    cacheTime: 90000,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    staleTime: Infinity,
  });
};

export default useTestSuiteQuery;
