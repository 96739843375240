// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  /* Track */
  /* Handle */
  /* Handle on hover */
}
*::-webkit-scrollbar {
  width: 0.3rem;
  display: none;
}
*::-webkit-scrollbar-track {
  background: #d9d9d9;
}
*::-webkit-scrollbar-thumb {
  background: black;
}
*::-webkit-scrollbar-thumb:hover {
  background: black;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/App.style.scss"],"names":[],"mappings":"AAAA;EAMM,UAAA;EAKA,WAAA;EAKA,oBAAA;AAZN;AAHI;EACI,aAAA;EACA,aAAA;AAKR;AADM;EACE,mBAAA;AAGR;AACM;EACE,iBAAA;AACR;AAGM;EACE,iBAAA;EACA,eAAA;AADR","sourcesContent":["*{\n    &::-webkit-scrollbar {\n        width: 0.3rem;\n        display: none;\n      }\n  \n      /* Track */\n      &::-webkit-scrollbar-track {\n        background: #d9d9d9;\n      }\n  \n      /* Handle */\n      &::-webkit-scrollbar-thumb {\n        background: black;\n      }\n  \n      /* Handle on hover */\n      &::-webkit-scrollbar-thumb:hover {\n        background: black;\n        cursor: pointer;\n      }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
