import React from "react";

const RefreshIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M6.7704 5.94641C8.22205 4.68854 10.0792 3.99733 12 4.00001C16.4184 4.00001 20 7.58161 20 12C20 13.7088 19.464 15.2928 18.552 16.592L16 12H18.4C18.4001 10.7453 18.0314 9.51825 17.3397 8.47141C16.6481 7.42457 15.6639 6.60413 14.5097 6.11211C13.3555 5.62008 12.0822 5.47817 10.848 5.70402C9.61376 5.92988 8.47316 6.51353 7.568 7.38241L6.7704 5.94641ZM17.2296 18.0536C15.778 19.3115 13.9208 20.0027 12 20C7.5816 20 4 16.4184 4 12C4 10.2912 4.536 8.70721 5.448 7.40801L8 12H5.6C5.5999 13.2547 5.9686 14.4818 6.66027 15.5286C7.35194 16.5754 8.33605 17.3959 9.49026 17.8879C10.6445 18.3799 11.9178 18.5218 13.152 18.296C14.3862 18.0701 15.5268 17.4865 16.432 16.6176L17.2296 18.0536Z"
      fill="#444141"
      fillOpacity="0.67"
    />
  </svg>
);

export default RefreshIcon;
