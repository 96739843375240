import React from "react";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { setEditStep } from "../../../Redux/TestAuthoring/TestAuthoring";
import { CustomInput } from "../../../components-new/common";
import ElementDetails from "./ElementDetails";

const TestDataConfig = ({ item }) => {
  const dispatch = useDispatch();

  const handleChange = (value, field) => {
    dispatch(setEditStep({ ...item, [field]: value }));
  };

  return (
    <Box pr={3}>
      <Box display="flex" columnGap={3} mb={2}>
        <CustomInput
          labelinput={"Test Data"}
          placeholder={"Enter test Data"}
          value={item.TestData}
          onChange={(e) => handleChange(e.target.value, "TestData")}
          flex={1}
          // TODO remove if not needed in future
          // isDisabled={item.Step_Number === 0}
        />
        <CustomInput
          labelinput={"Variable"}
          placeholder={"Enter Variable"}
          value={item.VarName}
          onChange={(e) => handleChange(e.target.value, "VarName")}
          flex={1}
          isDisabled={item.Step_Number === 0}
        />
      </Box>
      <ElementDetails item={item} />
    </Box>
  );
};

export default TestDataConfig;
