import axios from "axios";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "./../Config/Config";

export const getNewTestCase = async (product,module,createNewTestCase,stepDetails) => {
    const userDetails = localStorage.getItem("userDetail");
    const temp = JSON.parse(userDetails);
    const encodedStepDetails = encodeURIComponent(stepDetails);
    const Response = await axios
        .get(
       `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=createTestCase&companyid=${temp.companyid}&token=${temp.token}&product=${product}&module=${module}&newTestCase=${createNewTestCase}&stepDetails=${encodedStepDetails}`
    ).then((response) => {
        return response;
    }).catch((error) => {
        console.log(error.response.data.error);
    })
    return Response;
}

export const getAllComments = async (product, repo, fromDate, toDate, knowledge) => {
    const userDetails = localStorage.getItem("userDetail");
    const temp = JSON.parse(userDetails);
    const Response = await axios
        .get(
       `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getTCbyTags&companyid=${temp.companyid}&token=${temp.token}&product=${product}&repo=${encodeURIComponent(repo)}&fromdate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}&knowledge=${knowledge}`
    ).then((response) => {
        return response;
    }).catch((error) => {
        console.log(error.response.data.error);
    })
    return Response;
}

export const getRepos = async (productId) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);

  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getRepos&companyid=${temp.companyid}&token=${temp.token}&productid=${productId}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

// export const getTestCasesFromComments = async (product,comment) => {
//     const userDetails = localStorage.getItem("userDetail");
//     const temp = JSON.parse(userDetails);
//     const Response = await axios
//         .get(
//        `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getTCfromComments&companyid=${temp.companyid}&token=${temp.token}&product=${product}&comments=${(encodeURIComponent(comment))}`
//     ).then((response) => {
//         return response;
//     }).catch((error) => {
//         console.log(error.response.data.error);
//     })
//     return Response;
// }

export const getTestCasesFromComments = async (product,comment, suiteOption, suite, newTestSuite) => {
    const userDetails = localStorage.getItem("userDetail");
    const temp = JSON.parse(userDetails);
  let apiCall;
  if(suiteOption === "NoSelection"){
    apiCall =`${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getTCfromComments&companyid=${temp.companyid}&token=${temp.token}&product=${product}&suiteOption=${suiteOption}`
  }else if(suite !== ""){
    apiCall =`${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getTCfromComments&companyid=${temp.companyid}&token=${temp.token}&product=${product}&suiteOption=${suiteOption}&suiteId=${suite}`
  }else if(newTestSuite !== ""){
    apiCall =`${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getTCfromComments&companyid=${temp.companyid}&token=${temp.token}&product=${product}&suiteOption=${suiteOption}&newTestSuite=${newTestSuite}`
  }
    const Response = await axios
      .post(
        apiCall,
        comment
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error.response.data.error);
      });
    return Response;
  };