import { Box, Chip, css, Link, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import React, { useState } from "react";
import { ExeReportHistoryStatus } from "../../components-new/layout/constants";

const StyledTableCell = styled(TableCell)`
    color: #232D3D;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid #E2E6F3;
`;

const StyledTableBodyCell = styled(TableCell)`
    padding: 8px 20px;
    border-bottom: 1px solid #eee;
    p {
        color: #2B3539;
        font-size: 16px;
        font-weight: 500;
        padding-right: 15px;
        min-width: 60px;
    }
`;

const typeStyles = {
    PASS: css`
    border-radius: 41px;
    border: 0px solid #30ca17;
    background: #e7fbe4;
  `,
    FAIL: css`
    border-radius: 41px;
    border: 0px solid #e15151;
    background: #fbe7e7;
  `,
    ABEND: css`
    border-radius: 41px;
    background: #eef4fc;
  `,
  ABORTED: css`
  border-radius: 41px;
  background: #fbe7e7;
`,
};

const StatusPill = styled(Chip)`
  min-width: 82px;
  padding: 8px 10px;
  gap: 10px;
  ${(props) => typeStyles[props.type]}
  span {
    text-transform: capitalize;
  }
`;

const ExeTestCaseReportsTable = ({ data, isSuccess, testSuiteName, runDate }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleStepHistory = (e, item) => {
        e.preventDefault(); // Prevent default link behavior
        let url;
        if(!testSuiteName) {
            url = `/new/reports?tcrid=${item.idtest_case_results}&tcname=${item.testcase}&tcRundate=${item.Executed_Date}`;
        } else {
            url = `/new/reports?tcrid=${item.idtest_case_results}&name=${testSuiteName}&date=${runDate}&tcname=${item.testcase}&tcRundate=${item.Executed_Date}`;
        }
        window.open(url, '_blank');  // Open the link in a new tab
    };

    return (
        <Box flex={1}>
            {isSuccess && (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Run Date</StyledTableCell>
                                <StyledTableCell>Run By</StyledTableCell>
                                <StyledTableCell>Run Duration</StyledTableCell>
                                <StyledTableCell>Log File</StyledTableCell>
                                <StyledTableCell>Browser</StyledTableCell>
                                <StyledTableCell>Status</StyledTableCell>
                                <StyledTableCell>Actions</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.length ? 
                                data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                                    <TableRow key={index}>
                                        <StyledTableBodyCell>{item.Executed_Date}</StyledTableBodyCell>
                                        <StyledTableBodyCell>{item.Executed_By}</StyledTableBodyCell>
                                        <StyledTableBodyCell>{item.Duration}</StyledTableBodyCell>
                                        <StyledTableBodyCell><Link target="_blank" href={item.logfile}>{item.testcase}</Link></StyledTableBodyCell>
                                        <StyledTableBodyCell>{item.Browser}</StyledTableBodyCell>
                                        <StyledTableBodyCell>
                                            <StatusPill label={ExeReportHistoryStatus[item.Status]} type={item.Status} />
                                        </StyledTableBodyCell>
                                        {item.Status !== ExeReportHistoryStatus.ABORTED &&  <StyledTableBodyCell><Link target="_blank" sx={{ cursor: 'pointer' }} onClick={(e) => handleStepHistory(e,item)}>View test results</Link></StyledTableBodyCell>}
                                    </TableRow>
                                )) 
                                : <TableRow> <StyledTableBodyCell align="center" colSpan={7}>No data available</StyledTableBodyCell></TableRow>
                            }
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
            )}
        </Box>
    );
};

export default ExeTestCaseReportsTable;
