import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  testCases: [],
  tsrData: [],
  selectedProduct: "",
  selectedComponent: "",
  selectedTestCase: "",
  selectedTestcaseItem: {},
  recordings: [],
  editItemIndex: null,
  editStep: {},
};

const authoringSlice = createSlice({
  name: "testAuthoring",
  initialState,
  reducers: {
    setTestCases(state, action) {
      state.testCases = action.payload;
    },
    setTsrData(state, action) {
      state.tsrData = action.payload;
    },
    setSelectedProduct(state, action) {
      state.selectedProduct = action.payload;
      state.selectedComponent = initialState.selectedComponent;
    },
    setSelectedComponent(state, action) {
      state.selectedComponent = action.payload;
      state.selectedTestCase = initialState.selectedTestCase;
    },
    setSelectedTestCase(state, action) {
      state.selectedTestCase = action.payload;
    },
    setSelectedTestCaseItem(state, action) {
      state.selectedTestcaseItem = action.payload;
    },
    setRecordings(state, action) {
      state.recordings = action.payload;
    },
    setEditItemIndex(state, action) {
      state.editItemIndex = action.payload;
    },
    setEditStep(state, action) {
      state.editStep = action.payload;
    },
    resetAuthorings() {
      return { ...initialState };
    },
  },
});

export const {
  setTestCases,
  setTsrData,
  setSelectedProduct,
  setSelectedComponent,
  setSelectedTestCase,
  setSelectedTestCaseItem,
  resetAuthorings,
  setRecordings,
  setEditItemIndex,
  setEditStep,
} = authoringSlice.actions;

export default authoringSlice.reducer;
