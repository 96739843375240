import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

const fetchRecordedTestDataList = async (pid, cid) => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");

  const user = JSON.parse(userDetails);

  const response = await axios.get(
    `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getRecordedTestDataList&companyid=${user.companyid}&token=${user.token}&teststepid=${pid}&testcaseid=${cid}`,
  );

  return response.data;
};

const useRecordedTestDataListQuery = (pid, cid) => {
  return useQuery({
    queryKey: ["recordedTestDataList", pid, cid],
    queryFn: () => fetchRecordedTestDataList(pid, cid),
    retry: true,
    enabled: false,
    cacheTime: 90000,
    refetchInterval: false,
    staleTime: Infinity,
  });
};

export default useRecordedTestDataListQuery;
