import React from "react";

const LeftArrowIcon = () => (
  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.828 6.99974L7.778 11.9497L6.364 13.3637L0 6.99974L6.364 0.635742L7.778 2.04974L2.828 6.99974Z"
      fill="#03053D"
    />
  </svg>
);

export default LeftArrowIcon;
