
    export const CommonMethods = (navigate) =>{  
         let user = {
            email: "",
            firstname: "",
            lastname: "",
            company: "",
            phonenumber: "",
        };

        let json = JSON.stringify(user);
        localStorage.setItem("userDetail", json);
        navigate("/LoginPage");
    }