import React, { useState } from 'react';
import './MyCarouselScreenshot.scss';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TextField from "@mui/material/TextField";
import {colorExport } from '../common/common.Component';


const MyCarouselScreenshot = ({ firstImage, secondImage, thirdImage, passText, failText, textValue, textNegative }) => {
  const [activeIndex, setActiveIndex] = useState(1);
  console.log("pass Text", failText)
  const failedValue = failText?.split('\n')
  console.log(" fail text" , failedValue)

  const combinedContent = failedValue.join('<br>');

  const colors = colorExport();
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);

  const handlePrevious = () => {
    setActiveIndex((prevIndex) => {
      // Handle wrapping to the last image when clicking "Previous" on the first image
      if (prevIndex === 0) {
        return 2;
      } else {
        return prevIndex - 1;
      }
    });
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) => {
      // Handle wrapping to the first image when clicking "Next" on the last image
      if (prevIndex === 2) {
        return 0;
      } else {
        return prevIndex + 1;
      }
    });
  };

  return (
    <div className="carouselss">
      <div className="carousel-inner">
        <div className={`carousel-item ${activeIndex === 0 ? 'active' : ''}`}>
          {textValue === true ? 
          // <TextField
          // defaultValue={failText.split('\n')}
          // label="Failed Text"
          // className='TextBoxFail'/> 
                // <div dangerouslySetInnerHTML={{ __html: combinedContent }} />
                <div style={{ whiteSpace: 'pre-line' }}>
                  {failText}
                </div>
          :<img className="carousel-image" src={firstImage} alt="Slide 1" />}
          <div className="carousel-caption">
            <h4 className="heading-five">Failed Screenshot</h4>
          </div>
        </div>
        <div className={`carousel-item ${activeIndex === 1 ? 'active' : ''}`}>
          <img className="carousel-image" src={secondImage} alt="Slide 2" />
          <div className="carousel-caption">
            <h4 className="heading-five">Difference in Screenshots</h4>
          </div>
        </div>
        <div className={`carousel-item ${activeIndex === 2 ? 'active' : ''}`}>
          <img className="carousel-image" src={thirdImage} alt="Slide 3" />
          <div className="carousel-caption">
            <h4 className="heading-five">Passed Screenshot</h4>
          </div>
        </div>
      </div>

      <a style={{ backgroundColor: colors.secondaryColor,color: colors.primaryColor,}}  className="carousel-control-prev" onClick={handlePrevious}>
        <span className="sr-only"><ArrowBackIcon /></span>
      </a>
      <a style={{ backgroundColor: colors.secondaryColor,color: colors.primaryColor,}}  className="carousel-control-next" onClick={handleNext}>
        <span className="sr-only"><ArrowForwardIcon /></span>
      </a>
      <div className="carousel-indicators">
        <ul>
          <li onClick={handlePrevious} className={activeIndex === 0 ? 'active' : ''}></li>
          <li onClick={handlePrevious} className={activeIndex === 1 ? 'active' : ''}></li>
          <li onClick={handleNext} className={activeIndex === 2 ? 'active' : ''}></li>
        </ul>
      </div>
    </div>
  );
};

export default MyCarouselScreenshot;