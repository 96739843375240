import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "./LoginPage/LoginPage.component";
import SignUpPage from "./SignUpPage/SignUpPage.component";
import ResultStepPage from "./ResultStepPage/ResultStepPage";
import HomePage from "./HomePage/HomePage.component";
import Home from "./HomePage/Home";
import ChatBot from "./ChatBot/ChatBot";
import UploadPage from "./UploadPage/UploadPage.component";
import CreateCollectionPage from "./CreateCollectionPage/CreateCollectionPage.component";
import NewCreateCollectionPage from "./NewCreateCollectionPage/NewCreateCollectionPage.component";
import AdminPage from "./AdminPage/AdminPage.component";
import UserPage from "./UserPage/UserPage.component";
import ResolutionPage from "./ResolutionPage/ResolutionPage.component";
import APIPage from "./APIPage/APIPage.component";
import Report from "./ReportPage/Report";
import Test from "./ReportPage/Test";
import ForgotPasswordPage from "./ForgotPasswordPage/ForgotPasswordPage.component";
import CoveragePage from "../components/CoveragePage/CoveragePage.component";
import Treestructure from "../components/CoveragePage/TreeStructure.component";
import TestData from "../components/TestData/TestData";
import CoverageDashboard from "../components/CoveragePage/CoverageDashboard.component";
import CreateTestCase from "../components/TestCase/CreateTestCase";
import ImpactAnalysis from "../components/GitHubPage/ImpactAnalysis";
import Recordings from "./RecordingPage/Recordings";
import { DasboadLayout } from "../components-new/layout";
import { TestStudio, ExecutionReports, Analytics, TestSuite, Dashboard } from "../pages-new";
import { ExecutionReportsHistory, StepHistoryPage } from "../pages-new/Reports";

const AppRouting = () => {
  let temp;
  try {
    const userDetails = localStorage.getItem("userDetail");
    temp = JSON.parse(userDetails);
  } catch (error) {
    console.error("Failed to parse user details from localStorage", error);
    temp = null;
  }

  const isUserAuthorized = temp !== null && temp?.email;

  const dashboardRoutes = [
    { path: "/dashboard/app", element: <HomePage /> },
    {
      path: "/dashboard/app/productId/:pcid/testSuiteId/:tsid/resultSuiteId/:tsrid",
      element: <HomePage />,
    },
    { path: "/dashboard/recording", element: <Recordings /> },
    { path: "/dashboard/upload", element: <UploadPage /> },
    { path: "/dashboard/createCollection", element: <CreateCollectionPage /> },
    { path: "/dashboard/newcreateCollection", element: <NewCreateCollectionPage /> },
    { path: "/dashboard/createTestCase", element: <CreateTestCase /> },
    { path: "/dashboard/repos", element: <ImpactAnalysis /> },
    { path: "/dashboard/AdminPage", element: <AdminPage /> },
    { path: "/dashboard/CoveragePage/:product", element: <CoveragePage /> },
    { path: "/dashboard/Screenshot/:ssid/:product", element: <Treestructure /> },
    { path: "/dashboard/CoverageDashboard", element: <CoverageDashboard /> },
    { path: "/dashboard/UserPage", element: <UserPage /> },
    { path: "/dashboard/userExperience", element: <ResolutionPage /> },
    { path: "/dashboard/testData", element: <TestData /> },
    { path: "/dashboard/Api", element: <APIPage /> },
    { path: "/dashboard/Reports", element: <Report /> },
    { path: "/dashboard/Test", element: <Test /> },
    { path: "/dashboard/ResultStepPage/:tcid", element: <ResultStepPage /> },
    { path: "/dashboard/chatBot", element: <ChatBot /> },
  ];

  const newDashboardRoutes = [
    { path: "/new/dashboard", element: <Dashboard /> },
    { path: "/new/test-studio", element: <TestStudio /> },
    { path: "/new/reports", element: <ExecutionReports /> },
    { path: "/new/test-suites", element: <TestSuite /> },
    { path: "/new/analytics", element: <Analytics /> },
    { path: "/new/reports/history", element: <ExecutionReportsHistory /> },
    { path: "/new/dashboard/ResultStepPage/:tcid", element: <StepHistoryPage /> },
  ];

  return (
    <Routes>
      <Route
        path="/"
        element={
          isUserAuthorized ? (
            temp?.company?.gentype === "recording" ? (
              <HomePage />
            ) : (
              <HomePage />
            )
          ) : (
            <Navigate to="/LoginPage" />
          )
        }
      />
      {isUserAuthorized &&
        // temp?.company?.gentype === "recording" &&
        dashboardRoutes.map((route, index) => (
          <Route key={index} exact path={route.path} element={route.element} />
        ))}
      {/* for new dashboard now if user details not present then it will be re-directed to login page */}
      {isUserAuthorized ? (
        <Route path="/new" element={<DasboadLayout />}>
          <Route index element={<Dashboard />} />
          {newDashboardRoutes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Route>
      ) : (
        <Route path="*" element={<Navigate to="/LoginPage" />} />
      )}
      <Route path="/LoginPage" element={<LoginPage />} />
      <Route path="/SignUpPage" element={<SignUpPage />} />
      <Route path="/SignUpPage?codegen=codegen" element={<SignUpPage />} />
      <Route path="/SignUpPage?cname=cname" element={<SignUpPage />} />
      <Route path="/ForgotPasswordPage" element={<ForgotPasswordPage />} />
    </Routes>
  );
};

export default AppRouting;
