// CustomBreadcrumbs.js
import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const CustomBreadcrumbs = ({ separator, ariaLabel, mb, children }) => {
  return (
    <Breadcrumbs
      separator={separator || <NavigateNextIcon fontSize="small" />}
      aria-label={ariaLabel || "breadcrumb"}
      mb={mb || 2}
    >
      {children}
    </Breadcrumbs>
  );
};

export default CustomBreadcrumbs;
