import { Box, Link, Typography, styled } from "@mui/material";
import FileDownloadIcon from "../../../components-new/icons/FileDownloadIcon";
import { useSelector } from "react-redux";

const SynopContainer = styled(Box)`
  padding: 30px 25px;
  margin-bottom: 40px;
  background-color: white;
  border-radius: 0px 0px 12px 12px;
  box-shadow: 0px 24px 23.3px 0px rgba(0, 0, 0, 0.03);
`;

const CardHeader = styled(Typography)`
  color: #000000;
  font-size: 16px;
  font-weight: 500;
`;

const ItemRow = styled(Box)`
  display: flex;
  margin-bottom: 20px;
  gap: 35px;
`;

const Item = styled(Box)`
  display: flex;
  flex-direction: column;
  row-gap: 3px;
`;

const ItemHeader = styled(Typography)`
  color: #8089a8;
  font-size: 13px;
  font-weight: 500;
`;

const ItemValue = styled(Typography)`
  color: #2b3539;
  font-size: 16px;
  font-weight: 400;
`;

const statusMapper = {
  Positive: "Must Pass",
  Negative: "Must Fail",
  Optional: "Optional",
};

const FileDownloadButton = styled(Link)`
  padding: 0;
  padding-top: 3px;
  min-width: 10px;
`;

const Synopsis = ({ valuesSavedResult }) => {
  const item = useSelector((state) => state.authoringSlice.editStep);
  return (
    <Box>
      <SynopContainer>
        <Box mb={"20px"}>
          <CardHeader>Test Data Configuration</CardHeader>
        </Box>
        <Box>
          <Box display={"flex"} mb={"25px"} justifyContent={"space-between"}>
            <Item>
              <ItemHeader>Test Data</ItemHeader>
              <ItemValue>{item.TestData}</ItemValue>
            </Item>
            <Item>
              <ItemHeader>Variable</ItemHeader>
              <ItemValue>{item?.VarName}</ItemValue>
            </Item>
          </Box>
          <Item>
            <ItemHeader>Test data Source</ItemHeader>
            <ItemValue>{item.testdata_source}</ItemValue>
          </Item>
        </Box>
      </SynopContainer>
      <SynopContainer>
        <Box mb={"20px"}>
          <CardHeader>Element Details</CardHeader>
        </Box>
        <ItemRow>
          <Item minWidth={"126px"}>
            <ItemHeader>Element</ItemHeader>
            <ItemValue>{item?.Keyword}</ItemValue>
          </Item>
          <Item minWidth={"126px"}>
            <ItemHeader>Action</ItemHeader>
            <ItemValue>{item?.Action}</ItemValue>
          </Item>
          <Item minWidth={"126px"}>
            <ItemHeader>Status</ItemHeader>
            <ItemValue>{item.Flow ? statusMapper[item?.Flow] : ""}</ItemValue>
          </Item>
          <Item minWidth={"126px"}>
            <ItemHeader>Step Threshold</ItemHeader>
            <ItemValue>{item?.teststepthreshold}</ItemValue>
          </Item>
          <Item minWidth={"126px"}>
            <ItemHeader>Wait</ItemHeader>
            <ItemValue>{item?.wait}</ItemValue>
          </Item>
          <Item minWidth={"126px"}>
            <ItemHeader>Wait time</ItemHeader>
            <ItemValue>{item?.waittime}</ItemValue>
          </Item>
          <Item minWidth={"126px"}>
            <ItemHeader>Dynamic Value</ItemHeader>
            <ItemValue>{item?.isDynamic ? "Yes" : "No"}</ItemValue>
          </Item>
        </ItemRow>
        <Box mb={"20px"}>
          <Item>
            <ItemHeader>Page Description</ItemHeader>
            <ItemValue>{item?.Page_Description ? item?.Page_Description : "-"}</ItemValue>
          </Item>
        </Box>
        <Box mb={"20px"} display={"flex"} gap={"22px"}>
          <Item minWidth={"350px"}>
            <ItemHeader>Type</ItemHeader>
            <ItemValue>{item?.type ? item?.type : "-"}</ItemValue>
          </Item>
          <Item minWidth={"350px"}>
            <ItemHeader>Color</ItemHeader>
            <ItemValue>{item?.color ? item?.color : "-"}</ItemValue>
          </Item>
          <Item minWidth={"350px"}>
            <ItemHeader>Background Color</ItemHeader>
            <ItemValue>{item?.bgcolor ? item?.bgcolor : "-"}</ItemValue>
          </Item>
        </Box>
        <Box mb={"20px"}>
          <Item>
            <ItemHeader>Image Path</ItemHeader>
            <ItemValue>{item?.imgpath ? item?.imgpath : "-"}</ItemValue>
          </Item>
        </Box>
      </SynopContainer>
      <SynopContainer>
        <Box mb={"20px"}>
          <CardHeader>Element Locator</CardHeader>
        </Box>
        <ItemRow>
          <Item minWidth={"126px"}>
            <ItemHeader>Strategy</ItemHeader>
            <ItemValue>{item.stategy ? item.stategy : "X-Path"}</ItemValue>
          </Item>
          <Item minWidth={"126px"}>
            <ItemHeader>Object Identifier</ItemHeader>
            <ItemValue>{item?.Object_Xpath ? item?.Object_Xpath : "-"} </ItemValue>
          </Item>
        </ItemRow>
        <ItemRow>
          <Item minWidth={"126px"}>
            <ItemHeader>Tabs</ItemHeader>
            <ItemValue>{item?.TabId ? item?.TabId : "-"}</ItemValue>
          </Item>
          <Item minWidth={"126px"}>
            <ItemHeader>Iframexpath</ItemHeader>
            <ItemValue>{item?.iframexpath ? item?.iframexpath : "-"}</ItemValue>
          </Item>
        </ItemRow>
      </SynopContainer>
      <SynopContainer>
        <Box mb={"20px"}>
          <CardHeader>No Code Script</CardHeader>
        </Box>
        <ItemRow>
          <Item minWidth={"126px"}>
            <ItemHeader>Action</ItemHeader>
            {item?.Action === "Javascript" ? (
              <ItemValue>Javascript</ItemValue>
            ) : (
              <ItemValue>-</ItemValue>
            )}
          </Item>
          <Item minWidth={"126px"}>
            <ItemHeader>Script File</ItemHeader>
            {item.Action === "Javascript" ? (
              <ItemValue
                display="flex"
                gap={"5px"}
                alignContent={"center"}
                justifyContent={"center"}
              >
                {item.filename}
                <FileDownloadButton
                  target="_blank"
                  href={valuesSavedResult[0]?.logFile || ""}
                  underline="none"
                  color={"inherit"}
                >
                  <FileDownloadIcon />
                </FileDownloadButton>
              </ItemValue>
            ) : (
              <ItemValue>-</ItemValue>
            )}
          </Item>
        </ItemRow>
      </SynopContainer>
    </Box>
  );
};

export default Synopsis;
