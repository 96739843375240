import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { API_BASE_PATH, API_BASE_RELATIVE_PATH } from "../Config/Config";

const updateAssertionData = async (api_input) => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetails);

  let params = new URLSearchParams({
    action: "validate",
    companyid: user.companyid,
    token: user.token,
    testCaseId: api_input.Test_Case_Id,
    teststepid: api_input.idtest_step,
    valdtype: api_input.ValDevice,
  });

  switch (api_input.ValDevice) {
    case "DynamicText":
      params.append("actualdata", api_input.testdata_source);
      break;
    case "ChangeTestData":
      params.append("testdatavalue", api_input.TestData);
      break;
    case "DB":
      params.append("dbquery", api_input.DBQuery);
      break;
    case "Regex":
      params.append("regex", encodeURIComponent(api_input.regex));
      break;
    case "GoogleSheet":
      params.append("sheetId", api_input.fieldname);
      params.append("sheet", api_input.sheetId);
      params.append("cell", api_input.cell);
      params.append("scope", api_input.scope);
      params.append("endRange", api_input.endRange);
      break;
    case "InApi":
      params.append("fieldName", api_input.TestData);
      params.append("apiid", api_input.apiid);
      params.append("apiParam", api_input.apiparam || "");
      params.append("apiQuery", api_input.DBQuery || "");
      break;
    case "DependentonOtherTestScenario":
      params.append("dependantTC", api_input.DependentTC);
      params.append("testdatavalue", api_input.useVar ?? api_input.TestData);
      break;
    case "InGoogleSheet":
      params.append("sheetId", api_input.filename);
      params.append("sheet", api_input.sheet);
      params.append("cell", api_input.cell);
      params.append("scope", api_input.scope);
      if (api_input.endRange) {
        params.append("endRange", api_input.endRange);
      }
      break;
    case "Date":
      params.append("date", api_input.TestData.split("~")[2]);
      params.append("dateFormat", api_input.TestData?.split("~")[3]);
      break;
    case "FromFile": {
      const fileValue = api_input.FileName;
      if (api_input.fromFileType === "xlsx" || api_input.fromFileType === "csv") {
        params.append("name", fileValue);
        params.append("sheetId", api_input.sheet);
        params.append("cell", api_input.cell);
        params.append("scope", api_input.scope);
        params.append("endRange", api_input.endRange);
      } else if (api_input.fromFileType === "json") {
        params.append("name", fileValue);
        params.append("filter", api_input.filter);
        params.append("scope", api_input.scope);
        params.append("endRange", api_input.endRange);
      } else {
        params.append("name", api_input.filename);
      }
      break;
    }
    case "InRefrenceFile":
      const fileValue = encodeURIComponent(api_input.filename);
      if (api_input.fromFileType === "xlsx" || api_input.fromFileType === "csv") {
        params.append("name", fileValue);
        params.append("sheetId", api_input.sheet);
        params.append("cell", api_input.cell);
        params.append("scope", api_input.scope);
        params.append("endRange", api_input.endRange);
      } else if (api_input.fromFileType === "json") {
        params.append("name", fileValue);
        params.append("filter", api_input.filter);
        params.append("scope", api_input.scope);
        params.append("endRange", api_input.endRange);
      } else {
        params.append("fileName", api_input.filename);
      }
      break;
    case "InDownloadedFile":
      const downloadedFileValue = encodeURIComponent(
        `${api_input.DownloadedFile}~${api_input.TestData}`,
      );

      params.append("name", downloadedFileValue);
      break;
    case "InFile": {
      const fileValue = encodeURIComponent(api_input.filename);
      if (api_input.fromFileType === "xlsx" || api_input.fromFileType === "csv") {
        params.append("name", fileValue);
        params.append("sheetId", api_input.sheet);
        params.append("cell", api_input.cell);
        params.append("scope", api_input.scope);
        params.append("endRange", api_input.endRange);
      } else if (api_input.fromFileType === "json") {
        params.append("name", fileValue);
        params.append("filter", api_input.filter);
        params.append("scope", api_input.scope);
        params.append("endRange", api_input.endRange);
      } else {
        params.append("fileName", api_input.filename);
      }
      break;
    }
    case "DependantTC":
      params.append("dependantTC", api_input.dependantTestCaseId);
      params.append("useVar", encodeURIComponent(api_input.usevar));
      break;
    case "File":
      params.append("filename", api_input.filename);
      params.append("filefield", api_input.filefield);
      break;
    case "Screen":
      // No additional params needed
      break;
    case "UseVariableFromTheScenario":
      params.append("useVar", api_input.TestData);
      break;
    case "Search":
      params.append("testdata", api_input.testdata);
      break;
    case "ChangeText":
      params.append("testdatavalue", api_input.TestData);
      break;
    case "FromEmail":
      params.append("customerEmail", api_input.customerEmail);
      params.append("customerPassword", api_input.customerPassword);
      params.append("EmailSelectionCriteria", api_input.EmailSelectionCriteria);
      params.append("EmailFilter", api_input.EmailFilter);
      break;
    default:
      break;
  }

  const apiUrl = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?${params.toString()}`;

  const response = await axios
    .get(apiUrl)
    .then((response) => response)
    .catch((error) => {
      console.log(error);
      throw error;
    });

  return response;
};

const useUpdateAssertionQuery = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateAssertionData,
    onSuccess: () => {
      queryClient.invalidateQueries(["testCaseResults"]);
    },
    onError: (error) => {
      console.log("Error updating test data", error.message);
    },
  });
};

export default useUpdateAssertionQuery;
