import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

const scheduleSuite = async (scheduler, time, subfreq, date, suiteId) => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetails);

  const baseUrl = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=suiteScheduler&companyid=${user.companyid}&token=${user.token}&suiteid=${suiteId}`;

  const response = await axios.get(
    `${baseUrl}${scheduler ? `&frequency=${scheduler}` : ""}${time ? `&time=${time}` : ""}${subfreq ? `&subfreq=${subfreq}` : ""}${date ? `&date=${date}` : ""}`,
  );

  return response.data;
};

const useSuiteSchedulerQuery = (scheduler, time, subfreq, date, suiteId) => {
  return useQuery({
    queryKey: ["scheduleSuite"],
    queryFn: () => scheduleSuite(scheduler, time, subfreq, date, suiteId),
    enabled: false,
    retry: true,
    cacheTime: 90000,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    staleTime: Infinity,
  });
};

export default useSuiteSchedulerQuery;
