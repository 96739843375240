import React, { useState, useEffect } from 'react';
import "./TestData.scss";
import {
  ColorButton,
  colorExport,
  StyledTableRow,
  StyledTableCell,
} from "./../common/common.Component";
import { useNavigate } from "react-router-dom";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  IconButton,
  Typography,
  TableBody,
} from "@mui/material";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import { fetchProducts } from '../../API/NewCreateCollectionAPI';
import {
  fetchModules,
  fetchSuiteCase,
  fetchTestCasesfromModule,
} from '../../API/CreateCollectionAPI';
import { CommonMethods } from '../common/CommonMethods';
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { deleteProductFIle, getFileData, getFileCustomData, getTestData, refreshZip, uploadFile } from '../../API/TestDataApi';
import TextField from '@mui/material/TextField';
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import { API_BASE_PATH, API_BASE_RELATIVE_PATH } from "../../Config/Config";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Tooltip, Card } from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Tab from '@mui/material/Tab';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import Alert from "@mui/material/Alert";
import CancelIcon from '@mui/icons-material/Cancel';
import BlockIcon from '@mui/icons-material/Block';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
const TestData = () => {
  const colors = colorExport();
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const navigate = useNavigate();
  const [comanyid, setCompanyid] = useState();
  const [productList, setProductList] = useState([]);
  const [ModuleList, setModuleList] = useState([]);
  const [module, setModule] = useState('');
  const [product, setProduct] = useState('');
  const [TableData, setTableData] = useState([]);
  const [selectedFile, setSelectedFile] = useState({
    file: "",
    filename: "",
  });
  const [uploadnew, setUploadNew] = useState({
    file: "",
    filename: "",
  });
  const [uploadNewCustom, setUploadNewCustom] = useState({
    file: "",
    filename: "",
  });
  const [SuiteCaseList, setSuiteCaseList] = useState([]);
  const [suiteid, setSuiteid] = useState("");
  const [TestCaseList, setTestCaseList] = useState([]);
  const [idtestCase, setIdTestCase] = useState("");
  const [selectedfiledropdown, setSelectedFileDropdown] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [openAlertForZip, setOpenAlertForZip] = useState(false);
  const [refreshTable, setRefreshTable] = useState(true);
  const [refreshTableData, setRefreshTableData] = useState([]);
  const [formDataTable, setFormDataTable] = useState([]);
  const [formCustomDataTable, setFormCustomDataTable] = useState([]);
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const [selectedItems, setSelectedItems] = useState([]);
  const [deleteTestcasesPopup, setDeleteTestcasesPopup] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [showDropdowns, setShowDropdowns] = useState(false);
  const [showChooseFile, setShowChooseFile] = useState(false);
  const handleCheckboxToggle = (event, obj) => {
    const { checked } = event.target;
    if (checked) {
      setSelectedData(selectedData.concat(obj.FileName));
      setSelectedItems(selectedItems.concat(obj));
    } else {
      selectedData.map((el, i) =>
        obj["FileName"] === el
          ? selectedData.splice(i, 1)
          : console.log()
      );
      const copy = selectedData.slice();
      setSelectedData(copy);
      selectedItems.map((el, i) =>
        obj["FileName"] === el["FileName"]
          ? selectedItems.splice(i, 1)
          : console.log()
      );
      const copy1 = selectedItems.slice();
      setSelectedItems(copy1);
    }
  };
  const handleClickOpenAlert = () => {
    setOpenAlertForZip(false);
  };
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  const tableColums = [
    {
      name: "Testcase",
      id: "TestCase",
      isLongString: false,
      isNumeric: false,
      align: "center",
      colspan: 1,
      width: '15%',
    },
    {
      name: "Testcase Desc",
      id: "Testcase Desc",
      isLongString: false,
      isNumeric: false,
      align: "center",
      colspan: 2,
      width: '14%',
    },
    {
      name: "Step Number",
      id: "Stepnumber",
      isLongString: false,
      isNumeric: false,
      align: "center",
      colspan: 1,
      width: '15%',
    },
    // {
    //   name: "Stepname",
    //   id: "Stepname",
    //   isLongString: false,
    //   isNumeric: false,
    //   align: "center",
    //   colspan:1,
    //   width: '7%',
    // },
    {
      name: "Step Description",
      id: "stepdescription",
      isLongString: false,
      isNumeric: false,
      align: "center",
      colspan: 1,
      width: '10%',
    },
    {
      name: "Filename",
      id: "Filename",
      isLongString: false,
      isNumeric: false,
      align: "center",
      colspan: 3,
      width: '20%',
    },
    {
      name: "Filefield",
      id: "Filefield",
      isLongString: false,
      isNumeric: false,
      align: "center",
      colspan: 1,
      width: '20%',
    },
    {
      name: "Status",
      id: "Status",
      isLongString: false,
      isNumeric: false,
      align: "center",
      colspan: 2,
      width: '10%',
    },
  ];
  const tableColumnsFromData = [
    {
      name: "",
      id: "",
      isLongString: false,
      isNumeric: false,
      align: "center",
      colspan: 1,
      width: '15%',
    },
    {
      name: "File Name",
      id: "FIle Name",
      isLongString: false,
      isNumeric: false,
      align: "center",
      colspan: 1,
      width: '30%',
    },
    {
      name: "Last Modify",
      id: "Last Modify",
      isLongString: false,
      isNumeric: false,
      align: "center",
      colspan: 2,
      width: '30%',
    },
    {
      name: "FileSize",
      id: "FileSize",
      isLongString: false,
      isNumeric: false,
      align: "center",
      colspan: 1,
      width: '20%',
    },
  ]
  //   function TabPanel(props) {
  //   const { children, value, index, ...other } = props;

  //   return (
  //     <div
  //       role="tabpanel"
  //       hidden={value !== index}
  //       id={`full-width-tabpanel-${index}`}
  //       aria-labelledby={`full-width-tab-${index}`}
  //       {...other}
  //     >
  //       {value === index && (
  //         <Box sx={{ p: 3 }}>
  //           <Typography>{children}</Typography>
  //         </Box>
  //       )}
  //     </div>
  //   );
  // }
  // TabPanel.propTypes = {
  //   children: PropTypes.node,
  //   index: PropTypes.number.isRequired,
  //   value: PropTypes.number.isRequired,
  // };
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  const theme = useTheme();
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const handleFileInputChange = (e) => {
    // if (e.target.files) {
    //   setSelectedFile({
    //     file: e.target.files,
    //     filename: e.target.files[0].name,
    //   });
    //   console.log("selected files-->", selectedFile?.file[0]?.name)

    // }
    const file = e.target.files[0];
    if (file) {
      const fileName = file.name;
      const extension = fileName.split('.').pop().toLowerCase();
      if (extension === 'zip') {
        setSelectedFile({
          file: e.target.files,
          filename: fileName,
        });
      } else {
        // alert('Please select a ZIP file.');
        setOpenAlertForZip(true);
        e.target.value = null;
      }
    }
  };
  const handleUploadNew = (e) => {
    // if (e.target.files) {
    //   setUploadNew({
    //     file: e.target.files,
    //     filename: e.target.files[0].name,
    //   });
    // }
    const file = e.target.files[0];
    if (file) {
      const fileName = file.name;
      const extension = fileName.split('.').pop().toLowerCase();
      if (extension === 'zip') {
        setUploadNew({
          file: e.target.files,
          filename: fileName,
        });
      } else {
        setOpenAlertForZip(true);
        e.target.value = null;
      }
    }
  }

  const handleUploadNewCustom = (e) => {
    // if (e.target.files) {
    //   setUploadNew({
    //     file: e.target.files,
    //     filename: e.target.files[0].name,
    //   });
    // }
    const file = e.target.files[0];
    if (file) {
      const fileName = file.name;
      const extension = fileName.split('.').pop().toLowerCase();
      if (extension === 'zip') {
        setUploadNewCustom({
          file: e.target.files,
          filename: fileName,
        });
      } else {
        setOpenAlertForZip(true);
        e.target.value = null;
      }
    }
  }

  useEffect(() => {
    getProduct();
  }, []);
  // useEffect(() => {
  //   getFileNewData()
  // },[])
  const getProduct = async () => {
    await fetchProducts()
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
          setProductList(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }
  const getModules = async (id) => {
    await fetchModules(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === 'fail') {
            CommonMethods(navigate);
          } else {
            setModuleList(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const selectedProduct = (event) => {
    setProduct(event.target.value);
    getModules(event.target.value);
    getSuiteCase(event.target.value);
    getFileNewData(event.target.value);
  }
  const selectedModule = (event) => {
    setModule(event.target.value);
    getTestCasefromModule(event.target.value);
  }
  const getSuiteCase = async (id) => {
    await fetchSuiteCase(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === 'fail') {
            CommonMethods(navigate);
          } else {
            formateSuiteprops(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formateSuiteprops = (data) => {
    setSuiteCaseList(
      data.map((item) => {
        return {
          ...item[0],
          list: item[0].list.map((subitem) => subitem.testcases[0]),
        };
      })
    );
  };
  const getTestCasefromModule = async (id) => {
    await fetchTestCasesfromModule(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === 'fail') {
            CommonMethods(navigate);
          } else {
            setTestCaseList(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getTableTestData = async () => {
    await getTestData(comanyid, product, module, idtestCase, suiteid)
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
          setTableData(response.data)
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }
  const getUploadFile = () => {
    fetch(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=uploadZipFile&companyid=${temp.companyid}&token=${temp.token}&fileName=${selectedFile.file[0].name}`,
      {
        method: "POST",
        body: selectedFile.file[0],
        headers: {
          "content-type": selectedFile.file[0].type,
          "content-length": `${selectedFile.file[0].size}`,
        }
      }
    ).then((res) => {
      res.json();
      setSelectedFile({
        file: "",
        filename: "",
      });
      setOpenAlert(true);
    })
      .catch((error) => {
        console.log(error);
      })

  }
  const handleUploadNewFile = () => {
    fetch(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=uploadTestDataFile&companyid=${temp.companyid}&token=${temp.token}&prodid=${product}&fileName=${uploadnew.file[0].name}`,
      {
        method: "POST",
        body: uploadnew.file[0],
        headers: {
          "content-type": uploadnew.file[0].type,
          "content-length": `${uploadnew.file[0].size}`,
        }
      }
    ).then((res) => {
      res.json();
      setUploadNew({
        file: "",
        filename: "",
      });
      setOpenAlert(true);
    })
      .catch((error) => {
        console.log(error);
      })

  }

  const handleUploadCustomFile = () => {
    fetch(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=uploadFuntionZipFile&companyid=${temp.companyid}&token=${temp.token}&fileName=${uploadNewCustom.file[0].name}`,
      {
        method: "POST",
        body: uploadNewCustom.file[0],
        headers: {
          "content-type": uploadNewCustom.file[0].type,
          "content-length": `${uploadNewCustom.file[0].size}`,
        }
      }
    ).then((res) => {
      res.json();
      setUploadNewCustom({
        file: "",
        filename: "",
      });
      setOpenAlert(true);
    })
      .catch((error) => {
        console.log(error);
      })

  }

  const [refreshStatus, setRefreshStatus] = useState("");
  const [rereshCompleted, setRefreshCompleted] = useState(false);
  const getRefreshZip = async () => {
    await refreshZip()
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
          // if (response.data.refresh === "running") {
          setRefreshTable(false);
          setRefreshTableData(response.data.detail);
          // }
          setRefreshStatus(response.data);
          setRefreshCompleted(true);
        }
      }).catch((error) => {
        console.log(error);
      })
  }
  const handleUploadButton = async () => {
    getUploadFile();
    getRefreshZip();
    try {
      await getRefreshZip();
      while (refreshStatus.refresh === 'running') {
        await new Promise((resolve) => setInterval(resolve, 5000));
        await getRefreshZip();
      }
      if (refreshStatus.refresh === 'Completed') {
        await getRefreshZip();
      }
    } catch (error) {
      console.error('Error:', error);
    }
    // if (refreshStatus.refresh === "Completed") {
    //   getRefreshZip();
    // }
    // else {
    //   while (refreshStatus.refresh === "running") {
    //      setInterval(() => {
    //     getRefreshZip();
    //   },5000)
    //   }

    // }
  }
  const getFileNewData = async (product) => {
    await getFileData(product)
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
          setFormDataTable(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const getFileNewCustomData = async () => {
    await getFileCustomData()
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
          setFormCustomDataTable(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const deleteProductFIleData = async () => {
    await deleteProductFIle(selectedItems)
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
          getFileNewData(product);
          setSelectedData([]);
          setSelectedItems([]);
        }
      }).catch((error) => {
        console.log(error)
      });
    setSelectedData([]);
    setSelectedItems([]);
  }

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });
  const newData = () => {
    return (
      <div>
        <div
          className="testdat_header"
          style={{
            backgroundColor: colors?.secondaryColor,
            color: colors?.primaryColor,
          }}
        >
          <Typography paddingBottom={0} variant="h6" component="h1"
            sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
          >
            New Data
          </Typography>
          <div  >
            <div className="subheads">
              <FormControl sx={{ width: 240, color: colors.PrimaryColor }}>
                <InputLabel
                  style={{
                    color: colors.primaryColor,
                    backgroundColor: colors.secondaryColor,
                    padding: 5,
                  }}
                  id={"product"}
                >
                  Product
                </InputLabel>
                <Select
                  labelId={"product"}
                  id={"product Select"}
                  value={product}
                  onChange={selectedProduct}
                  name={"product"}
                  label="product"
                  IconComponent={(props) => (
                    <ArrowDropDownSharpIcon
                      style={{ color: colors.primaryColor, fontSize: 40 }}
                      {...props}
                    />
                  )}
                  sx={{
                    color: colors.primaryColor,
                    border: `2px solid ${colors.primaryColor}`,
                    fontSize: 16,
                    "&:focus": {
                      borderRadius: 4,
                      border: `4px solid ${colors.primaryColor}`,
                    },
                  }}
                  style={{
                    minWidth: 100,
                    marginRight: "2rem",
                  }}
                >
                  {productList.map((product, index) => (
                    <MenuItem key={index} value={product.idproducts}>
                      {product.productname}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {product !== "" && (
                <ColorButton
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                  sx={{ marginLeft: 4 }}
                >
                  Upload new
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleUploadNew}
                    accept=".zip"
                  />
                </ColorButton>
              )}
              {uploadnew.file[0] && (
                <Typography variant="body2" sx={{ marginLeft: 2 }}>
                  {uploadnew.file[0].name}
                </Typography>
              )}
              {uploadnew.file[0] && (
                <ColorButton variant="contained"
                  sx={{ marginLeft: 10 }}
                  onClick={() => {
                    handleUploadNewFile();
                    setTimeout(() => {
                      getFileNewData(product);
                    }, 2000)
                  }
                  }
                >
                  Upload
                </ColorButton>
              )}
              {selectedItems.length > 0 && (
                <ColorButton variant='contained'
                  sx={{ marginLeft: 100 }}
                  onClick={deleteProductFIleData}
                >
                  Delete Multiple
                </ColorButton>
              )}

            </div>
          </div>
        </div>
        <div
        //  style={{
        //     overflow: "hidden",
        //      height: "700px"
        //     }}
        >
          <Table
            stickyHeader aria-label="a dense table" sx={{ minWidth: 650 }}
          >
            <TableHead >
              <StyledTableRow >
                {tableColumnsFromData.map((item) => (
                  <>
                    {/* <StyledTableCell colSpan={1} /> */}
                    <StyledTableCell key={item.id} align={item.align} colSpan={item.colspan} width={item.width}>
                      {item.name}
                    </StyledTableCell>
                  </>
                ))}
                <StyledTableCell />
                <StyledTableCell colSpan={1} />
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {
                formDataTable.map((item) => {
                  return (
                    <React.Fragment>
                      <StyledTableRow
                      >
                        <StyledTableCell width='15%' align="center" colSpan={1}>
                          <Checkbox {...label}
                            checked={selectedData.includes(item.FileName)}
                            onChange={(event) => handleCheckboxToggle(event, item)}

                          />
                        </StyledTableCell>
                        <StyledTableCell width='30%' align="center" colSpan={1} sx={{ fontSize: 20 }}>
                          {item.FileName}
                        </StyledTableCell>
                        <StyledTableCell width='30%' align="center" colSpan={2}>
                          {item.LastModified}
                        </StyledTableCell>
                        <StyledTableCell width='20%' align="center" colSpan={1}>
                          {item.FileSize}
                        </StyledTableCell>
                      </StyledTableRow>
                    </React.Fragment>
                  )
                })
              }
              {/* <StyledTableRow
                     >
                        <StyledTableCell  width='6%' align="center" colSpan={1}>
                             <Checkbox {...label}  />
                         </StyledTableCell>
                        <StyledTableCell  width='20%' align="center" colSpan={1}>
                            Field Name
                         </StyledTableCell>
                        <StyledTableCell  width='20%' align="center" colSpan={2}>
                          Last Modify
                        </StyledTableCell>
                      </StyledTableRow> */}
            </TableBody>
          </Table>
        </div>
      </div>
    )
  }
  const customFunction = () => {
    return (
      <div>
        <div
          className="testdat_header"
          style={{
            backgroundColor: colors?.secondaryColor,
            color: colors?.primaryColor,
          }}
        >
          <Typography paddingBottom={0} variant="h6" component="h1"
            sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
          >
            New Data
          </Typography>
          <div  >
            <div className="subheads">
            <FormControl sx={{ width: 240, color: colors.PrimaryColor }}>
              <InputLabel
                style={{
                  color: colors.primaryColor,
                  backgroundColor: colors.secondaryColor,
                  padding: 5,
                }}
                id={"Company Name"}
              >
                Company Name
              </InputLabel>
              <Select
                labelId={"Company name"}
                id={"Company name Select"}
                value={comanyid}
                onChange={(e) => {
                   setCompanyid(e.target.value);
                   getFileNewCustomData();
                  } 
                  }
                name={"company"}
                label="Company"
                IconComponent={(props) => (
                  <ArrowDropDownSharpIcon
                    style={{ color: colors.primaryColor, fontSize: 40 }}
                    {...props}
                  />
                )}
                sx={{
                  color: colors.primaryColor,
                  border: `2px solid ${colors.primaryColor}`,
                  fontSize: 16,
                  "&:focus": {
                    borderRadius: 4,
                    border: `4px solid ${colors.primaryColor}`,
                  },
                }}
                style={{
                  minWidth: 100,
                  marginRight: "2rem",
                }}
              >
                <MenuItem value={1} > {temp.company.companyname} </MenuItem>
              </Select>
            </FormControl>
              {comanyid && (
                <ColorButton
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                  sx={{ marginLeft: 4 }}
                >
                  Upload new
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleUploadNewCustom}
                    accept=".zip"
                  />
                </ColorButton>
              )}
              {uploadNewCustom.file[0] && (
                <Typography variant="body2" sx={{ marginLeft: 2 }}>
                  {uploadNewCustom.file[0].name}
                </Typography>
              )}
              {uploadNewCustom.file[0] && (
                <ColorButton variant="contained"
                  sx={{ marginLeft: 10 }}
                  onClick={() => {
                    handleUploadCustomFile();
                    setTimeout(() => {
                      getFileNewCustomData();
                    }, 2000)
                  }
                  }
                >
                  Upload
                </ColorButton>
              )}
              {selectedItems.length > 0 && (
                <ColorButton variant='contained'
                  sx={{ marginLeft: 100 }}
                  onClick={deleteProductFIleData}
                >
                  Delete Multiple
                </ColorButton>
              )}

            </div>
          </div>
        </div>
        <div
        //  style={{
        //     overflow: "hidden",
        //      height: "700px"
        //     }}
        >
          <Table
            stickyHeader aria-label="a dense table" sx={{ minWidth: 650 }}
          >
            <TableHead >
              <StyledTableRow >
                {tableColumnsFromData.map((item) => (
                  <>
                    {/* <StyledTableCell colSpan={1} /> */}
                    <StyledTableCell key={item.id} align={item.align} colSpan={item.colspan} width={item.width}>
                      {item.name}
                    </StyledTableCell>
                  </>
                ))}
                <StyledTableCell />
                <StyledTableCell colSpan={1} />
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {
                formCustomDataTable.map((item) => {
                  return (
                    <React.Fragment>
                      <StyledTableRow
                      >
                        <StyledTableCell width='15%' align="center" colSpan={1}>
                          <Checkbox {...label}
                            checked={selectedData.includes(item.FileName)}
                            onChange={(event) => handleCheckboxToggle(event, item)}

                          />
                        </StyledTableCell>
                        <StyledTableCell width='30%' align="center" colSpan={1} sx={{ fontSize: 20 }}>
                        <a href={item.link} target="_blank">{item.FileName}</a>
                        </StyledTableCell>
                        <StyledTableCell width='30%' align="center" colSpan={2}>
                          {item.LastModified}
                        </StyledTableCell>
                        <StyledTableCell width='20%' align="center" colSpan={1}>
                          {item.FileSize}
                        </StyledTableCell>
                      </StyledTableRow>
                    </React.Fragment>
                  )
                })
              }
              {/* <StyledTableRow
                     >
                        <StyledTableCell  width='6%' align="center" colSpan={1}>
                             <Checkbox {...label}  />
                         </StyledTableCell>
                        <StyledTableCell  width='20%' align="center" colSpan={1}>
                            Field Name
                         </StyledTableCell>
                        <StyledTableCell  width='20%' align="center" colSpan={2}>
                          Last Modify
                        </StyledTableCell>
                      </StyledTableRow> */}
            </TableBody>
          </Table>
        </div>
      </div>
    )
  }
  const refreshData = () => {
    return (
      <div>
        <div className="testdat_header"
          style={{
            backgroundColor: colors?.secondaryColor,
            color: colors?.primaryColor,
          }}
        >
          {/* <div className="adjust" style={{backgroundColor: colors?.secondaryColor,color: colors?.primaryColor,}}>
                  <Typography paddingBottom={0} variant="h6" component="h1">
                     Test Data
                    </Typography>
              </div> */}
          <Box
            sx={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
              // paddingX: "1.5rem",
              height: 80,
              backgroundColor: colors.SecondaryColor,
              color: colors.PrimaryColor,
            }}
          >
            <Typography paddingBottom={0} variant="h6" component="h1" sx={{ marginLeft: "53rem", marginBottom: "5px" }}>
              Refresh Data
            </Typography>
            <Box
              sx={{
                display: "flex",
                ml: "5rem",
                //  justifyContent: "flex-end",
                alignItems: "center",
                //  flexDirection: "row",
                //  paddingY: "1.5rem",
                //  backgroundColor: colors.secondaryColor,
                //  color: colors.primaryColor,
                marginRight: '2rem'
              }}
            >
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  marginLeft: "0rem",
                  display: "flex",
                  //  alignItems: "center",
                  width: 400,
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search Test "
                  inputProps={{ "aria-label": "Search Test" }}
                // defaultValue={SearchString}
                // onChange={(e) => {
                //   e.preventDefault();
                //   setSearchString(e.target.value);
                // }}
                />
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                // onClick={() => {
                //   searchTestCaseInMod(state.idmodules, SearchString);
                // }}
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </Box>
          </Box>
          {/* <div> */}
          <div className="subheads">
            <FormControl sx={{ width: 240, color: colors.PrimaryColor }}>
              <InputLabel
                style={{
                  color: colors.primaryColor,
                  backgroundColor: colors.secondaryColor,
                  padding: 5,
                }}
                id={"Company Name"}
              >
                Company Name
              </InputLabel>
              <Select
                labelId={"Company name"}
                id={"Company name Select"}
                value={comanyid}
                onChange={(e) => setCompanyid(e.target.value)}
                name={"company"}
                label="Company"
                IconComponent={(props) => (
                  <ArrowDropDownSharpIcon
                    style={{ color: colors.primaryColor, fontSize: 40 }}
                    {...props}
                  />
                )}
                sx={{
                  color: colors.primaryColor,
                  border: `2px solid ${colors.primaryColor}`,
                  fontSize: 16,
                  "&:focus": {
                    borderRadius: 4,
                    border: `4px solid ${colors.primaryColor}`,
                  },
                }}
                style={{
                  minWidth: 100,
                  marginRight: "2rem",
                }}
              >
                <MenuItem value={1} > {temp.company.companyname} </MenuItem>
              </Select>
            </FormControl>
            {comanyid && (
              <FormControl sx={{ width: 240, color: colors.PrimaryColor }}>
                <InputLabel
                  style={{
                    color: colors.primaryColor,
                    backgroundColor: colors.secondaryColor,
                    padding: 5,
                  }}
                  id={"product"}
                >
                  Product
                </InputLabel>
                <Select
                  labelId={"product"}
                  id={"product Select"}
                  value={product}
                  onChange={selectedProduct}
                  name={"product"}
                  label="product"
                  IconComponent={(props) => (
                    <ArrowDropDownSharpIcon
                      style={{ color: colors.primaryColor, fontSize: 40 }}
                      {...props}
                    />
                  )}
                  sx={{
                    color: colors.primaryColor,
                    border: `2px solid ${colors.primaryColor}`,
                    fontSize: 16,
                    "&:focus": {
                      borderRadius: 4,
                      border: `4px solid ${colors.primaryColor}`,
                    },
                  }}
                  style={{
                    minWidth: 100,
                    marginRight: "2rem",
                  }}
                >
                  {productList.map((product, index) => (
                    <MenuItem key={index} value={product.idproducts}>
                      {product.productname}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {/*  */}
            {product && (
              <Box
                style={{
                  marginRight: "2rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {suiteid === "" && (
                  <FormControl sx={{ width: 240, color: colors.PrimaryColor }}>
                    <InputLabel
                      style={{
                        color: colors.primaryColor,
                        backgroundColor: colors.secondaryColor,
                        padding: 5,
                      }}
                      id={"Module "}
                    >
                      Module
                    </InputLabel>
                    <Select
                      labelId={"Module"}
                      id={"Module Select"}
                      value={module}
                      onChange={selectedModule}
                      name={"Module"}
                      label="Module"
                      IconComponent={(props) => (
                        <ArrowDropDownSharpIcon
                          style={{ color: colors.primaryColor, fontSize: 40 }}
                          {...props}
                        />
                      )}
                      sx={{
                        color: colors.primaryColor,
                        border: `2px solid ${colors.primaryColor}`,
                        fontSize: 16,
                        "&:focus": {
                          borderRadius: 4,
                          border: `4px solid ${colors.primaryColor}`,
                        },
                      }}
                      style={{
                        minWidth: 100,
                      }}
                    >
                      {ModuleList.map((item, index) => (
                        <MenuItem key={index} value={item.idmodules}>
                          {item.modulename}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {
                  module === "" &&
                  suiteid === "" && (
                    <Box
                      sx={{
                        marginX: "1rem",
                      }}
                    >
                      or
                    </Box>
                  )
                }
                {module === "" && (
                  <FormControl sx={{ width: 240 }}>
                    <InputLabel
                      style={{
                        color: colors.primaryColor,
                        backgroundColor: colors.secondaryColor,
                        padding: 5,
                      }}
                      id={"Test Suite"}
                    >
                      Test Suite
                    </InputLabel>
                    <Select
                      labelId={"Test Suite name"}
                      IconComponent={(props) => (
                        <ArrowDropDownSharpIcon
                          style={{ color: colors.primaryColor, fontSize: 40 }}
                          {...props}
                        />
                      )}
                      sx={{
                        color: colors.primaryColor,
                        border: `2px solid ${colors.primaryColor}`,
                        fontSize: 16,
                        "&:focus": {
                          borderRadius: 4,
                          border: `4px solid ${colors.primaryColor}`,
                        },
                      }}
                      id={"Test Suite name Select"}
                      value={suiteid}
                      label="Test Suite"
                      onChange={(event) => {
                        setSuiteid(event.target.value);
                      }}
                      style={{
                        minWidth: 100,
                      }}
                    >
                      {SuiteCaseList.map((item, index) => (
                        <MenuItem key={index} value={item.idtest_suite}>
                          {item.Test_Suite}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Box>
            )}
            {module !== "" && (
              <FormControl
                sx={{
                  width: 240,
                }}
              >
                <InputLabel
                  style={{
                    color: colors.primaryColor,
                    backgroundColor: colors.secondaryColor,
                    padding: 5,
                  }}
                  id={"Test Case name"}
                >
                  Test Case
                </InputLabel>
                <Select
                  labelId={"Test Case name"}
                  IconComponent={(props) => (
                    <ArrowDropDownSharpIcon
                      style={{ color: colors.primaryColor, fontSize: 40 }}
                      {...props}
                    />
                  )}
                  sx={{
                    color: colors.primaryColor,
                    border: `2px solid ${colors.primaryColor}`,
                    fontSize: 16,
                    "&:focus": {
                      borderRadius: 4,
                      border: `4px solid ${colors.primaryColor}`,
                    },
                  }}
                  id={"Test Case name Select"}
                  value={idtestCase}
                  label="Test Case"
                  onChange={(event) => {
                    setIdTestCase(event.target.value)
                  }}
                  style={{
                    minWidth: 100,
                  }}
                >
                  {TestCaseList.map((item, index) => (
                    <MenuItem key={index} value={item.idtest_case}>
                      {item.Test_Case}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {(idtestCase !== "" || suiteid !== "" || module !== "") && (
              <>
                <Tooltip title={"Clear Filter"}>
                  <IconButton
                    size="small"
                    variant="contained"
                    onClick={(event) => {
                      setProduct("")
                      setSuiteid("")
                      setIdTestCase("")
                      setModule("")
                      // setCompanyid("");
                    }}
                    style={{
                      color: colors.primaryColor,
                      backgroundColor: colors.secondaryColor,
                      marginLeft: 20,
                      // fontSize: '32px',
                    }}
                  >
                    <ClearAllIcon style={{ fontSize: 50 }} />
                  </IconButton>
                </Tooltip>
              </>
            )}
            {/*  */}
            {comanyid && (
              <ColorButton
                variant="contained"
                //  size="small"
                sx={{ marginLeft: 4, width: 90 }}
                onClick={() => {
                  getTableTestData();
                  setShowChooseFile(true);
                }}
              >
                Get
              </ColorButton>
            )}
            {showChooseFile === true && (
              <ColorButton
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
                sx={{ marginLeft: 4 }}
              >
                Choose File
                <input
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleFileInputChange}
                  accept=".zip"
                />
              </ColorButton>
            )}
            {selectedFile.file[0] && (
              <Typography variant="body2" sx={{ marginLeft: 2 }}>
                {selectedFile.file[0].name}
              </Typography>
            )}
            {selectedFile.file[0] && (
              <ColorButton variant="contained"
                sx={{ marginLeft: 10 }}
                onClick={handleUploadButton}
              >
                Upload
              </ColorButton>
            )}
          </div>
        </div>

        {/* </div> */}
        <div
        //   style={{
        //    overflow: "hidden",
        //    height: "700px"
        // }}
        >
          <Table
            stickyHeader aria-label="a dense table" sx={{ minWidth: 650 }}
          >
            <TableHead >
              <StyledTableRow >
                {tableColums.map((item) => (
                  <StyledTableCell key={item.id} align={item.align} colSpan={item.colspan} width={item.width}>
                    {item.name}
                  </StyledTableCell>
                ))}
                {/* {state.display === "execute" && (
                    <StyledTableCell align="right">Status</StyledTableCell>
                  )} */}
                <StyledTableCell />
                <StyledTableCell colSpan={1} />
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {
                refreshTable === true && (
                  <>
                    {
                      TableData.map((item) => {
                        return (
                          <React.Fragment>
                            <StyledTableRow
                            >
                              <StyledTableCell width='15%' align="center" colSpan={1}>
                                {item.TestcaseName}
                              </StyledTableCell>
                              <StyledTableCell width='14%' align="center" colSpan={2}>
                                {/* {item.IdTeststep} */}
                              </StyledTableCell>
                              <StyledTableCell width='15%' align="center" colSpan={1}>
                                {item.IdTeststep}
                              </StyledTableCell>
                              <StyledTableCell width='10%' align="center" colSpan={1}>
                                {item.TeststepDesc}
                              </StyledTableCell>
                              <StyledTableCell width='20%' align="center" colSpan={3}>
                                {item.FileName}
                              </StyledTableCell>
                              <StyledTableCell width='20%' align="center" colSpan={1}>
                                {item.FileField}
                              </StyledTableCell>
                              <StyledTableCell width='10%' align="center" colSpan={2}>
                                {
                                  item.status === "true" ? (
                                    // <Box
                                    //   sx={{
                                    //     alignItems: "center",
                                    //     display: "flex",
                                    //     justifyContent: "center",
                                    //     paddingY: 1,
                                    //     color: "#FFF",
                                    //     borderRadius: "0.5rem",
                                    //     backgroundColor: "green",
                                    //     width:80,
                                    //     fontSize: "1rem",
                                    //     fontWeight: "700",
                                    //   }}
                                    //     >
                                    <CheckCircleIcon sx={{ color: 'green' }} />
                                    //  </Box>
                                  ) : item.status === "false" ? (
                                    //       <Box
                                    // sx={{
                                    //   alignItems: "center",
                                    //   display: "flex",
                                    //   justifyContent: "center",
                                    //   paddingY: 1,
                                    //   color: "#FFF",
                                    //   borderRadius: "0.5rem",
                                    //   backgroundColor: "red",
                                    //   width:80,
                                    //   fontSize: "1rem",
                                    //   fontWeight: "700",
                                    // }}
                                    //   >
                                    <CancelIcon color="inherited" />
                                    //  </Box>
                                  ) :
                                    //  <Box
                                    //    sx={{
                                    //    alignItems: "center",
                                    //    display: "flex",
                                    //    justifyContent: "center",
                                    //    paddingY: 1,
                                    //    color: "#FFF",
                                    //    borderRadius: "0.5rem",
                                    //    backgroundColor: "green",
                                    //    width:80,
                                    //    fontSize: "1rem",
                                    //    fontWeight: "700",
                                    //  }}
                                    //   >
                                    <BlockIcon color="inherited" />
                                  //  </Box>
                                }
                              </StyledTableCell>
                            </StyledTableRow>
                          </React.Fragment>
                        )
                      })
                    }
                  </>
                )
              }
              {
                refreshTable === false && (
                  <>
                    {
                      refreshTableData.map((item) => {
                        return (
                          <React.Fragment>
                            <StyledTableRow
                            >
                              <StyledTableCell width='15%' align="center" colSpan={1}>
                                {item.TestcaseName}
                                {/* gggggg */}
                              </StyledTableCell>
                              <StyledTableCell width='14%' align="center" colSpan={2}>
                                {/* {item.IdTeststep} */}
                              </StyledTableCell>
                              <StyledTableCell width='15%' align="center" colSpan={1}>
                                {item.IdTeststep}
                                {/* tttttttt */}
                              </StyledTableCell>
                              <StyledTableCell width='10%' align="center" colSpan={1}>
                                {item.TeststepDesc}
                              </StyledTableCell>
                              <StyledTableCell width='20%' align="center" colSpan={3}>
                                {item.FileName}
                              </StyledTableCell>
                              <StyledTableCell width='20%' align="center" colSpan={1}>
                                {item.FileField}
                              </StyledTableCell>
                              <StyledTableCell width='10%' align="center" colSpan={2}>
                                {
                                  item.status === "true" ? (
                                    // <Box
                                    //   sx={{
                                    //     alignItems: "center",
                                    //     display: "flex",
                                    //     justifyContent: "center",
                                    //     paddingY: 1,
                                    //     color: "#FFF",
                                    //     borderRadius: "0.5rem",
                                    //     backgroundColor: "green",
                                    //     width:80,
                                    //     fontSize: "1rem",
                                    //     fontWeight: "700",
                                    //   }}
                                    //     >
                                    <CheckCircleIcon sx={{ color: "green" }} />
                                    //  </Box>
                                  ) : item.status === "false" ? (
                                    //       <Box
                                    // sx={{
                                    //   alignItems: "center",
                                    //   display: "flex",
                                    //   justifyContent: "center",
                                    //   paddingY: 1,
                                    //   color: "#FFF",
                                    //   borderRadius: "0.5rem",
                                    //   backgroundColor: "red",
                                    //   width:80,
                                    //   fontSize: "1rem",
                                    //   fontWeight: "700",
                                    // }}
                                    //   >
                                    <CancelIcon color="inherited" />
                                    //  </Box>
                                  ) :
                                    //  <Box
                                    //     sx={{
                                    //       alignItems: "center",
                                    //       display: "flex",
                                    //       justifyContent: "center",
                                    //       paddingY: 1,
                                    //       color: "#FFF",
                                    //       borderRadius: "0.5rem",
                                    //       backgroundColor: "red",
                                    //       width:80,
                                    //       fontSize: "1rem",
                                    //       fontWeight: "700",
                                    //   }}
                                    //     >
                                    <BlockIcon color="inherited" />
                                  //  </Box>
                                }
                              </StyledTableCell>
                            </StyledTableRow>
                          </React.Fragment>
                        )
                      })
                    }
                  </>
                )
              }
            </TableBody>
          </Table>
        </div>
      </div>
    )
  }
  return (
    // Tab start///
    <Box
      sx={{
        display: "flex",
        flex: 1,
        alignItem: "center",
        justifyContent: "space-evenly",
        paddingTop: "2rem",
        flexDirection: "column",
        paddingX: "2rem",
      }}
    >
      {/* <Card
        sx={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          paddingY: "1.5rem",
          backgroundColor: colors.secondaryColor,
          color: colors.primaryColor,
        }}
      >
        <Typography paddingBottom={0} variant="h6" component="h2">
          Test Data
        </Typography>
      </Card> */}

      {/* <Box 
        sx={{ 
          bgcolor: 'background.paper',        
          display: "flex",
            flexDirection: "column",
          }}
        >
      <AppBar position="static" style={{backgroundColor:"transparent"}}>
        <Tabs
          value={value}
          onChange={handleChange}
          // indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
              id="tabs"
              style={{width:"100%"}}
        >
          <Tab style={{color:colors.primaryColor,backgroundColor:colors.secondaryColor}} label="New Data" {...a11yProps(0)}  className="tab1" />
          <Tab style={{color:colors.primaryColor,backgroundColor:colors.secondaryColor}} label="Refresh Data" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        // axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
          onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction} >
          {newData()}
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          {refreshData()}
        </TabPanel>
      </SwipeableViews>
    </Box> */}
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', marginLeft: 4 }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="New Data" value="1" />
              <Tab label="Refresh Data" value="2" />
              <Tab label="Custom Function" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">{newData()}</TabPanel>
          <TabPanel value="2"> {refreshData()}</TabPanel>
          <TabPanel value="3"> {customFunction()}</TabPanel>

        </TabContext>
      </Box>
      {openAlert && (
        <Alert
          variant="filled"
          severity="success"
          onClose={handleCloseAlert}
          open={openAlert}
          className="alert"
        >
          file is successfully uploaded.
        </Alert>
      )}
      {openAlertForZip && (
        <Alert
          variant="filled"
          severity="warning"
          onClose={handleClickOpenAlert}
          open={openAlertForZip}
          className="alert"
        >
          Please select a ZIP file.
        </Alert>
      )}
      {
        deleteTestcasesPopup && (
          <Alert
            variant="filled"
            severity="warning"
            className="delete-suite"
            style={{
              color: colors?.secondaryColor,
              border: " 4px black solid",
              width: "550px",
              height: '150px',
              borderRadius: '10px'
            }}
          >
            <span className="delete-text">
              Are you sure you want to delete this Test Case?
            </span>
            <div className="delete_button">
              <ColorButton
                size="small"
                variant="contained"
                sx={{ margin: "10px 2px 10px 5px" }}
              // onClick={() => {
              //   addordelete("deleteTCfromTSuite");
              //   setDeleteTestcasesPopup(false);
              // }}
              >
                yes
              </ColorButton>
              <ColorButton
                size="small"
                variant="contained"
                sx={{ margin: "10px 2px 10px 5px" }}
              // onClick={() => setDeleteTestcasesPopup(!deleteTestcasesPopup)}
              >
                no
              </ColorButton>
            </div>
          </Alert>
        )
      }
    </Box>
    // Tab End///

  )
}
export default TestData;