import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, styled, Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import SyncDisabledIcon from "@mui/icons-material/SyncDisabled";

import { EditHeader, Settings, StudioSelection, TSMenu } from "./Studio";
import {
  useTestCaseResultsQuery,
  useRecentTestCaseResultsQuery,
  useTSRQuery,
  useAddStepQuery,
  useExecuteTCQuery,
  useProductQuery,
  useModuleQuery,
  useTestCaseQuery,
  useGetEnvQuery,
} from "../useQuery";
import { ContextIcon, RefreshIcon, ExportIcon, VisiblityIcon } from "../components-new/icons";
import { IconContainer, PageTitle, TestItemCard } from "../components-new/common";
import EditTabs from "./Studio/EditTabs";
import {
  setEditItemIndex,
  setEditStep,
  setSelectedComponent,
  setSelectedProduct,
  setSelectedTestCase,
  setSelectedTestCaseItem,
  setTestCases,
  setTsrData,
} from "../Redux/TestAuthoring/TestAuthoring";
import useSaveStepQuery from "../useQuery/useSaveStepQuery";
import { useLocation, useNavigate } from "react-router-dom";
import { useItemWidth, useNotification } from "../hooks";

const StyledButton = styled(Button)`
  padding: 15px 28px;
  border-radius: 8px;
  text-transform: capitalize;
`;

const TestStudio = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const notify = useNotification();
  const RouteState = useLocation();
  const containerRef = useRef(null);
  const itemsPerRow = 4;
  const gap = 32;
  const itemWidth = useItemWidth(containerRef, itemsPerRow, gap);

  const [refreshFlag, setRefreshFlag] = useState(true);
  const [editData, setEditData] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = useState(false);
  const [pageTitle, setPageTitle] = useState("");
  const [testCaseSearchKeyword, setTestCaseSearchKeyword] = useState("");

  const selectedTestCase = useSelector((state) => state.authoringSlice.selectedTestCase);
  const selectedProduct = useSelector((state) => state.authoringSlice.selectedProduct);
  const selectedModule = useSelector((state) => state.authoringSlice.selectedComponent);
  const currentEditItemIndex = useSelector((state) => state.authoringSlice.editItemIndex);
  const [isProductSetInitially, setIsProductSetInitially] = useState(false);

  const { data: products, refetch: refetchProductQuery } = useProductQuery();
  const { data: modules, refetch: refetchModuleQuery } = useModuleQuery(selectedProduct, {
    enabled: selectedProduct !== "" && selectedProduct !== 0,
  });
  // eslint-disable-next-line no-unused-vars
  const { data: envDetails, refetch: refetchEnvQuery } = useGetEnvQuery(selectedProduct);

  const {
    data: testCases,
    isLoadingTestcase,
    refetch: refetchTestCases,
  } = useTestCaseQuery(selectedModule, testCaseSearchKeyword, {
    enabled: selectedModule !== "" && selectedModule !== 0,
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const {
    data: testCaseResults,
    isLoading,
    isSuccess,
  } = useTestCaseResultsQuery(selectedTestCase, {
    enabled: selectedTestCase !== "",
  });

  const { mutate: addStep, isSuccess: isAddSuccess } = useAddStepQuery();
  const { mutate: saveStep, isSuccess: isSaveSuccess } = useSaveStepQuery();
  const { mutate: executeTest, isSuccess: isExecuteSuccess } = useExecuteTCQuery();

  const { data: latestRecordings } = useRecentTestCaseResultsQuery({
    refetchInterval: refreshFlag ? 5000 : null,
  });
  const { data: tsrData } = useTSRQuery(selectedTestCase);

  useEffect(() => {
    if (selectedModule) refetchTestCases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedModule]);

  useEffect(() => {
    refetchProductQuery();
    if (RouteState?.state?.fromExecutionReports) {
      setRefreshFlag(false);
      dispatch(setSelectedProduct(RouteState?.state?.idproducts));
      dispatch(setSelectedComponent(RouteState?.state?.idmodules));
      dispatch(setSelectedTestCase(RouteState?.state?.idtest_case));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RouteState?.state, dispatch]);

  useEffect(() => {
    refetchModuleQuery();
  }, [refetchModuleQuery, selectedProduct]);

  useEffect(() => {
    if (isAddSuccess || isSaveSuccess) {
      setOpen(true);
      notify("Step updated successfully", "success");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveSuccess, isAddSuccess]);

  useEffect(() => {
    dispatch(setEditStep(""));
    dispatch(setEditItemIndex(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (testCaseResults) dispatch(setTestCases(testCaseResults.data ?? []));
  }, [dispatch, testCaseResults]);

  useEffect(() => {
    dispatch(setTsrData(tsrData));
  }, [dispatch, tsrData]);

  useEffect(() => {
    if (currentEditItemIndex != null) {
      const currentEditItem = testCaseResults?.data[currentEditItemIndex];
      console.log(currentEditItem, "curr");
      setEditData(currentEditItem);
      dispatch(setEditStep(currentEditItem));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEditItemIndex]);

  useEffect(() => {
    if (!selectedProduct && latestRecordings && !isProductSetInitially) {
      dispatch(setSelectedProduct(latestRecordings.Product));
      dispatch(setSelectedComponent(latestRecordings.Modules));
      dispatch(setSelectedTestCase(latestRecordings.Testcase));
      setPageTitle(latestRecordings?.testcase?.Test_Case);
      setIsProductSetInitially(true);
      setTestCaseSearchKeyword(latestRecordings.testcase.Test_Case);
    }
  }, [latestRecordings, dispatch, selectedProduct, isProductSetInitially]);

  useEffect(() => {
    if (isSuccess) {
      setPageTitle(testCaseResults.testcase.Test_Case);
      dispatch(setSelectedTestCaseItem(testCaseResults.testcase));
    }
  }, [dispatch, isSuccess, selectedProduct, testCaseResults]);

  useEffect(() => {
    if (isExecuteSuccess) navigate("/new/reports", { state: { 
      isExecuteClick: true, fromTestStudio: true, idproducts: selectedProduct,
      idmodules: selectedModule,
      idtest_case: selectedTestCase,
      Test_Case: decodeURI(testCaseResults.testcase.Test_Case) } });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExecuteSuccess]);

  const handleEdit = (item) => {
    setRefreshFlag(false);
    const currenItemIndex = testCaseResults.data.findIndex(
      (testCaseItem) => testCaseItem.Step_Number === item.Step_Number,
    );
    dispatch(setEditItemIndex(currenItemIndex));
  };

  const handleExecuteTest = (isBaseline) => {
    const selectedItem = testCaseResults.testcase;
    const obj = {
      id: selectedItem.idtest_case,
      SelectEnvironmentName: selectedItem.envname,
      SelectEnvironmentUrl: selectedItem.envurl,
      SelectBrowserName: selectedItem.browser,
      SelectResolution: selectedItem.SelectResolution,
      SelectRunonce: selectedItem.multirun,
      TcThreshold: selectedItem.testcasethreshold,
      testcase: selectedItem?.Test_Case,
      proxyurl: selectedItem.proxyurl,
      baseline: isBaseline,
    };
    executeTest(obj);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const addStepQuery = (payload) => {
    addStep(payload);
  };
  const saveStepQuery = (payload) => {
    saveStep(payload);
  };

  return (
    <Box sx={{ py: 4 }}>
      {currentEditItemIndex === null && (
        <>
          <Box mb={2} display="flex" alignItems="center">
            <PageTitle title={pageTitle} variant="h4" />
            <Box
              display={"flex"}
              columnGap={"1rem"}
              alignItems="center"
              flex={1}
              justifyContent="flex-end"
            >
              <Settings />
              <Box>
                {refreshFlag ? (
                  <IconContainer
                    handleClick={() => {
                      setRefreshFlag(false);
                      clearInterval(window.ExecuteTimer);
                    }}
                  >
                    <SyncDisabledIcon />
                  </IconContainer>
                ) : (
                  <IconContainer
                    handleClick={() => {
                      setRefreshFlag(true);
                    }}
                  >
                    <RefreshIcon />
                  </IconContainer>
                )}
              </Box>

              <TSMenu
                handleExecuteTest={handleExecuteTest}
                anchorEl={anchorEl}
                handleClose={handleMenuClose}
                setTestCaseSearchKeyword={setTestCaseSearchKeyword}
              />
              <Box onClick={handleMenuClick}>
                <IconContainer>
                  <ContextIcon />
                </IconContainer>
              </Box>
              <StyledButton
                variant="contained"
                endIcon={<VisiblityIcon />}
                onClick={() => {
                  navigate("/new/reports", {
                    state: {
                      fromTestStudio: true,
                      idproducts: selectedProduct,
                      idmodules: selectedModule,
                      idtest_case: selectedTestCase,
                      Test_Case: testCaseResults.testcase.Test_Case,
                    },
                  });
                }}
                sx={{
                  backgroundColor: "#2550d1",
                  boxShadow: "0px 16px 30.6px 0px rgba(37, 80, 209, 0.27)",
                  width: "229px",
                }}
              >
                Execution Reports
              </StyledButton>
              <StyledButton
                variant="contained"
                color="warning"
                onClick={() => handleExecuteTest(false)}
                endIcon={<ExportIcon />}
                sx={{
                  backgroundColor: "#ff5336",
                  boxShadow: "0px 16px 30.6px 0px rgba(255, 83, 54, 0.27)",
                }}
              >
                Run
              </StyledButton>
            </Box>
          </Box>
          <Box display="flex" justifyContent={"space-between"} alignItems="center">
            <Box display="flex" columnGap={3} alignItems="center">
              <StudioSelection
                refetchTestCases={refetchTestCases}
                products={products}
                modules={modules}
                testCases={testCases}
                isLoadingTestcase={isLoadingTestcase}
                setTestCaseSearchKeyword={setTestCaseSearchKeyword}
                setRefreshFlag={setRefreshFlag}
              />
            </Box>
          </Box>
          {isLoading ? <CircularProgress /> : null}
          <Box ref={containerRef} display="flex" columnGap={4} flexWrap="wrap" rowGap={4} mt={3}>
            {selectedTestCase && testCaseResults ? (
              testCaseResults.data.map((result, index) => (
                <TestItemCard
                  item={result}
                  index={index}
                  key={result.idtest_step}
                  handleEdit={handleEdit}
                  width={itemWidth}
                />
              ))
            ) : (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                height={"300px"}
                width={"100%"}
              >
                No data available
              </Box>
            )}
          </Box>
        </>
      )}
      {currentEditItemIndex !== null && (
        <>
          <EditHeader
            editItem={editData}
            handleAddStep={addStepQuery}
            handleSaveStep={saveStepQuery}
          />
          <EditTabs editItem={editData} />
        </>
      )}
    </Box>
  );
};

export default TestStudio;
