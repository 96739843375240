import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton } from "@mui/material";

import MyCarouselScreenshot from "./MyCarouselScreenshot";
import { ColorButton } from "./../common/common.Component";
import { useEffect, useState } from "react";
// import {editStepInrecording} from "./../../API/RecordingTableAPI";
import {
  editStepInrecording,
  fetchRecordingInResult,
  addStepInrecording,
} from "./../../API/RecordingTableAPI";
import { API_BASE_PATH, API_BASE_RELATIVE_PATH } from "./../../Config/Config";
import { CommonMethods } from "./../common/CommonMethods";
import { useNavigate } from "react-router-dom";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { InputLabel, FormControl } from "@mui/material";
import "./TCTable.style.scss";

import { modalExecute, postEnviromentSettingtoTestSuite } from "./../../API/CreateCollectionAPI";

const userDetails = localStorage.getItem("userDetail");
const temp = JSON.parse(userDetails);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const ScreenshotComparison = ({ Values, handleClose }) => {
  const [textValue, setTextvalue] = useState(false);

  const handleTextValuePositive = () => {
    setTextvalue(true);
  };

  const handleTextValueNegative = () => {
    setTextvalue(false);
  };

  return (
    <>
      <BootstrapDialog
        onClose={() => {
          handleClose();
        }}
        className="custom-dialog"
        aria-labelledby="customized-dialog"
        open={true}
        sx={{
          marginTop: 5,
          width: 10 / 10,
          height: 9 / 10,
        }}
      >
        <div className="alinging-textbox-two">
          <BootstrapDialogTitle
            id="customized-dialog"
            onClose={() => {
              handleClose();
            }}
            sx={{
              width: 10 / 10,
            }}
          >
            <span className="main-heading-result">{"ScreenShot Comparison"}</span>
            <ColorButton onClick={handleTextValuePositive}>Text Difference</ColorButton>
          </BootstrapDialogTitle>
        </div>
        <DialogContent dividers className="dialogcontent-css">
          {
            <MyCarouselScreenshot
              firstImage={Values.failScreenshot}
              secondImage={Values.diffScreenshot}
              thirdImage={Values.passScreenshot}
              passText={Values.passText}
              failText={Values.failText}
              textValue={textValue}
              textNegative={handleTextValueNegative}
            />
          }
          {/* <>
                    <img src={Values.passScreenshot} />
                    <img src={Values.failScreenshot}/>
                    <img src={Values.diffScreenshot}/>
                </> */}
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default ScreenshotComparison;
