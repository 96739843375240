import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_BASE_PATH, API_BASE_RELATIVE_PATH } from "../Config/Config";
import axios from "axios";

const copyTC = async ({ tcid, copyNewName }) => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetails);

  const response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=copyTC&companyid=${user.companyid}&token=${user.token}&tcid=${tcid}&tcname=${copyNewName}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });

  return response;
};

const useCopyTCQuery = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: copyTC,
    onSuccess: () => {
      queryClient.invalidateQueries(["testCaseResults", "searchTCSearch"]);
    },
    onError: (error) => {
      console.log("Error uploading file", error.message);
    },
  });
};

export default useCopyTCQuery;
