export const EnvOptions = [
  { value: "Dev", label: "Dev" },
  { value: "Test", label: "Test" },
  { value: "QA", label: "QA" },
  { value: "Prod", label: "Prod" },
];

export const BrowserOptions = [
  { value: "Firefox", label: "Firefox" },
  { value: "Chrome", label: "Chrome" },
  { value: "Safari", label: "Safari" },
  { value: "Edge", label: "Edge" },
  { value: "ALL", label: "All" },
];

export const BrowserOptions1 = [
  { value: "Firefox", label: "Firefox" },
  { value: "Chrome", label: "Chrome" },
  { value: "Safari", label: "Safari" },
  { value: "Edge", label: "Edge" },
  { value: "All", label: "All" },
];

export const ResolutionOptions = [
  { value: "1920x1080", label: "1920x1080" },
  { value: "1440x780", label: "1440x780" },
  { value: "1366X768", label: "1366X768" },
  { value: "1280X780", label: "1280X780" },
];

export const IntegrationOptions = [
  { value: "Azure Dev Ops", label: "Azure Dev Ops" },
  { value: "MS Teams Channel", label: "MS Teams Channel" },
  { value: "Jira", label: "Jira" },
  { value: "Slack", label: "Slack" },
  { value: "Github", label: "Github" },
  { value: "Impact Analysis", label: "Impact Analysis" },
];

export const SchedulerOptions = [
  { value: "daily", label: "Daily" },
  { value: "weekly", label: "Weekly" },
  { value: "other", label: "Other" },
];

export const Days = [
  "All Day",
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday ",
  "Saturday ",
];
