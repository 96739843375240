import React from "react";

const ScreenshotIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <path
      d="M4.51099 19.4822L4.49323 19.5L4.47458 19.4822H2.8808C2.64712 19.482 2.42308 19.389 2.25793 19.2237C2.09277 19.0584 2 18.8342 2 18.6006V4.38169C2.00162 4.1485 2.09492 3.92532 2.25972 3.76035C2.42453 3.59538 2.64762 3.50186 2.8808 3.5H18.8772C19.3638 3.5 19.758 3.89512 19.758 4.38169V18.6006C19.7564 18.8337 19.6631 19.0569 19.4983 19.2219C19.3335 19.3869 19.1104 19.4804 18.8772 19.4822H4.51099ZM17.9822 14.1548V5.2758H3.7758V17.7064L12.6548 8.82741L17.9822 14.1548ZM17.9822 16.6658L12.6548 11.3384L6.28679 17.7064H17.9822V16.6658ZM7.32741 10.6032C6.85644 10.6032 6.40476 10.4161 6.07173 10.0831C5.7387 9.75007 5.55161 9.29839 5.55161 8.82741C5.55161 8.35644 5.7387 7.90476 6.07173 7.57173C6.40476 7.2387 6.85644 7.05161 7.32741 7.05161C7.79839 7.05161 8.25007 7.2387 8.5831 7.57173C8.91613 7.90476 9.10322 8.35644 9.10322 8.82741C9.10322 9.29839 8.91613 9.75007 8.5831 10.0831C8.25007 10.4161 7.79839 10.6032 7.32741 10.6032Z"
      fill="currentColor"
    />
  </svg>
);

export default ScreenshotIcon;
