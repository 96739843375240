import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { styled } from "@mui/material/styles";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton } from "@mui/material";
import { ColorButton } from "./../common/common.Component";
import { useEffect, useState } from "react";
// import {editStepInrecording} from "./../../API/RecordingTableAPI";
import {
  editStepInrecording,
  fetchRecordingInResult,
  addStepInrecording,
} from "./../../API/RecordingTableAPI";
import { API_BASE_PATH, API_BASE_RELATIVE_PATH } from "./../../Config/Config";
import { CommonMethods } from "./../common/CommonMethods";
import { useNavigate } from "react-router-dom";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { InputLabel, FormControl } from "@mui/material";
import "./TCTable.style.scss";

import { modalExecute, postEnviromentSettingtoTestSuite } from "./../../API/CreateCollectionAPI";

const userDetails = localStorage.getItem("userDetail");
const temp = JSON.parse(userDetails);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const ResultScreenShotModal = ({
  handleCloseResult,
  valuesSavedResult,
  indexValue,
  testStepName,
}) => {
  const [indexValueChange, setIndexValueChange] = useState(indexValue);

  const incrementCount = () => {
    if (indexValueChange < valuesSavedResult.length - 1) {
      setIndexValueChange(indexValueChange + 1);
    }
  };

  const decrementCount = () => {
    if (indexValueChange > 0) {
      setIndexValueChange(indexValueChange - 1);
    }
  };

  return (
    <>
      <BootstrapDialog
        onClose={() => {
          handleCloseResult();
        }}
        aria-labelledby="customized-dialog"
        open={true}
        className="custom-dialog"
        sx={{
          marginTop: 5,
          width: 10 / 10,
          height: 9 / 10,
        }}
      >
        <div className="alinging-textbox-two">
          <BootstrapDialogTitle
            id="customized-dialog"
            onClose={() => {
              handleCloseResult();
            }}
            sx={{
              width: 10 / 10,
            }}
          >
            <span className="main-heading-result">{testStepName} - Executed @ </span>
            <span className="main-heading-result">
              {valuesSavedResult[indexValueChange]?.executedDate}
            </span>

            <TextField
              className="header-textfield"
              label="Page Name"
              variant="filled"
              value={valuesSavedResult[indexValueChange]?.page_Name}
              style={{
                width: 400,
              }}
              InputLabelProps={{ shrink: true }}
            />
          </BootstrapDialogTitle>
        </div>
        <DialogContent dividers className="dialogcontent-css">
          {valuesSavedResult[indexValueChange]?.screenShot === null ||
          valuesSavedResult[indexValueChange]?.screenShot === "" ||
          valuesSavedResult[indexValueChange]?.screenShot === undefined ? (
            <div className="image-box">
              <span className="image-text">Screenshot not available</span>
            </div>
          ) : (
            <img
              className="image"
              src={valuesSavedResult[indexValueChange]?.screenShot}
              width="100%"
              height="80%"
              alt="Error In Loading Image, Please conatact the Administrator."
            />
          )}
          <div className="alinging-textbox">
            <TextField
              className="textfield"
              label="Step Number"
              variant="filled"
              fullWidth
              value={valuesSavedResult[indexValueChange]?.stepNumber}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              className="textfield"
              label="Keyword"
              variant="filled"
              fullWidth
              value={valuesSavedResult[indexValueChange]?.keyword}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              className="textfield"
              label="Action"
              variant="filled"
              fullWidth
              value={valuesSavedResult[indexValueChange]?.action}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              className="textfield"
              label={"Duration(s)"}
              variant="filled"
              fullWidth
              value={valuesSavedResult[indexValueChange]?.duration}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              className="textfield"
              label={"Status"}
              variant="filled"
              fullWidth
              value={valuesSavedResult[indexValueChange]?.status}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              className="textfield"
              label={"Validate In"}
              variant="filled"
              fullWidth
              value={valuesSavedResult[indexValueChange]?.device}
              InputLabelProps={{ shrink: true }}
            />
          </div>

          <div className="alinging-textbox-two">
            <TextField
              className="textfield-two"
              label="Issues"
              variant="filled"
              fullWidth
              value={valuesSavedResult[indexValueChange]?.issues}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              className="textfield-two"
              label="TestData"
              variant="filled"
              fullWidth
              value={valuesSavedResult[indexValueChange]?.testData}
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <ColorButton
            size="small"
            onClick={() => {
              decrementCount();
            }}
            disabled={indexValueChange === 0 ? true : false}
          >
            Previous
          </ColorButton>

          <ColorButton
            size="small"
            onClick={() => {
              incrementCount();
            }}
            disabled={indexValueChange === valuesSavedResult.length - 1 ? true : false}
          >
            next
          </ColorButton>

          <Button
            autoFocus
            onClick={() => {
              handleCloseResult();
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

export default ResultScreenShotModal;
