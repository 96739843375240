import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

const executeTestSuites = async ({
  prodid,
  suitid,
  envname,
  envUrl,
  browser,
  stop,
  rerun,
  runparallel,
  ssfail,
  indEmail,
  inputemail,
  height,
  width,
  tsrId,
  iterations,
  proxy,
  statustype,
}) => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetails);

  const response = await axios.get(
    `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=ExecuteTestSuites&companyid=${
      user.companyid
    }&token=${user.token}&prodid=${prodid}&SuiteId=${suitid}&envName=${envname}&envUrl=${envUrl}&browsername=${browser}
    &height=${height}&width=${width}&RerunFailTest=${rerun}&StopTestAfterFailure=${stop}&RunsTestsinParallel=${runparallel}
    &resultsemail=${inputemail}&criteria=${statustype}&tsrid=${tsrId}&ssonerror=${ssfail}
    &iterations=${iterations}&proxy=${proxy}&indEmail=${indEmail}`
  );

  return response.data;
};

const useExecuteTestSuitesMutation = (options) => {
  return useMutation({
    mutationFn: executeTestSuites,
    ...options, // You can pass additional options here
  });
};

export default useExecuteTestSuitesMutation;
