import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

const fetchTestStepResult = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetails);

  const response = await axios.get(
    `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=searchTSRDropDown&companyid=${user.companyid}&token=${user.token}&tcrid=${id}`,
  );

  return response.data;
};

const useFetchTestStepResultQuery = (id) => {
  return useQuery({
    queryKey: ["TestStepResult"],
    queryFn: () => fetchTestStepResult(id),
    retry: true,
    enabled: false,
    cacheTime: 0,
    refetchIntervalInBackground: false,
    staleTime: 0,
  });
};

export default useFetchTestStepResultQuery;
