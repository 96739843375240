import React, { useState } from 'react';
import { fetchProducts } from '../../API/CreateCollectionAPI';
import { CommonMethods } from './../../components/common/CommonMethods';
import { useNavigate } from "react-router-dom";
import './ChatBot.scss';

function ChatBot() {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const navigate = useNavigate();
    const [productList, setProductList] = useState([]);

    const sendMessage = async (text) => {
        const newMessages = [...messages, { sender: 'user', text }];
        setMessages(newMessages);
    
        await fetchProducts()
            .then((response) => {
                if (response.status === 200 && response.data.message !== "fail") {
                    setProductList(response.data); // Assuming response.data is the product list
                    // Format productList into a string or prepare individual messages
                    const productListMessage = response.data.map(product => product.productname).join(', ');
                    setMessages(messages => [...messages, { sender: 'bot', text: `Products: ${productListMessage}` }]);
                } else {
                    CommonMethods(navigate);
                }
            })
            .catch((error) => {
                console.log(error);
                setMessages(messages => [...messages, { sender: 'bot', text: "Failed to fetch products." }]);
            });
    };
    

    const handleInput = (event) => setInput(event.target.value);

    const handleSubmit = (event) => {
        event.preventDefault();
        sendMessage(input);
        setInput('');
    };

    return (
        <div className="chat-container">
            <div className="messages">
                {messages.map((message, index) => (
                    <p key={index}><b>{message.sender}:</b> {message.text}</p>
                ))}
            </div>
            <form onSubmit={handleSubmit} className="message-form">
                <textarea 
                    value={input} 
                    onChange={handleInput} 
                    rows="2" // Sets the initial number of lines
                    placeholder="Type your message here..."
                />
                <button type="submit">Send</button>
            </form>
        </div>
    );
}

export default ChatBot;
