import React from "react";
import { Radio, FormControlLabel } from "@mui/material";
import { styled } from "@mui/system";
import CheckIcon from "@mui/icons-material/Check";

const CustomRadio = styled(Radio)(({ theme }) => ({
  display: "none",
}));

const PillLabel = styled(FormControlLabel)(({ theme }) => ({
  border: `1px solid #D1DBFF`,
  borderRadius: 16,
  height: "32px",
  padding: "4px 12px",
  margin: "4px",
  transition: "background-color 0.3s, color 0.3s",
  backgroundColor: "transparent",
  fontSize: "12px",
  "& .MuiTypography-root": {
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
  },
  "&.Mui-checked": {
    backgroundColor: "#2550D1",
    color: theme.palette.common.white,
    "& .MuiTypography-root": {
      color: theme.palette.common.white,
    },
  },
}));

const PillRadio = ({ value, label, checked, onChange }) => (
  <PillLabel
    control={<CustomRadio checked={checked} onChange={onChange} />}
    label={
      <>
        {label}
        {checked && <CheckIcon style={{ marginLeft: 8 }} />}
      </>
    }
    checked={checked}
    value={value}
    onChange={onChange}
    className={checked ? "Mui-checked" : ""}
  />
);

export default PillRadio;
