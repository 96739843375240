/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Pagination } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useItemWidth, useNotification } from "../../hooks";
import TestSuiteCard from "./TestSuiteCard";
import CustomDialog from "../../components-new/common/CustomDialog";
import { useDeleteTestSuiteQuery } from "../../useQuery";
import { useQueryClient } from "@tanstack/react-query";
import TestSuiteSettings from "./TestSuiteSettings";

export default function TestSuiteItems({
  data,
  itemsPerPage,
  showTestCases,
  itemsPerRow,
  handleSuiteClick,
}) {
  const containerRef = useRef(null);
  const gap = 16;
  const itemWidth = useItemWidth(containerRef, itemsPerRow, gap);
  const queryClient = useQueryClient();
  const notify = useNotification();

  const [testSuitePage, setTestSuitePage] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [suiteId, setSuiteId] = useState(null);
  const [openSuiteSettings, setOpenSuiteSettings] = useState(false);
  const [suiteItem, setSuiteItem] = useState(null);

  const { refetch, isSuccess } = useDeleteTestSuiteQuery(suiteId, false);

  const paginatedData = (data ?? [])
    .flat()
    .slice((testSuitePage - 1) * itemsPerPage, testSuitePage * itemsPerPage);

  const handleDeleteClick = (e, suite) => {
    e.stopPropagation();
    setShowDeleteModal(true);
    setSuiteId(suite.idtest_suite);
  };

  const handleSettingsClick = (e, suite) => {
    e.stopPropagation();
    setSuiteItem(suite);
    setOpenSuiteSettings(true);
  };

  useEffect(() => {
    if (!isSuccess) return;
    notify("Test suite deleted", "success");
  }, [isSuccess]);

  const DeleteAction = () => {
    return (
      <>
        <Button
          sx={{ color: "#ff5336" }}
          onClick={() => {
            setSuiteId(null);
            setShowDeleteModal(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ background: "#ff5336" }}
          onClick={() => {
            refetch().finally(() => {
              setShowDeleteModal(false);
              setSuiteId(null);
              queryClient.invalidateQueries(["testSuiteNew"]);
            });
          }}
        >
          Delete
        </Button>
      </>
    );
  };

  return (
    <>
      <CustomDialog
        title={"Are you sure you want to delete this Suite?"}
        open={showDeleteModal}
        actions={<DeleteAction />}
      />
      <Box
        width={"100%"}
        py={1}
        ref={containerRef}
        display="flex"
        columnGap={2}
        rowGap={2}
        flexWrap="wrap"
      >
        {paginatedData?.length > 0
          ? paginatedData?.map((suite) => (
              <TestSuiteCard
                item={suite}
                width={itemWidth}
                isFolder={!showTestCases}
                key={suite.idtest_suite}
                handleSuiteClick={handleSuiteClick}
                handleDeleteClick={(e) => handleDeleteClick(e, suite)}
                handleSettings={(e) => handleSettingsClick(e, suite)}
              />
            ))
          : null}
        {data?.length < 1 && <Box>No Test Suites Available</Box>}
      </Box>
      {data?.length > itemsPerPage && (
        <Box py={2} display="flex" justifyContent="center">
          <Pagination
            count={Math.ceil(data.flat().length / itemsPerPage)}
            page={testSuitePage}
            onChange={(e, newPage) => setTestSuitePage(newPage)}
            color="primary"
            variant="outlined"
            shape="rounded"
          />
        </Box>
      )}
      {openSuiteSettings && (
        <TestSuiteSettings
          open={openSuiteSettings}
          setOpenSuiteSettings={setOpenSuiteSettings}
          suite={suiteItem}
        />
      )}
    </>
  );
}
