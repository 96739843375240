import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { List, ListItem as MuiListItem, ListItemText, ListItemIcon } from "@mui/material";

import { styled } from "@mui/material/styles";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

import { navItems } from "./constants";

const StyledListItem = styled(MuiListItem)(({ theme, active }) => ({
  color: "#8C9EB8",
  columnGap: "15px",
  marginBottom: "25px",
  "& .MuiTypography-root": {
    fontSize: "14px",
  },
  "& .MuiListItemText-root": {
    fontSize: "14px",
  },
  "&:hover": {
    backgroundColor: "#FF5336",
    borderRadius: "8px",
    color: "#242f3f",
    "& .MuiListItemIcon-root": {
      color: "#ffffff",
    },
    "& .MuiListItemText-root": {
      color: "#ffffff",
    },
  },
  "& .MuiListItemIcon-root": {
    color: "#8C9EB8",
    minWidth: "unset",
  },
  ...(active && {
    backgroundColor: "#FF5336",
    color: "#ffffff",
    borderRadius: "8px",
    "& .MuiListItemIcon-root": {
      color: "#ffffff",
      minWidth: "unset",
    },
    "& .MuiListItemText-root": {
      color: "#ffffff",
    },
  }),
}));

const Sidebar = () => {
  const location = useLocation();

  return (
    <>
      <List sx={{ flex: "1" }}>
        {navItems.map((item) => {
          const isActive = (location.pathname === item.path || (location.pathname === '/new' && item.text === 'Dashboard')) ||
          (
            location.pathname === item.path || 
            location.pathname === `${item.path}/history`
        );
          return (
            <StyledListItem
              key={item.text}
              component={NavLink}
              to={item.path}
              active={isActive ? 1 : 0}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </StyledListItem>
          );
        })}
      </List>
      <List>
        <StyledListItem sx={{ marginBottom: 0 }}>
          <ListItemIcon>
            <SettingsOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary={"Settings"} />
        </StyledListItem>
      </List>
    </>
  );
};

export default Sidebar;
