import React from "react";

const DeleteIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="currentColor"
  >
    <path
      d="M11.7117 3H15.4617V4.5H13.9617V14.25C13.9617 14.4489 13.8827 14.6397 13.742 14.7803C13.6013 14.921 13.4106 15 13.2117 15H2.71167C2.51276 15 2.32199 14.921 2.18134 14.7803C2.04069 14.6397 1.96167 14.4489 1.96167 14.25V4.5H0.46167V3H4.21167V0.75C4.21167 0.551088 4.29069 0.360322 4.43134 0.21967C4.57199 0.0790176 4.76276 0 4.96167 0H10.9617C11.1606 0 11.3513 0.0790176 11.492 0.21967C11.6327 0.360322 11.7117 0.551088 11.7117 0.75V3ZM12.4617 4.5H3.46167V13.5H12.4617V4.5ZM5.71167 1.5V3H10.2117V1.5H5.71167Z"
      fill="currentColor"
      fillOpacity="0.87"
    />
  </svg>
);

export default DeleteIcon;
