import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { API_BASE_PATH, API_BASE_RELATIVE_PATH } from "../Config/Config";

const fetchRecentTestCaseResults = async () => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetails);

  const response = await axios.get(
    `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=latestRecording&companyid=${user.companyid}&token=${user.token}`,
  );

  return response.data;
};

const useRecentTestCaseResultsQuery = (options) => {
  return useQuery({
    queryKey: ["latestRecordings"],
    queryFn: () => fetchRecentTestCaseResults(),
    retry: true,
    cacheTime: 90000,
    refetchInterval: options.refetchInterval,
    refetchIntervalInBackground: false,
    staleTime: options.refetchInterval,
  });
};

export default useRecentTestCaseResultsQuery;
