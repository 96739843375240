import React from "react";

const ElementLocator = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M15 18H16.5C17.163 18 17.7989 17.7366 18.2678 17.2678C18.7366 16.7989 19 16.163 19 15.5C19 14.837 18.7366 14.2011 18.2678 13.7322C17.7989 13.2634 17.163 13 16.5 13H3V11H16.5C17.6935 11 18.8381 11.4741 19.682 12.318C20.5259 13.1619 21 14.3065 21 15.5C21 16.6935 20.5259 17.8381 19.682 18.682C18.8381 19.5259 17.6935 20 16.5 20H15V22L11 19L15 16V18ZM3 4H21V6H3V4ZM9 18V20H3V18H9Z"
      fill="currentColor"
    />
  </svg>
);

export default ElementLocator;
