import { Box, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useGetLast5ExecutionQuery } from "../../useQuery";

const Wrapper = styled(Box)`
    display: flex;
    padding: 30px;
    flex-direction: column;
    gap: 1;
    border-radius: 8px;
    background: var(--surface-bg, #FFF);
    box-shadow: 0px -3px 17.1px 0px rgba(0, 0, 0, 0.07);
    flex: 1;
`

const StyledTableCell = styled(TableCell)`
    color: #232D3D;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid #E2E6F3;
    padding-left: 0;
`

const RunItem = styled(Box)`
    display: flex;
    width: 48px;
    height: 48px;
    padding: 8px 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 0px solid ${(props) => (props.type === 'PASS' ? '#30CA17' : props.type === 'FAIL' ? '#30CA17' : '#EEF4FC')};
    background: ${(props) => (props.type === 'PASS' ? '#E7FBE4' : props.type === 'FAIL' ? '#FBE7E7' : '#EEF4FC')};
    border-radius: 41px;
`

const StyledTableBodyCell = styled(TableCell)`
    border-bottom: none;
    color: #2B3539;
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 0;
    padding-left: 0;
    `

const DataText = styled('span')`
    color: #28AC12;
    font-size: 13px;
    font-weight: 600;
`

const TotalText = styled('span')`
    color: #28AC12;
    font-size: 11px;
    font-weight: 400;
`

const NotExeText = styled('span')`
    color: #5F7493;
    text-align: center;
    font-size: 8px;
    font-weight: 400;
`

const ExecutionLast5ExeTrends = () => {

    const { data: Last5ExeTestSuites, isSuccess } = useGetLast5ExecutionQuery();

    return (<Wrapper>
        <Typography fontSize={'18px'} fontWeight={600} color={'#2b3529'}>Trends for the Last 5 Executed Test Suites</Typography>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Test Suite Name</StyledTableCell>
                        <StyledTableCell>Last 5 run status</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isSuccess && Last5ExeTestSuites.map((item, ind) => (
                        <TableRow key={ind}>
                            <StyledTableBodyCell>{item.testSuiteName}</StyledTableBodyCell>
                            <StyledTableBodyCell sx={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>{item.last5Run.map((runItem, index) => (
                                <RunItem key={index} type={runItem.type}>
                                    {runItem.type !== 'notExecuted' ?
                                        <>
                                            <DataText>{runItem.data}</DataText>
                                            <TotalText>/{runItem.total}</TotalText>
                                        </>
                                        : <NotExeText>Not Executed</NotExeText>}
                                </RunItem>

                            ))}</StyledTableBodyCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </Wrapper>)
}
export default ExecutionLast5ExeTrends;