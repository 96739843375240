import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

const fetchTestSuiteResult = async (tsid, tsrid) => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetails);

  const response = await axios.get(
   `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getTestSuiteResult&companyid=${user.companyid}&token=${user.token}&idtest_suite=${tsid}&TSRId=${tsrid}`,
  );

  return response.data;
};

const useTestSuiteResultQuery = (tsid,tsrid, options) => {
  return useQuery({
    queryKey: ["testSuiteResult", tsid],
    queryFn: () => fetchTestSuiteResult(tsid, tsrid),
    enabled: options.enabled,
    retry: true,
    cacheTime: 90000,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    staleTime: Infinity,
  });
};

export default useTestSuiteResultQuery;
