import React from "react";

const CalendarIcon = ({ color = "#FF5336" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M12 1.6H15.2C15.4122 1.6 15.6157 1.68429 15.7657 1.83431C15.9157 1.98434 16 2.18783 16 2.4V15.2C16 15.4122 15.9157 15.6157 15.7657 15.7657C15.6157 15.9157 15.4122 16 15.2 16H0.8C0.587827 16 0.384344 15.9157 0.234315 15.7657C0.0842854 15.6157 0 15.4122 0 15.2V2.4C0 2.18783 0.0842854 1.98434 0.234315 1.83431C0.384344 1.68429 0.587827 1.6 0.8 1.6H4V0H5.6V1.6H10.4V0H12V1.6ZM10.4 3.2H5.6V4.8H4V3.2H1.6V6.4H14.4V3.2H12V4.8H10.4V3.2ZM14.4 8H1.6V14.4H14.4V8Z"
      fill={color}
    />
  </svg>
);

export default CalendarIcon;
