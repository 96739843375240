import React, { useEffect } from "react";
import "./features/css/listfile.css";
import ListBody from "./ListBody";

function ListFile({
  testcase,
  addtoar,
  delButton,
  removetoar,
  product,
  moduleid,
  handledrag,
  handledrop,
  clickonSuites,
}) {
  let caseslist = testcase;

  // const [caseslist, changeCaselist] = useState(testcase);
  let cases = caseslist;
  // const [cases, changecase] = useState(caseslist);
  // let selectedcase = [];

  if (testcase.length > 0 && delButton) {
    if (testcase[0]?.hasOwnProperty("list")) {
      let cse = testcase[0].list;
      cases = cse;
    }
    
    // changecase(cse);
  }
  // if (!delButton) console.log(caseslist);
  if (!delButton) cases = caseslist;
  return (
    <div className="listfile">
      <div className="flexing">
        {cases?.map((suite, i) => (
          <ListBody
            key={i}
            addtoar={addtoar}
            removetoar={removetoar}
            testcases={suite}
            ind={i}
            product={product}
            moduleid={moduleid}
            dragon={handledrag}
            dropon={handledrop}
            delButton={delButton}
            clickonSuites={clickonSuites}
          />
        ))}
      </div>
    </div>
  );
}

export default ListFile;
