import React from "react";

const HistoryIcon = () => (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.54422 0L8.09978 1.55556H14.7778C15.2071 1.55556 15.5556 1.904 15.5556 2.33333V13.2222C15.5556 13.6516 15.2071 14 14.7778 14H0.777778C0.348444 14 0 13.6516 0 13.2222V0.777778C0 0.348444 0.348444 0 0.777778 0H6.54422ZM5.90022 1.55556H1.55556V12.4444H14V3.11111H7.45578L5.90022 1.55556ZM8.55556 4.66667V7.77778H10.8889V9.33333H7V4.66667H8.55556Z" fill="#242F3F" />
    </svg>

);

export default HistoryIcon;
