import React, { useState } from "react";
import { TextField, Button, Box } from "@mui/material";

const FileUpload = ({ handleUpload }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const onUploadClick = () => {
    handleUpload(selectedFile);
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 2,
        mt: 2,
        minWidth: "400px",
      }}
    >
      <TextField type="file" onChange={handleFileChange} />
      <Button
        style={{ textTransform: "capitalize", background: "#FF5336" }}
        variant="contained"
        color="warning"
        onClick={onUploadClick}
        disabled={!selectedFile}
      >
        Upload
      </Button>
    </Box>
  );
};

export default FileUpload;
