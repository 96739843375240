import React, { useEffect, useState } from "react";

import { ColorButton } from "./../common/common.Component";
import SideBarFolder from "./SideBarFolder";
import "./features/css/sidebar.css";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Input } from "@mui/material";
import { colorExport } from "./../common/common.Component";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import FolderIcon from "@mui/icons-material/Folder";

function SideBar({
  fromImpactpage,
  funcCollect,
  fromImpactselected,
  fromImpactpagedata,
  fromImpactpageid,
  testcase,
  suites,
  settingExecute,
  newsuite,
  deletesuite,
  product,
  suiteSelectedid,
}) {
  let impactdata = fromImpactpagedata;
  let impactsuitid = fromImpactpageid;
  let caseslist = testcase;
  let suitesList = suites;
  const [createfolder, setCreatefolder] = useState(false);
  const [newname, setName] = useState("");
  const [error, setError] = useState(false);
  const [newid, setNewid] = useState("");
  const handleNameChange = (event) => {
    const newName = event.target.value;
    if (newName.length <= 10 || newName.includes(" ")) {
      setName(newName);
      setError(false);
    } else {
      setError(true);
    }
  };
  const colors = colorExport();
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  useEffect(() => {
    if (fromImpactselected === "NoSelection") {
      collectimpacted();
      handleItemClick("fromimpact");
    } else if (fromImpactselected === "ExistingTestSuite") {
      // timers()
      setNewid(impactsuitid);
    } else if (fromImpactselected === "NewTestSuite") {
      setTimeout(() => {
        setNewid(false);
      }, 1);
    }
  }, [fromImpactselected, impactsuitid]);
  useEffect(() => {
    if (suitesList !== [] || caseslist !== []) {
      if (caseslist[0]?.hasOwnProperty("Test_Case")) {
        handleItemClick(true);
        funcCollect("", caseslist, false, false, "All Test Cases");
      }
    }
  }, [caseslist]);
  function clicked() {
    if (newname !== "") newsuite(newname);
    setName("");
    if (newname !== "") close();
  }
  function collectrestsuites(suite, suiteid, bool) {
    let oldBool = false;
    let oldsuite = caseslist;
    let oldsuiteid = "";
    let header = "All Test Cases";
    if (bool) {
      oldBool = bool;
      oldsuite = suite;
      oldsuiteid = suiteid;
      header = oldsuite[0].Test_Suite;
    }
    funcCollect(oldsuiteid, oldsuite, oldBool, true, header);
  }
  function collectimpacted() {
    let oldBool = false;
    let allsuites = impactdata;
    let oldsuiteid = "";
    funcCollect(oldsuiteid, allsuites, oldBool, true, "Impacted");
  }
  const collectAllSuites = () => {
    let oldBool = false;
    let allsuites = suitesList;
    let oldsuiteid = "";
    funcCollect(oldsuiteid, allsuites, oldBool, true, "All Test Suites");
  };
  function close() {
    setCreatefolder(!createfolder);
  }
  const [activeItem, setActiveItem] = useState(false);
  const [allTestCases, setAllTestCases] = useState(false);
  const [allTestSuites, setAllTestSuites] = useState(false);
  const [allimpacted, setAllimpacted] = useState(false);
  const handleItemClick = (index) => {
    if (index === true) {
      setAllTestCases(true);
      setAllTestSuites(false);
      setActiveItem(false);
      setAllimpacted(false);
    } else if (index === false) {
      setAllTestSuites(true);
      setActiveItem(false);
      setAllTestCases(false);
      setAllimpacted(false);
    } else if (index === "fromimpact") {
      setAllTestSuites(false);
      setActiveItem(false);
      setAllTestCases(false);
      setAllimpacted(true);
    } else {
      setActiveItem(index);
      setAllTestSuites(false);
      setAllTestCases(false);
      setAllimpacted(false);
    }
  };
  return (
    <div className="sidebar">
      <span hidden={product === "" || createfolder}>
        <ColorButton
          startIcon={<CreateNewFolderIcon />}
          size="small"
          variant="contained"
          sx={{
            margin: "10px 2px 2px 20px",
            backgroundColor: colors?.secondaryColor,
          }}
          onClick={() => {
            setCreatefolder(!createfolder);
          }}
        >
          Create Suite
        </ColorButton>
      </span>
      <span hidden={!createfolder}>
        <Input
          placeholder="Suite name"
          value={newname}
          style={{ width: "130px", marginLeft: "19px" }}
          // onChange={(e) => setName(e.target.value)}
          onChange={handleNameChange}
        ></Input>
        <IconButton onClick={clicked}>
          <DoneIcon />
        </IconButton>
        <IconButton onClick={close}>
          <CloseIcon />
        </IconButton>
        {error && (
          <div style={{ color: "red" }}>
            Name should have a space after 10 characters
          </div>
        )}
      </span>

      <div
      // className={`side_bar${sidebarActive ? 'active' : ''}`}
      >
        {fromImpactpage === true ? (
          <div
            onClick={() => {
              collectimpacted();
              handleItemClick("fromimpact");
            }}
            // className="Sidebarfolder"
            className={`Sidebarfolder ${
              allimpacted ? "Sidebarfolder--active" : ""
            }`}
          >
            <FolderIcon />
            <p style={{ marginLeft: "15px" }}>All Impacted Cases</p>
          </div>
        ) : (
          ""
        )}
        <span hidden={caseslist.length === 0}>
          <div
            onClick={() => {
              collectrestsuites();
              handleItemClick(true);
            }}
            // className="Sidebarfolder"
            className={`Sidebarfolder ${
              allTestCases ? "Sidebarfolder--active" : ""
            }`}
          >
            <FolderIcon />
            <p style={{ marginLeft: "15px" }}>All Test Cases</p>
          </div>
        </span>
        {product !== "" ? (
          <div
            onClick={() => {
              collectAllSuites();
              handleItemClick(false);
            }}
            // className="Sidebarfolder"
            className={`Sidebarfolder ${
              allTestSuites ? "Sidebarfolder--active" : ""
            }`}
          >
            <FolderIcon />
            <p style={{ marginLeft: "15px" }}>All Test Suites</p>
          </div>
        ) : (
          ""
        )}
        {suitesList.map((el, i) => (
          <SideBarFolder
            key={i}
            index={i}
            testsuite={el}
            funcCollector={collectrestsuites}
            settingExecute={settingExecute}
            deletesuite={deletesuite}
            isActives={activeItem === i}
            setActiveItem={() => handleItemClick(i)}
            collectAllSuites={collectAllSuites}
            product={product}
            suiteSelectedid={suiteSelectedid}
            impactsuitid={newid}
            listall={suitesList.length}
          />
        ))}
      </div>
    </div>
  );
}

export default SideBar;
