import React, { useState, useEffect } from "react";
import { Box, styled } from "@mui/material";
import { useSelector } from "react-redux";

const StyledBox = styled(Box)`
  position: absolute;
  padding: 6px 12px;
  border-radius: 25px;
  background: rgba(255, 255, 255, 1);
  backdrop-filter: blur(2px);
  min-width: 150px;
  font-size: 12px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #444141;
  letter-spacing: 0.9px;
  bottom: 10px;
`;

const Screenshot = () => {
  const tsrData = useSelector((state) => state.authoringSlice.tsrData);
  const currentEditItemIndex = useSelector((state) => state.authoringSlice.editItemIndex);

  // eslint-disable-next-line no-unused-vars
  const [isBeforeImageLoaded, setIsBeforeImageLoaded] = useState(false);
  const [beforeImageSrc, setBeforeImageSrc] = useState(
    tsrData[currentEditItemIndex]?.screenshot1 || "https://via.placeholder.com/900?text=No+Image",
  );

  // eslint-disable-next-line no-unused-vars
  const [isAfterImageLoaded, setIsAfterImageLoaded] = useState(false);
  const [afterImageSrc, setAfterImageSrc] = useState(
    tsrData[currentEditItemIndex]?.Failure_Screenshot_Location ||
      "https://via.placeholder.com/900?text=No+Image",
  );

  const handleBeforeImageLoad = () => {
    setIsBeforeImageLoaded(true);
  };

  const handleBeforeImageError = (e) => {
    e.preventDefault();
    setIsBeforeImageLoaded(false);
    setBeforeImageSrc("https://via.placeholder.com/900?text=No+Image");
  };

  const handleAfterImageLoad = () => {
    setIsAfterImageLoaded(true);
  };

  const handleAfterImageError = (e) => {
    e.preventDefault();
    setIsAfterImageLoaded(false);
    setAfterImageSrc("https://via.placeholder.com/900?text=No+Image");
  };

  useEffect(() => {
    setBeforeImageSrc(
      tsrData[currentEditItemIndex]?.screenshot1 || "https://via.placeholder.com/900?text=No+Image",
    );
    setAfterImageSrc(
      tsrData[currentEditItemIndex]?.Failure_Screenshot_Location ||
        "https://via.placeholder.com/900?text=No+Image",
    );
  }, [tsrData, currentEditItemIndex]);

  return (
    <Box
      border={`2px solid rgba(0, 0, 0, 0.30)`}
      borderRadius="12px"
      height={500}
      display="flex"
      overflow="hidden"
    >
      <Box display="flex" alignItems="center" justifyContent="center" flex={1} position="relative">
        <img
          src={beforeImageSrc}
          alt="Before"
          style={{ width: "100%", height: "100%" }}
          onLoad={handleBeforeImageLoad}
          onError={handleBeforeImageError}
        />
        <StyledBox>Element Selected</StyledBox>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" flex={1} position="relative">
        <img
          src={afterImageSrc}
          alt="After"
          style={{ width: "100%", height: "100%" }}
          onLoad={handleAfterImageLoad}
          onError={handleAfterImageError}
        />
        <StyledBox>Action Completed</StyledBox>
      </Box>
    </Box>
  );
};

export default Screenshot;
