import { Box, Button, IconButton, InputAdornment } from "@mui/material";
import { CustomInput, CustomSelect } from "../../../components-new/common";
import { GenDataOptions, scopeOptionList, TestDataOptions } from "../constants";
import { useDispatch, useSelector } from "react-redux";
import { setEditStep } from "../../../Redux/TestAuthoring/TestAuthoring";
import {
  useAllApiQuery,
  useModuleQuery,
  useProductQuery,
  useDownloadedFileQuery,
  useRecordedTestDataListQuery,
  useUpdateTestDataQuery,
  useTestCaseQuery,
  useFileDataNameQuery,
} from "../../../useQuery";
import { useEffect, useState } from "react";
import { useNotification } from "../../../hooks";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { SearchIcon } from "../../../components-new/icons";

const TestDataSetup = ({ item }) => {
  const dispatch = useDispatch();
  const notify = useNotification();
  const [testCaseSearchKeyword, setTestCaseSearchKeyword] = useState("");
  const [selectedTestCase, setSelectedTestCase] = useState(item.DependentTC || "");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedModule, setSelectedModule] = useState("");
  const [fileNameType, setFileNameType] = useState("");

  const selectedProductID = useSelector((state) => state.authoringSlice.selectedProduct);

  const productKey = item.apiproduct === 0 ? item.DependentProduct : item.apiproduct;

  const { data: products } = useProductQuery();
  const { data: downloadedFiles } = useDownloadedFileQuery(item.Test_Case_Id);
  const {
    data: refFiles,
    refetch: refetchFiles,
    isSuccess: isRefFilesSuccess,
  } = useFileDataNameQuery(selectedProductID);
  const { data: recordedTestDataList, refetch: refetchRecordedTestDataList } =
    useRecordedTestDataListQuery(item.idtest_step, item.DependentTC);

  const {
    data,
    mutate: updateTestData,
    isSuccess: isUpdateSuccess,
    isError,
  } = useUpdateTestDataQuery();

  // {product: 68, modulename: "project management ", idmodules: 60}
  const { data: modules, refetch: refetchModules } = useModuleQuery(productKey, {
    enabled: item.apiproduct !== 0 || !item.DependentProduct,
  });

  const { data: allApi, refetch: refetchAllApi } = useAllApiQuery(
    selectedModule || item.apimodule,
    {
      enabled: item.apimodule !== 0 || !item.DependentModule,
    },
  );

  const { data: testCases, refetch: refetchTestCases } = useTestCaseQuery(
    item.apimodule || item.DependentModule,
    testCaseSearchKeyword,
    {
      enabled: item.apimodule !== 0 || item.DependentModule !== 0,
    },
  );

  useEffect(() => {
    if (item.testdata_source === "FromDB" && isError) {
      notify("Please enter valid query", "error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    refetchModules();
  }, [refetchModules, selectedProduct]);

  useEffect(() => {
    refetchTestCases();
  }, [refetchTestCases, selectedModule]);

  useEffect(() => {
    if (item.testdata_source === "FromReferenceFile") {
      if (item.filename) {
        const simulatedEvent = {
          target: {
            value: item.filename,
          },
        };
        handleRefFileChange(simulatedEvent);
      }
      if (!item.scope) {
        handleChange("Single Run", "scope");
      }
    }
    if (item.testdata_source === "FromFile") {
      if (item.filename) {
        const simulatedEvent = {
          target: {
            value: item.filename,
          },
        };
        handleRefFileChange(simulatedEvent);
      }
      if (!item.scope) {
        handleChange("Single Run", "scope");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefFilesSuccess, item.filename, item.scope, item.testdata_source]);

  useEffect(() => {
    if (isUpdateSuccess) {
      const updatedData = data?.data?.data;
      if (updatedData && Object.values(updatedData)?.length) {
        const findStepByNumber = Object.values(updatedData).find(
          (step) => step.Step_Number === item.Step_Number,
        );

        dispatch(setEditStep({ ...item, ...findStepByNumber }));
      }

      notify("The item has been updated successfully.", "success");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateSuccess]);

  useEffect(() => {
    if (selectedTestCase !== "" && selectedTestCase !== 0) {
      refetchRecordedTestDataList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTestCase]);

  useEffect(() => {
    if (item.testdata_source === "FromReferenceFile") {
      refetchFiles();
    }
  }, [item.testdata_source, refetchFiles]);

  const handleChange = (value, field) => {
    dispatch(setEditStep({ ...item, [field]: value }));
  };
  const handleChangeMultiple = (value) => {
    dispatch(setEditStep({ ...item, ...value }));
  };

  // const handleOptionChange = (event, value) => {
  //   setTestCaseSearchKeyword(value);
  // };
  // const handleOptionChange = (event, value) => {
  //   const tempTC = testCases?.filter((item) => item.Test_Case === value);
  //   setSelectedTestCase(tempTC?.[0]?.idtest_case);
  //   handleChangeMultiple({
  //     idtest_case_One: tempTC?.[0]?.idtest_case,
  //     DependentTC: tempTC?.[0]?.idtest_case,
  //   });
  //   refetchRecordedTestDataList();
  // };

  // const handleSearchClick = (e) => {
  //   handleChange(selectedTestCase, "DependentTC");
  //   refetchTestCases();
  //   refetchRecordedTestDataList();
  // };

  const handleTestCaseSearch = () => {
    refetchTestCases();
  };

  const handleDateChange = (value, field, testdata) => {
    const parts = testdata.split("~");
    let index;
    if (field === "date") index = 2;
    if (field === "dateformat") index = 3;

    parts[index] = value;
    const updatedTestData = parts.join("~");

    dispatch(setEditStep({ ...item, TestData: updatedTestData }));
  };

  const handleGenChange = (value, field, testdata) => {
    const parts = testdata.split("~");
    let index;
    if (field === "generateDateFormat") index = 2;
    if (field === "generateDateOffset") index = 3;
    parts[index] = value;
    const updatedTestData = parts.join("~");
    dispatch(setEditStep({ ...item, TestData: updatedTestData }));
  };

  const handleDateFormat = (value, field) => {
    const updatedValue = `${item.TestData}~${value}`;
    dispatch(setEditStep({ ...item, [field]: updatedValue }));
  };

  const handleAssertionValueChange = (value, field) => {
    const updatedValue = `${item.TestData}~${value}`;
    dispatch(setEditStep({ ...item, [field]: updatedValue }));
  };

  const handleSubmit = () => {
    updateTestData({ api_input: item, fromStrategy: false });
  };

  const handleRefFileChange = (event) => {
    const filename = event.target.value;
    if (filename) {
      const parts = filename.split(".");
      const fileExtension = parts.pop();
      handleChangeMultiple({ filename: filename, fromFileType: fileExtension });
      setFileNameType(fileExtension);
    }
  };

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      const filename = event.target.files[0].name;
      const parts = filename.split(".");
      const fileExtension = parts.pop();
      handleChangeMultiple({ filename: event.target.files[0].name, fromFileType: fileExtension });
      setFileNameType(fileExtension);
    }
  };

  return (
    <Box>
      {item.Step_Number !== 0 && (
        <Box mb={3}>
          <CustomSelect
            label={"Test Data Source"}
            options={TestDataOptions}
            width={"100%"}
            background={"#ffffff"}
            value={item.testdata_source}
            onChange={(e) => {
              handleChange(e.target.value, "testdata_source");
            }}
          />
        </Box>
      )}
      {item.testdata_source === "GenData" && (
        <Box display="flex" columnGap={2}>
          <CustomSelect
            label={"Generate Data Type"}
            options={GenDataOptions}
            width={"100%"}
            background={"#ffffff"}
            value={item.gendataType}
            onChange={(e) => handleChange(e.target.value, "gendataType")}
          />

          {item.gendataType === "TODAY" ? (
            <>
              <CustomInput
                placeholder="Enter date format"
                labelinput="Date format"
                value={item.gendateformat}
                onChange={(e) =>
                  handleGenChange(e.target.value, "generateDateFormat", item.TestData)
                }
                flex={1}
              />
              <CustomInput
                placeholder="Date offset (+/- days)"
                labelinput="Date offset (+/- days)"
                value={item.gendateoffset}
                onChange={(e) =>
                  handleGenChange(e.target.value, "generateDateOffset", item.TestData)
                }
                flex={1}
              />
            </>
          ) : (
            <CustomInput
              onChange={(e) => handleChange(e.target.value, "gendatalen")}
              placeholder="Generate Data Length"
              labelinput="Generate Data Length"
              value={item.gendatalen}
              flex={1}
              type="number"
              pattern="[0-9]*"
            />
          )}
        </Box>
      )}
      {item.testdata_source === "SendEmail" && (
        <Box display="flex" columnGap={2}>
          <CustomInput
            onChange={(e) => handleChange(e.target.value, "customerEmail")}
            placeholder="Enter email"
            labelinput="Email"
            value={item.customerEmail || ""}
            flex={1}
          />
          <CustomInput
            type="password"
            placeholder="Enter password"
            labelinput="Password"
            value={item.customerPassword || ""}
            onChange={(e) => handleChange(e.target.value, "customerPassword")}
            flex={1}
          />
          <CustomInput
            onChange={(e) => handleChange(e.target.value, "toaddress")}
            placeholder="Enter to address"
            labelinput="To address"
            value={item.toaddress || ""}
            flex={1}
          />
          <CustomInput
            onChange={(e) => handleChange(e.target.value, "emailsubject")}
            placeholder="Enter subject"
            labelinput="Subject"
            value={item.emailsubject || ""}
            flex={1}
          />
          <CustomInput
            onChange={(e) => handleChange(e.target.value, "emailcontent")}
            placeholder="Enter content"
            labelinput="Content"
            value={item.emailcontent || ""}
            flex={3}
          />
        </Box>
      )}
      {item.testdata_source === "FromEmail" && (
        <Box display="flex" columnGap={2}>
          <CustomInput
            onChange={(e) => handleChange(e.target.value, "customerEmail")}
            placeholder="Enter email"
            labelinput="Email"
            value={item.customerEmail || ""}
            flex={1}
          />
          <CustomInput
            type="password"
            placeholder="Enter password"
            labelinput="Password"
            value={item.customerPassword || ""}
            onChange={(e) => handleChange(e.target.value, "customerPassword")}
            flex={1}
          />
          <CustomInput
            placeholder="Enter criterion"
            labelinput="Selection Criterion"
            value={item.EmailSelectionCriteria || ""}
            onChange={(e) => handleChange(e.target.value, "EmailSelectionCriteria")}
            flex={1}
          />
          <CustomInput
            placeholder="Enter filter"
            labelinput="Filter"
            value={item.EmailFilter || ""}
            onChange={(e) => handleChange(e.target.value, "EmailFilter")}
            flex={1}
          />
        </Box>
      )}
      {item.testdata_source === "ChangeTestData" && (
        <CustomInput
          placeholder="Enter new data"
          labelinput="New Data"
          value={item.TestData || ""}
          onChange={(e) => handleChange(e.target.value, "TestData")}
          flex={1}
        />
      )}
      {item.testdata_source === "Date" && (
        <Box display="flex" columnGap={2}>
          <CustomInput
            placeholder="Enter date"
            labelinput="Date"
            value={item.TestData.split("~")[2] || ""}
            onChange={(e) => handleDateChange(e.target.value, "date", "TestData")}
            flex={1}
          />
          <CustomInput
            placeholder="Enter date format"
            labelinput="Date format"
            value={item.TestData.split("~")[3] || ""}
            onChange={(e) => handleDateFormat(e.target.value, "dateformat", "TestData")}
            flex={1}
          />
        </Box>
      )}
      {item.testdata_source === "FromApi" && (
        <>
          <Box display="flex" columnGap={2} mb={2}>
            <CustomSelect
              label={"Product"}
              options={(products ?? []).map((prodItem) => ({
                label: prodItem.productname,
                value: prodItem.idproducts,
              }))}
              width={"100%"}
              background={"#ffffff"}
              value={item.apiproduct}
              onChange={(e) => {
                setSelectedProduct(e.target.value);
                handleChange(e.target.value, "apiproduct");
              }}
            />
            {item.apiproduct !== "" && item.apiproduct !== 0 && (
              <CustomSelect
                label={"Module"}
                options={(Array.isArray(modules) ? modules : []).map((modItem) => ({
                  label: modItem.modulename,
                  value: modItem.idmodules,
                }))}
                width={"100%"}
                background={"#ffffff"}
                value={item.apimodule}
                onChange={(e) => {
                  setSelectedModule(e.target.value);
                  handleChange(e.target.value, "apimodule");
                  refetchAllApi();
                }}
              />
            )}
            {item.apimodule !== "" && item.apimodule !== 0 && (
              <CustomSelect
                label={"Api name"}
                options={(Array.isArray(allApi) ? allApi : []).map((apiItem) => ({
                  label: apiItem.name,
                  value: apiItem.idapi,
                }))}
                width={"100%"}
                background={"#ffffff"}
                value={item.apiid}
                onChange={(e, selected) => {
                  // dispatch(setEditStep({ ...item, apiid: e.target.value }));
                  handleChangeMultiple({
                    apiid: e.target.value,
                    TestData: selected.props.children,
                  });
                  // dispatch(setEditStep({ ...item, TestData: selected.props.children }));
                }}
              />
            )}
            <Button
              variant="text"
              sx={{ marginTop: "24px" }}
              onClick={() => {
                handleChangeMultiple({
                  apimodule: "",
                  apiName: "",
                  apiproduct: "",
                  apiId: "",
                  apiQuery: "",
                  apiParam: "",
                });
              }}
            >
              Clear
            </Button>
          </Box>
          {item.apimodule !== "" && item.apimodule !== 0 && item.apiName !== "" && (
            <Box display="flex" columnGap={2}>
              <CustomInput
                placeholder="Enter api Param"
                labelinput="Api Param"
                value={item.apiparam || ""}
                onChange={(e) => handleChange(e.target.value, "apiparam")}
                flex={1}
              />
              <CustomInput
                placeholder="Enter api query"
                labelinput="Api Query"
                value={item.DBQuery || ""}
                onChange={(e) => handleChange(e.target.value, "DBQuery")}
                flex={1}
              />
            </Box>
          )}
        </>
      )}
      {/* {item.testdata_source === "SameRow" && (
        <Box display="flex" columnGap={2}>
          <CustomSelect
            label={"Element Type"}
            options={[{ label: "Image", value: "IMG" }]}
            width={"100%"}
            background={"#ffffff"}
            value={item.samerowtype}
            onChange={(e) => {
              handleChange(e.target.value, "samerowtype");
            }}
          />
          <CustomInput
            placeholder="Element Index"
            labelinput="Element Index"
            value={item.samerowindex || ""}
            onChange={(e) => handleChange(e.target.value, "samerowindex")}
            flex={1}
          />
        </Box>
      )} */}
      {item.testdata_source === "FromDB" && (
        <CustomInput
          placeholder="Enter DB query"
          labelinput="DB Query"
          value={item.DBQuery || ""}
          onChange={(e) => handleChange(e.target.value, "DBQuery")}
          flex={1}
        />
      )}
      {item.testdata_source === "Regex" && (
        <CustomInput
          placeholder="Enter Regex"
          labelinput="Regex"
          value={item.regex || ""}
          onChange={(e) => handleChange(e.target.value, "regex")}
          flex={1}
        />
      )}
      {item.testdata_source === "InDownloadedFile" && (
        <Box display="flex" columnGap={2}>
          <CustomSelect
            label={"Downloaded Files"}
            options={(downloadedFiles ?? [{ label: "", item: "" }]).map((fileItem) => ({
              label: fileItem,
              value: fileItem,
            }))}
            width={"100%"}
            background={"#ffffff"}
            value={item.filename.split("~")[0]}
            onChange={(e) => handleChange(e.target.value, "filename")}
          />
          <CustomInput
            placeholder="Enter assertion value"
            labelinput="Assertion value"
            value={item.filename.split("~")[1]}
            onChange={(e) => handleAssertionValueChange(e.target.value, "DBQuery")}
            flex={1}
          />
        </Box>
      )}
      {item.testdata_source === "UseVariableFromTheScenario" && (
        <CustomSelect
          label={"Use var from this scenario"}
          options={
            Array.isArray(recordedTestDataList?.list) && recordedTestDataList.list.length > 0
              ? recordedTestDataList.list.map((fileItem) => ({
                  label: fileItem.label || fileItem,
                  value: fileItem.value || fileItem,
                }))
              : [{ label: "No data available", value: "" }]
          }
          width={"100%"}
          background={"#ffffff"}
          value={item.TestData}
          onChange={(e) => handleChange(e.target.value, "TestData")}
        />
      )}
      {item.testdata_source === "FromFile" && (
        <Box display="flex" columnGap={2} alignItems={"center"}>
          <Button
            sx={{ height: " 50px", marginTop: "20px" }}
            size="medium"
            variant="contained"
            component={"label"}
            startIcon={<CloudUploadIcon />}
          >
            Upload file
            <input type="file" hidden onChange={handleFileChange} />
          </Button>
          <CustomInput
            labelinput="File Name"
            value={item.filename}
            variant="outlined"
            disabled
            InputProps={{
              readOnly: true,
            }}
          />
          {(fileNameType === "xlsx" || fileNameType === "csv") && (
            <>
              <CustomInput
                placeholder="Sheet"
                labelinput="Sheet"
                value={item.sheet}
                onChange={(e) => handleChange(e.target.value, "sheet")}
              />
              <CustomInput
                placeholder="Cell"
                labelinput="Cell"
                value={item.cell}
                onChange={(e) => handleChange(e.target.value, "cell")}
              />
              <CustomSelect
                label={"Scope"}
                options={(scopeOptionList ?? [{ label: "", item: "" }]).map((item) => ({
                  label: item.value,
                  value: item.label,
                }))}
                width={"100%"}
                background={"#ffffff"}
                value={item.scope}
                onChange={(e) => handleChange(e.target.value, "scope")}
              />
              {item?.scope === scopeOptionList[0].value && (
                <CustomInput
                  placeholder="End Range"
                  labelinput="End Range"
                  value={item.endRange}
                  onChange={(e) => handleChange(e.target.value, "endRange")}
                />
              )}
            </>
          )}
          {fileNameType === "json" && (
            <>
              <CustomInput
                placeholder="Query"
                labelinput="Query"
                value={item.filter}
                onChange={(e) => handleChange(e.target.value, "filter")}
              />
              <CustomSelect
                label={"Scope"}
                options={(scopeOptionList ?? [{ label: "", item: "" }]).map((item) => ({
                  label: item.value,
                  value: item.label,
                }))}
                width={"100%"}
                background={"#ffffff"}
                value={item.scope}
                onChange={(e) => handleChange(e.target.value, "scope")}
              />
              {item?.scope === scopeOptionList[0].value && (
                <CustomInput
                  placeholder="End Range"
                  labelinput="End Range"
                  value={item.endRange}
                  onChange={(e) => handleChange(e.target.value, "endRange")}
                />
              )}
            </>
          )}
        </Box>
      )}
      {item.testdata_source === "FromReferenceFile" && (
        <Box display="flex" columnGap={2}>
          <CustomSelect
            label={"Select file"}
            options={
              Array.isArray(refFiles?.list) && refFiles?.list.length > 0
                ? refFiles.list.map((fileItem) => ({
                    label: fileItem.label || fileItem,
                    value: fileItem.value || fileItem,
                  }))
                : [{ label: "No files available", value: "" }]
            }
            width={"100%"}
            background={"#ffffff"}
            value={item.filename}
            onChange={handleRefFileChange}
          />
          {(fileNameType === "xlsx" || fileNameType === "csv") && (
            <>
              <CustomInput
                placeholder="Sheet"
                labelinput="Sheet"
                value={item.sheet}
                onChange={(e) => handleChange(e.target.value, "sheet")}
              />
              <CustomInput
                placeholder="Cell"
                labelinput="Cell"
                value={item.cell}
                onChange={(e) => handleChange(e.target.value, "cell")}
              />
              <CustomSelect
                label={"Scope"}
                options={(scopeOptionList ?? [{ label: "", item: "" }]).map((item) => ({
                  label: item.value,
                  value: item.label,
                }))}
                width={"100%"}
                background={"#ffffff"}
                value={item.scope}
                onChange={(e) => handleChange(e.target.value, "scope")}
              />
              {item?.scope === scopeOptionList[0].value && (
                <CustomInput
                  placeholder="End Range"
                  labelinput="End Range"
                  value={item.endRange}
                  onChange={(e) => handleChange(e.target.value, "endRange")}
                />
              )}
            </>
          )}
          {fileNameType === "json" && (
            <>
              <CustomInput
                placeholder="Query"
                labelinput="Query"
                value={item.filter}
                onChange={(e) => handleChange(e.target.value, "filter")}
              />
              <CustomSelect
                label={"Scope"}
                options={(scopeOptionList ?? [{ label: "", item: "" }]).map((item) => ({
                  label: item.value,
                  value: item.label,
                }))}
                width={"100%"}
                background={"#ffffff"}
                value={item.scope}
                onChange={(e) => handleChange(e.target.value, "scope")}
              />
              {item?.scope === scopeOptionList[0].value && (
                <CustomInput
                  placeholder="End Range"
                  labelinput="End Range"
                  value={item.endRange}
                  onChange={(e) => handleChange(e.target.value, "endRange")}
                />
              )}
            </>
          )}
        </Box>
      )}
      {item.testdata_source === "GoogleSheet" && (
        <Box display="flex" columnGap={2}>
          <CustomInput
            placeholder="Sheet Id"
            labelinput="Sheet Id"
            value={item.filename}
            onChange={(e) => handleChange(e.target.value, "filename")}
          />
          <CustomInput
            placeholder="Sheet"
            labelinput="Sheet"
            value={item.sheet}
            onChange={(e) => handleChange(e.target.value, "sheet")}
          />
          <CustomInput
            placeholder="Cell"
            labelinput="Cell"
            value={item.cell}
            onChange={(e) => handleChange(e.target.value, "cell")}
          />
          <CustomSelect
            label={"Scope"}
            options={(scopeOptionList ?? [{ label: "", item: "" }]).map((item) => ({
              label: item.value,
              value: item.label,
            }))}
            width={"100%"}
            background={"#ffffff"}
            value={item.scope}
            onChange={(e) => handleChange(e.target.value, "scope")}
          />
          {item?.scope === scopeOptionList[0].value && (
            <CustomInput
              placeholder="End Range"
              labelinput="End Range"
              value={item.endRange}
              onChange={(e) => handleChange(e.target.value, "endRange")}
            />
          )}
        </Box>
      )}
      {item.testdata_source === "DependentonOtherTestScenario" && (
        <Box display="flex" columnGap={2} alignItems={"center"}>
          <CustomSelect
            label={"Product"}
            options={products.map((prodItem) => ({
              label: prodItem.productname,
              value: prodItem.idproducts,
            }))}
            width={"100%"}
            background={"#ffffff"}
            value={item.DependentProduct}
            onChange={(e) => {
              setSelectedProduct(e.target.value);
              handleChangeMultiple({
                DependentTC: 0,
                DependentModule: 0,
                DependentProduct: e.target.value,
                apiproduct: e.target.value,
              });
              refetchModules();
            }}
          />
          {item.DependentProduct ? (
            <CustomSelect
              label={"Module"}
              options={(Array.isArray(modules) ? modules : []).map((modItem) => ({
                label: modItem.modulename,
                value: modItem.idmodules,
              }))}
              width={"100%"}
              background={"#ffffff"}
              value={item.DependentModule}
              onChange={(e) => {
                setSelectedModule(e.target.value);
                handleChangeMultiple({
                  DependentModule: e.target.value,
                  apimodule: e.target.value,
                  DependentTC: "",
                });
                refetchTestCases();
              }}
            />
          ) : null}
          {item.DependentModule ? (
            <Box display="flex" columnGap={2}>
              {/* <AutocompleteComponent
                options={(Array.isArray(testCases) ? testCases : []).map((tc) => {
                  return {
                    label: tc.Test_Case,
                    value: tc.idtest_case,
                  };
                })}
                value={
                  selectedTestCase && testCases?.length
                    ? (testCases ?? []).find(
                        (testCase) => testCase.idtest_case === selectedTestCase,
                      ) || null
                    : ""
                }
                onChange={(e, selected) => {
                  console.log("here change");
                  setSelectedTestCase(selected?.value);
                  handleChangeMultiple({
                    idtest_case_One: selected?.value,
                    useVar: selected?.label,
                    DependentTC: selected?.value,
                  });
                  refetchRecordedTestDataList();
                }}
                onInputChange={handleOptionChange}
                handleSearchClick={handleSearchClick}
                placeholder="Search Test Cases"
                getOptionLabel={(testCase) => {
                  return testCase.label ?? "";
                }}
              /> */}
              <CustomSelect
                label={"Select Test Cases"}
                options={(Array.isArray(testCases) ? testCases : []).map((tc) => {
                  return {
                    label: tc.Test_Case,
                    value: tc.idtest_case,
                  };
                })}
                width={"300px"}
                background={"#ffffff"}
                value={item.DependentTC}
                onChange={(e) => {
                  handleChangeMultiple({ DependentTC: e.target.value });
                  setSelectedTestCase(e.target.value);
                  refetchRecordedTestDataList();
                }}
              />
              <CustomInput
                placeholder="Enter Search Test Case here..."
                labelinput="Search Test Case"
                value={testCaseSearchKeyword}
                onChange={(e) => setTestCaseSearchKeyword(e.target.value)}
                sx={{ width: "300px" }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={handleTestCaseSearch}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Box>
          ) : null}
          {item.DependentTC !== "" && item.DependentTC !== 0 ? (
            <CustomSelect
              label={"Use var from this scenario"}
              options={
                Array.isArray(recordedTestDataList?.list) && recordedTestDataList.list.length > 0
                  ? recordedTestDataList.list.map((fileItem) => ({
                      label: fileItem.label || fileItem,
                      value: fileItem.value || fileItem,
                    }))
                  : [{ label: "No data available", value: "" }]
              }
              width={"100%"}
              background={"#ffffff"}
              value={item.TestData}
              onChange={(e) => handleChangeMultiple({ useVar: e.target.value })}
            />
          ) : null}
          {item.DependentModule ? (
            <Box mt={2}>
              <Button
                variant="contained"
                onClick={() => {
                  handleChangeMultiple({
                    DependentProduct: "",
                    DependentModule: "",
                    DependentTC: "",
                  });
                }}
              >
                Clear
              </Button>
            </Box>
          ) : null}
        </Box>
      )}
      <Box mt={2}>
        <Button variant="contained" onClick={handleSubmit}>
          Submit
        </Button>
      </Box>
    </Box>
  );
};
export default TestDataSetup;
