import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

const getExecutionThreadChart = async (fromDate, toDate) => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetails);

  const response = await axios.get(
    `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getExecutionThreadChart&companyid=${user.companyid}&token=${user.token}&fromDateTime=${fromDate}&toDateTime=${toDate}`,
  );

  return response.data;
};

const useExecutionThreadChartQuery = ({fromDate, toDate}, options) => {
  return useQuery({
    queryKey: ["ExecutionThreadChart"],
    queryFn: () => getExecutionThreadChart(fromDate, toDate),
    retry: true,
    enabled: false,
    cacheTime: 0,
    refetchIntervalInBackground: false,
    staleTime: 0,
  });
};

export default useExecutionThreadChartQuery;
