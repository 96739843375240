import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

const fetchTestCaseRecords = async (id,criteria) => {
    console.log('here')
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetails);

  const response = await axios.get(
    `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getTestCaseResultFromTestSuiteResult&companyid=${user.companyid}&token=${user.token}&testSuiteResultId=${id}&criteria=${criteria}`,
  );

  return response.data;
};

const useTestCaseResultsFromTestSuiteQuery = (id,criteria,  options) => {
  return useQuery({
    queryKey: ["TestCaseResultsfromTS", id],
    queryFn: () => fetchTestCaseRecords(id,criteria),
    enabled: options.enabled,
    retry: true,
    cacheTime: 90000,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    staleTime: Infinity
  });
};

export default useTestCaseResultsFromTestSuiteQuery;
