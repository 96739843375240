import axios from "axios";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "./../Config/Config";

export const sendUserInfo = async (data) => {
  const Response = await axios
    .post(`${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=registerUser`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const changePassword = async (data) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=changePassword&companyid=${temp.companyid}&token=${temp.token}`,
      data
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const fetchLoginDetails = async (data) => {
  const Response = await axios
    .post(`${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=auth`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const fetchForgotPasswordToken = async (id) => {
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=valkeygen&username=${id}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const validateOTP = async (id, token) => {
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=verifyvalkey&username=${id}&kayval=${token}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const changepasswordFromForgotPassword = async (data) => {
  const Response = await axios
    .post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=forgetPassword`,
      data
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};
