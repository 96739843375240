import React, { useEffect, useState } from "react";
// import "./ResultStepPage.scss";
// import TableContainer from "@mui/material/TableContainer";
// import Paper from "@mui/material/Paper";
// import TableHead from "@mui/material/TableHead";
import {
  StyledTableRow,
  StyledTableCell,
  BootstrapTooltip,
} from "./../../components/common/common.Component";
import { fetchfiledatafromDB } from "./../../API/CreateCollectionAPI";
import TextField from "@mui/material/TextField";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";

import {
  fetchProducts,
  fetchModules,
  fetchEnvironments,
  fetchTestCasesfromModule,
  fetchdownloadedfiles,
} from "./../../API/CreateCollectionAPI";
import Alert from "@mui/material/Alert";
import {
  Table,
  Box,
  Link,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { API_BASE_PATH, API_BASE_RELATIVE_PATH } from "./../../Config/Config";
import { fetchAllApiDetails } from "./../../API/Api";

import {
  validateDetailsFromDB,
  validateDetailsFromEmail,
  validateDetailsFromFile,
  postEnviromentSubmit,
  validateDetailsOnScreen,
  validateDetailsBySearch,
  validateDetailsFromRegex,
  validateDetailsFromDate,
  validateDetailsFromApi,
  validateDetailsFromGoogleSheet,
  validateDetailsFromDependantTC,
  validateDetailsChangeText,
  validateReferenceFileData,
  validateDownloadedFile,
} from "../../API/ValidateAPI";

import {
  handleFlowChange,
  handleSelectChange,
  handleCheckBoxChange,
  handleValidateChange,
  handleBeforeChange,
  handleWaitTimeChange,
  fetchRecording,
  // executeTest,
  deleteStep,
  handleTDChange,
  fetchSelectFromListData,
  addStepInrecording,
  editStepInrecording,
  handleTestCaseSearchByMid,
} from "./../../API/RecordingTableAPI";

import { getResolution } from "./../../API/adminAPI";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";

import { useNavigate, useLocation } from "react-router-dom";
import { CommonMethods } from "./../../components/common/CommonMethods";
// import {
//     fetchTestStepFailResult,
//   } from "./../../API/CreateCollectionAPI";
// import TableBody from "@mui/material/TableBody";
// import ResultScreenShotModal from "./../../components/TCTable/ResultScreenShotModal";

const ModalExpand = ({
  test,
  keywordValue,
  handleClose,
  additionalTestCase,
  productId,
  TestDetails,
}) => {
  const DBTypeLIstData = [
    { value: "Oracle", name: "Oracle" },
    { value: "MYSQL", name: "MySQL" },
    { value: "MSSQL", name: "MS SQL" },
    { value: "Other", name: "Other" },
  ];

  const selectLIstData = [
    { value: "AsRecorded", name: "As Recorded" },
    { value: "ChangeTestData", name: "Change TestData" },
    { value: "IsAVar", name: "Create As Reference Variable" },
    { value: "FromApi", name: "From Api" },
    { value: "FromDB", name: "From DB" },
    { value: "FromEmail", name: "From Email" },

    { value: "FromFile", name: "From File" },
    { value: "FromReferenceFile", name: "From TestData" },
    { value: "GenData", name: "Generate Data" },
    { value: "GoogleSheet", name: "Google Sheet" },
    { value: "SameRow", name: "On Same Row as Test Data" },
    { value: "SameRowElement", name: "On Same Row as Previous Element" },

    { value: "UseVariableFromTheScenario", name: "Data Dependence-This Scenario" },
    { value: "DependentonOtherTestScenario", name: "Data Dependence-Other Scenario" },
    // { value: "Script", name: "Script" },
    { value: "SendEmail", name: "Send Email" },
  ];

  // const selectLIstData = [
  // { value: "D0", name: "Designate As D0" },
  // { value: "DNext", name: "Element next to D0" },
  // { value: "DBelow", name: "Element Below D0" },
  //   ];

  const validateSelectLIstData = [
    { value: "ChangeTestData", name: "Change Text" },

    { value: "Date", name: "Date" },
    { value: "DynamicText", name: "Dynamic Text" },
    { value: "FromEmail", name: "From Email" },
    { value: "InApi", name: "In Api" },
    { value: "DB", name: "In DB" },
    { value: "InDownloadedFile", name: "In Downloaded Files" },
    { value: "InFile", name: "In File" },
    { value: "InGoogleSheet", name: "In Google Sheet" },
    { value: "InRefrenceFile", name: "In TestData" },
    { value: "OnScreen", name: "On Screen" },
    { value: "UseVariableFromTheScenario", name: "Data Dependence-This Scenario" },
    { value: "DependentonOtherTestScenario", name: "Data Dependence-Other Scenario" },
    { value: "Regex", name: "Regex" },
  ];

  // const validateSelectLIstData = [
  //     { value: "ChangeText", name: "Change Text" },
  //     { value: "InFile", name: "In File" },
  //     { value: "UseVariableFromTheScenario", name: "Refer From this Scenario" },
  //   ];

  const fileNameParts = test.filename ? test.filename.split("~") : "";

  const fileNameParts2 = test.filename ? test.filename.split("~") : "";

  const [TestDataDetails, setTestDataDetails] = useState({
    id: test.idtest_step ? test.idtest_step : "",
    Source: test.testdata_source ? test.testdata_source : "",
    UploadString: test.TestData ? test.TestData : "",
    GenerateDataType: test.gendataType ? test.gendataType : "",
    generateDataLength: test.gendatalen ? test.gendatalen : "",
    generateDateFormat: test.gendateformat ? test.gendateformat : "",
    generateDateOffset: test.gendateoffset ? test.gendateoffset : "",
    useVar: test.TestData ? test.TestData : "",
    DBType: test.DBType ? test.DBType : "",
    DBOtherType: "",
    DBURL: test.DBUrl ? test.DBUrl : "",
    DBusername: test.DBUsername ? test.DBUsername : "",
    DBpwd: test.DBPwd ? test.DBPwd : "",
    DBQuery: test.DBQuery ? test.DBQuery : "",
    file: "",
    FileName: "",
    filename: test.filename,
    filefield: test.fileField,
    testCaseId: "",
    filefromDB: "",
    SelectEnvironmentName: TestDetails.SelectEnvironmentName
      ? TestDetails.SelectEnvironmentName
      : "",
    SelectEnvironmentUrl: TestDetails.SelectEnvironmentUrl ? TestDetails.SelectEnvironmentUrl : "",
    Height: TestDetails.Height ? TestDetails.Height : 0,
    Width: TestDetails.Width ? TestDetails.Width : 0,
    SelectBrowserName: TestDetails.SelectBrowserName ? TestDetails.SelectBrowserName : "",
    regex: test.regex ? test.regex : "",
    date: "",
    dateFromat: "",
    xpath: "",
    testdata: "",
    search: "",
    ElementType: test.samerowtype ? test.samerowtype : "",
    ElementIndex: test.samerowindex ? test.samerowindex : "",
    Target: test.ValDevice ? test.ValDevice : "",
    DependentModule: test.DependentModule,
    DependentProduct: test.DependentProduct,
    DependentTC: test.DependentTC,
    toAddress: test.toaddress ? test.toaddress : "",
    subject: test.emailsubject ? test.emailsubject : "",
    content: test.emailcontent ? test.emailcontent : "",
    customerEmail: test.customerEmail ? test.customerEmail : "",
    customerPassword: test.customerPassword ? test.customerPassword : "",
    EmailSelectionCriteria: test.EmailSelectionCriteria ? test.EmailSelectionCriteria : "",
    EmailFilter: test.EmailFilter ? test.EmailFilter : "",
    apiName: test.apiid,
    apiParam: test.apiparam,
    apiQuery: test.DBQuery,
    apiproduct: test?.apiproduct,
    apimodule: test?.apimodule,
    FileName: test.filename,
    apiId: test.TestData,
    sheetId: test.sheet,
    cell: test.cell,
    scope: test.scope === null ? "Single Run" : test.scope,
    endRange: test.endRange,
    filter: test.fileField,
    fromFileType: "",
    AssertionValue: fileNameParts.length > 1 ? fileNameParts[1] : test.filename,
    DownloadedFile: fileNameParts2[0],
  });

  const [state, setState] = useState({
    file: "",
    tc: "",
    xls: [],
    tcs: {},
    stepnums: [],
    display: "view",
    Height: 0,
    Width: 0,
    testCaseId: -1,
    expanded: false,
    isGenDataSelected: false,
    generateDataValue: "",
    productname: "",
    modulename: "",
    idproducts: "",
    idproducts_One: "",
    idmodules: "",
    idmodules_One: "",
    idtest_case: "",
    idtest_case_One: "",
    Test_Case: "",
    Test_Case_One: "",
    browserDetails: [
      { browsername: "Chrome" },
      { browsername: "Edge" },
      { browsername: "Firefox" },
      { browsername: "Safari" },
      { browsername: "ALL" },
    ],
    runOnceDetails: [{ option: "Yes" }, { option: "No" }],
    resolutionDetails: [],
    envDetails: [],
    SelectEnvironmentName: "",
    SelectEnvironmentUrl: "",
    SelectResolution: "",
    SelectBrowserName: "Chrome",
    SelectRunonce: "Yes",
  });
  const [filedropdowndata, setFiledropdowndata] = useState([]);
  const [ListData, SetListData] = useState([]);
  const [ModuleList, setModuleList] = useState([]);
  const [ModuleListOne, setModuleListOne] = useState([]);
  const [productList, setproductList] = useState([]);
  const [TestCaseList, setTestCaseList] = useState([]);
  const [TestCaseListOne, setTestCaseListOne] = useState([]);
  const [EnvironmentList, setEnvironmentList] = useState([]);
  const [testCaseSearchValueInTable, setTestCaseSearchValueInTable] = useState(false);
  const [SearchStringTestDataSource, setSearchStringTestDataSource] = useState();
  const [apiList, setApiList] = useState([]);
  const [SearchStringValidation, setSearchStringValidation] = useState();
  const RouteState = useLocation();
  const navigate = useNavigate();
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const [fileTypeCheck, setFileTypeCheck] = useState();
  const [fromTypeCheck, setFromTypeCheck] = useState();
  const [fileNameTypeCheck, setFileNameTypeCheck] = useState();
  const [downloadedFileData, setDownloadedFileData] = useState([]);

  useEffect(() => {
    getProducts();
    handleNameType(TestDataDetails.filename);
    handleFromType(TestDataDetails.FileName);
  }, []);

  useEffect(() => {
    if (productList[0]?.idproducts !== undefined) {
      getEnvironments(productList[0]?.idproducts);
    }
  }, [productList[0]?.idproducts]);

  useEffect(() => {
    if (
      TestDataDetails.Target === "UseVariableFromTheScenario" ||
      TestDataDetails.Source === "UseVariableFromTheScenario"
    ) {
      getSelectFromListData(test.idtest_step, test.Test_Case_Id);
    }
  }, [TestDataDetails.Target, TestDataDetails.Source]);

  useEffect(() => {
    let arr = TestCaseListOne;
    if (
      !TestCaseListOne.find((val) => val.idtest_case === additionalTestCase.idtest_case) &&
      !testCaseSearchValueInTable
    ) {
      arr = [...arr, additionalTestCase];
      setTestCaseListOne(arr);
    }
  }, [TestCaseListOne, testCaseSearchValueInTable]);

  useEffect(() => {
    if (TestDataDetails.DependentProduct && TestDataDetails.DependentProduct !== null) {
      getModules(TestDataDetails.DependentProduct);
    }
  }, [TestDataDetails.DependentProduct]);

  useEffect(() => {
    if (TestDataDetails.DependentModule && TestDataDetails.DependentModule !== null) {
      getTestCase(TestDataDetails.DependentModule);
    }
  }, [TestDataDetails.DependentModule]);

  useEffect(() => {
    if (TestDataDetails.DependentTC && TestDataDetails.DependentTC !== null) {
      getSelectFromListData(test.idtest_step, TestDataDetails.DependentTC);
    }
  }, [TestDataDetails.DependentTC]);

  useEffect(() => {
    if (TestDataDetails.apiproduct && TestDataDetails.apiproduct !== null) {
      getModules(TestDataDetails.apiproduct);
    }
  }, [TestDataDetails.apiproduct]);

  useEffect(() => {
    if (TestDataDetails.apimodule && TestDataDetails.apimodule !== null) {
      getAllApiDetails(TestDataDetails.apimodule);
    }
  }, [TestDataDetails.apimodule]);

  useEffect(() => {
    if (TestDataDetails.FileName && TestDataDetails.FileName !== null) {
      getSelectFildata(productId);
    }
  }, [TestDataDetails.FileName]);

  useEffect(() => {
    if (TestDataDetails.DownloadedFile && TestDataDetails.DownloadedFile !== null) {
      getDownloadedFile(test.Test_Case_Id);
    }
  }, [TestDataDetails.DownloadedFile]);

  const getProducts = async (api_input) => {
    await fetchProducts(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setproductList(response.data);
            getResolutionData(response.data[0].idproducts);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getModules = async (id) => {
    await fetchModules(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setModuleList(response.data);
            setModuleListOne(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTestCase = async (id) => {
    await fetchTestCasesfromModule(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setTestCaseList(response.data);
            setTestCaseListOne(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSelectFildata = async (id) => {
    await fetchfiledatafromDB(id)
      .then((response) => {
        if (response.status === 200) {
          // setloader(false);
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setFiledropdowndata(response.data.list);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAllApiDetails = async (id) => {
    await fetchAllApiDetails(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setApiList(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getEnvironments = async (id) => {
    await fetchEnvironments(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setEnvironmentList(response.data);
            setState((prev) => {
              return {
                ...prev,
                envDetails: response.data,
              };
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getResolutionData = async (id) => {
    await getResolution(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setState((prev) => {
              return {
                ...prev,
                resolutionDetails: response.data.ResolutionList,
              };
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUploadClick = (fromtestdatasource) => {
    if (!TestDataDetails.file) {
      return;
    }
    let apicall;
    if (fileTypeCheck === "xlsx" || fileTypeCheck === "csv") {
      apicall = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestData&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${test.Test_Case_Id}&teststepid=${TestDataDetails.id}&testdata=${TestDataDetails.Source}&name=${TestDataDetails.file[0].name}&sheetId=${TestDataDetails.sheetId}&cell=${TestDataDetails.cell}&scope=${TestDataDetails.scope}&endRange=${TestDataDetails.endRange}`;
    } else if (fileTypeCheck === "json") {
      apicall = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestData&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${test.Test_Case_Id}&teststepid=${TestDataDetails.id}&testdata=${TestDataDetails.Source}&name=${TestDataDetails.file[0].name}&filter=${TestDataDetails.filter}&scope=${TestDataDetails.scope}&endRange=${TestDataDetails.endRange}`;
    } else {
      apicall = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestData&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${test.Test_Case_Id}&teststepid=${TestDataDetails.id}&testdata=${TestDataDetails.Source}&name=${TestDataDetails.file[0].name}`;
    }
    fetch(apicall, {
      method: "POST",
      body: TestDataDetails.file[0],
      headers: {
        "content-type": TestDataDetails.file[0].type,
        "content-length": `${TestDataDetails.file[0].size}`, // 👈 Headers need to be a string
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (fromtestdatasource) {
          ChangeTableRowData(TestDataDetails);
        }
      })
      .catch((err) => console.error(err));
    setTestDataDetails({
      id: "",
      Source: "",
      UploadString: "",
      GenerateDataType: "",
      generateDataLength: "",
      generateDateFormat: "",
      generateDateOffset: "",
      useVar: "",
      DBType: "",
      DBOtherType: "",
      DBURL: "",
      DBusername: "",
      DBpwd: "",
      DBQuery: "",
      file: "",
      filename: "",
      FileName: "",
      filefield: "",
      testCaseId: "",
      SelectEnvironmentName: "",
      SelectEnvironmentUrl: "",
      regex: "",
      xpath: "",
      testdata: "",
      search: "",
      ElementType: "",
      ElementIndex: "",
      valTarget: "OnScreen",
    });
  };

  const ChangeTableRowData = async (api_input) => {
    await handleTDChange(api_input)
      .then((response) => {
        if (response.status === 200) {
          // setloader(false);
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            //   setState((prev) => {
            //     return {
            //       ...prev,
            //       xls: Object.values(response?.data.data),
            //     };
            //   });
            setTestDataDetails({
              id: "",
              Source: "",
              UploadString: "",
              FileName: "",
              GenerateDataType: "",
              generateDataLength: "",
              generateDateFormat: "",
              generateDateOffset: "",
              useVar: "",
              DBType: "",
              DBOtherType: "",
              DBURL: "",
              DBusername: "",
              DBpwd: "",
              DBQuery: "",
              file: "",
              filename: "",
              filefield: "",
              testCaseId: "",
              SelectEnvironmentName: "",
              SelectEnvironmentUrl: "",
              regex: "",
              testdata: "",
              xpath: "",
              search: "",
              ElementType: "",
              ElementIndex: "",
              valTarget: "OnScreen",
              customerEmail: "",
              customerPassword: "",
              EmailSelectionCriteria: "",
              EmailFilter: "",
              toAddress: "",
              subject: "",
              content: "",
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validateDataFromFile = async () => {
    if (!TestDataDetails.file) {
      return;
    }
    // let apicall = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=validate&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${test.Test_Case_Id}&teststepid=${TestDataDetails.id}&valdtype=InFile&testdata=${TestDataDetails.Source}&name=${TestDataDetails.file[0].name}&filefield=${TestDataDetails.filefield}`;
    let apicall;
    if (fileTypeCheck === "xlsx" || fileTypeCheck === "csv") {
      apicall = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=validate&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${test.Test_Case_Id}&teststepid=${TestDataDetails.id}&valdtype=InFile&testdata=${TestDataDetails.Source}&name=${TestDataDetails.file[0].name}&sheetId=${TestDataDetails.sheetId}&cell=${TestDataDetails.cell}&scope=${TestDataDetails.scope}&endRange=${TestDataDetails.endRange}`;
    } else if (fileTypeCheck === "json") {
      apicall = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=validate&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${test.Test_Case_Id}&teststepid=${TestDataDetails.id}&valdtype=InFile&testdata=${TestDataDetails.Source}&name=${TestDataDetails.file[0].name}&filter=${TestDataDetails.filter}&scope=${TestDataDetails.scope}&endRange=${TestDataDetails.endRange}`;
    } else {
      apicall = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=validate&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${test.Test_Case_Id}&teststepid=${TestDataDetails.id}&valdtype=InFile&testdata=${TestDataDetails.Source}&name=${TestDataDetails.file[0].name}`;
    }
    fetch(apicall, {
      method: "POST",
      body: TestDataDetails.file[0],
      headers: {
        "content-type": TestDataDetails.file[0].type,
        "content-length": `${TestDataDetails.file[0].size}`, // 👈 Headers need to be a string
      },
    });
    //   .then((res) => res.json())
    //   .then((data) => {
    //     // setState((prev) => {
    //     //   return {
    //     //     ...prev,
    //     //     xls: Object.values(data.data),
    //     //     // resolutionDetails:response.data.resolutionDetails,
    //     //   };
    //     // });
    //   })
    //   .catch((err) => console.error(err));
  };

  const SendEnviromentOnSubmit = async (api_input) => {
    await postEnviromentSubmit(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          }
          console.log("validateDetails FromDB succesfull");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validateDataFromDB = async (api_input) => {
    await validateDetailsFromDB(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setState((prev) => {
              return {
                ...prev,
                xls: Object.values(response.data.data),
                // resolutionDetails:response.data.resolutionDetails,
              };
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validateDataByRegex = async (api_input) => {
    await validateDetailsFromRegex(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          }
          //   else{
          //   setState((prev) => {
          //     return {
          //       ...prev,
          //       xls: Object.values(response.data.data),
          //       // resolutionDetails:response.data.resolutionDetails,
          //     };
          //   });
          // }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validateDataByApi = async (api_input) => {
    await validateDetailsFromApi(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setState((prev) => {
              return {
                ...prev,
                xls: Object.values(response.data.data),
                // resolutionDetails:response.data.resolutionDetails,
              };
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validateDataByGoogleSheet = async (api_input) => {
    await validateDetailsFromGoogleSheet(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setState((prev) => {
              return {
                ...prev,
                xls: Object.values(response.data.data),
                // resolutionDetails:response.data.resolutionDetails,
              };
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const validateDataByDate = async (api_input) => {
    await validateDetailsFromDate(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setState((prev) => {
              return {
                ...prev,
                xls: Object.values(response.data.data),
                // resolutionDetails:response.data.resolutionDetails,
              };
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const validateDataByDependence = async (api_input) => {
    await validateDetailsFromDependantTC(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setState((prev) => {
              return {
                ...prev,
                xls: Object.values(response.data.data),
                // resolutionDetails:response.data.resolutionDetails,
              };
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDownloadedFile = async (tcid) => {
    await fetchdownloadedfiles(tcid)
      .then((response) => {
        if (response.status === 200) {
          // setloader(false);
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setDownloadedFileData(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validatefiledatareference = async (api_input) => {
    await validateReferenceFileData(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setState((prev) => {
              return {
                ...prev,
                xls: Object.values(response.data.data),
                // resolutionDetails:response.data.resolutionDetails,
              };
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validationdownloadfiles = async (api_input) => {
    await validateDownloadedFile(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setState((prev) => {
              return {
                ...prev,
                xls: Object.values(response.data.data),
                // resolutionDetails:response.data.resolutionDetails,
              };
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validateDataChangeData = async (api_input) => {
    await validateDetailsChangeText(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setState((prev) => {
              return {
                ...prev,
                xls: Object.values(response.data.data),
                // resolutionDetails:response.data.resolutionDetails,
              };
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validateDataOnScreen = async (api_input) => {
    await validateDetailsOnScreen(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setState((prev) => {
              return {
                ...prev,
                xls: Object.values(response.data.data),
                // resolutionDetails:response.data.resolutionDetails,
              };
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validateDataBySearch = async (api_input) => {
    await validateDetailsBySearch(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setState((prev) => {
              return {
                ...prev,
                xls: Object.values(response.data.data),
                // resolutionDetails:response.data.resolutionDetails,
              };
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const searchTestCaseInModinTable = async (mid, key) => {
    await handleTestCaseSearchByMid(mid, key)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setTestCaseSearchValueInTable(true);
            setTestCaseListOne(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSelectFromListData = async (api_input, tcid) => {
    await fetchSelectFromListData(api_input, tcid)
      .then((response) => {
        if (response.status === 200) {
          // setloader(false);
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            SetListData(response.data.list);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFileChange = (e) => {
    if (e.target.files) {
      setTestDataDetails((prevState) => ({
        ...prevState,
        file: e.target.files,
        filename: e.target.files[0].name,
      }));
      setFileNameTypeCheck();
      const filename = e.target.files[0].name;
      const parts = filename.split(".");
      const fileExtension = parts.pop();

      setFileTypeCheck(fileExtension);
    }
  };
  const handleNameType = (name) => {
    if (name !== null) {
      const parts = name.split(".");
      const fileExtension = parts.pop();
      setFileNameTypeCheck(fileExtension);
    }
  };

  const handleFromType = (name) => {
    if (name !== null) {
      const parts = name.split(".");
      const fileExtension = parts.pop();
      setTestDataDetails((prevState) => ({
        ...prevState,
        fromFileType: fileExtension,
        FileName: name,
      }));
      setFromTypeCheck(fileExtension);
    }
  };

  const Formfield = (row) => {
    return (
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-end",
          //
        }}
      >
        <FormControl>
          <InputLabel id={`TestData Source-${row.idtest_step}`}>Test Data Source</InputLabel>
          <Select
            key={row.idtest_step}
            labelId={`TestData Source-${row.idtest_step}`}
            id={`row.idtest_step-${row.idtest_step}`}
            defaultValue={TestDataDetails.Source}
            label="TestData Source"
            name="Test Data Source"
            variant="standard"
            onChange={(event) => {
              handleNameType(TestDataDetails.filename);

              setTestDataDetails({
                ...TestDataDetails,
                id: row.idtest_step,
                testCaseId: state.tcs.idtest_case,
                Source: event.target.value,
                testdata: row.TestData,
              });
              if (event.target.value === "UseVariableFromTheScenario") {
                getSelectFromListData(row.idtest_step, test.Test_Case_Id);
              }
              if (event.target.value === "FromReferenceFile") {
                getSelectFildata(productId);
              }
            }}
            style={{ minWidth: 168, marginRight: "2rem", alignItems: "center" }}
          >
            {selectLIstData.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {TestDataDetails.Source === "FromReferenceFile" && (
          <>
            <FormControl>
              <InputLabel id={`FromReferenceFile-${row.idtest_step}`}>Select File</InputLabel>
              <Select
                key={row.idtest_step}
                variant="standard"
                labelId={`FromReferenceFile-${row.idtest_step}`}
                id={`row.idtest_step-${row.idtest_step}`}
                label="FromReferenceFile"
                value={TestDataDetails.FileName}
                onChange={(event) => {
                  handleFromType(event.target.value);
                }}
                sx={{ width: 180 }}
              >
                {filedropdowndata.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* <TextField
              sx={{ marginX: "2rem" }}
              label="File Field"
              variant="standard"
              defaultValue={TestDataDetails.filefield}
             onChange={(event)=>{
              setTestDataDetails({
                ...TestDataDetails,
                filefield: event.target.value,
            
              });
             }}
            /> */}
            {fromTypeCheck === "xlsx" || fromTypeCheck === "csv" ? (
              <>
                <TextField
                  sx={{ marginX: "2rem" }}
                  label="Sheet"
                  variant="standard"
                  defaultValue={TestDataDetails.sheetId}
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      sheetId: event.target.value,
                    });
                  }}
                />
                <TextField
                  sx={{ marginX: "2rem", width: "4%" }}
                  label="Cell"
                  variant="standard"
                  defaultValue={TestDataDetails.cell}
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      cell: event.target.value,
                    });
                  }}
                />

                <div>
                  <Typography>Scope</Typography>
                  <Select
                    variant="standard"
                    key={row.idtest_step}
                    labelId={`demo-simple-select-label-${row.idtest_step}`}
                    id={`row.idtest_step-${row.idtest_step}`}
                    value={TestDataDetails.scope}
                    label="Scope"
                    onChange={(event) => {
                      setTestDataDetails({
                        ...TestDataDetails,
                        scope: event.target.value,
                      });
                    }}
                  >
                    <MenuItem value="Multi Run">Multi Run</MenuItem>
                    <MenuItem value="Multi Execution">Multi Execution</MenuItem>
                    <MenuItem value="Single Run">Single Run</MenuItem>
                  </Select>
                </div>
                {TestDataDetails.scope === "Multi Run" ? (
                  <TextField
                    sx={{ marginX: "2rem", width: "4%" }}
                    label="End Range"
                    variant="standard"
                    defaultValue={TestDataDetails.endRange}
                    onChange={(event) => {
                      setTestDataDetails({
                        ...TestDataDetails,
                        endRange: event.target.value,
                      });
                    }}
                  />
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
            {fromTypeCheck === "json" ? (
              <>
                <TextField
                  sx={{ marginX: "2rem", width: "25%", marginLeft: "2rem" }}
                  label="Filter"
                  variant="standard"
                  defaultValue={TestDataDetails.filter}
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      filter: event.target.value,
                    });
                  }}
                />
                <div>
                  <Typography>Scope</Typography>
                  <Select
                    variant="standard"
                    key={row.idtest_step}
                    labelId={`demo-simple-select-label-${row.idtest_step}`}
                    id={`row.idtest_step-${row.idtest_step}`}
                    value={TestDataDetails.scope}
                    label="Scope"
                    onChange={(event) => {
                      setTestDataDetails({
                        ...TestDataDetails,
                        scope: event.target.value,
                      });
                    }}
                  >
                    <MenuItem value="Multi Run">Multi Run</MenuItem>
                    <MenuItem value="Multi Execution">Multi Execution</MenuItem>
                    <MenuItem value="Single Run">Single Run</MenuItem>
                  </Select>
                </div>
                {TestDataDetails.scope === "Multi Run" ? (
                  <TextField
                    sx={{ marginX: "2rem", width: "25%" }}
                    label="End Range"
                    variant="standard"
                    defaultValue={TestDataDetails.endRange}
                    onChange={(event) => {
                      setTestDataDetails({
                        ...TestDataDetails,
                        endRange: event.target.value,
                      });
                    }}
                  />
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </>
        )}
        {TestDataDetails.Source === "ChangeTestData" && (
          <TextField
            sx={{ marginRight: "2rem" }}
            label="New Data"
            variant="standard"
            defaultValue={TestDataDetails.UploadString}
            onChange={(event) => {
              event.preventDefault();
              setTestDataDetails((prev) => {
                return {
                  ...prev,
                  UploadString: event.target.value,
                };
              });
            }}
          />
        )}
        {TestDataDetails.Source === "GenData" && (
          <>
            <FormControl>
              <InputLabel id={`Generate Data Type-${row.idtest_step}`}>
                Generate Data Type
              </InputLabel>
              <Select
                labelId={`Generate Data Type-${row.idtest_step}`}
                id={`row.idtest_step-${row.idtest_step}`}
                value={TestDataDetails.GenerateDataType}
                label="Generate Data Type"
                variant="standard"
                onChange={(event) => {
                  setTestDataDetails({
                    ...TestDataDetails,
                    GenerateDataType: event.target.value,
                  });
                }}
                style={{
                  minWidth: 192,
                  marginRight: "2rem",
                  alignItems: "center",
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="CHAR">CHAR</MenuItem>
                <MenuItem value="ALPHA">ALPHA</MenuItem>
                <MenuItem value="NUMBER">NUMBER</MenuItem>
                <MenuItem value="EMAIL">EMAIL</MenuItem>
                <MenuItem value="TODAY">TODAY</MenuItem>
              </Select>
            </FormControl>
            {TestDataDetails.GenerateDataType === "TODAY" ? (
              <>
                <TextField
                  key="date-offset"
                  sx={{ marginRight: "2rem" }}
                  label="Date Format"
                  variant="standard"
                  defaultValue={TestDataDetails.generateDateFormat}
                  onChange={(event) => {
                    event.preventDefault();
                    setTestDataDetails((prev) => {
                      return {
                        ...prev,
                        generateDateFormat: event.target.value,
                      };
                    });
                  }}
                />

                <TextField
                  key="date-format"
                  sx={{ marginRight: "2rem" }}
                  label="Date Offset (+/- days)"
                  variant="standard"
                  defaultValue={TestDataDetails.generateDateOffset}
                  onChange={(event) => {
                    event.preventDefault();
                    setTestDataDetails((prev) => {
                      return {
                        ...prev,
                        generateDateOffset: event.target.value,
                      };
                    });
                  }}
                />
              </>
            ) : (
              <TextField
                sx={{ marginRight: "2rem" }}
                label="Generate Data Length"
                variant="standard"
                defaultValue={TestDataDetails.generateDataLength}
                onChange={(event) => {
                  event.preventDefault();
                  setTestDataDetails((prev) => {
                    return {
                      ...prev,
                      generateDataLength: event.target.value,
                    };
                  });
                }}
              />
            )}
            ;
          </>
        )}
        {TestDataDetails.Source === "GoogleSheet" && (
          <>
            <TextField
              sx={{ marginLeft: "2rem", width: "25%" }}
              label="Sheet Id"
              variant="standard"
              defaultValue={TestDataDetails.filename}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    filename: event.target.value,
                  };
                });
              }}
            />
            <TextField
              sx={{ marginX: "2rem" }}
              label="Sheet"
              variant="standard"
              defaultValue={TestDataDetails.sheetId}
              onChange={(event) => {
                setTestDataDetails({
                  ...TestDataDetails,
                  sheetId: event.target.value,
                });
              }}
            />
            <TextField
              sx={{ marginX: "2rem", width: "4%" }}
              label="Cell"
              variant="standard"
              defaultValue={TestDataDetails.cell}
              onChange={(event) => {
                setTestDataDetails({
                  ...TestDataDetails,
                  cell: event.target.value,
                });
              }}
            />
            <div>
              <Typography>Scope</Typography>
              <Select
                variant="standard"
                key={row.idtest_step}
                labelId={`demo-simple-select-label-${row.idtest_step}`}
                id={`row.idtest_step-${row.idtest_step}`}
                value={TestDataDetails.scope}
                label="Scope"
                onChange={(event) => {
                  setTestDataDetails({
                    ...TestDataDetails,
                    scope: event.target.value,
                  });
                }}
              >
                <MenuItem value="Multi Run">Multi Run</MenuItem>
                <MenuItem value="Multi Execution">Multi Execution</MenuItem>
                <MenuItem value="Single Run">Single Run</MenuItem>
              </Select>
            </div>
            {TestDataDetails.scope === "Multi Run" ? (
              <TextField
                sx={{ marginX: "2rem", width: "4%" }}
                label="End Range"
                variant="standard"
                defaultValue={TestDataDetails.endRange}
                onChange={(event) => {
                  setTestDataDetails({
                    ...TestDataDetails,
                    endRange: event.target.value,
                  });
                }}
              />
            ) : (
              ""
            )}
          </>
        )}

        {TestDataDetails.Source === "FromApi" && (
          <>
            <FormControl>
              <InputLabel id={`DB Type-${row.idtest_step}`}>Product</InputLabel>
              <Select
                labelId={"product name"}
                id={"product name Select"}
                variant="standard"
                value={TestDataDetails.apiproduct}
                name={"product"}
                label="Product"
                onChange={(event) => {
                  setTestDataDetails({
                    ...TestDataDetails,
                    apiproduct: event.target.value,
                  });
                  getModules(event.target.value);
                }}
                style={{
                  minWidth: 100,
                  marginRight: "2rem",
                  alignItems: "center",
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {productList.map((item, index) => (
                  <MenuItem key={index} value={item.idproducts}>
                    {item.productname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {TestDataDetails.apiproduct !== "" && TestDataDetails.apiproduct !== 0 && (
              <FormControl>
                <InputLabel id={"module name"}> Module </InputLabel>
                <Select
                  labelId={"module name"}
                  id={"module name Select"}
                  variant="standard"
                  defaultValue={TestDataDetails.apimodule}
                  label="Module"
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      apimodule: event.target.value,
                    });
                    getAllApiDetails(event.target.value);
                  }}
                  style={{
                    minWidth: 100,
                    marginRight: "2rem",
                    alignItems: "center",
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {ModuleListOne.map((item, index) => (
                    <MenuItem key={index} value={item.idmodules}>
                      {item.modulename}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {TestDataDetails.apimodule !== "" && TestDataDetails.apimodule !== 0 && (
              <>
                <FormControl>
                  <InputLabel id={"Api name"}> Api Name </InputLabel>
                  <Select
                    labelId={"Api name"}
                    id={"Api Name Select"}
                    variant="standard"
                    defaultValue={TestDataDetails.apiName}
                    label="Api name"
                    onChange={(event) => {
                      const selectedApiName = event.target.value;
                      const selectedApi = apiList.find((item) => item.idapi === selectedApiName);
                      setTestDataDetails({
                        ...TestDataDetails,
                        apiName: selectedApiName,
                        apiId: selectedApi ? selectedApi.name : null,
                      });
                      clearInterval(window.myTimer);
                    }}
                    style={{
                      minWidth: 100,
                      marginRight: "2rem",
                      alignItems: "center",
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {apiList.map((item, index) => (
                      <MenuItem key={index} value={item.idapi}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
            {TestDataDetails.apimodule !== "" &&
              TestDataDetails.apimodule !== 0 &&
              TestDataDetails.apiName !== "" && (
                <>
                  <FormControl>
                    <TextField
                      sx={{ marginRight: "2rem" }}
                      label="Api Param"
                      variant="standard"
                      defaultValue={TestDataDetails.apiParam}
                      onChange={(event) => {
                        event.preventDefault();
                        setTestDataDetails((prev) => {
                          return {
                            ...prev,
                            apiParam: event.target.value,
                          };
                        });
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <TextField
                      sx={{ marginRight: "2rem" }}
                      label="Api Query"
                      variant="standard"
                      defaultValue={TestDataDetails.apiQuery}
                      onChange={(event) => {
                        event.preventDefault();
                        setTestDataDetails((prev) => {
                          return {
                            ...prev,
                            apiQuery: event.target.value,
                          };
                        });
                      }}
                    />
                  </FormControl>
                </>
              )}

            {TestDataDetails.apimodule !== "" && TestDataDetails.apimodule !== 0 && (
              <Button
                onClick={(event) => {
                  setTestDataDetails({
                    ...TestDataDetails,
                    apimodule: "",
                    apiName: "",
                    apiproduct: "",
                    apiId: "",
                    apiQuery: "",
                    apiParam: "",
                  });
                  setSearchStringTestDataSource("");
                }}
                sx={{ marginLeft: 3 }}
              >
                Clear
              </Button>
            )}
          </>
        )}

        {TestDataDetails.Source === "SameRow" && (
          <>
            <FormControl>
              <InputLabel id={`SameRow-${row.idtest_step}`}>Element Type</InputLabel>
              <Select
                labelId={`SameRow-${row.idtest_step}`}
                id={`row.idtest_step-${row.idtest_step}`}
                value={TestDataDetails.ElementType}
                label="Element Type"
                variant="standard"
                onChange={(event) => {
                  setTestDataDetails({
                    ...TestDataDetails,
                    ElementType: event.target.value,
                  });
                }}
                style={{
                  minWidth: 192,
                  marginRight: "2rem",
                  alignItems: "center",
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="IMG">Image</MenuItem>
              </Select>
            </FormControl>
            <TextField
              sx={{ marginRight: "2rem" }}
              label="Element Index"
              variant="standard"
              defaultValue={TestDataDetails.ElementIndex}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    ElementIndex: event.target.value,
                  };
                });
              }}
            />
          </>
        )}

        {TestDataDetails.Source === "UseVariableFromTheScenario" && (
          <FormControl>
            <InputLabel id={`Use Var From this Scenario-${row.idtest_step}`}>
              Use Var From this Scenario
            </InputLabel>
            <Select
              key={row.idtest_step}
              variant="standard"
              labelId={`Use Var From this Scenario-${row.idtest_step}`}
              id={`row.idtest_step-${row.idtest_step}`}
              value={TestDataDetails.useVar}
              label="Use Var From this Scenario"
              onChange={(event) => {
                setTestDataDetails({
                  ...TestDataDetails,
                  useVar: event.target.value,
                });
              }}
              style={{
                minWidth: 240,
                marginRight: "2rem",
                alignItems: "center",
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {ListData?.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {TestDataDetails.Source === "FromFile" && (
          <>
            {!TestDataDetails.file && (
              <TextField
                sx={{ marginX: "2rem", width: "15%" }}
                label="File Name"
                variant="standard"
                defaultValue={TestDataDetails.filename}
                disabled={true}
              />
            )}
            <TextField
              type="file"
              id="recording-file-upload"
              onChange={(event) => {
                handleFileChange(event);
              }}
              variant="standard"
              sx={{ width: "20%" }}
            />
            {fileTypeCheck === "xlsx" ||
            fileTypeCheck === "csv" ||
            fileNameTypeCheck === "xlsx" ||
            fileNameTypeCheck === "csv" ? (
              <>
                <TextField
                  sx={{ marginX: "2rem" }}
                  label="Sheet"
                  variant="standard"
                  defaultValue={TestDataDetails.sheetId}
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      sheetId: event.target.value,
                    });
                  }}
                />
                <TextField
                  sx={{ marginX: "2rem", width: "4%" }}
                  label="Cell"
                  variant="standard"
                  defaultValue={TestDataDetails.cell}
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      cell: event.target.value,
                    });
                  }}
                />
                <div>
                  <Typography>Scope</Typography>
                  <Select
                    variant="standard"
                    key={row.idtest_step}
                    labelId={`demo-simple-select-label-${row.idtest_step}`}
                    id={`row.idtest_step-${row.idtest_step}`}
                    value={TestDataDetails.scope}
                    label="Scope"
                    onChange={(event) => {
                      setTestDataDetails({
                        ...TestDataDetails,
                        scope: event.target.value,
                      });
                    }}
                  >
                    <MenuItem value="Multi Run">Multi Run</MenuItem>
                    <MenuItem value="Multi Execution">Multi Execution</MenuItem>
                    <MenuItem value="Single Run">Single Run</MenuItem>
                  </Select>
                </div>
                {TestDataDetails.scope === "Multi Run" ? (
                  <TextField
                    sx={{ marginX: "2rem", width: "4%" }}
                    label="End Range"
                    variant="standard"
                    defaultValue={TestDataDetails.endRange}
                    onChange={(event) => {
                      setTestDataDetails({
                        ...TestDataDetails,
                        endRange: event.target.value,
                      });
                    }}
                  />
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            {fileTypeCheck === "json" || fileNameTypeCheck === "json" ? (
              <>
                <TextField
                  sx={{ marginX: "2rem", width: "25%" }}
                  label="Query"
                  variant="standard"
                  defaultValue={TestDataDetails.filter}
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      filter: event.target.value,
                    });
                  }}
                />
                <div>
                  <Typography>Scope</Typography>
                  <Select
                    variant="standard"
                    key={row.idtest_step}
                    labelId={`demo-simple-select-label-${row.idtest_step}`}
                    id={`row.idtest_step-${row.idtest_step}`}
                    value={TestDataDetails.scope}
                    label="Scope"
                    onChange={(event) => {
                      setTestDataDetails({
                        ...TestDataDetails,
                        scope: event.target.value,
                      });
                    }}
                  >
                    <MenuItem value="Multi Run">Multi Run</MenuItem>
                    <MenuItem value="Multi Execution">Multi Execution</MenuItem>
                    <MenuItem value="Single Run">Single Run</MenuItem>
                  </Select>
                </div>
                {TestDataDetails.scope === "Multi Run" ? (
                  <TextField
                    sx={{ marginX: "2rem", width: "25%" }}
                    label="End Range"
                    variant="standard"
                    defaultValue={TestDataDetails.endRange}
                    onChange={(event) => {
                      setTestDataDetails({
                        ...TestDataDetails,
                        endRange: event.target.value,
                      });
                    }}
                  />
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
            <Button
              component="label"
              size="medium"
              onClick={() => {
                handleUploadClick(true);
                handleClose("Save");
              }}
              sx={{ marginLeft: 4 }}
            >
              Upload
            </Button>
          </>
        )}
        {TestDataDetails.Source === "FromDB" && (
          <>
            <TextField
              sx={{ marginRight: "2rem" }}
              label="DB Query"
              variant="standard"
              defaultValue={TestDataDetails.DBQuery}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    DBQuery: event.target.value,
                  };
                });
              }}
            />
          </>
        )}

        {TestDataDetails.Source === "FromEmail" && (
          <>
            <TextField
              sx={{ marginRight: "2rem" }}
              label="Email"
              variant="standard"
              defaultValue={TestDataDetails.customerEmail}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    customerEmail: event.target.value,
                  };
                });
              }}
            />
            <TextField
              sx={{ marginRight: "2rem" }}
              label="Password"
              type="password"
              variant="standard"
              defaultValue={TestDataDetails.customerPassword}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    customerPassword: event.target.value,
                  };
                });
              }}
            />
            <TextField
              sx={{ marginRight: "2rem" }}
              label="Selection Criterion"
              variant="standard"
              defaultValue={TestDataDetails.EmailSelectionCriteria}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    EmailSelectionCriteria: event.target.value,
                  };
                });
              }}
            />
            <TextField
              sx={{ marginRight: "2rem" }}
              label="Filter"
              variant="standard"
              defaultValue={TestDataDetails.EmailFilter}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    EmailFilter: event.target.value,
                  };
                });
              }}
            />
          </>
        )}

        {TestDataDetails.Source === "SendEmail" && (
          <>
            <TextField
              sx={{ marginRight: "2rem" }}
              label="Email"
              variant="standard"
              defaultValue={TestDataDetails.customerEmail}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    customerEmail: event.target.value,
                  };
                });
              }}
            />
            <TextField
              sx={{ marginRight: "2rem" }}
              label="Password"
              type="password"
              variant="standard"
              defaultValue={TestDataDetails.customerPassword}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    customerPassword: event.target.value,
                  };
                });
              }}
            />
            <TextField
              sx={{ marginRight: "2rem" }}
              label="To Address"
              variant="standard"
              defaultValue={TestDataDetails.toAddress}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    toAddress: event.target.value,
                  };
                });
              }}
            />
            <TextField
              sx={{ marginRight: "2rem" }}
              label="Subject"
              variant="standard"
              defaultValue={TestDataDetails.subject}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    subject: event.target.value,
                  };
                });
              }}
            />
            <TextField
              sx={{ marginRight: "2rem", width: "28rem" }}
              label="Content"
              inputProps={{ maxLength: 500 }}
              variant="standard"
              defaultValue={TestDataDetails.content}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    content: event.target.value,
                  };
                });
              }}
            />
          </>
        )}
        {TestDataDetails.Source === "DependentonOtherTestScenario" && (
          <>
            <FormControl>
              <InputLabel id={`DB Type-${row.idtest_step}`}>Product</InputLabel>
              <Select
                labelId={"product name"}
                id={"product name Select"}
                variant="standard"
                value={TestDataDetails.DependentProduct}
                name={"product"}
                label="Product"
                onChange={(event) => {
                  setTestDataDetails({
                    ...TestDataDetails,
                    DependentProduct: event.target.value,
                  });
                  getModules(event.target.value);
                }}
                style={{
                  minWidth: 100,
                  marginRight: "2rem",
                  alignItems: "center",
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {productList.map((item, index) => (
                  <MenuItem key={index} value={item.idproducts}>
                    {item.productname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {TestDataDetails.DependentProduct !== "" && TestDataDetails.DependentProduct !== 0 && (
              <FormControl>
                <InputLabel id={"module name"}> Module </InputLabel>
                <Select
                  labelId={"module name"}
                  id={"module name Select"}
                  variant="standard"
                  defaultValue={TestDataDetails.DependentModule}
                  label="Module"
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      DependentModule: event.target.value,
                    });
                    getTestCase(event.target.value);
                  }}
                  style={{
                    minWidth: 100,
                    marginRight: "2rem",
                    alignItems: "center",
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {ModuleListOne.map((item, index) => (
                    <MenuItem key={index} value={item.idmodules}>
                      {item.modulename}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {TestDataDetails.DependentModule !== "" && TestDataDetails.DependentModule !== 0 && (
              <FormControl>
                <InputLabel id={"Test Case name"}> Test Case </InputLabel>
                <Select
                  labelId={"Test Case name"}
                  id={"Test Case Name Select"}
                  variant="standard"
                  defaultValue={TestDataDetails.DependentTC}
                  label="Test Case"
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      DependentTC: event.target.value,
                    });
                    clearInterval(window.myTimer); ////to close auto refesh
                    //getSearchValue(event.target.value);
                    //getSearchValueForOtherScenario  (event.target.value);
                    getSelectFromListData(row.idtest_step, event.target.value);
                    // setTestDataDetails({
                    //   ...TestDataDetails,
                    //   DependantTC: event.target.value,
                    // });
                  }}
                  style={{
                    minWidth: 100,
                    marginRight: "2rem",
                    alignItems: "center",
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {TestCaseListOne.map((item, index) => (
                    <MenuItem key={index} value={item.idtest_case}>
                      {item.Test_Case}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {TestDataDetails.DependentTC !== "" && TestDataDetails.DependentTC !== 0 && (
              <FormControl>
                <InputLabel id={`Use Var From this Scenario-${row.idtest_step}`}>
                  Use Var From this Scenario
                </InputLabel>
                <Select
                  key={row.idtest_step}
                  variant="standard"
                  labelId={`Use Var From this Scenario-${row.idtest_step}`}
                  id={`row.idtest_step-${row.idtest_step}`}
                  defaultValue={TestDataDetails.useVar}
                  label="Use Var From this Scenario"
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      useVar: event.target.value,
                      testCaseId: row.Test_Case_Id,
                      id: row.idtest_step,
                    });
                  }}
                  style={{
                    minWidth: 240,
                    marginRight: "2rem",
                    alignItems: "center",
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {ListData.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {TestDataDetails.DependentProduct !== "" &&
              TestDataDetails.DependentProduct !== 0 &&
              TestDataDetails.DependentModule !== 0 &&
              TestDataDetails.DependentModule !== "" && (
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    marginLeft: "0rem",
                    display: "flex",
                    alignItems: "center",
                    width: 300,
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search Test Case here..."
                    inputProps={{ "aria-label": "Search Test Case here..." }}
                    value={SearchStringTestDataSource}
                    onChange={(e) => {
                      e.preventDefault();
                      setSearchStringTestDataSource(e.target.value);
                    }}
                  />
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                    onClick={() => {
                      searchTestCaseInModinTable(
                        TestDataDetails.DependentModule,
                        SearchStringTestDataSource,
                      );
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
              )}

            {TestDataDetails.DependentModule !== "" && TestDataDetails.DependentModule !== 0 && (
              <Button
                onClick={(event) => {
                  setTestDataDetails({
                    ...TestDataDetails,
                    DependentModule: "",
                    DependentTC: "",
                    useVar: "",
                    DependentProduct: "",
                  });
                  setSearchStringTestDataSource("");
                }}
                sx={{ marginLeft: 3 }}
              >
                Clear
              </Button>
            )}
          </>
        )}

        {TestDataDetails.Source !== "" &&
          TestDataDetails.Source !== "Script" &&
          TestDataDetails.Source !== "FromFile" && (
            <Button
              onClick={() => {
                ChangeTableRowData({
                  ...TestDataDetails,
                  DBType:
                    TestDataDetails.DBType === "Other"
                      ? TestDataDetails.DBOtherType
                      : TestDataDetails.DBType,
                  testCaseId: row.Test_Case_Id,
                  id: row.idtest_step,
                  idtest_case_One:
                    state.idtest_case_One === ""
                      ? TestDataDetails.DependentTC
                      : state.idtest_case_One,
                });
                handleClose("Save");
              }}
              disabled={
                TestDataDetails.Source === "SameRow" &&
                (TestDataDetails.ElementIndex === "" || TestDataDetails.ElementType === "")
                  ? true
                  : false
              }
            >
              Submit
            </Button>
          )}
      </Box>
    );
  };

  const fromURL = (row) => {
    return (
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-end",
          //
        }}
      >
        <FormControl>
          <InputLabel id={`TestData Source-${row.idtest_step}`}>Environment</InputLabel>
          <Select
            labelId={`TestData Source-${row.idtest_step}`}
            id={`row.idtest_step-${row.idtest_step}`}
            value={TestDataDetails.SelectEnvironmentName}
            label="Environment"
            name="SelectEnvironment"
            variant="standard"
            onChange={(event) => {
              setTestDataDetails({
                ...TestDataDetails,
                id: row.idtest_step,
                testCaseId: state.tcs.idtest_case,
                SelectEnvironmentName: event.target.value,
                SelectEnvironmentUrl: EnvironmentList.filter(
                  (item) => item.envurl === event.target.value,
                ).map((item) => item.envurl)[0],
              });
            }}
            style={{ minWidth: 168, marginRight: "2rem", alignItems: "center" }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {EnvironmentList.map((item, index) => (
              <MenuItem key={index} value={item.envurl}>
                {item.envname}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography style={{ minWidth: 168, marginRight: "2rem", alignItems: "center" }}>
          {TestDataDetails.SelectEnvironmentUrl}
        </Typography>
        {TestDataDetails.SelectEnvironmentName !== "" && (
          <>
            <FormControl>
              <InputLabel id={`TestData Environment`}>Browser</InputLabel>
              <Select
                labelId={`TestData Browser`}
                id={`TestData Browser select`}
                value={TestDataDetails.SelectBrowserName}
                label="Browser"
                variant="standard"
                name="SelectBrowser"
                IconComponent={(props) => <ArrowDropDownSharpIcon {...props} />}
                onChange={(event) => {
                  setTestDataDetails({
                    ...TestDataDetails,
                    testCaseId: state.tcs.idtest_case,
                    SelectBrowserName: event.target.value,
                  });
                }}
                style={{
                  minWidth: 168,
                  marginRight: "2rem",
                  alignItems: "center",
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {state.browserDetails.map((item, index) => (
                  <MenuItem key={index} value={item.browsername}>
                    {item.browsername}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id={`TestData Environment`}>Resolution</InputLabel>
              <Select
                labelId={`TestData Resolution`}
                id={`TestData Resolution select`}
                value={TestDataDetails.Height}
                label="Resolution"
                name="SelectResolution"
                variant="standard"
                IconComponent={(props) => <ArrowDropDownSharpIcon {...props} />}
                onChange={(event) => {
                  if (event.target.value !== "") {
                    setTestDataDetails({
                      ...TestDataDetails,
                      Height: event.target.value,
                      Width: state.resolutionDetails.filter(
                        (item) => item.Height === event.target.value,
                      )[0].Width,
                    });
                  } else {
                    setTestDataDetails({
                      ...TestDataDetails,
                      Height: 0,
                      Width: 0,
                    });
                  }
                }}
                style={{
                  minWidth: 168,
                  marginRight: "2rem",
                  alignItems: "center",
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {state.resolutionDetails.map((item, index) => (
                  <MenuItem key={index} value={item.Height}>
                    {`${item.Height}x${item.Width}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Button
              onClick={() => {
                SendEnviromentOnSubmit({
                  idtest_case: row.Test_Case_Id,
                  EnvironmentUrl: TestDataDetails.SelectEnvironmentUrl,
                  EnvironmentBrowser: TestDataDetails.SelectBrowserName,
                  Width: TestDataDetails.Width,
                  Height: TestDataDetails.Height,
                  EnvironmentRunonce: TestDataDetails.SelectRunonce,
                });
                handleClose("Save");
              }}
            >
              Submit
            </Button>
          </>
        )}
      </Box>
    );
  };

  const fromValidate = (row) => {
    return (
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "flex-end",
          //
        }}
      >
        <FormControl>
          <InputLabel id={`TestData Source-${row.idtest_step}`}>Assertion Target</InputLabel>
          <Select
            key={row.idtest_step}
            labelId={`TestData Source-${row.idtest_step}`}
            id={`row.idtest_step-${row.idtest_step}`}
            defaultValue={TestDataDetails.Target}
            label="TestData Source"
            name="Test Data Source"
            variant="standard"
            onChange={(event) => {
              setTestDataDetails({
                ...TestDataDetails,
                id: row.idtest_step,
                testCaseId: state.tcs.idtest_case,
                Target: event.target.value,
              });

              if (event.target.value === "UseVariableFromTheScenario") {
                getSelectFromListData(test.idtest_step, test.Test_Case_Id);
              }
              if (event.target.value === "InRefrenceFile") {
                getSelectFildata(productId);
              }
              if (event.target.value === "InDownloadedFile") {
                getDownloadedFile(test.Test_Case_Id);
              }
            }}
            style={{ minWidth: 168, marginRight: "2rem", alignItems: "center" }}
          >
            {/* <MenuItem value="">
                  <em>None</em>
                </MenuItem> */}

            {validateSelectLIstData.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {TestDataDetails.Target === "InRefrenceFile" && (
          <>
            <FormControl>
              <InputLabel id={`InRefrenceFile-${row.idtest_step}`}>Select File</InputLabel>

              <Select
                key={row.idtest_step}
                variant="standard"
                labelId={`InRefrenceFile-${row.idtest_step}`}
                id={`row.idtest_step-${row.idtest_step}`}
                label="InRefrenceFile"
                value={TestDataDetails.FileName}
                onChange={(event) => {
                  handleFromType(event.target.value);
                  // setTestDataDetails({
                  //   ...TestDataDetails,
                  //   FileName: event.target.value,
                  //   // id: row.idtest_step,
                  // });
                  // setSelectedvaluefile(event.target.value)
                }}
                sx={{ width: 180 }}
              >
                {filedropdowndata?.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {fromTypeCheck === "xlsx" || fromTypeCheck === "csv" ? (
              <>
                <TextField
                  sx={{ marginX: "2rem" }}
                  label="Sheet"
                  variant="standard"
                  defaultValue={TestDataDetails.sheetId}
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      sheetId: event.target.value,
                    });
                  }}
                />
                <TextField
                  sx={{ marginX: "2rem", width: "4%" }}
                  label="Cell"
                  variant="standard"
                  defaultValue={TestDataDetails.cell}
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      cell: event.target.value,
                    });
                  }}
                />
                <div>
                  <Typography>Scope</Typography>
                  <Select
                    variant="standard"
                    key={row.idtest_step}
                    labelId={`demo-simple-select-label-${row.idtest_step}`}
                    id={`row.idtest_step-${row.idtest_step}`}
                    value={TestDataDetails.scope}
                    label="Scope"
                    onChange={(event) => {
                      setTestDataDetails({
                        ...TestDataDetails,
                        scope: event.target.value,
                      });
                    }}
                  >
                    <MenuItem value="Multi Run">Multi Run</MenuItem>
                    <MenuItem value="Multi Execution">Multi Execution</MenuItem>
                    <MenuItem value="Single Run">Single Run</MenuItem>
                  </Select>
                </div>
                {TestDataDetails.scope === "Multi Run" ? (
                  <TextField
                    sx={{ marginX: "2rem", width: "4%" }}
                    label="End Range"
                    variant="standard"
                    defaultValue={TestDataDetails.endRange}
                    onChange={(event) => {
                      setTestDataDetails({
                        ...TestDataDetails,
                        endRange: event.target.value,
                      });
                    }}
                  />
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
            {fromTypeCheck === "json" ? (
              <>
                <TextField
                  sx={{ marginX: "2rem", width: "25%" }}
                  label="Filter"
                  variant="standard"
                  defaultValue={TestDataDetails.filter}
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      filter: event.target.value,
                    });
                  }}
                />
                <div>
                  <Typography>Scope</Typography>
                  <Select
                    variant="standard"
                    key={row.idtest_step}
                    labelId={`demo-simple-select-label-${row.idtest_step}`}
                    id={`row.idtest_step-${row.idtest_step}`}
                    value={TestDataDetails.scope}
                    label="Scope"
                    onChange={(event) => {
                      setTestDataDetails({
                        ...TestDataDetails,
                        scope: event.target.value,
                      });
                    }}
                  >
                    <MenuItem value="Multi Run">Multi Run</MenuItem>
                    <MenuItem value="Multi Execution">Multi Execution</MenuItem>
                    <MenuItem value="Single Run">Single Run</MenuItem>
                  </Select>
                </div>
                {TestDataDetails.scope === "Multi Run" ? (
                  <TextField
                    sx={{ marginX: "2rem", width: "25%" }}
                    label="End Range"
                    variant="standard"
                    defaultValue={TestDataDetails.endRange}
                    onChange={(event) => {
                      setTestDataDetails({
                        ...TestDataDetails,
                        endRange: event.target.value,
                      });
                    }}
                  />
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </>
        )}

        {TestDataDetails.Target === "ChangeTestData" && (
          <TextField
            sx={{ marginRight: "2rem" }}
            label="New Data"
            variant="standard"
            defaultValue={TestDataDetails.UploadString}
            onChange={(event) => {
              event.preventDefault();
              setTestDataDetails((prev) => {
                return {
                  ...prev,
                  UploadString: event.target.value,
                };
              });
            }}
          />
        )}
        {TestDataDetails.Target === "InDownloadedFile" && (
          <>
            <FormControl>
              <InputLabel id={`Download Files-${row.idtest_step}`}>Downloaded Files</InputLabel>
              <Select
                key={row.idtest_step}
                variant="standard"
                labelId={`Download Files-${row.idtest_step}`}
                id={`row.idtest_step-${row.idtest_step}`}
                value={TestDataDetails.DownloadedFile}
                label="Download Files"
                onChange={(event) => {
                  setTestDataDetails({
                    ...TestDataDetails,
                    DownloadedFile: event.target.value,
                  });
                }}
                style={{
                  minWidth: 240,
                  marginRight: "2rem",
                  alignItems: "center",
                }}
              >
                {downloadedFileData.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              sx={{ marginX: "2rem" }}
              label="Assertion Value"
              variant="standard"
              value={TestDataDetails.AssertionValue}
              onChange={(event) => {
                setTestDataDetails({
                  ...TestDataDetails,
                  AssertionValue: event.target.value,
                });
              }}
            />
          </>
        )}
        {TestDataDetails.Target === "InFile" && (
          <>
            {!TestDataDetails.file && (
              <TextField
                sx={{ marginX: "2rem", width: "15%" }}
                label="File Name"
                variant="standard"
                defaultValue={TestDataDetails.filename}
                disabled={true}
              />
            )}
            <TextField
              type="file"
              id="recording-file-upload"
              onChange={(event) => {
                handleFileChange(event);
              }}
              variant="standard"
              sx={{ width: "20%", marginLeft: "2rem" }}
            />
            {/* <TextField
              sx={{ marginX: "2rem" }}
              label="File Field"
              variant="standard"
              defaultValue={TestDataDetails.filefield}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    filefield: event.target.value,
                  };
                });
              }}
            /> */}
            {fileTypeCheck === "xlsx" ||
            fileTypeCheck === "csv" ||
            fileNameTypeCheck === "xlsx" ||
            fileNameTypeCheck === "csv" ? (
              <>
                <TextField
                  sx={{ marginX: "2rem" }}
                  label="Sheet"
                  variant="standard"
                  defaultValue={TestDataDetails.sheetId}
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      sheetId: event.target.value,
                    });
                  }}
                />
                <TextField
                  sx={{ marginX: "2rem", width: "4%" }}
                  label="Cell"
                  variant="standard"
                  defaultValue={TestDataDetails.cell}
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      cell: event.target.value,
                    });
                  }}
                />
                <div>
                  <Typography>Scope</Typography>
                  <Select
                    variant="standard"
                    key={row.idtest_step}
                    labelId={`demo-simple-select-label-${row.idtest_step}`}
                    id={`row.idtest_step-${row.idtest_step}`}
                    value={TestDataDetails.scope}
                    label="Scope"
                    onChange={(event) => {
                      setTestDataDetails({
                        ...TestDataDetails,
                        scope: event.target.value,
                      });
                    }}
                  >
                    <MenuItem value="Multi Run">Multi Run</MenuItem>
                    <MenuItem value="Multi Execution">Multi Execution</MenuItem>
                    <MenuItem value="Single Run">Single Run</MenuItem>
                  </Select>
                </div>
                {TestDataDetails.scope === "Multi Run" ? (
                  <TextField
                    sx={{ marginX: "2rem", width: "4%" }}
                    label="End Range"
                    variant="standard"
                    defaultValue={TestDataDetails.endRange}
                    onChange={(event) => {
                      setTestDataDetails({
                        ...TestDataDetails,
                        endRange: event.target.value,
                      });
                    }}
                  />
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            {fileTypeCheck === "json" || fileNameTypeCheck === "json" ? (
              <>
                <TextField
                  sx={{ marginX: "2rem", width: "25%" }}
                  label="Filter"
                  variant="standard"
                  defaultValue={TestDataDetails.filter}
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      filter: event.target.value,
                    });
                  }}
                />
                <div>
                  <Typography>Scope</Typography>
                  <Select
                    variant="standard"
                    key={row.idtest_step}
                    labelId={`demo-simple-select-label-${row.idtest_step}`}
                    id={`row.idtest_step-${row.idtest_step}`}
                    value={TestDataDetails.scope}
                    label="Scope"
                    onChange={(event) => {
                      setTestDataDetails({
                        ...TestDataDetails,
                        scope: event.target.value,
                      });
                    }}
                  >
                    <MenuItem value="Multi Run">Multi Run</MenuItem>
                    <MenuItem value="Multi Execution">Multi Execution</MenuItem>
                    <MenuItem value="Single Run">Single Run</MenuItem>
                  </Select>
                </div>
                {TestDataDetails.scope === "Multi Run" ? (
                  <TextField
                    sx={{ marginX: "2rem", width: "25%" }}
                    label="End Range"
                    variant="standard"
                    defaultValue={TestDataDetails.endRange}
                    onChange={(event) => {
                      setTestDataDetails({
                        ...TestDataDetails,
                        endRange: event.target.value,
                      });
                    }}
                  />
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
            <Button
              component="label"
              size="medium"
              onClick={() => {
                validateDataFromFile();
                handleClose("Save");
              }}
              sx={{ marginLeft: 4 }}
            >
              Upload
            </Button>
          </>
        )}

        {TestDataDetails.Target === "InGoogleSheet" && (
          <>
            <TextField
              sx={{ marginRight: "2rem", width: "20%" }}
              label="Sheet Id"
              variant="standard"
              defaultValue={TestDataDetails.filename}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    filename: event.target.value,
                  };
                });
              }}
            />
            <TextField
              sx={{ marginX: "2rem" }}
              label="Sheet"
              variant="standard"
              defaultValue={TestDataDetails.sheetId}
              onChange={(event) => {
                setTestDataDetails({
                  ...TestDataDetails,
                  sheetId: event.target.value,
                });
              }}
            />
            <TextField
              sx={{ marginX: "2rem", width: "4%" }}
              label="Cell"
              variant="standard"
              defaultValue={TestDataDetails.cell}
              onChange={(event) => {
                setTestDataDetails({
                  ...TestDataDetails,
                  cell: event.target.value,
                });
              }}
            />
            <div>
              <Typography>Scope</Typography>
              <Select
                variant="standard"
                key={row.idtest_step}
                labelId={`demo-simple-select-label-${row.idtest_step}`}
                id={`row.idtest_step-${row.idtest_step}`}
                value={TestDataDetails.scope}
                label="Scope"
                onChange={(event) => {
                  setTestDataDetails({
                    ...TestDataDetails,
                    scope: event.target.value,
                  });
                }}
              >
                <MenuItem value="Multi Run">Multi Run</MenuItem>
                <MenuItem value="Multi Execution">Multi Execution</MenuItem>
                <MenuItem value="Single Run">Single Run</MenuItem>
              </Select>
            </div>
            {TestDataDetails.scope === "Multi Run" ? (
              <TextField
                sx={{ marginX: "2rem", width: "4%" }}
                label="End Range"
                variant="standard"
                defaultValue={TestDataDetails.endRange}
                onChange={(event) => {
                  setTestDataDetails({
                    ...TestDataDetails,
                    endRange: event.target.value,
                  });
                }}
              />
            ) : (
              ""
            )}
            {/* <TextField
                      sx={{ marginX: "2rem" }}
                      label="File Name"
                      variant="standard"
                      defaultValue={TestDataDetails.filefield}
                      onChange={(event) => {
                        event.preventDefault();
                        setTestDataDetails((prev) => {
                          return {
                            ...prev,
                            filefield: event.target.value,
                          };
                        });
                      }}
                    /> */}
          </>
        )}

        {TestDataDetails.Target === "InApi" && (
          <>
            <FormControl>
              <InputLabel id={`DB Type-${row.idtest_step}`}>Product</InputLabel>
              <Select
                labelId={"product name"}
                id={"product name Select"}
                variant="standard"
                value={TestDataDetails.apiproduct}
                name={"product"}
                label="Product"
                onChange={(event) => {
                  setTestDataDetails({
                    ...TestDataDetails,
                    apiproduct: event.target.value,
                  });
                  getModules(event.target.value);
                }}
                style={{
                  minWidth: 100,
                  marginRight: "2rem",
                  alignItems: "center",
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {productList.map((item, index) => (
                  <MenuItem key={index} value={item.idproducts}>
                    {item.productname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {TestDataDetails.apiproduct !== "" && TestDataDetails.apiproduct !== 0 && (
              <FormControl>
                <InputLabel id={"module name"}> Module </InputLabel>
                <Select
                  labelId={"module name"}
                  id={"module name Select"}
                  variant="standard"
                  defaultValue={TestDataDetails.apimodule}
                  label="Module"
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      apimodule: event.target.value,
                    });
                    getAllApiDetails(event.target.value);
                  }}
                  style={{
                    minWidth: 100,
                    marginRight: "2rem",
                    alignItems: "center",
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {ModuleListOne.map((item, index) => (
                    <MenuItem key={index} value={item.idmodules}>
                      {item.modulename}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {TestDataDetails.apimodule !== "" && TestDataDetails.apimodule !== 0 && (
              <>
                <FormControl>
                  <InputLabel id={"Api name"}> Api Name </InputLabel>
                  <Select
                    labelId={"Api name"}
                    id={"Api Name Select"}
                    variant="standard"
                    defaultValue={TestDataDetails.apiName}
                    label="Api name"
                    onChange={(event) => {
                      const selectedApiName = event.target.value;
                      const selectedApi = apiList.find((item) => item.idapi === selectedApiName);
                      setTestDataDetails({
                        ...TestDataDetails,
                        apiName: selectedApiName, //wanted the id to get the name
                        apiId: selectedApi ? selectedApi.name : null, //wanted the name to pass in the api
                      });

                      clearInterval(window.myTimer);
                    }}
                    style={{
                      minWidth: 100,
                      marginRight: "2rem",
                      alignItems: "center",
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {apiList.map((item, index) => (
                      <MenuItem key={index} value={item.idapi}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
            {TestDataDetails.apimodule !== "" &&
              TestDataDetails.apimodule !== 0 &&
              TestDataDetails.apiName !== "" && (
                <>
                  <FormControl>
                    <TextField
                      sx={{ marginRight: "2rem" }}
                      label="Api Param"
                      variant="standard"
                      defaultValue={TestDataDetails.apiParam}
                      onChange={(event) => {
                        event.preventDefault();
                        setTestDataDetails((prev) => {
                          return {
                            ...prev,
                            apiParam: event.target.value,
                          };
                        });
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <TextField
                      sx={{ marginRight: "2rem" }}
                      label="Api Query"
                      variant="standard"
                      defaultValue={TestDataDetails.apiQuery}
                      onChange={(event) => {
                        event.preventDefault();
                        setTestDataDetails((prev) => {
                          return {
                            ...prev,
                            apiQuery: event.target.value,
                          };
                        });
                      }}
                    />
                  </FormControl>
                </>
              )}

            {/* {(TestDataDetails.DependentProduct !== "" && TestDataDetails.DependentProduct !== 0) && (TestDataDetails.DependentModule !== 0 && TestDataDetails.DependentModule !== "") && (
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  marginLeft: "0rem",
                  display: "flex",
                  alignItems: "center",
                  width: 300,
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search Api Name here..."
                  inputProps={{ "aria-label": "Search Api Name here..." }}
                  value={SearchStringTestDataSource}
                  onChange={(e) => {
                    e.preventDefault();
                    setSearchStringTestDataSource(e.target.value);
                  }}
                />
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                  onClick={() => {
                    searchTestCaseInModinTable(TestDataDetails.DependentModule, SearchStringTestDataSource);
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            )} */}

            {TestDataDetails.apimodule !== "" && TestDataDetails.apimodule !== 0 && (
              <Button
                onClick={(event) => {
                  setTestDataDetails({
                    ...TestDataDetails,
                    apimodule: "",
                    apiName: "",
                    apiproduct: "",
                    apiId: "",
                    apiQuery: "",
                    apiParam: "",
                  });
                  // setSearchStringTestDataSource("");
                }}
                sx={{ marginLeft: 3 }}
              >
                Clear
              </Button>
            )}
          </>
        )}

        {TestDataDetails.Target === "DependentonOtherTestScenario" && (
          <>
            <FormControl>
              <InputLabel id={`DB Type-${row.idtest_step}`}>Product</InputLabel>
              <Select
                labelId={"product name"}
                id={"product name Select"}
                variant="standard"
                value={TestDataDetails.DependentProduct}
                name={"product"}
                label="Product"
                onChange={(event) => {
                  setTestDataDetails({
                    ...TestDataDetails,
                    DependentProduct: event.target.value,
                  });
                  getModules(event.target.value);
                }}
                style={{
                  minWidth: 100,
                  marginRight: "2rem",
                  alignItems: "center",
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {productList.map((item, index) => (
                  <MenuItem key={index} value={item.idproducts}>
                    {item.productname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {TestDataDetails.DependentProduct !== "" && TestDataDetails.DependentProduct !== 0 && (
              <FormControl>
                <InputLabel id={"module name"}> Module </InputLabel>
                <Select
                  labelId={"module name"}
                  id={"module name Select"}
                  variant="standard"
                  defaultValue={TestDataDetails.DependentModule}
                  label="Module"
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      DependentModule: event.target.value,
                    });
                    getTestCase(event.target.value);
                  }}
                  style={{
                    minWidth: 100,
                    marginRight: "2rem",
                    alignItems: "center",
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {ModuleListOne.map((item, index) => (
                    <MenuItem key={index} value={item.idmodules}>
                      {item.modulename}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {TestDataDetails.DependentModule !== "" && TestDataDetails.DependentModule !== 0 && (
              <FormControl>
                <InputLabel id={"Test Case name"}> Test Case </InputLabel>
                <Select
                  labelId={"Test Case name"}
                  id={"Test Case Name Select"}
                  variant="standard"
                  defaultValue={TestDataDetails.DependentTC}
                  label="Test Case"
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      DependentTC: event.target.value,
                    });
                    clearInterval(window.myTimer); ////to close auto refesh
                    //getSearchValue(event.target.value);
                    //getSearchValueForOtherScenario(event.target.value);
                    getSelectFromListData(test.idtest_step, event.target.value);
                    // setTestDataDetails({
                    //   ...TestDataDetails,
                    //   DependantTC: event.target.value,
                    // });
                  }}
                  style={{
                    minWidth: 100,
                    marginRight: "2rem",
                    alignItems: "center",
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {TestCaseListOne.map((item, index) => (
                    <MenuItem key={index} value={item.idtest_case}>
                      {item.Test_Case}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {TestDataDetails.DependentTC !== "" && TestDataDetails.DependentTC !== 0 && (
              <FormControl>
                <InputLabel id={`Use Var From this Scenario-${row.idtest_step}`}>
                  Use Var From this Scenario
                </InputLabel>
                <Select
                  key={row.idtest_step}
                  variant="standard"
                  labelId={`Use Var From this Scenario-${row.idtest_step}`}
                  id={`row.idtest_step-${row.idtest_step}`}
                  defaultValue={TestDataDetails.useVar}
                  label="Use Var From this Scenario"
                  onChange={(event) => {
                    setTestDataDetails({
                      ...TestDataDetails,
                      useVar: event.target.value,
                    });
                  }}
                  style={{
                    minWidth: 240,
                    marginRight: "2rem",
                    alignItems: "center",
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {ListData.map((item, index) => (
                    <MenuItem key={index} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {TestDataDetails.DependentProduct !== "" &&
              TestDataDetails.DependentProduct !== 0 &&
              TestDataDetails.DependentModule !== 0 &&
              TestDataDetails.DependentModule !== "" && (
                <Paper
                  component="form"
                  sx={{
                    p: "2px 4px",
                    marginLeft: "0rem",
                    display: "flex",
                    alignItems: "center",
                    width: 300,
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search Test Case here..."
                    inputProps={{ "aria-label": "Search Test Case here..." }}
                    value={SearchStringValidation}
                    onChange={(e) => {
                      e.preventDefault();
                      setSearchStringValidation(e.target.value);
                    }}
                  />
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="search"
                    onClick={() => {
                      searchTestCaseInModinTable(
                        TestDataDetails.DependentModule,
                        SearchStringValidation,
                      );
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
              )}

            {TestDataDetails.DependentModule !== "" && TestDataDetails.DependentModule !== 0 && (
              <Button
                onClick={(event) => {
                  setTestDataDetails({
                    ...TestDataDetails,
                    DependentModule: "",
                    DependentTC: "",
                    useVar: "",
                    DependentProduct: "",
                  });
                  setSearchStringValidation("");
                }}
                sx={{ marginLeft: 3 }}
              >
                Clear
              </Button>
            )}
          </>
        )}

        {TestDataDetails.Target === "FromEmail" && (
          <>
            <TextField
              sx={{ marginRight: "2rem" }}
              label="Email"
              variant="standard"
              defaultValue={TestDataDetails.customerEmail}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    customerEmail: event.target.value,
                  };
                });
              }}
            />
            <TextField
              sx={{ marginRight: "2rem" }}
              label="Password"
              type="password"
              variant="standard"
              defaultValue={TestDataDetails.customerPassword}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    customerPassword: event.target.value,
                  };
                });
              }}
            />
            <TextField
              sx={{ marginRight: "2rem" }}
              label="Selection Criterion"
              variant="standard"
              defaultValue={TestDataDetails.EmailSelectionCriteria}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    EmailSelectionCriteria: event.target.value,
                  };
                });
              }}
            />
            <TextField
              sx={{ marginRight: "2rem" }}
              label="Filter"
              variant="standard"
              defaultValue={TestDataDetails.EmailFilter}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    EmailFilter: event.target.value,
                  };
                });
              }}
            />
          </>
        )}

        {TestDataDetails.Target === "UseVariableFromTheScenario" && (
          <FormControl>
            <InputLabel id={`Use Var From this Scenario-${row.idtest_step}`}>
              Use Var From this Scenario
            </InputLabel>
            <Select
              key={row.idtest_step}
              variant="standard"
              labelId={`Use Var From this Scenario-${row.idtest_step}`}
              id={`row.idtest_step-${row.idtest_step}`}
              value={TestDataDetails.useVar}
              label="Use Var From this Scenario"
              onChange={(event) => {
                setTestDataDetails({
                  ...TestDataDetails,
                  useVar: event.target.value,
                });
              }}
              style={{
                minWidth: 240,
                marginRight: "2rem",
                alignItems: "center",
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>

              {ListData.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {TestDataDetails.Target === "Regex" && (
          <>
            <TextField
              sx={{ marginX: "2rem" }}
              label="Regex"
              variant="standard"
              defaultValue={TestDataDetails.regex}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    regex: event.target.value,
                  };
                });
              }}
            />
          </>
        )}
        {TestDataDetails.Target === "Date" && (
          <>
            <TextField
              sx={{ marginX: "2rem" }}
              label="Date"
              variant="standard"
              defaultValue={splitDateString(row.TestData).dateData}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    date: event.target.value,
                  };
                });
              }}
            />
            <TextField
              sx={{ marginX: "2rem" }}
              label="DateFormat"
              variant="standard"
              defaultValue={splitDateString(row.TestData).dateFormatData}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    dateFormat: event.target.value,
                  };
                });
              }}
            />
          </>
        )}
        {TestDataDetails.Target === "DB" && (
          <>
            {/* <FormControl>
                  <InputLabel id={`DB Type-${row.idtest_step}`}>DB Type</InputLabel>
                  <Select
                    key={row.idtest_step}
                    labelId={`DB Type-${row.idtest_step}`}
                    variant="standard"
                    id={`row.idtest_step-${row.idtest_step}`}
                    value={TestDataDetails.DBType}
                    label="list data Source"
                    onChange={(event) => {
                      setTestDataDetails({
                        ...TestDataDetails,
                        DBType: event.target.value,
                      });
                    }}
                    style={{
                      minWidth: 100,
                      marginRight: "2rem",
                      alignItems: "center",
                    }}
                  >
                    {DBTypeLIstData.map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {TestDataDetails.DBType === "Other" && (
                  <TextField
                    sx={{ marginRight: "2rem" }}
                    label="DataBase Type"
                    variant="standard"
                    defaultValue={TestDataDetails.DBOtherType}
                    onChange={(event) => {
                      event.preventDefault();
                      setTestDataDetails((prev) => {
                        return {
                          ...prev,
                          DBOtherType: event.target.value,
                        };
                      });
                    }}
                  />
                )}
                <TextField
                  sx={{ marginRight: "2rem" }}
                  label="DB URL"
                  variant="standard"
                  defaultValue={TestDataDetails.DBURL}
                  onChange={(event) => {
                    event.preventDefault();
                    setTestDataDetails((prev) => {
                      return {
                        ...prev,
                        DBURL: event.target.value,
                      };
                    });
                  }}
                />
                <TextField
                  sx={{ marginRight: "2rem" }}
                  label="DB username"
                  variant="standard"
                  defaultValue={TestDataDetails.DBusername}
                  onChange={(event) => {
                    event.preventDefault();
                    setTestDataDetails((prev) => {
                      return {
                        ...prev,
                        DBusername: event.target.value,
                      };
                    });
                  }}
                />
                <TextField
                  sx={{ marginRight: "2rem" }}
                  label="DB password"
                  variant="standard"
                  defaultValue={TestDataDetails.DBpwd}
                  onChange={(event) => {
                    event.preventDefault();
                    setTestDataDetails((prev) => {
                      return {
                        ...prev,
                        DBpwd: event.target.value,
                      };
                    });
                  }}
                /> */}
            <TextField
              sx={{ marginRight: "2rem" }}
              label="DB Query"
              variant="standard"
              defaultValue={TestDataDetails.DBQuery}
              onChange={(event) => {
                event.preventDefault();
                setTestDataDetails((prev) => {
                  return {
                    ...prev,
                    DBQuery: event.target.value,
                  };
                });
              }}
            />
          </>
        )}

        {TestDataDetails.Target !== "" && TestDataDetails.Target !== "InFile" && (
          <Button
            onClick={() => {
              if (TestDataDetails.Target === "DB") {
                validateDataFromDB({
                  testCaseId: test.Test_Case_Id,
                  teststepid: test.idtest_step,
                  valdtype: TestDataDetails.Target,
                  dbquery: TestDataDetails.DBQuery,
                });
                handleClose("Save");
              } else if (TestDataDetails.Target === "Regex") {
                validateDataByRegex({
                  teststepid: TestDataDetails.id,
                  testCaseId: test.Test_Case_Id,
                  valdtype: "Regex",
                  regex: TestDataDetails.regex,
                });
                handleClose("Save");
              } else if (TestDataDetails.Target === "InGoogleSheet") {
                validateDataByGoogleSheet({
                  teststepid: TestDataDetails.id,
                  testCaseId: test.Test_Case_Id,
                  valdtype: "InGoogleSheet",
                  fieldname: TestDataDetails.filename,
                  cell: TestDataDetails.cell,
                  sheetId: TestDataDetails.sheetId,
                  scope: TestDataDetails.scope,
                  endRange: TestDataDetails.endRange,
                });
                handleClose("Save");
              } else if (TestDataDetails.Target === "InApi") {
                validateDataByApi({
                  teststepid: TestDataDetails.id,
                  testCaseId: test.Test_Case_Id,
                  valdtype: "InApi",
                  fieldName: TestDataDetails.apiId,
                  apiid: TestDataDetails.apiName,
                  apiParam: TestDataDetails.apiParam,
                  apiQuery: TestDataDetails.apiQuery,
                });
                handleClose("Save");
              } else if (TestDataDetails.Target === "ChangeTestData") {
                validateDataChangeData({
                  teststepid: test.idtest_step,
                  testCaseId: test.Test_Case_Id,
                  valdtype: "ChangeTestData",
                  UploadString: TestDataDetails.UploadString,
                });
                handleClose("Save");
              } else if (TestDataDetails.Target === "Date") {
                validateDataByDate({
                  teststepid: TestDataDetails.id,
                  testCaseId: test.Test_Case_Id,
                  valdtype: "Date",
                  date: TestDataDetails.date,
                  dateFormat: TestDataDetails.dateFormat,
                });
                handleClose("Save");
              } else if (TestDataDetails.Target === "FromEmail") {
                validateDetailsFromEmail({
                  teststepid: test.idtest_step,
                  testCaseId: test.Test_Case_Id,
                  valdtype: "FromEmail",
                  customerEmail: TestDataDetails.customerEmail,
                  customerPassword: TestDataDetails.customerPassword,
                  EmailSelectionCriteria: TestDataDetails.EmailSelectionCriteria,
                  EmailFilter: TestDataDetails.EmailFilter,
                });
                handleClose("Save");
              } else if (TestDataDetails.Target === "OnScreen") {
                validateDataOnScreen({
                  teststepid: test.idtest_step,
                  testCaseId: test.Test_Case_Id,
                  valdtype: "OnScreen",
                });
                handleClose("Save");
              } else if (TestDataDetails.Target === "Search") {
                validateDataBySearch({
                  teststepid: TestDataDetails.id,
                  testCaseId: test.Test_Case_Id,
                  valdtype: "Search",
                  testdata: row.TestData,
                });
                handleClose("Save");
              } else if (TestDataDetails.Target === "DynamicText") {
                validateDataOnScreen({
                  teststepid: TestDataDetails.id,
                  testCaseId: test.Test_Case_Id,
                  valdtype: "DynamicText",
                });
                handleClose("Save");
              } else if (TestDataDetails.Target === "DependentonOtherTestScenario") {
                validateDataByDependence({
                  teststepid: test.idtest_step,
                  testCaseId: test.Test_Case_Id,
                  dependantTestCaseId: TestDataDetails.DependentTC,
                  usevar: TestDataDetails.useVar,
                  valdtype: "DependentonOtherTestScenario",
                });
                handleClose("Save");
              } else if (TestDataDetails.Target === "UseVariableFromTheScenario") {
                validateDataByDependence({
                  teststepid: test.idtest_step,
                  testCaseId: test.Test_Case_Id,
                  dependantTestCaseId: state.idtest_case_One,
                  usevar: TestDataDetails.useVar,
                  valdtype: "UseVariableFromTheScenario",
                });
                handleClose("Save");
              } else if (TestDataDetails.Target === "InRefrenceFile") {
                validatefiledatareference({
                  teststepid: test.idtest_step,
                  testCaseId: test.Test_Case_Id,
                  filefield: TestDataDetails.filefield,
                  FileName: TestDataDetails.FileName,
                  valdtype: "InRefrenceFile",
                  cell: TestDataDetails.cell,
                  sheetId: TestDataDetails.sheetId,
                  scope: TestDataDetails.scope,
                  endRange: TestDataDetails.endRange,
                  filter: TestDataDetails.filter,
                  fromFileType: TestDataDetails.fromFileType,
                });

                handleClose("Save");
              } else if (TestDataDetails.Target === "InDownloadedFile") {
                validationdownloadfiles({
                  teststepid: test.idtest_step,
                  testCaseId: test.Test_Case_Id,
                  DownloadedFile: TestDataDetails.DownloadedFile,
                  AssertionValue: TestDataDetails.AssertionValue,
                  valdtype: "InDownloadedFile",
                });
                handleClose("Save");
              }
            }}
          >
            Submit
          </Button>
        )}
      </Box>
    );
  };

  const splitDateString = (string) => {
    const dateValue = string.split("~");
    const dateData = dateValue[2];
    const dateFormatData = dateValue[3];
    return { dateData, dateFormatData };
  };
  return (
    <>
      {test ? (
        <StyledTableRow key={test.Action}>
          <StyledTableCell align="center" colSpan={11} style={{ height: "5rem" }}>
            {keywordValue === "URL"
              ? fromURL(test)
              : keywordValue === "Assertion"
                ? fromValidate(test)
                : Formfield(test)}
          </StyledTableCell>
        </StyledTableRow>
      ) : null}
    </>
  );
};

export default ModalExpand;
