import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  styled
} from '@mui/material';

const StyledCloseButton = styled(Button)`
    color: #FF5336;
    font-size: 17px;
    font-weight: 600;
    text-transform: none;
`

const StyledDialogTitle = styled(DialogTitle)`
    color: #1D4ED8;
    font-size: 24px;
    font-weight: 600;
    padding-top: 40px;
    padding-left: 55px;
    padding-right: 55px;
`

const StyledTableHeaderCell = styled(TableCell)`
    color: #232D3D;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid #E2E6F3;
`

const StyledTableBodyCell = styled(TableCell)`
    color: #2B3539;
    font-size: 16px;
    font-weight: 500;
    border: none;
`

const StyledDialog = styled(Dialog)`
border-radius: 14px;
`

const TestCaseDialog = ({ open, handleClose, columns, data, dialogHeader }) => {
  return (
    <StyledDialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'lg'}>
      <StyledDialogTitle>{dialogHeader}</StyledDialogTitle>
      <DialogContent>
        <TableContainer sx={{paddingX: '25px', paddingBottom:"25px"}}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <StyledTableHeaderCell key={index}>{column.headerName}</StyledTableHeaderCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {columns.map((column, colIndex) => (
                    <StyledTableBodyCell key={colIndex}>{row[column.field]}</StyledTableBodyCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <StyledCloseButton onClick={handleClose}>Close</StyledCloseButton>
      </DialogActions>
    </StyledDialog>
  );
};

export default TestCaseDialog;
