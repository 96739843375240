// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button#full-width-tab-0 {
    margin-top: 4px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    /* border-radius: 20px; */
    background:#032F40;
}
button#full-width-tab-1 {
    margin-top: 4px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    /* border-radius: 20px; */
    background:#032F40;
    margin-left: 5px;
}
.MuiTabs-root.css-1ujnqem-MuiTabs-root {
    background: #fff !important;
    background-color:#fff;
    width: 776px;
}
.css-hip9hq-MuiPaper-root-MuiAppBar-root{
    background-color:#fff;
    
}

`, "",{"version":3,"sources":["webpack://./src/pages/AdminPage/Admin.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,6BAA6B;IAC7B,4BAA4B;IAC5B,yBAAyB;IACzB,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,6BAA6B;IAC7B,4BAA4B;IAC5B,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,2BAA2B;IAC3B,qBAAqB;IACrB,YAAY;AAChB;AACA;IACI,qBAAqB;;AAEzB","sourcesContent":["button#full-width-tab-0 {\n    margin-top: 4px;\n    border-top-right-radius: 10px;\n    border-top-left-radius: 10px;\n    /* border-radius: 20px; */\n    background:#032F40;\n}\nbutton#full-width-tab-1 {\n    margin-top: 4px;\n    border-top-right-radius: 10px;\n    border-top-left-radius: 10px;\n    /* border-radius: 20px; */\n    background:#032F40;\n    margin-left: 5px;\n}\n.MuiTabs-root.css-1ujnqem-MuiTabs-root {\n    background: #fff !important;\n    background-color:#fff;\n    width: 776px;\n}\n.css-hip9hq-MuiPaper-root-MuiAppBar-root{\n    background-color:#fff;\n    \n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
