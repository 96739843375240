import React, { useState, useEffect } from "react";
import "./features/css/sidebar.css";
import FolderIcon from "@mui/icons-material/Folder";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { color } from "highcharts";
function SideBarFolder({
  index,
  testsuite,
  funcCollector,
  settingExecute,
  deletesuite,
  isActives,
  setActiveItem,
  collectAllSuites,
  product,
  suiteSelectedid,
  impactsuitid,
  listall
}) {
  const [isActive, setisActive] = useState(false);
  function active() {
    setisActive((preselect) => !preselect);
    funcCollector(testsuite, testsuite[0]["idtest_suite"], true);
  }
  function showsetting() {
    settingExecute(testsuite, true);
  }
  const activeStyle = {
    backgroundColor: 'blue',
    color: 'white',
  };
  useEffect(()=>{
    if(impactsuitid===testsuite[0].idtest_suite){
      funcCollector(testsuite, testsuite[0]["idtest_suite"], true);
      setActiveItem();
    }else if(impactsuitid===false){
      if(listall-1===index){
        funcCollector(testsuite, testsuite[0]["idtest_suite"], true);
        setActiveItem();
      }
    }
  },[impactsuitid])
  useEffect(() => {
    if (suiteSelectedid !== null) {
      if (suiteSelectedid === testsuite[0]["idtest_suite"]) {
        funcCollector(testsuite, testsuite[0]["idtest_suite"], true);
        setActiveItem();
      }
    }
  }, [suiteSelectedid])
  return (
    <div>
      <div
        // className={`Sidebarfolder `}
        className={`Sidebarfolder ${isActives ? 'Sidebarfolder--active' : ''}`}
        onClick={() => {
          setActiveItem();
        }}
      >
        {/* ${isActive && "Sidebarfolder--active"} */}
        <div className="middle" onClick={active}>
          <FolderIcon />
          <p>{testsuite[0]["Test_Suite"]}</p>
        </div>
        <div className="end"  >
          <IconButton onClick={() => deletesuite(testsuite)}>
            <DeleteIcon />
          </IconButton>
          <IconButton>
            <SettingsIcon onClick={showsetting}
              className="arrow"
            //  className={`arrow ${isActives ? 'active' : ''}`}
            />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

export default SideBarFolder;
