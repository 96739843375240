import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { Box } from "@mui/material";
import { CommonMethods } from './../../components/common/CommonMethods';
import { getDownloadData } from '../../API/TestDataApi';
import {
    ColorButton,
    colorExport,
    StyledTableRow,
    StyledTableCell,
} from "./../../components/common/common.Component";
import {
    TableBody,
} from "@mui/material";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
const Home = () => {
    const location = useLocation();
    const [TableData, setTableData] = useState([]);
    const navigate = useNavigate();


    const tableColums = [
        {
            name: "File Name",
            id: "FileName",
            isLongString: false,
            isNumeric: false,
            align: "center",
            colspan: 1,
            width: '30%',
        },
        {
            name: "Created Date",
            id: "CreatedDate",
            isLongString: false,
            isNumeric: false,
            align: "center",
            colspan: 2,
            width: '30%',
        },
        {
            name: "Download File",
            id: "DownloadFile",
            isLongString: false,
            isNumeric: false,
            align: "center",
            colspan: 1,
            width: '40%',
        },
    ]

    useEffect(() => {
        getListData();
    }, []);

    const getListData = async () => {
        await getDownloadData()
            .then((response) => {
                if (response.status === 200 && response.data.message === "fail") {
                    CommonMethods(navigate);
                } else {
                    setTableData(response.data)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    return (
        <Box
            sx={{
                paddingTop: "2rem",
                paddingX: "2rem",
            }}
        >
            <Table
                stickyHeader aria-label="a dense table" sx={{ minWidth: 650 }}
            >
                <TableHead >
                    <StyledTableRow >
                        {tableColums.map((item) => (
                            <StyledTableCell key={item.id} align={item.align} colSpan={item.colspan} width={item.width}>
                                {item.name}
                            </StyledTableCell>
                        ))}
                        <StyledTableCell />
                        <StyledTableCell colSpan={1} />
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {
                            TableData.length > 0 ?
                                TableData.map((item) => {
                                    return (
                                        <React.Fragment>
                                            <StyledTableRow
                                            >
                                                <StyledTableCell width='30%' align="center" colSpan={1}>
                                                    {item.FileName}
                                                </StyledTableCell>
                                                <StyledTableCell width='30%' align="center" colSpan={2}>
                                                    {item.LastModified}
                                                </StyledTableCell>
                                                <StyledTableCell width='40%' align="center" colSpan={1}>
                                                    <a href={item.link}>{"Download this file"}</a>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        </React.Fragment>
                                    )
                                }) : (
                                <StyledTableRow>
                                <StyledTableCell
                                        colSpan={tableColums.length + 2} 
                                        align="center"
                                        style={{
                                            fontWeight: 'bold', // Make text bold
                                            fontSize: '2rem', // You can adjust the size as needed
                                        }}
                                    >
                                    {"There is no data to show"}
                                </StyledTableCell>
                            </StyledTableRow>
                                )
                    }
                </TableBody>
            </Table>
        </Box>
    );
};

export default Home;