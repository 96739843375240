import React, { useState, useEffect } from "react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import "./Report.scss";
import {
    ColorButton,
    colorExport
} from "../../components/common/common.Component";
import {
    Box,
    Card,
    Typography,
    MenuItem,
    FormControl,
    Select,
    InputLabel
} from "@mui/material";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import {
    fetchProducts,
    fetchSuiteCase,
    fetchTestSuitesResult,
    fetchAllTestCaseCount
} from "./../../API/CreateCollectionAPI";
import { CommonMethods } from "./../../components/common/CommonMethods";
import { useNavigate } from "react-router-dom";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const Report = () => {

    const [state, setState] = useState({
        productname: "",
        idproducts: "",
        idtest_suite: ""
    });
    const [productList, setproductList] = useState([]);
    const [SuiteCaseList, setSuiteCaseList] = useState([]);
    const [TestCaseResults, setTestCaseResults] = useState([]);
    const [allTestCases, setAllTestCases] = useState([]);
    const [toDate, setToDate] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [convertedToDate, setConvertedToDate] = useState(null);
    const [convertedFormDate, setConvertedFormDate] = useState(null);
    const [showChart, setShowChart] = useState(false);
    const [options, setOptions] = useState(null); 
    const [optionsTestCaseCount, setOptionsTestCaseCount] = useState(null); 

    const navigate = useNavigate();

   const colors = colorExport();
   const userDetails = localStorage.getItem("userDetail");
   const temp = JSON.parse(userDetails);

    useEffect(() => {
        getProducts();
    }, []);

    const getProducts = async (api_input) => {
        await fetchProducts(api_input)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.message === 'fail') {
                        CommonMethods(navigate);
                    } else {
                        setproductList(response.data);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getSuiteCase = async (id) => {
        await fetchSuiteCase(id)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.message === 'fail') {
                        CommonMethods(navigate);
                    } else {
                        formateSuiteprops(response.data);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const formateSuiteprops = (data) => {
        setSuiteCaseList(
            data.map((item) => {
                return {
                    ...item[0],
                    list: item[0].list.map((subitem) => subitem.testcases[0]),
                };
            })
        );
    };

    const getTestSuiteResult = async (tsid, tsrid) => {
        await fetchTestSuitesResult(tsid, tsrid)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.message === 'fail') {
                        CommonMethods(navigate);
                    } else {
                        setTestCaseResults(response.data);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getAllTestCaseCount = async (convertedFormDate, convertedToDate) => {
        await fetchAllTestCaseCount(convertedFormDate, convertedToDate)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.message === 'fail') {
                        CommonMethods(navigate);
                    } else {
                        setAllTestCases(response.data);
                        console.log(response.data)
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const filterDataByDateRange = (data) => {
        if (!fromDate || !toDate) {
            return data;
        }

        const fromDateTimestamp = new Date(fromDate).getTime();
        const toDateTimestamp = new Date(toDate).getTime();

        return data.filter((point) => {
            const timestamp = new Date(point.Executed_Date).getTime();
            const graphData = timestamp >= fromDateTimestamp && timestamp <= toDateTimestamp;
            return graphData;
        });
    };

    useEffect(() => {
        if (showChart && TestCaseResults.length > 0 && fromDate && toDate) {
            const filteredData = filterDataByDateRange(TestCaseResults);
            const updatedOptions = getChartOptions(filteredData);
            setOptions(updatedOptions);
        }
    }, [showChart, TestCaseResults, fromDate, toDate]);


    const getChartOptions = (data) => {
        const numDataPoints = data.length;

        // Calculate dynamic padding values based on the number of data points
        // const pointPadding = Math.max(0.1, 0.5 / numDataPoints);
        const groupPadding = Math.max(0.3, 0.9 / numDataPoints);
        const options = {
            chart: {
                type: 'column',
            },
            title: {
                text: 'Test Suite Insights',
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                column: {
                    pointWidth: 5,
                    stacking: 'normal',
                    // pointPadding: pointPadding,
                    groupPadding: groupPadding,
                },
                // series: {
                //     pointPlacement: 'on',
                // }
            },
            xAxis: {
                type: 'datetime',
                pointRange: 24 * 3600 * 1000,
            },
            series: [
                {
                    name: "Successful",
                    data: data.map((point) => {
                        const date = new Date(point.Executed_Date);
                        const year = date.getUTCFullYear();
                        const month = date.getUTCMonth();
                        const day = date.getUTCDate();
                        const timestamp = Date.UTC(year, month, day);
                        return [timestamp, point.passCount];
                    }),
                    stack: 'Successful',
                    color: 'rgb(50,205,50)'
                },
                {
                    name: "Failed",
                    data: data.map((point) => {
                        const date = new Date(point.Executed_Date);
                        const year = date.getUTCFullYear();
                        const month = date.getUTCMonth();
                        const day = date.getUTCDate();
                        const timestamp = Date.UTC(year, month, day);
                        return [timestamp, point.failCount];
                    }),
                    stack: 'Failed',
                    color: 'rgba(255, 0, 0)'
                }
            ],
        };

        return options;
    };

    const convertDate = (inputDate) => {

        const dateString = inputDate.toString();
        const dateParts = dateString.split(' ');
        const day = dateParts[2];
        const month = getMonthNumber(dateParts[1]);
        const year = dateParts[3];
        const time = dateParts[4];

        const formattedDate = `${year}-${month}-${day} ${time}.0`;

        return formattedDate;
    };

    const getMonthNumber = (monthName) => {
        const months = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
        return String(months.indexOf(monthName) + 1).padStart(2, '0');
    };

    useEffect(() => {
        if (fromDate !== null && toDate !== null) {
            setConvertedFormDate(convertDate(fromDate.$d));
            setConvertedToDate(convertDate(toDate.$d));

        }
    }, [fromDate, toDate])

    const filterDateByDateRange = (data) => {
        if (!fromDate || !toDate) {
            return data;
        }

        const fromDateTimestamp = new Date(fromDate).getTime();
        const toDateTimestamp = new Date(toDate).getTime();

        return data.filter((point) => {
            const timestamp = new Date(point.Date).getTime();
            const graphData = timestamp >= fromDateTimestamp && timestamp <= toDateTimestamp;
            return graphData;
        });
    };

    useEffect(() => {
        if (showChart && allTestCases.length > 0 && fromDate && toDate) {
            const filteredData = filterDateByDateRange(allTestCases);
            const updatedOptions = getAllTestCaseCountOptions(filteredData);
            setOptionsTestCaseCount(updatedOptions);
        }
    }, [showChart, allTestCases, fromDate, toDate]);

    const getAllTestCaseCountOptions = (data) => {
        const numDataPoints = data.length;

        const groupPadding = Math.max(0.3, 0.98 / numDataPoints);
        const optionsTestCaseCount = {
            chart: {
                type: 'column',
            },
            title: {
                text: 'All Test Cases Insights',
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                column: {
                    pointWidth: 5,
                    stacking: 'normal',
                    // pointPadding: pointPadding,
                    groupPadding: groupPadding,
                },
            },
            xAxis: {
                type: 'datetime',
                pointRange: 24 * 3600 * 1000,
            },
            series: [
                {
                    name: "Successful",
                    data: data.map((point) => {
                        const date = new Date(point.Date);
                        const year = date.getUTCFullYear();
                        const month = date.getUTCMonth();
                        const day = date.getUTCDate();
                        const timestamp = Date.UTC(year, month, day);
                        return [timestamp, point.PassCount];
                    }),
                    stack: 'Successful',
                    color: 'rgb(50,205,50)'
                },
                {
                    name: "Failed",
                    data: data.map((point) => {
                        const date = new Date(point.Date);
                        const year = date.getUTCFullYear();
                        const month = date.getUTCMonth();
                        const day = date.getUTCDate();
                        const timestamp = Date.UTC(year, month, day);
                        return [timestamp, point.FailCount];
                    }),
                    stack: 'Failed',
                    color: 'rgba(255, 0, 0)'
                }
            ],
        };

        return optionsTestCaseCount;
    };

    const pieChartOptions = {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
        },
        credits: {
            enabled: false
        },
        title: {
          text: 'Most Run Test Cases',
          align: 'center',
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
        },
        accessibility: {
          point: {
            valueSuffix: '%',
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
            },
          },
        },
        series: [
          {
            name: 'Brands',
            colorByPoint: true,
            data: [
              {
                name: 'Real Test Case',
                y: 70.67,
                sliced: true,
                selected: true,
              },
              {
                name: 'DND',
                y: 14.77,
              },
              {
                name: 'New Dnd',
                y: 4.86,
              },
              {
                name: 'Avicss',
                y: 2.63,
              },
              {
                name: 'Big Basket',
                y: 1.53,
              },
              {
                name: 'DND 2',
                y: 1.40,
              },
              {
                name: 'Demo',
                y: 0.84,
              },
              {
                name: 'First Case',
                y: 0.51,
              },
              {
                name: 'Other',
                y: 2.6,
              },
            ],
          },
        ],
      };

      const lineOptions = {
        chart: {
          type: 'line',
        },
        title: {
          text: 'Monthly Average Run',
        },
        credits: {
            enabled: false
        },
        // subtitle: {
        //   text:
        //     'Source: <a href="https://en.wikipedia.org/wiki/List_of_cities_by_average_temperature" target="_blank">Wikipedia.com</a>',
        // },
        xAxis: {
          categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
        yAxis: {
          title: {
            text: 'Values',
          },
        },
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
            },
            enableMouseTracking: false,
          },
        },
        series: [
          {
            name: 'Real Test Case',
            data: [16.0, 18.2, 23.1, 27.9, 32.2, 36.4, 39.8, 38.4, 35.5, 29.2, 22.0, 17.8],
          },
          {
            name: 'Dnd',
            data: [0.9, 3.6, 0.6, 4.8, 10.2, 14.5, 17.6, 16.5, 12.0, 6.5, 2.0, 0.9],
          },
        ],
      };

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flex: 1,
                    alignItem: "center",
                    justifyContent: "space-evenly",
                    paddingTop: "2rem",
                    flexDirection: "column",
                    paddingX: "2rem",
                }}
            >
                <Card
                    sx={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                        paddingY: "0.5rem",
                        backgroundColor: colors.secondaryColor,
                        color: colors.primaryColor,
                    }}
                >
                    <Typography paddingBottom={0} variant="h6" component="h2">
                        Insights
                    </Typography>
                </Card>

                <Card
                    sx={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "left",
                        alignItems: "center",
                        paddingY: "1rem",
                        backgroundColor: colors.secondaryColor,
                        color: colors.primaryColor,
                    }}
                >
                    <FormControl sx={{ width: 240, color: colors.primaryColor, marginLeft: 2 }}>
                        <InputLabel
                            style={{
                                backgroundColor: colors.secondaryColor,
                                color: colors.primaryColor,
                                padding: 5,
                            }}
                            id={"product name"}
                        >
                            Product
                        </InputLabel>
                        <Select
                            labelId={"product name"}
                            id={"product name Select"}
                            value={state.idproducts}
                            name={"product"}
                            label="Product"
                            IconComponent={(props) => (
                                <ArrowDropDownSharpIcon
                                    style={{ color: colors.primaryColor, fontSize: 40 }}
                                    {...props}
                                />
                            )}
                            sx={{
                                color: colors.primaryColor,
                                border: `2px solid ${colors.primaryColor}`,
                                fontSize: 16,
                                "&:focus": {
                                    borderRadius: 4,
                                    border: `4px solid ${colors.primaryColor}`,
                                },
                            }}
                            onChange={(event) => {
                                setState({
                                    ...state,
                                    idproducts: event.target.value,
                                    idtest_suite: "",
                                });
                                clearInterval(window.ExecuteTimer);
                                getSuiteCase(event.target.value);
                            }}
                            style={{
                                minWidth: 100,
                                marginRight: "2rem",
                            }}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {productList.map((item, index) => (
                                <MenuItem key={index} value={item.idproducts}>
                                    {item.productname}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {state.idproducts !== "" && (
                        <FormControl sx={{ width: 240 }}>
                            <InputLabel
                                style={{
                                    backgroundColor: colors.secondaryColor,
                                    color: colors.primaryColor,
                                    padding: 5,
                                }}
                                id={"Test Suite"}
                            >
                                Test Suite
                            </InputLabel>
                            <Select
                                labelId={"Test Suite name"}
                                IconComponent={(props) => (
                                    <ArrowDropDownSharpIcon
                                        style={{ color: colors.primaryColor, fontSize: 40 }}
                                        {...props}
                                    />
                                )}
                                sx={{
                                    color: colors.primaryColor,
                                    border: `2px solid ${colors.primaryColor}`,
                                    fontSize: 16,
                                    "&:focus": {
                                        borderRadius: 4,
                                        border: `4px solid ${colors.primaryColor}`,
                                    },
                                }}
                                id={"Test Suite name Select"}
                                value={state.idtest_suite}
                                label="Test Suite"
                                onChange={(event) => {
                                    //getTestCasefromTestSuite(event.target.value);
                                    getTestSuiteResult(event.target.value, "");
                                    setState({
                                        ...state,
                                        idtest_suite: event.target.value,
                                        showResult: true,
                                    });
                                }}
                                style={{
                                    minWidth: 100,
                                }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {SuiteCaseList.map((item, index) => (
                                    <MenuItem key={index} value={item.idtest_suite}>
                                        {item.Test_Suite}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                    {state.idtest_suite !== "" && (
                        <FormControl sx={{ width: 340, color: colors.primaryColor, marginLeft: 2 }}>
                            <>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DateTimePicker']}>
                                        <DateTimePicker
                                        className="date-picker"
                                            label="From Date"
                                            value={fromDate}
                                            onChange={(date) => setFromDate(date)}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </>
                        </FormControl>
                    )}
                    {state.idtest_suite !== "" && (
                        <FormControl sx={{ width: 340, color: colors.primaryColor, marginLeft: 2 }}>
                            <>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DateTimePicker']}>
                                        <DateTimePicker
                                            className="date-picker"
                                            label="To Date"
                                            value={toDate}
                                            onChange={(date) => setToDate(date)}

                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </>
                        </FormControl>
                    )}

                    <ColorButton
                        onClick={() => {
                            setShowChart(true);
                            getAllTestCaseCount(convertedFormDate,convertedToDate)
                        }}
                        sx={{
                            marginLeft: 2
                        }}
                        variant="contained"
                        disabled={state.idproducts === "" || state.idtest_suite === "" || toDate === "" || fromDate === "" ? true : false}
                    >
                        Get Insight
                    </ColorButton>
                    {state.idproducts !== "" && (
                    <ColorButton
                        sx={{
                            marginLeft: 2
                        }}
                       
                        variant="contained"
                        onClick={() => {
                            setState({
                                ...state,
                                productname: "",
                                idproducts: "",
                                idtest_suite: ""
                            });
                            setFromDate(null);
                            setToDate(null);
                            setShowChart(false);
                        }}
                    >
                        Clear All
                    </ColorButton>
                    )}
                </Card>
            </Box>
            <div style={{ display: 'flex' }}>
                {showChart && options && optionsTestCaseCount && (
                    <>
                        <div style={{ flex: 1 }}>
                            <HighchartsReact highcharts={Highcharts} options={options} />
                        </div>
                        <div style={{ flex: 1 }}>
                            <HighchartsReact highcharts={Highcharts} options={optionsTestCaseCount} />
                        </div>
                    </>
                )}
            </div>
            <div style={{ display: 'flex' }}>
            {showChart && (
                    //pieChartOptions
                    <>
                        <div style={{ flex: 1 }}>
                    <HighchartsReact highcharts={Highcharts} options={pieChartOptions} />
                    </div>
                        <div style={{ flex: 1 }}>
                    <HighchartsReact highcharts={Highcharts} options={lineOptions} />
                    </div>
                    </>
                )}
            </div>
        </>
    );
};

export default Report;
