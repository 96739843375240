import React from "react";

const RightArrowIcon = () => (
  <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.91306 6.99974L0.963058 11.9497L2.37706 13.3637L8.74106 6.99974L2.37706 0.635742L0.963058 2.04974L5.91306 6.99974Z"
      fill="#03053D"
    />
  </svg>
);

export default RightArrowIcon;
