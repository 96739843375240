import React from "react";
import "./HomePage.style.scss";

import { useLocation } from "react-router-dom";

import { Box } from "@mui/material";

import TopCards from "../../components/TopCards/TopCards.components";
import TCTable from "../../components/TCTable/TCTable.component";

const HomePage = () => {
  const location = useLocation();
  return (
    <Box
      sx={{
        paddingTop: "2rem",
        paddingX: "2rem",
      }}
    >
      <TopCards />
      <TCTable isExecuteClick={location?.state?.isExecuteClick?true:false} />
    </Box>
  );
};

export default HomePage;
