import React from "react";

const PlusIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
    <path
      d="M6.37415 6V0H8.37415V6H14.3741V8H8.37415V14H6.37415V8H0.374146V6H6.37415Z"
      fill="currentColor"
    />
  </svg>
);

export default PlusIcon;
