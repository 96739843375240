import React from "react";

const DashboardIcon = (props) => (
    <svg width="20" height="21" viewBox="0 0 20 21" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
        <path d="M10 0.680664C15.523 0.680664 20 5.15766 20 10.6807C20 16.2037 15.523 20.6807 10 20.6807C4.477 20.6807 0 16.2037 0 10.6807C0 5.15766 4.477 0.680664 10 0.680664ZM10 2.68066C5.582 2.68066 2 6.26266 2 10.6807C2 15.0987 5.582 18.6807 10 18.6807C14.418 18.6807 18 15.0987 18 10.6807C18 6.26266 14.418 2.68066 10 2.68066ZM13.833 6.01766C14.07 5.85166 14.392 5.87966 14.596 6.08466C14.8 6.28866 14.826 6.61066 14.659 6.84466C12.479 9.89066 11.279 11.5227 11.061 11.7417C10.475 12.3267 9.525 12.3267 8.939 11.7417C8.354 11.1557 8.354 10.2057 8.939 9.61966C9.313 9.24666 10.944 8.04566 13.833 6.01766ZM15.5 9.68066C16.052 9.68066 16.5 10.1287 16.5 10.6807C16.5 11.2327 16.052 11.6807 15.5 11.6807C14.948 11.6807 14.5 11.2327 14.5 10.6807C14.5 10.1287 14.948 9.68066 15.5 9.68066ZM4.5 9.68066C5.052 9.68066 5.5 10.1287 5.5 10.6807C5.5 11.2327 5.052 11.6807 4.5 11.6807C3.948 11.6807 3.5 11.2327 3.5 10.6807C3.5 10.1287 3.948 9.68066 4.5 9.68066ZM6.818 6.08466C7.208 6.47466 7.208 7.10766 6.818 7.49866C6.428 7.88866 5.794 7.88866 5.404 7.49866C5.014 7.10866 5.014 6.47466 5.404 6.08466C5.794 5.69466 6.427 5.69466 6.818 6.08466ZM10 4.18066C10.552 4.18066 11 4.62866 11 5.18066C11 5.73266 10.552 6.18066 10 6.18066C9.448 6.18066 9 5.73266 9 5.18066C9 4.62866 9.448 4.18066 10 4.18066Z" />
    </svg>

);

export default DashboardIcon;
