import { Box, Button, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { optimizationTableData, TestcaseColumn, TestcaseColumnData, TestStepColumn, TestStepColumnData } from "./Dummydata";
import ViewIcon from "../../components-new/icons/ViewIcon";
import { useState } from "react";
import TestCaseDialog from "./TestCaseDialog";

const SectionHeader = styled(Box)`
    display: flex;
    padding: 20px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    background-color: #fff;
    p {
        color: var(--body-text, #2B3539);
        font-size: 18px;
        font-weight: 600;
    }
`

const StyledTableCell = styled(TableCell)`
    color: #232D3D;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid #E2E6F3;
`

const StyledTableBodyCell = styled(TableCell)`
    padding: 8px 20px;
    border-bottom: 1px solid #eee;
    p {
        color: #2B3539;
        font-size: 16px;
        font-weight: 500;
        padding-right: 15px;
        min-width: 60px;
    }
`

const StyledButton = styled(Button)`
    display: flex;
    padding: 8px 25px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid #2550D1;
    color: #2550D1;

    ${(props) => props.btnType === 'error' && `
        border: 1px solid #FF5336;
        color: #FF5336;
    `}
`;



const OptimizationSection = () => {
    const [open, setOpen] = useState(false);

    const [dialogTableHeader, setDialogTableHeader] = useState([])
    const [dialogTableData, setDialogTableData] = useState([])
    const [dialogHeader, setDialogHeader] = useState('')

    const handleClose = () => {
        setOpen(false)
    }

    const handleShowDialog = (header, data, title) => {
        setDialogTableHeader(header)
        setDialogTableData(data)
        setDialogHeader(title)
        setOpen(true)
    }

    return (
        <Box py={3}>
            <SectionHeader><Typography>Most Test Steps duplicated</Typography></SectionHeader>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Test Steps duplicated</StyledTableCell>
                            <StyledTableCell>Test Cases duplicated in</StyledTableCell>
                            <StyledTableCell>Match percentage</StyledTableCell>
                            <StyledTableCell>Can be removed ?</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {optimizationTableData.map(item =>
                            <TableRow>
                                <StyledTableBodyCell>
                                    <Box display={'flex'} alignItems={'center'}>
                                        <Typography>{item.testSteps}</Typography>
                                        <StyledButton onClick={() => handleShowDialog(TestcaseColumn, TestcaseColumnData, 'Test Cases for which Test steps are duplicated')} endIcon={<ViewIcon />} variant="outlined">View</StyledButton>
                                    </Box>
                                </StyledTableBodyCell>
                                <StyledTableBodyCell>
                                    <Box display={'flex'} alignItems={'center'}>
                                        <Typography>{item.testSteps}</Typography>
                                        <StyledButton onClick={() => handleShowDialog(TestStepColumn, TestStepColumnData, 'Test Steps duplicated ')} variant="outlined" endIcon={<ViewIcon />}>View</StyledButton>
                                    </Box>
                                </StyledTableBodyCell>
                                <StyledTableBodyCell>
                                    <Typography>{item.matchPercentage}</Typography>
                                </StyledTableBodyCell>
                                <StyledTableBodyCell>
                                    <StyledButton btnType={'error'} variant="outlined">Remove from list</StyledButton>
                                </StyledTableBodyCell>
                            </TableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>
            <TestCaseDialog open={open} handleClose={handleClose} columns={dialogTableHeader} data={dialogTableData} dialogHeader={dialogHeader}/>
        </Box>
    )
}
export default OptimizationSection;