// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 100px;
  height: 100px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Blue */
  border-radius: 50%;
  animation: spinner 1s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
}`, "",{"version":3,"sources":["webpack://./src/components/common/LoaderSpinner.scss"],"names":[],"mappings":"AAAA;EACI;IACE,uBAAA;EACJ;EACE;IACI,yBAAA;EACN;EACE;IACE,yBAAA;EACJ;AACF;AACE;EACE,YAAA;EACA,aAAA;EACA,0BAAA,EAAA,eAAA;EACA,8BAAA,EAAA,SAAA;EACA,kBAAA;EACA,qCAAA;AACJ;;AAEE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,cAAA;AACJ","sourcesContent":["@keyframes spinner {\n    0% {\n      transform: rotate(0deg);\n    }\n    50% {\n        transform: rotate(180deg);\n      }\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n  .loading-spinner {\n    width: 100px;\n    height: 100px;\n    border: 10px solid #f3f3f3; /* Light grey */\n    border-top: 10px solid #383636; /* Blue */\n    border-radius: 50%;\n    animation: spinner 1s linear infinite;\n  }\n  \n  .spinner-container {\n    display: grid;\n    justify-content: center;\n    align-items: center;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    z-index: 99999;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
