import React from "react";

const DownloadIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="14"
    viewBox="0 0 17 14"
    fill="currentColor"
  >
    <path
      d="M9.01723 5.44444H12.9061L8.23945 10.1111L3.57278 5.44444H7.46167V0H9.01723V5.44444ZM2.01723 12.4444H14.4617V7H16.0172V13.2222C16.0172 13.4285 15.9353 13.6263 15.7894 13.7722C15.6436 13.9181 15.4457 14 15.2394 14H1.23945C1.03317 14 0.835337 13.9181 0.689476 13.7722C0.543614 13.6263 0.46167 13.4285 0.46167 13.2222V7H2.01723V12.4444Z"
      fill="currentColor"
    />
  </svg>
);

export default DownloadIcon;
