import React from "react";
import "./LoaderSpinner.scss";

const LoaderSpinner =() =>{
  return (
    <div className="spinner-container">
      <div className="loading-spinner"></div>
    </div>
  );
}
export default LoaderSpinner;