import React from "react";
import {
  validateDetailsBySearch,
} from "../../API/ValidateAPI";
import { InputLabel, FormControl, Button, TextField } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { ColorButton, colorExport } from "./../common/common.Component";
import { CommonMethods } from "./../common/CommonMethods";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_PATH, API_BASE_RELATIVE_PATH } from "./../../Config/Config";
import { useNavigate } from "react-router-dom";
import "./RecordingTable.style.scss";
import {
  handleTDChange,
} from "./../../API/RecordingTableAPI";


const ElementLocate = ({ setTestDataDetails, TestDataDetails, setState, newTestStep, handleClose, editValue, valuesSavedResult, indexValueChange, setNewTestStep }) => {
// console.log("newTestStep.strategy",newTestStep.strategy)
  // const KeywordData = [
  //   { value: "X-Path", name: "X-Path" },
  //   { value: "Search", name: "Search on Page"},
  //   { value: "Test Data", name: "Test Data" },
  //   { value: "Previous", name: "On Same Row as Previous Element" } ,
  //   ];
 


  const colors = colorExport();
  const navigate = useNavigate();
  const [chooseData, setChooseData] = useState((newTestStep.strategy === "" || newTestStep.strategy === null || newTestStep.strategy === undefined) ? "X-Path" : newTestStep.strategy)

  useEffect(()=>{
    setChooseData((newTestStep.strategy === "" || newTestStep.strategy === null || newTestStep.strategy === undefined) ? "X-Path" : newTestStep.strategy);
  
  },[newTestStep.strategy])

  const postDataBySearch = async (api_input) => {
    let data = {
      body: api_input.file,
      headers: {},
    };

    const userDetails = localStorage.getItem("userDetail");
    const temp = JSON.parse(userDetails);
    const Response = await axios
      .post(
        `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestData&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${newTestStep.Test_Case_Id}&teststepid=${newTestStep.tsid}&strategy=${chooseData}&testdata=${"Search"}&actualdata=${newTestStep.TestData}`,
        data
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
    return Response;

  };

  const postDataForXPath = async (api_input) => {
    let data = {
      body: api_input.file,
      headers: {},
    };
    const userDetails = localStorage.getItem("userDetail");
    const temp = JSON.parse(userDetails);
    const Response = await axios
      .post(
        `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestData&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${newTestStep.Test_Case_Id}&teststepid=${newTestStep.tsid}&strategy=${chooseData}&testdata=${"AsRecorded"}&xpath=${encodeURIComponent(newTestStep.ObjectIdentifier)}`,
        data
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
    return Response;

  };

  const postDataForXY = async (api_input) => {
    let data = {
      body: api_input.file,
      headers: {},
    };
    const userDetails = localStorage.getItem("userDetail");
    const temp = JSON.parse(userDetails);
    const Response = await axios
      .post(
        `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestData&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${newTestStep.Test_Case_Id}&teststepid=${newTestStep.tsid}&strategy=${chooseData}&testdata=${"AsRecorded"}&xpos=${newTestStep.xpos}&ypos=${newTestStep.ypos}`,
        data
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
    return Response;

  };

  const onSameRowData = async (api_input) => {

    await handleTDChange(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === 'fail') {
            CommonMethods(navigate);
          } else {
            console.log();
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const validateDataBySearch = async (api_input) => {
        await validateDetailsBySearch(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === 'fail') {
            CommonMethods(navigate);
          } else {
            setState((prev) => {
              return {
                ...prev,
                xls: Object.values(response.data.data),
                // resolutionDetails:response.data.resolutionDetails,
              };
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDataChange = (api_input) => {

    if (chooseData === 'Search' && newTestStep?.Keyword !== 'Validation') {
      postDataBySearch(api_input);
    } else if (chooseData === 'Search' && newTestStep?.Keyword === 'Validation') {
      validateDataBySearch({
        teststepid: newTestStep.tsid,
        testCaseId: newTestStep.Test_Case_Id,
        valdtype: 'Search',
        testdata: newTestStep.TestData,
        strategy: chooseData
      });
    } else if(chooseData === 'X-Path'){
      postDataForXPath(api_input);
    } else if(chooseData === 'XY'){
      postDataForXY(api_input);  
    } else if(chooseData === 'Test Data'){
      onSameRowData({
        id: newTestStep.tsid,
        testCaseId: newTestStep.Test_Case_Id,
        Source: 'SameRow',
        ElementType: newTestStep.samerowtype,
        ElementIndex: newTestStep.samerowindex
        // ElementType: TestDataDetails.ElementType,
        // ElementIndex: TestDataDetails.ElementIndex
      })
    } else if(chooseData === 'Previous'){
      onSameRowData({
        id: newTestStep.tsid,
        testCaseId: newTestStep.Test_Case_Id,
        Source: 'SameRowElement',
        ElementType: TestDataDetails.ElementType,
        ElementIndex: TestDataDetails.ElementIndex,
        testdata: newTestStep.testdata
      })
    }
    handleClose("Save");
   };

  const handleChange = (event) => {
    setChooseData(event.target.value);

  }
// console.log("chooseData",chooseData)
  return (
    <div className="edit-all-secondrow">
      <div className="dropdown-search">
        <FormControl>
          <InputLabel>Strategy</InputLabel>
          <Select
            variant="standard"
            InputLabelProps={{ shrink: true }}
            value={chooseData}
            onChange={handleChange}
            style={{ minWidth: 168, marginRight: "2rem", alignItems: "center" }}
          >
            <MenuItem value="X-Path">X-Path</MenuItem>
            <MenuItem value="Search">Search on Page</MenuItem>
            <MenuItem value="XY">X-Y Coordinates</MenuItem>
            <MenuItem value="Test Data">On Same Row as Test Data</MenuItem>
            <MenuItem value="Previous">On Same Row as Previous Element</MenuItem>
          </Select>
        </FormControl>
      </div>
      {chooseData === 'X-Path' && (
        <div className="textfield-OI">
          <InputLabel>Object Identifier</InputLabel>
          <TextField
            // style={{backgroundColor:'red'}}
            variant="standard"
            fullWidth
            value={editValue === true ? newTestStep?.ObjectIdentifier : valuesSavedResult[indexValueChange]?.issues}
            InputLabelProps={{ shrink: true }}
            style={{ backgroundColor: colors?.tertiaryColor, marginTop: '5px' }}
            onChange={(event) => {
              event.preventDefault();
              setNewTestStep((prev) => {
                return {
                  ...prev,
                  ObjectIdentifier: event.target.value,
                };
              });
            }}
          // disabled={!editValue}
          />
        </div>
      )}
      {chooseData === 'XY' && (
        <>
        <div className="xpos-OI">
          <InputLabel>X Coordinate</InputLabel>
          <TextField
            // style={{backgroundColor:'red'}}
            variant="standard"
            fullWidth
            value={editValue === true ? newTestStep?.xpos : valuesSavedResult[indexValueChange]?.issues}
            InputLabelProps={{ shrink: true }}
            style={{ backgroundColor: colors?.tertiaryColor, marginTop: '5px' }}
            onChange={(event) => {
              event.preventDefault();
              setNewTestStep((prev) => {
                return {
                  ...prev,
                  xpos: event.target.value,
                };
              });
              
            }}

          // disabled={!editValue}
          />
        </div>
        <div className="ypos-OI" style={{ marginLeft: '20px' }}>
        <InputLabel>Y Coordinate</InputLabel>
        <TextField
          // style={{backgroundColor:'red'}}
          variant="standard"
          fullWidth
          value={editValue === true ? newTestStep?.ypos : valuesSavedResult[indexValueChange]?.issues}
          InputLabelProps={{ shrink: true }}
          style={{ backgroundColor: colors?.tertiaryColor, marginTop: '5px' }}
          onChange={(event) => {
            event.preventDefault();
            setNewTestStep((prev) => {
              return {
                ...prev,
                ypos: event.target.value,
              };
            });
          }}
        // disabled={!editValue}
        />
      </div>
      </>
      )}
      {chooseData === 'Test Data' && (

        <>
          <FormControl>
            <InputLabel>
              Element Type
            </InputLabel>
            <Select

              value={newTestStep.samerowtype}
              label="Element Type"
              variant="standard"
              onChange={(event) => {
                setNewTestStep({
                  ...newTestStep,
                  samerowtype: event.target.value,
                });
              }}
              style={{
                minWidth: 192,
                marginRight: "2rem",
                alignItems: "center",
              }}
            >
       
              <MenuItem value="IMG">Image</MenuItem>
              <MenuItem value="SVG">SVG</MenuItem>
              <MenuItem value="button">Button</MenuItem>
            </Select>
          </FormControl>
          <TextField
            sx={{ marginRight: "2rem" }}
            label="Element Index"
            variant="standard"
            value={newTestStep.samerowindex}
            onChange={(event) => {
              event.preventDefault();
              setNewTestStep((prev) => {
                return {
                  ...prev,
                  samerowindex: event.target.value,
                };
              });
            }}
          />
        </>

      )}

      <div style={{ marginTop: '24px' }}>
        <Button onClick={handleDataChange}>
          SUBMIT
        </Button>
      </div>
    </div>
  );
}

export default ElementLocate