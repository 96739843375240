// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  height: 80px;
  border-radius: 4px;
  margin: 0 32px;
  margin-top: 96px;
}

.header .adjust {
  padding: 24px 0;
  text-align: center;
}

.header h2 {
  color: #0fc6ee;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.0075em;
}

.header-table {
  margin: 0 32px;
}`, "",{"version":3,"sources":["webpack://./src/pages/ResultStepPage/ResultStepPage.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EAEA,kBAAA;EACA,cAAA;EACA,gBAAA;AAAJ;;AAEE;EACE,eAAA;EACA,kBAAA;AACJ;;AACE;EACE,cAAA;EACA,uDAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;EACA,wBAAA;AAEJ;;AACE;EACE,cAAA;AAEJ","sourcesContent":[".header {\n    height: 80px;\n    // background-color: hwb(197 1% 75%);\n    border-radius: 4px;\n    margin: 0 32px;\n    margin-top: 96px;\n  }\n  .header .adjust {\n    padding: 24px 0;\n    text-align: center;\n  }\n  .header h2 {\n    color: #0fc6ee;\n    font-family: \"Roboto\", \"Helvetica\", \"Arial\", sans-serif;\n    font-weight: 500;\n    font-size: 1.25rem;\n    line-height: 1.6;\n    letter-spacing: 0.0075em;\n  }\n\n  .header-table{\n    margin: 0 32px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
