import React from "react";
import { MenuItem, ListItemIcon, Typography } from "@mui/material";
import { styled } from "@mui/system";

const TSMenuItemStyled = styled(MenuItem)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
  "&.MuiButtonBase-root.MuiMenuItem-root": {
    display: "flex",
    padding: "12px 25px",
  },
  "& .MuiListItemIcon-root": {
    minWidth: "36px",
    color: "#242F3F",
    "& svg": {
      width: "20px",
      height: "20px",
    },
  },
  "&:last-child .MuiListItemIcon-root": {
    color: "#FF310E",
  },
  "& .MuiTypography-root": {
    fontSize: "14px",
    fontWeight: 500,
    color: "#2B3539",
  },
  "&:last-child .MuiTypography-root": {
    color: "#FF310E",
  },
}));

const TSMenuItem = ({ icon, text, onClick }) => (
  <TSMenuItemStyled onClick={onClick}>
    <ListItemIcon>{icon}</ListItemIcon>
    <Typography>{text}</Typography>
  </TSMenuItemStyled>
);

export default TSMenuItem;
