import React from 'react';
import ReactApexChart from 'react-apexcharts';
import _ from 'lodash'

const BarChart = ({
  series,
  categories,
  height = 350,
  showHorizontal = false,
  dataLabelFormatter,
  chartOptions,
  tooltipFormatter = (series, seriesIndex, dataPointIndex, w) => `${series[seriesIndex][dataPointIndex]}`
}) => {

  const currentFontFamily = getComputedStyle(document.body).fontFamily;

  const options = {
    chart: {
      height: height,
      type: 'bar',
      fontFamily: currentFontFamily,
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: showHorizontal ? 'top' : 'center'

        },
        columnWidth: '40%',
        barHeight: '40%',
        horizontal: showHorizontal,
      }
    },
    colors: ['#1D4ED8'],
    dataLabels: {
      enabled: true,
      formatter: dataLabelFormatter ? dataLabelFormatter : (val) => {
        return val + "";
      },
      offsetX: showHorizontal ? -10 : 0,
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
      },
      background: {
        enabled: true,
        foreColor: '#000',
        borderRadius: '2',
        padding: 6,
        opacity: 0.9,
        borderWidth: 1,
        borderColor: '#fff'
      },
    },
    xaxis: {
      categories: categories,
      position: 'bottom',
      axisBorder: {
        show: true
      },
      axisTicks: {
        show: false
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          }
        }
      }
    },
    yaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        formatter: function (val) {
          return val + "";
        }
      },
      title: {
        style: {
          fontSize: '14px',
          colors: '#2B3539',
          fontWeight: 400,
        },
        offsetX: 10,
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: false
        }
      }
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return tooltipFormatter(series, seriesIndex, dataPointIndex, w);
      }
    }
  };
  const modifiedChartOptions = _.merge(options, chartOptions)

  return (
    <div>
      <ReactApexChart options={modifiedChartOptions} series={series} type="bar" height={height} />
    </div>
  );
};

export default BarChart;
