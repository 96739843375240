import React, { useState } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { styled } from "@mui/system";
import {
  ElementIcon,
  ElementLocatorIcon,
  EqualizerIcon,
  ScriptIcon,
} from "../../components-new/icons";
import { TestDataConfig, ElementLocator, Script, Elements } from "./Edit";
import { useSelector } from "react-redux";

const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: "transparent",
  width: "auto",
  "& .MuiTabs-flexContainer": {
    columnGap: "2px",
  },
  "& .MuiTabs-indicator": {
    background: `#ffffff`,
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  background: "#ffffff",
  borderBottom: "1px solid #E2E6F3",
  "& p": {
    color: "#404040",
  },
  "&.Mui-selected": {
    border: 0,
    background: `#2550D1`,
    "& div": {
      color: "#ffffff",
    },
    "& svg, & p": {
      color: "#ffffff",
    },
  },
  "&:hover": {
    backgroundColor: "#2550D1",
    "& svg": {
      color: "#ffffff",
    },
    "& p": {
      color: "#ffffff",
    },
  },
}));

const CustomTab = styled(Box)(({ theme }) => ({
  display: "flex",
  columnGap: "7px",
  textTransform: "capitalize",
  width: "100%",
  alignItems: "center",
  "& svg": {
    width: "23px",
    color: "#8C9EB8",
  },
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </Box>
  );
}

const EditTabContainer = ({ item }) => {
  const [value, setValue] = useState(0);
  const editStep = useSelector((state) => state.authoringSlice.editStep);

  return (
    <Box
      display="flex"
      bgcolor="#ffffff"
      py={4}
      columnGap={"20px"}
      height={550}
      boxShadow={`0px 24px 23.3px 0px rgba(0, 0, 0, 0.03)`}
      borderRadius={`0px 0px 12px 12px`}
    >
      <Box>
        <StyledTabs
          orientation="vertical"
          value={value}
          onChange={(event, newValue) => setValue(newValue)}
        >
          <StyledTab
            label={
              <CustomTab>
                <EqualizerIcon />
                <Typography fontSize={"14px"} fontWeight={500}>
                  Test Data Configuration
                </Typography>
              </CustomTab>
            }
          />
          <StyledTab
            disabled={item.Step_Number === 0}
            label={
              <CustomTab>
                <ElementIcon />
                <Typography fontSize={"14px"} fontWeight={500}>
                  Element Details
                </Typography>
              </CustomTab>
            }
          />
          <StyledTab
            disabled={item.Step_Number === 0}
            label={
              <CustomTab>
                <ElementLocatorIcon />
                <Typography fontSize={"14px"} fontWeight={500}>
                  Element Locator
                </Typography>
              </CustomTab>
            }
          />
          <StyledTab
            disabled={item.Step_Number === 0}
            label={
              <CustomTab>
                <ScriptIcon />
                <Typography fontSize={"14px"} fontWeight={500}>
                  No Code Script
                </Typography>
              </CustomTab>
            }
          />
        </StyledTabs>
      </Box>

      <Box flex={2} pb={"30px"} overflow="scroll">
        <CustomTabPanel value={value} index={0}>
          <TestDataConfig item={editStep} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Elements item={editStep} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <ElementLocator item={editStep} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Script item={item} />
        </CustomTabPanel>
      </Box>
      {/* <Box flex={1}>3</Box> */}
    </Box>
  );
};

export default EditTabContainer;
