import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, styled, Switch, Typography } from "@mui/material";
import { CustomInput } from "../../components-new/common";
import { TestSuiteStatusOptions } from "../../components-new/layout/constants";
import { useSelector } from "react-redux";
import { BrowserOptions1, EnvOptions, ResolutionOptions } from "../../components-new/utils/constants";
import { useExecuteTestSuitesMutation } from "../../useQuery";
import { useNavigate } from "react-router-dom";

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: "300px",
        },
    },
};

const StyledSelect = styled(Select)`
    border-radius: 5px;
    border: 1px solid #d1dbff;
    font-size: 16px;
    color: #2b3539;
    width: 200px;
    max-height: 300px;
    background-color: #ffffff;
    .MuiSelect-select {
      padding: 13px 15px;
    }
`;

const TestSuiteExecuteDialog = ({ item, handleCloseDialog, openDialog, statusType }) => {
    const navigate = useNavigate();
    const [iterations, setIterations] = useState("");
    const [selectedSuite, setSelectedSuite] = useState("");
    const [selectedEnvironment, setSelectedEnvironment] = useState("");
    const [selectedBrowser, setSelectedBrowser] = useState("");
    const [selectedResolution, setSelectedResolution] = useState("");
    const [selectedStatus, setSelectedStatus] = useState("");
    const [rerunFailTest, setRerunFailTest] = useState(false);
    const [stopTestAfterFail, setStopTestAfterFail] = useState(false);
    const [runParallel, setRunParallel] = useState(false);
    const [isSynchronized, setIsSynchronized] = useState(false);
    const [headlessExecute, setHeadlessExecute] = useState(false);
    const [screenShotFail, setScreenShotFail] = useState(false);
    const [indEmail, setIndEmail] = useState(false);
    const [proxy, setProxy] = useState('')
    const [resultsEmail, setResultsEmail] = useState('')

    const suitesList = useSelector((state) => state.executionSlice.selectedTestSuiteData);
    const productId = useSelector((state) => state.executionSlice.selectedProduct);

  const {mutate: handleExecuteTestSuite, isSuccess: isExecuteTestSuiteSuccess} = useExecuteTestSuitesMutation()

  useEffect(()=> {
    if(isExecuteTestSuiteSuccess) {
        handleCloseDialog()
        navigate("/new/reports", { state: { isExecuteClick: true,selectedSuit: selectedSuite, product: productId, fetchTestSuite: true } });
    }
    }, [handleCloseDialog, isExecuteTestSuiteSuccess, navigate, productId, selectedSuite])


    useEffect(() => {
        if (suitesList.length !== 0) {
            const selectedTS = suitesList[0];
            setSelectedSuite(selectedTS["idtest_suite"]);
            setSelectedBrowser(selectedTS.browser);
            setSelectedEnvironment(selectedTS.environment);
            setSelectedResolution(selectedTS.resolution);
            setSelectedStatus(selectedTS.status);
            setRerunFailTest(selectedTS.rerunfailedtests);
            setStopTestAfterFail(selectedTS.stopafterfailure);
            setRunParallel(selectedTS.supports_parallel_execution);
            setIsSynchronized(selectedTS.synctoggle);
            setHeadlessExecute(selectedTS.headless);
            setScreenShotFail(selectedTS.ssonerror);
            setIndEmail(selectedTS.indEmail);
            setSelectedStatus(statusType || 'ALL')
            setResultsEmail(selectedTS.resultsemail);
        }
    }, []);

    const handleSwitchChange = (setter) => (event) => {
        setter(event.target.checked);
    };

    const handleExecuteTS = () => {
        const resolutionMap = {
            '1920x1080': { width: 1920, height: 1080 },
            '1440x780': { width: 1440, height: 780 },
            '1366X768': { width: 1366, height: 768 },
            '1280X780': { width: 1280, height: 780 },
        };
        const resolution = resolutionMap[selectedResolution] || {};
        const executionData = {
            prodid: productId,
            suitid: selectedSuite,
            envname: selectedEnvironment,
            envUrl: '', // Add logic to retrieve the environment URL if necessary
            browser: selectedBrowser,
            stop: stopTestAfterFail,
            rerun: rerunFailTest,
            runparallel: runParallel,
            ssfail: screenShotFail,
            indEmail: indEmail,
            inputemail: resultsEmail,
            height: resolution.height || '', 
            width: resolution.width || '',
            tsrId: item.idtest_suite_results, 
            iterations: iterations || '1', 
            proxy: proxy, 
            statustype: selectedStatus,
        };

        handleExecuteTestSuite(executionData)
        // Pass executionData to the API or further processing
    };
    

    const renderEnvironmentContent = () => (
        <Box display={'flex'}>
            <Box flex={1}>
                <Box>
                    <Typography color="#8089A8" fontSize="13px" fontWeight={500}>
                        Test Suite
                    </Typography>
                    <StyledSelect
                        MenuProps={MenuProps}
                        value={selectedSuite}
                        onChange={(e) => setSelectedSuite(e.target.value)}
                    >
                        {suitesList.map((suite, index) => (
                            <MenuItem key={index} value={suite["idtest_suite"]}>
                                {suite["Test_Suite"]}
                            </MenuItem>
                        ))}
                    </StyledSelect>
                </Box>
                <Box mt={2}>
                    <Typography color="#8089A8" fontSize="13px" fontWeight={500}>
                        Environment
                    </Typography>
                    <StyledSelect
                        MenuProps={MenuProps}
                        value={selectedEnvironment}
                        onChange={(e) => setSelectedEnvironment(e.target.value)}
                    >
                        {EnvOptions.map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </StyledSelect>
                </Box>
                <Box mt={2}>
                    <Typography color="#8089A8" fontSize="13px" fontWeight={500}>
                        Browser
                    </Typography>
                    <StyledSelect
                        MenuProps={MenuProps}
                        value={selectedBrowser}
                        onChange={(e) => setSelectedBrowser(e.target.value)}
                    >
                        {BrowserOptions1.map((browser, index) => (
                            <MenuItem key={index} value={browser.value}>
                                {browser.label}
                            </MenuItem>
                        ))}
                    </StyledSelect>
                </Box>
                <Box mt={2} width={'200px'}>
                    <CustomInput labelinput="Proxy" value={proxy} onChange={(event) => { setProxy(event.target.value) }} />
                </Box>
            </Box>
            <Box flex={1}>
                <Box>
                    <Typography color="#8089A8" fontSize="13px" fontWeight={500}>
                        Resolution
                    </Typography>
                    <StyledSelect
                        MenuProps={MenuProps}
                        value={selectedResolution}
                        onChange={(e) => setSelectedResolution(e.target.value)}
                    >
                        {ResolutionOptions.map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </StyledSelect>
                </Box>
                <Box mt={2}>
                    <Typography color="#8089A8" fontSize="13px" fontWeight={500}>
                        Status type
                    </Typography>
                    <StyledSelect
                        MenuProps={MenuProps}
                        value={selectedStatus}
                        onChange={(e) => setSelectedStatus(e.target.value)}
                    >
                        {TestSuiteStatusOptions.map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </StyledSelect>
                </Box>
                <Box mt={2} width={'200px'}>
                    <CustomInput
                        labelinput="Iterations"
                        value={iterations}
                        onChange={(e) => setIterations(e.target.value)}
                    />
                </Box>
                <Box mt={2} width={'200px'}>
                    <CustomInput value={resultsEmail} labelinput="Results Email" onChange={(e) => { setResultsEmail(e.target.value) }} />
                </Box>
            </Box>
            <Box flex={1}>
                <Box display={'flex'} alignItems={"center"}>
                    <Switch
                        checked={stopTestAfterFail}
                        onChange={handleSwitchChange(setStopTestAfterFail)}
                    />
                    <Typography color="#8089A8" fontSize="14px" fontWeight={500}>
                        Stop test after fail
                    </Typography>
                </Box>
                <Box display={'flex'} alignItems={"center"} mt={2}>
                    <Switch
                        checked={rerunFailTest}
                        onChange={handleSwitchChange(setRerunFailTest)}
                    />
                    <Typography color="#8089A8" fontSize="14px" fontWeight={500}>
                        Rerun fail test
                    </Typography>
                </Box>
                <Box display={'flex'} alignItems={"center"} mt={2}>
                    <Switch
                        checked={runParallel}
                        onChange={handleSwitchChange(setRunParallel)}
                    />
                    <Typography color="#8089A8" fontSize="14px" fontWeight={500}>
                        Run Tests in Parallel
                    </Typography>
                </Box>
                <Box display={'flex'} alignItems={"center"} mt={2}>
                    <Switch
                        checked={isSynchronized}
                        onChange={handleSwitchChange(setIsSynchronized)}
                    />
                    <Typography color="#8089A8" fontSize="14px" fontWeight={500}>
                        Synchronized Tests
                    </Typography>
                </Box>
                <Box display={'flex'} alignItems={"center"} mt={2}>
                    <Switch
                        checked={headlessExecute}
                        onChange={handleSwitchChange(setHeadlessExecute)}
                    />
                    <Typography color="#8089A8" fontSize="14px" fontWeight={500}>
                        Headless Execution
                    </Typography>
                </Box>
                <Box display={'flex'} alignItems={"center"} mt={2}>
                    <Switch
                        checked={screenShotFail}
                        onChange={handleSwitchChange(setScreenShotFail)}
                    />
                    <Typography color="#8089A8" fontSize="14px" fontWeight={500}>
                        Screenshots on Failure
                    </Typography>
                </Box>
                <Box display={'flex'} alignItems={"center"} mt={2}>
                    <Switch
                        checked={indEmail}
                        onChange={handleSwitchChange(setIndEmail)}
                    />
                    <Typography color="#8089A8" fontSize="14px" fontWeight={500}>
                        Send Individual Result Email
                    </Typography>
                </Box>
            </Box>
        </Box>
    );

    return (
        <Dialog
            fullWidth
            maxWidth={'md'}
            open={openDialog}
            onClose={handleCloseDialog}
        >
            <DialogTitle>Execution</DialogTitle>
            <DialogContent>
                {renderEnvironmentContent()}
            </DialogContent>
            <DialogActions sx={{ justifyContent: "flex-end", paddingRight: "16px", marginTop: '12px' }}>
                <Button variant="contained" onClick={handleExecuteTS} sx={{ marginRight: "8px" }}>Execute</Button>
                <Button variant="contained" onClick={handleCloseDialog}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default TestSuiteExecuteDialog;
