import React, { useEffect, useState } from "react";
import "./ResultStepPage.scss";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import {
    StyledTableRow,
    StyledTableCell,
    BootstrapTooltip
  } from "./../../components/common/common.Component";
import {
    Table,
    Box,
    Link,
    MenuItem,
    FormControl,
    Select,
    InputLabel,
    Typography,
  } from "@mui/material";
import { useNavigate, useParams  } from "react-router-dom";
import { colorExport } from "./../../components/common/common.Component";
import {CommonMethods} from "./../../components/common/CommonMethods";
import {
    fetchTestStepFailResult,
  } from "./../../API/CreateCollectionAPI";  
import TableBody from "@mui/material/TableBody";
import ResultScreenShotModal from "./../../components/TCTable/ResultScreenShotModal";

const ResultStepPage = () => {
  const [TestStepFailResults, setTestStepFailResults] = useState([]);
  const [indexValue, setIndexValue] = useState();
  const [valuesSavedResult, setValuesSavedResult] = useState([]);
  const [testStepName, settTestStepName] = useState();
  const [stepNumber, setStepNumber] = useState();
  const [openDialogResult, setOpenDialogResult] = useState(false);
  const navigate = useNavigate();
  let { tcid } = useParams();
    console.log("tcid",tcid)
    console.log("stepNumber",stepNumber)
useEffect(()=>{
if(tcid !== null){
    getTestStepFailResult(tcid);
}
},[tcid])

const colors = colorExport();

  const getTestStepFailResult = async (id) => {
    await fetchTestStepFailResult(id)
      .then((response) => {
        if (response.status === 200) {
          if(response.data.message === 'fail'){
            CommonMethods(navigate);
          }else{
          setTestStepFailResults(Object.values(response.data[0].data));
          settTestStepName(response.data[0].TestCase)
          setStepNumber(response.data[0].data[2].Step_Number);
        }
      }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClickOpenThree = (allRows,index) => {
    clearInterval(window.ExecuteTimer);
    console.log("allRows",allRows)
    setValuesSavedResult(allRows.map(rr=>{
      return {
      stepNumber: rr.Step_Number, 
      page_Description: rr.Page_Description,
      keyword: rr.Keyword,
      action: rr.Action,
      testData: rr.TestData,
      duration: rr.Duration,
      status: rr.test_step_status,
      screenShot: rr.Failure_Screenshot_Location,
      device: rr.ValDevice,
      issues: rr.issues,
      flow: rr.Flow,
      objectXpath: rr.Object_Xpath,
      page_Name: rr.Page_Name,
      executedDate: rr.Executed_Date
    }}))
   setIndexValue(index);
   setOpenDialogResult(true);
  };

  const handleCloseResult = () => {
    setValuesSavedResult([]);
    setIndexValue();
    setOpenDialogResult(false);
  };

  return(
    <>
    <div >
        <div className="header" style={{backgroundColor: colors.secondaryColor}}>
          <div className="adjust">
            <Typography paddingBottom={0} variant="h6" component="h2" style={{color: colors.primaryColor,}}>
              Test Step - {testStepName}, Step Number - {stepNumber}
            </Typography>
          </div>
        </div>
        
        {/* <TableContainer component={Paper} sx={{ height: 500,marginTop: "2rem", width: "96%", marginLeft: "2rem"}}> */}
        <div className="header-table"> 
          <Table aria-label="a dense table" sx={{  height: "max-content", minWidth: "96%" }} size="small">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Results Id</StyledTableCell>
                <StyledTableCell>Executed Date</StyledTableCell>
                <StyledTableCell align="center">Keyword</StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
                <StyledTableCell align="center">Flow</StyledTableCell>
                <StyledTableCell align="center">TestData</StyledTableCell>
                <StyledTableCell align="center">Duration</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
                <StyledTableCell align="center">Screenshot</StyledTableCell>
                <StyledTableCell align="center">Validate In</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {TestStepFailResults.map((subitem,index) => (
                <StyledTableRow
                  key={subitem.action}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <StyledTableCell component="th" scope="row">
                    {subitem.Test_Case_Results_Id}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {subitem.Executed_Date}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {subitem.Keyword}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {subitem.Action}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {subitem.Flow}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {subitem.TestData?.length <= 40 ? (
                      subitem.TestData
                    ) : (
                      <BootstrapTooltip
                        title={
                          <React.Fragment>
                            <Typography color="inherit">
                              {subitem.TestData}
                            </Typography>
                          </React.Fragment>
                        }
                        arrow
                      >
                        <div>{subitem.TestData?.slice(0, 40) + "..."}</div>
                      </BootstrapTooltip>
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {subitem.Duration}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {subitem.Status}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {subitem.Failure_Screenshot_Location && (
                      <Link onClick={(event) => {
                        
                        handleClickOpenThree(TestStepFailResults, index);
                      }}
                        underline="hover"
                      >
                        Screenshot
                      </Link>
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {subitem.ValDevice}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
</div>
        {/* </TableContainer> */}
      
    </div>
    {openDialogResult &&
        <ResultScreenShotModal
          handleCloseResult={handleCloseResult}
          valuesSavedResult={valuesSavedResult}
          indexValue={indexValue}
          testStepName={testStepName}
        />
      }
    </>
  ) 
};

export default ResultStepPage;
