import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

const StyledBox = styled(Box)`
  width: 40px;
  height: 40px;
  border-radius: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const IconContainerCircle = ({ children, background = "transparent" }) => {
  return (
    <StyledBox sx={{ backgroundColor: background }} border={"2px solid #D7DBE8"}>
      {children}
    </StyledBox>
  );
};

export default IconContainerCircle;
