import { Box, Button, styled, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  CustomSelect,
  IconContainer,
  IconContainerCircle,
  PageTitle,
} from "../components-new/common";
import { ContextIcon, DeleteIcon, PlusIcon, RefreshIcon } from "../components-new/icons";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import {
  useModuleQuery,
  useProductQuery,
  useTestCaseQuery,
  useTestSuiteQueryNew,
} from "../useQuery";
import TestSuiteItems from "./TestSuite/TestSuiteItems";
import TestSuiteCases from "./TestSuite/TestSuiteCases";

const StyledButton = styled(Button)`
  padding: 15px 28px;
  border-radius: 8px;
  text-transform: capitalize;
`;

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #e2e6f3;
  border-radius: 8px;
  cursor: pointer;
  padding: 9px 20px;
`;

const extractTestCases = (data, targetTestSuiteId = null) => {
  return data.flatMap((suite) =>
    suite.flatMap((suiteItem) =>
      suiteItem.list
        .filter((item) => targetTestSuiteId === null || item.TestSuiteId === targetTestSuiteId)
        .flatMap((item) => Object.values(item.testcases)),
    ),
  );
};

const TestSuite = () => {
  const itemsPerRow = 4;
  const itemsPerPage = 52;

  const [productId, setProductId] = useState(null);
  const [moduleId, setModuleId] = useState(null);
  // const [testCaseId, setTestCaseId] = useState(null);
  const [showTestCases, setShowTestCases] = useState(false);
  const [suiteId, setSuiteId] = useState(null);

  const { data: products } = useProductQuery();
  const { data, refetch } = useTestSuiteQueryNew(productId, !!productId);
  const { data: modules, refetch: refetchModules } = useModuleQuery(productId, {
    enabled: false,
  });
  const { data: testCases, refetch: refetchTestCases } = useTestCaseQuery(moduleId, "", {
    enabled: false,
  });

  useEffect(() => {
    if (!productId) return;

    refetch();
    refetchModules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  useEffect(() => {
    if (!moduleId) return;
    refetchTestCases();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleId]);

  const handleSuiteClick = (item) => {
    setSuiteId(item);
    setShowTestCases((pre) => !pre);
  };

  const handleClearSelection = () => {
    setProductId(null);
    setModuleId(null);
    setShowTestCases(false);
  };

  return (
    <>
      <Box sx={{ mt: 2, mb: 4 }} display="flex" alignItems="center" justifyContent="space-between">
        <PageTitle title="Test Suite" />
        <Box display="flex" alignItems="center">
          <Box onClick={() => null} mr={2}>
            <IconContainer>
              <RefreshIcon />
            </IconContainer>
          </Box>
          <StyledBox
            display="flex"
            alignItems="center"
            onClick={() => {
              setShowTestCases((prev) => !prev);
              setSuiteId(null);
            }}
          >
            <FolderOutlinedIcon />
            <Typography ml={1}>View All Test {!showTestCases ? "Cases" : "Suites"}</Typography>
          </StyledBox>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <Box display="flex" alignItems="center" columnGap={2} rowGap={2}>
          <CustomSelect
            label={"Product"}
            options={(Array.isArray(products) ? products : []).map((prodItem) => ({
              label: prodItem.productname,
              value: prodItem.idproducts,
            }))}
            width={"230px"}
            background={"#ffffff"}
            value={productId || ""}
            onChange={(e) => {
              setProductId(e.target.value);
              setModuleId(null);
            }}
          />
          {productId && (
            <CustomSelect
              label={"Module"}
              options={(Array.isArray(modules) ? modules : []).map((modItem) => ({
                label: modItem.modulename,
                value: modItem.idmodules,
              }))}
              width={"230px"}
              background={"#ffffff"}
              value={moduleId || ""}
              onChange={(e) => {
                setModuleId(e.target.value);
                // setTestCaseId(null);
              }}
            />
          )}
          <Button
            variant="outlined"
            startIcon={<DeleteIcon />}
            size="medium"
            onClick={handleClearSelection}
            sx={{
              color: "#828286",
              background: "#ffffff",
              height: "44px",
              border: "1px solid #E2E6F3",
              outline: 0,
              textTransform: "capitalize",
              mt: 2.5,
            }}
          >
            Clear filter
          </Button>
        </Box>
        <Box display="flex" alignItems="center">
          <Box onClick={() => null} mr={2}>
            <IconContainerCircle>
              <ContextIcon />
            </IconContainerCircle>
          </Box>
          <StyledButton
            variant="contained"
            color="warning"
            onClick={() => null}
            endIcon={<PlusIcon />}
            sx={{
              backgroundColor: "#ff5336",
              boxShadow: "0px 16px 30.6px 0px rgba(255, 83, 54, 0.27)",
            }}
          >
            Create Suite
          </StyledButton>
        </Box>
      </Box>
      {!showTestCases && productId && (
        <TestSuiteItems
          data={data}
          itemsPerPage={itemsPerPage}
          itemsPerRow={itemsPerRow}
          showTestCases={showTestCases}
          handleSuiteClick={handleSuiteClick}
        />
      )}

      {showTestCases && (
        <TestSuiteCases
          data={suiteId || !moduleId ? extractTestCases(data ?? [], suiteId) : testCases}
          itemsPerPage={itemsPerPage}
          itemsPerRow={itemsPerRow}
          showTestCases={showTestCases}
          testSuiteId={suiteId}
        />
      )}
    </>
  );
};

export default TestSuite;
