import React from "react";

const VisiblityIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
    <path
      d="M9.61689 0C14.4098 0 18.3973 3.44889 19.2338 8C18.3982 12.5511 14.4098 16 9.61689 16C4.824 16 0.836444 12.5511 0 8C0.835556 3.44889 4.824 0 9.61689 0ZM9.61689 14.2222C11.4298 14.2218 13.1888 13.6061 14.6061 12.4757C16.0234 11.3453 17.015 9.76735 17.4187 8C17.0135 6.23405 16.0213 4.65778 14.6041 3.52891C13.1869 2.40004 11.4287 1.78536 9.61689 1.78536C7.80506 1.78536 6.04684 2.40004 4.62967 3.52891C3.2125 4.65778 2.22024 6.23405 1.81511 8C2.21875 9.76735 3.21038 11.3453 4.62768 12.4757C6.04499 13.6061 7.80403 14.2218 9.61689 14.2222ZM9.61689 12C8.55602 12 7.53861 11.5786 6.78846 10.8284C6.03832 10.0783 5.61689 9.06087 5.61689 8C5.61689 6.93913 6.03832 5.92172 6.78846 5.17157C7.53861 4.42143 8.55602 4 9.61689 4C10.6778 4 11.6952 4.42143 12.4453 5.17157C13.1955 5.92172 13.6169 6.93913 13.6169 8C13.6169 9.06087 13.1955 10.0783 12.4453 10.8284C11.6952 11.5786 10.6778 12 9.61689 12ZM9.61689 10.2222C10.2063 10.2222 10.7715 9.9881 11.1882 9.57135C11.605 9.1546 11.8391 8.58937 11.8391 8C11.8391 7.41063 11.605 6.8454 11.1882 6.42865C10.7715 6.0119 10.2063 5.77778 9.61689 5.77778C9.02752 5.77778 8.46229 6.0119 8.04554 6.42865C7.62879 6.8454 7.39467 7.41063 7.39467 8C7.39467 8.58937 7.62879 9.1546 8.04554 9.57135C8.46229 9.9881 9.02752 10.2222 9.61689 10.2222Z"
      fill="currentColor"
    />
  </svg>
);

export default VisiblityIcon;
