import React, { useEffect, useState } from "react";
import "./CreateCollection.style.scss";

import { GridContextProvider, GridDropZone, GridItem } from "react-grid-dnd";
import { Button, TextField, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { SecondaryColor, PrimaryColor, ColorButton } from "./../common/common.Component";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import Divider from "@mui/material/Divider";

import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import SettingsIcon from "@mui/icons-material/Settings";

import Dialog from "@mui/material/Dialog";
import Alert from "@mui/material/Alert";

import { CommonMethods } from "./../common/CommonMethods";

import { Box, Card, Typography, FormControl, InputLabel, Select, MenuItem } from "@mui/material";

import {
  fetchProducts,
  fetchModules,
  fetchTestCasesfromModule,
  fetchSuiteCase,
  sendSuitesWithExcuiteInfo,
  sendsuitesInfo,
  deleteTestSuitefromProduct,
  editTestSuitefromProduct,
  fetchEnvironments,
  postEnviromentSettingtoTestSuite,
} from "./../../API/CreateCollectionAPI";
import { getResolution } from "./../../API/adminAPI";
import { useNavigate } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const CreateCollection = () => {
  const [state, setState] = useState({
    productname: "",
    modulename: "",
    idproducts: "",
    idmodules: "",
    newName: "",

    browserDetails: [
      { browsername: "Chrome" },
      { browsername: "Edge" },
      { browsername: "Firefox" },
      { browsername: "Safari" },
      { browsername: "ALL" },
    ],
    resolutionDetails: [],
    envDetails: [],
    SelectEnvironmentName: "",
    SelectEnvironmentUrl: "",
    SelectResolution: "",
    SelectBrowserName: "Chrome",
    RerunFailTest: false,
    StopTestAfterFailue: false,
    Height: 0,
    Width: 0,
  });
  const [stateTestSuite, setStateTestSuite] = useState({
    browserDetails: [
      { browsername: "Chrome" },
      { browsername: "Edge" },
      { browsername: "Firefox" },
      { browsername: "Safari" },
      { browsername: "ALL" },
    ],
    resolutionDetails: [],
    envDetails: [],
    SelectEnvironmentName: "",
    SelectEnvironmentUrl: "",
    SelectResolution: "",
    SelectBrowserName: "Chrome",
    RerunFailTest: false,
    StopTestAfterFailue: false,
    Height: 0,
    Width: 0,
  });

  const [productList, setproductList] = useState([]);
  const [licenseKey, setLicenseKey] = useState([]);
  const [CurrentSuite, setCurrentSuite] = useState({});
  const [ModuleList, setModuleList] = useState([]);
  const [TestCaseList, setTestCaseList] = useState([]);
  const [SuiteCaseList, setSuiteCaseList] = useState([]);
  const [openDialogTestSuiteEdit, setOpenDialogTestSuiteEdit] = useState(false);
  const [openDialogExecute, setOpenDialogExecute] = useState(false);
  const [openDialogTestSuite, setOpenDialogTestSuite] = useState(false);
  const [excuteSuite, setExcuteSuite] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogUrl, setOpenDialogUrl] = useState(false);
  const [testCaseUrl, setTestCaseUrl] = useState();
  const [newSuit, setNewSuit] = useState({
    idtest_suite: "",
    Test_Suite: "",
    list: [],
  });
  const [SearchString, setSearchString] = useState("");
  const navigate = useNavigate();

  const handleClickOpenExecute = () => {
    setOpenDialogExecute(true);
  };
  const handleCloseExecute = () => {
    setOpenDialogExecute(false);
  };
  const handleClickOpenTestSuiteEdit = () => {
    setOpenDialogTestSuiteEdit(true);
  };
  const handleCloseTestSuiteEdit = () => {
    setOpenDialogTestSuiteEdit(false);
  };

  const handleClickOpenTestSuite = () => {
    setOpenDialogTestSuite(true);
  };
  const handleCloseTestSuite = () => {
    setOpenDialogTestSuite(false);
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleClickOpenUrl = () => {
    setOpenDialogUrl(true);
  };
  const handleCloseUrl = () => {
    setOpenDialogUrl(false);
  };

  const onChange = (sourceId, sourceIndex, targetIndex, targetId) => {
    if (targetId) {
      let tempList = SuiteCaseList.filter((temp) => temp.idtest_suite === targetId)[0].list;
      if (!tempList.includes(TestCaseList[sourceIndex])) {
        tempList.push(TestCaseList[sourceIndex]);
        setSuiteCaseList(
          SuiteCaseList.map((item) => {
            if (item.idtest_suite === targetId) {
              return { ...item, list: tempList };
            } else return item;
          }),
        );
        sendSuites({
          SuiteCaseList: SuiteCaseList.map((item) => {
            if (item.idtest_suite === targetId) {
              return { ...item, list: tempList };
            } else return item;
          }),
          id: state.idproducts,
        });
      } else {
        console.log("cant add this test case in " + targetId + " list");
      }
    }
  };
  const deleteTestCasefromSuite = (suit_id, list, deleteItem) => {
    const newlist = list.filter((item) => {
      if (item.idtest_case !== deleteItem.idtest_case) return item;
      else return false;
    });
    setSuiteCaseList(
      SuiteCaseList.map((item) => {
        if (item.idtest_suite === suit_id) {
          return { ...item, list: newlist };
        } else return item;
      }),
    );
    sendSuites({
      SuiteCaseList: SuiteCaseList.map((item) => {
        if (item.idtest_suite === suit_id) {
          return { ...item, list: newlist };
        } else return item;
      }),
      id: state.idproducts,
    });
  };

  const sendSuites = async (data) => {
    await sendsuitesInfo(data)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            console.log("succefully submit");
            getSuiteCase(state.idproducts);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProducts = async (api_input) => {
    await fetchProducts(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setLicenseKey(response.data.map((ele) => ele.licensekey));
            setproductList(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getModules = async (id) => {
    await fetchModules(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setModuleList(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getSuiteCase = async (id) => {
    await fetchSuiteCase(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            formateSuiteprops(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getResolutionData = async (id) => {
    await getResolution(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setState((prev) => {
              return {
                ...prev,
                resolutionDetails: response.data.ResolutionList,
              };
            });
            console.log("heelo", response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const formateSuiteprops = (data) => {
    setSuiteCaseList(
      data.map((item) => {
        return {
          ...item[0],
          list: item[0].list.map((subitem) => subitem.testcases[0]),
        };
      }),
    );
  };
  const getTestCase = async (id) => {
    await fetchTestCasesfromModule(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setTestCaseList(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sendSuitesWithExecute = async (data) => {
    await sendSuitesWithExcuiteInfo(data)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          }
        } else if (data.ActionValue === "DLExecuteSuite") {
          setTestCaseUrl(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getEnvironments = async (id) => {
    await fetchEnvironments(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setState((prev) => {
              return {
                ...prev,
                envDetails: response.data,
              };
            });
            setStateTestSuite((prev) => {
              return {
                ...prev,
                envDetails: response.data,
              };
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const saveEnviromentSettingtoTestSuite = async (data) => {
    await postEnviromentSettingtoTestSuite(data)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          }
          console.log("succesful");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const editTestSuite = async (id, name) => {
    await editTestSuitefromProduct(id, name)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            getSuiteCase(state.idproducts);
            setState((prev) => {
              return {
                ...prev,
                newName: "",
              };
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const deleteTestSuite = async (id) => {
    await deleteTestSuitefromProduct(CurrentSuite.idtest_suite)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            getSuiteCase(state.idproducts);
          }
          // setState((prev) => {
          //   return {
          //     ...prev,
          //     envDetails: response.data,
          //   };
          // });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <>
      {openDialogUrl && (
        <Alert icon={false} onClose={handleCloseUrl} open={openDialogUrl} className="url-alert">
          <span className="url-span-url">{testCaseUrl}</span>
        </Alert>
      )}
      {openDialog && (
        <Alert icon={false} onClose={handleClose} open={openDialog} className="add-suites">
          <h2 className="suite-heading">Add Suites</h2>
          <div className="suite-textfield">
            {" "}
            <TextField
              sx={{ marginRight: "2rem" }}
              label="Suite Name"
              variant="standard"
              defaultValue={newSuit.Test_Suite}
              onChange={(event) => {
                event.preventDefault();
                setNewSuit((prev) => {
                  return {
                    ...prev,
                    Test_Suite: event.target.value,
                    idtest_suite: (SuiteCaseList.length + 1) * -1,
                  };
                });
              }}
            />
          </div>
          <div className="suite-button">
            <ColorButton
              autoFocus
              onClick={() => {
                setSuiteCaseList([...SuiteCaseList, newSuit]);
                setNewSuit({ idtest_suite: "", Test_Suite: "", list: [] });
                handleClose();
              }}
            >
              Add Suit
            </ColorButton>
            <ColorButton className="suite-cancel-button" autoFocus onClick={handleClose}>
              Cancel
            </ColorButton>
          </div>
        </Alert>
      )}
      <Box
        sx={{
          display: "flex",
          flex: 1,
          alignItem: "center",
          justifyContent: "space-evenly",
          paddingTop: "2rem",
          flexDirection: "column",
          paddingX: "2rem",
        }}
      >
        <Card
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            paddingY: "1.5rem",
            backgroundColor: SecondaryColor,
            color: PrimaryColor,
          }}
        >
          <Typography paddingBottom={0} variant="h6" component="h2">
            Create Test Suite
          </Typography>
        </Card>
        <Box
          sx={{
            paddingTop: "1rem",
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            style={{
              flex: 1,
              display: "flex",
            }}
          >
            <FormControl sx={{ width: 240 }}>
              <InputLabel id={"product name"}>Product</InputLabel>
              <Select
                labelId={"product name"}
                variant="standard"
                id={"product name Select"}
                value={state.idproducts}
                name={"product"}
                label="Product"
                onChange={(event) => {
                  setState({
                    ...state,
                    idproducts: event.target.value,
                  });

                  getModules(event.target.value);
                  getEnvironments(event.target.value);
                  getSuiteCase(event.target.value);
                  getResolutionData(event.target.value);
                }}
                style={{
                  minWidth: 100,
                  marginRight: "2rem",
                  alignItems: "center",
                }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {productList.map((item, index) => (
                  <MenuItem key={index} value={item.idproducts}>
                    {item.productname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {state.idproducts !== "" && (
              <FormControl sx={{ width: 240 }}>
                <InputLabel id={"module name"}>Module</InputLabel>
                <Select
                  labelId={"module name"}
                  variant="standard"
                  id={"module name Select"}
                  value={state.idmodules}
                  label="Module"
                  style={{
                    minWidth: 100,
                    marginRight: "2rem",
                    alignItems: "center",
                  }}
                  onChange={(event) => {
                    setState({
                      ...state,
                      idmodules: event.target.value,
                    });
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {ModuleList.map((item, index) => (
                    <MenuItem key={index} value={item.idmodules}>
                      {item.modulename}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {state.idmodules !== "" && (
              <ColorButton
                size="small"
                variant="contained"
                sx={{ marginRight: "2rem" }}
                onClick={() => {
                  getTestCase(state.idmodules);
                }}
              >
                Get Test Cases
              </ColorButton>
            )}
            {TestCaseList.length !== 0 && (
              <>
                <FormControl sx={{ width: 240 }}>
                  <InputLabel id={"product name"}>Suites</InputLabel>
                  <Select
                    labelId={"Suites name"}
                    variant="standard"
                    id={"Suites name Select"}
                    value={excuteSuite}
                    name={"Suites"}
                    label="Suites"
                    onChange={(event) => {
                      setExcuteSuite(event.target.value);
                    }}
                    style={{
                      minWidth: 100,
                      marginRight: "2rem",
                      alignItems: "center",
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {SuiteCaseList.map((item, index) => (
                      <MenuItem key={index} value={item.idtest_suite}>
                        {item.Test_Suite}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <ColorButton
                  size="small"
                  variant="contained"
                  onClick={() => {
                    sendSuitesWithExecute({
                      prodId: state.idproducts,
                      id: excuteSuite,
                      SuiteCaseList: SuiteCaseList,
                      SelectEnvironmentName: state.SelectEnvironmentName,
                      SelectEnvironmentUrl: state.SelectEnvironmentUrl,
                      SelectBrowserName: state.SelectBrowserName,
                      SelectResolution: state.SelectResolution,
                      RerunFailTest: state.RerunFailTest,
                      StopTestAfterFailue: state.StopTestAfterFailue,
                      ActionValue: "ExecuteTestSuites",
                      licenseKeyValue: null,
                    });
                  }}
                >
                  Execute
                </ColorButton>
                <ColorButton
                  size="small"
                  variant="contained"
                  style={{
                    marginLeft: "1rem",
                  }}
                  onClick={() => {
                    handleClickOpenUrl();
                    sendSuitesWithExecute({
                      prodId: state.idproducts,
                      id: excuteSuite,
                      SuiteCaseList: SuiteCaseList,
                      SelectEnvironmentName: state.SelectEnvironmentName,
                      SelectEnvironmentUrl: state.SelectEnvironmentUrl,
                      SelectBrowserName: state.SelectBrowserName,
                      SelectResolution: state.SelectResolution,
                      RerunFailTest: state.RerunFailTest,
                      StopTestAfterFailue: state.StopTestAfterFailue,
                      ActionValue: "DLExecuteSuite",
                      licenseKeyValue: licenseKey,
                    });
                  }}
                >
                  Get Suite URL
                </ColorButton>
              </>
            )}
          </Box>
          {TestCaseList.length !== 0 && (
            <Box
              style={{
                display: "flex",
              }}
            >
              <ColorButton
                size="small"
                variant="contained"
                color="inherit"
                onClick={(event) => {
                  handleClickOpenExecute();
                }}
                sx={{ marginLeft: 2 }}
              >
                <SettingsIcon />
              </ColorButton>
              <BootstrapDialog
                onClose={handleCloseExecute}
                aria-labelledby="customized-dialog"
                open={openDialogExecute}
                className="custom-dialog"
              >
                <BootstrapDialogTitle id="customized-dialog" onClose={handleCloseExecute}>
                  Change Execute settings
                </BootstrapDialogTitle>
                <DialogContent>
                  <FormControl
                    sx={{
                      marginTop: "1rem",
                    }}
                    fullWidth
                  >
                    <InputLabel
                      id={`TestData Environment`}
                      style={{
                        paddingTop: 4,
                      }}
                    >
                      Environment
                    </InputLabel>
                    <Select
                      labelId={`TestData Environment`}
                      id={`TestData Environment select`}
                      value={state.SelectEnvironmentName}
                      label="Environment"
                      fullWidth
                      name="SelectEnvironment"
                      IconComponent={(props) => <ArrowDropDownSharpIcon {...props} />}
                      onChange={(event) => {
                        setState({
                          ...state,
                          SelectEnvironmentName: event.target.value,
                          SelectEnvironmentUrl: state.envDetails
                            .filter((item) => item.envname === event.target.value)
                            .map((item) => item.envurl)[0],
                        });
                      }}
                      style={{
                        minWidth: 168,
                        alignItems: "center",
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {state.envDetails.map((item, index) => (
                        <MenuItem key={index} value={item.envname}>
                          {item.envname}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {state.SelectEnvironmentUrl !== "" && (
                    <Typography
                      paddingBottom={0}
                      variant="body1"
                      component="h2"
                      style={{
                        textTransform: "lowercase",
                        // marginLeft: 4,
                      }}
                    >
                      {state.SelectEnvironmentUrl}
                    </Typography>
                  )}
                  <FormControl
                    sx={{
                      marginTop: "1rem",
                    }}
                    fullWidth
                  >
                    <InputLabel
                      id={`TestData Environment`}
                      style={{
                        paddingTop: 4,
                      }}
                    >
                      Browser
                    </InputLabel>
                    <Select
                      labelId={`TestData Browser`}
                      id={`TestData Browser select`}
                      value={state.SelectBrowserName}
                      label="Browser"
                      name="SelectBrowser"
                      IconComponent={(props) => <ArrowDropDownSharpIcon {...props} />}
                      onChange={(event) => {
                        setState({
                          ...state,
                          SelectBrowserName: event.target.value,
                        });
                      }}
                      style={{
                        minWidth: 168,
                        alignItems: "center",
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {state.browserDetails.map((item, index) => (
                        <MenuItem key={index} value={item.browsername}>
                          {item.browsername}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{
                      marginTop: "1rem",
                    }}
                    fullWidth
                  >
                    <InputLabel
                      id={`TestData Environment`}
                      style={{
                        paddingTop: 4,
                      }}
                    >
                      Resolution
                    </InputLabel>
                    <Select
                      labelId={`TestData Resolution`}
                      id={`TestData Resolution select`}
                      value={state.Height}
                      label="Resolution"
                      name="SelectResolution"
                      IconComponent={(props) => <ArrowDropDownSharpIcon {...props} />}
                      onChange={(event) => {
                        if (event.target.value !== "") {
                          setState({
                            ...state,
                            Height: event.target.value,
                            Width: state.resolutionDetails.filter(
                              (item) => item.Height === event.target.value,
                            )[0].Width,
                          });
                        } else {
                          setState({
                            ...state,
                            Height: 0,
                            Width: 0,
                          });
                        }
                      }}
                      style={{
                        minWidth: 168,
                        alignItems: "center",
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {state.resolutionDetails.map((item, index) => (
                        <MenuItem key={index} value={item.Height}>
                          {`${item.Height}x${item.Width}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControlLabel
                    value={state.StopTestAfterFailue}
                    control={<Switch color="primary" checked={state.StopTestAfterFailue} />}
                    label="Stop Test After Failue"
                    labelPlacement="start"
                    onChange={() => {
                      setState({
                        ...state,
                        StopTestAfterFailue: !state.StopTestAfterFailue,
                      });
                    }}
                  />
                  <FormControlLabel
                    value={state.RerunFailTest}
                    control={<Switch color="primary" checked={state.RerunFailTest} />}
                    label="Rerun Fail Test"
                    labelPlacement="start"
                    onChange={() => {
                      setState({
                        ...state,
                        RerunFailTest: !state.RerunFailTest,
                      });
                    }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={handleCloseExecute}>
                    Close
                  </Button>
                </DialogActions>
              </BootstrapDialog>
            </Box>
          )}
        </Box>
        {TestCaseList.length !== 0 && (
          <Box>
            <GridContextProvider onChange={onChange}>
              <div className="container">
                <Box className="left-box">
                  <Box marginY={"1rem"}>
                    <Paper
                      component="form"
                      sx={{
                        p: "2px 4px",
                        marginLeft: "0rem",
                        display: "flex",
                        alignItems: "center",
                        width: 400,
                      }}
                    >
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search here..."
                        inputProps={{ "aria-label": "Search here..." }}
                        defaultValue={SearchString}
                        onChange={(e) => {
                          e.preventDefault();
                          setSearchString(e.target.value);
                        }}
                      />
                      <SearchIcon />
                    </Paper>
                  </Box>
                  <GridDropZone
                    className="dropzone-left"
                    id="left"
                    boxesPerRow={2}
                    rowHeight={52}
                    style={{ position: "static" }}
                  >
                    {TestCaseList.filter((value) => value.Test_Case.includes(SearchString)).map(
                      (item) => (
                        <GridItem
                          key={item.idtest_case}
                          style={{
                            transform: "none",
                            zIndex: 0,
                            position: "static",
                            opacity: 1,
                            boxSizing: "border-box",
                            maxWidth: "8rem",
                          }}
                        >
                          <div className="grid-item">
                            <Typography
                              paddingBottom={0}
                              variant="body1"
                              component="div"
                              sx={{
                                fontWeight: 700,
                                fontSize: "1rem",
                                alignItems: "center",
                                cursor: "pointer",
                                ":hover": {
                                  textDecoration: "underline",
                                },
                              }}
                              onClick={() => {
                                navigate("/dashboard/recording", {
                                  state: {
                                    fromRecordingPage: true,
                                    idproducts: state.idproducts,
                                    idmodules: state?.idmodules,
                                    idtest_case: item.idtest_case,
                                    Test_Case: item.Test_Case,
                                    details: item,
                                  },
                                });
                              }}
                            >
                              {item.Test_Case}
                            </Typography>
                          </div>
                        </GridItem>
                      ),
                    )}
                  </GridDropZone>
                </Box>
                <Divider orientation="vertical" flexItem variant="middle" />
                <Box className="right-box">
                  <Box style={{ margin: "1rem 0" }}>
                    <ColorButton size="small" variant="contained" onClick={handleClickOpen}>
                      Add Suites
                    </ColorButton>
                    <ColorButton
                      size="small"
                      variant="contained"
                      sx={{ ml: "1rem" }}
                      onClick={() => {
                        sendSuites({
                          SuiteCaseList: SuiteCaseList,
                          id: state.idproducts,
                        });
                      }}
                    >
                      Submit
                    </ColorButton>
                  </Box>
                  <Box
                    style={{
                      flex: 1,
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      overflowX: "scroll",
                      scrollbarWidth: 0,
                      paddingBottom: "1rem",
                      maxWidth: "70rem",
                    }}
                  >
                    {SuiteCaseList.map((suit) => (
                      <Box className="suite-box" key={suit.idtest_suite}>
                        <BootstrapDialog
                          onClose={handleCloseTestSuite}
                          aria-labelledby="customized-dialog"
                          open={openDialogTestSuite}
                          className="custom-dialog"
                        >
                          <BootstrapDialogTitle
                            id="customized-dialog"
                            onClose={handleCloseTestSuite}
                          >
                            New TestSuite Name
                          </BootstrapDialogTitle>
                          <DialogContent>
                            <TextField
                              sx={{ marginRight: "2rem" }}
                              label="New Test Suite Name"
                              variant="standard"
                              value={state.newName}
                              onChange={(event) => {
                                event.preventDefault();
                                setState((prev) => {
                                  return {
                                    ...prev,
                                    newName: event.target.value,
                                  };
                                });
                              }}
                            />
                          </DialogContent>
                          <DialogActions>
                            <Button
                              autoFocus
                              onClick={() => {
                                console.log(suit);
                                editTestSuite(CurrentSuite.idtest_suite, state.newName);
                                handleCloseTestSuite();
                              }}
                            >
                              Submit
                            </Button>
                            <Button autoFocus onClick={handleCloseTestSuite}>
                              Close
                            </Button>
                          </DialogActions>
                        </BootstrapDialog>
                        <BootstrapDialog
                          onClose={handleCloseExecute}
                          aria-labelledby="customized-dialog"
                          open={openDialogTestSuiteEdit}
                          className="custom-dialog"
                        >
                          <BootstrapDialogTitle
                            id="customized-dialog"
                            onClose={handleCloseTestSuiteEdit}
                          >
                            Change Execute settings for
                            {" " + CurrentSuite?.Test_Suite}
                          </BootstrapDialogTitle>
                          <DialogContent>
                            <FormControl
                              sx={{
                                marginTop: "1rem",
                              }}
                              fullWidth
                            >
                              <InputLabel
                                id={`TestData Environment`}
                                style={{
                                  paddingTop: 4,
                                }}
                              >
                                Environment
                              </InputLabel>
                              <Select
                                labelId={`TestData Environment`}
                                id={`TestData Environment select`}
                                value={stateTestSuite.SelectEnvironmentName}
                                label="Environment"
                                fullWidth
                                name="SelectEnvironment"
                                IconComponent={(props) => <ArrowDropDownSharpIcon {...props} />}
                                onChange={(event) => {
                                  setStateTestSuite({
                                    ...stateTestSuite,
                                    SelectEnvironmentName: event.target.value,
                                    SelectEnvironmentUrl: stateTestSuite.envDetails
                                      .filter((item) => item.envname === event.target.value)
                                      .map((item) => item.envurl)[0],
                                  });
                                }}
                                style={{
                                  minWidth: 168,
                                  alignItems: "center",
                                }}
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                {stateTestSuite.envDetails.map((item, index) => (
                                  <MenuItem key={index} value={item.envname}>
                                    {item.envname}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {stateTestSuite.SelectEnvironmentUrl !== "" && (
                              <Typography
                                paddingBottom={0}
                                variant="body1"
                                component="h2"
                                style={{
                                  textTransform: "lowercase",
                                  // marginLeft: 4,
                                }}
                              >
                                {stateTestSuite.SelectEnvironmentUrl}
                              </Typography>
                            )}
                            <FormControl
                              sx={{
                                marginTop: "1rem",
                              }}
                              fullWidth
                            >
                              <InputLabel
                                id={`TestData Environment`}
                                style={{
                                  paddingTop: 4,
                                }}
                              >
                                Browser
                              </InputLabel>
                              <Select
                                labelId={`TestData Browser`}
                                id={`TestData Browser select`}
                                value={stateTestSuite.SelectBrowserName}
                                label="Browser"
                                name="SelectBrowser"
                                IconComponent={(props) => <ArrowDropDownSharpIcon {...props} />}
                                onChange={(event) => {
                                  setStateTestSuite({
                                    ...stateTestSuite,
                                    SelectBrowserName: event.target.value,
                                  });
                                }}
                                style={{
                                  minWidth: 168,
                                  alignItems: "center",
                                }}
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                {stateTestSuite.browserDetails.map((item, index) => (
                                  <MenuItem key={index} value={item.browsername}>
                                    {item.browsername}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <FormControl
                              sx={{
                                marginTop: "1rem",
                              }}
                              fullWidth
                            >
                              <InputLabel
                                id={`TestData Environment`}
                                style={{
                                  paddingTop: 4,
                                }}
                              >
                                Resolution
                              </InputLabel>
                              <Select
                                labelId={`TestData Resolution`}
                                id={`TestData Resolution select`}
                                value={stateTestSuite.Height}
                                label="Resolution"
                                name="SelectResolution"
                                IconComponent={(props) => <ArrowDropDownSharpIcon {...props} />}
                                onChange={(event) => {
                                  if (event.target.value !== "") {
                                    setStateTestSuite({
                                      ...stateTestSuite,
                                      Height: event.target.value,
                                      Width: state.resolutionDetails.filter(
                                        (item) => item.Height === event.target.value,
                                      )[0].Width,
                                    });
                                  } else {
                                    setStateTestSuite({
                                      ...stateTestSuite,
                                      Height: 0,
                                      Width: 0,
                                    });
                                  }
                                }}
                                style={{
                                  minWidth: 168,
                                  alignItems: "center",
                                }}
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                {state.resolutionDetails.map((item, index) => (
                                  <MenuItem key={index} value={item.Height}>
                                    {`${item.Height}x${item.Width}`}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <FormControlLabel
                              value={stateTestSuite.StopTestAfterFailue}
                              control={
                                <Switch
                                  color="primary"
                                  checked={stateTestSuite.StopTestAfterFailue}
                                />
                              }
                              label="Stop Test After Failue"
                              labelPlacement="start"
                              onChange={() => {
                                setStateTestSuite({
                                  ...stateTestSuite,
                                  StopTestAfterFailue: !stateTestSuite.StopTestAfterFailue,
                                });
                              }}
                            />
                            <FormControlLabel
                              value={stateTestSuite.RerunFailTest}
                              control={
                                <Switch color="primary" checked={stateTestSuite.RerunFailTest} />
                              }
                              label="Rerun Fail Test"
                              labelPlacement="start"
                              onChange={() => {
                                setStateTestSuite({
                                  ...stateTestSuite,
                                  RerunFailTest: !stateTestSuite.RerunFailTest,
                                });
                              }}
                            />
                          </DialogContent>
                          <DialogActions>
                            <Button
                              autoFocus
                              onClick={() => {
                                saveEnviromentSettingtoTestSuite({
                                  id_test_suite: CurrentSuite.idtest_suite,
                                  EnvironmentUrl: stateTestSuite.SelectEnvironmentUrl,
                                  EnvironmentBrowser: stateTestSuite.SelectBrowserName,
                                  StopAfterFailure: stateTestSuite.StopTestAfterFailue,
                                  ReRunFailedTests: stateTestSuite.RerunFailTest,
                                  Width: stateTestSuite.Width,
                                  Height: stateTestSuite.Height,
                                });
                              }}
                            >
                              save
                            </Button>
                            <Button autoFocus onClick={handleCloseTestSuiteEdit}>
                              Close
                            </Button>
                          </DialogActions>
                        </BootstrapDialog>
                        <Typography
                          paddingBottom={0}
                          variant="body1"
                          component="div"
                          sx={{
                            fontWeight: 700,
                            fontSize: "1rem",
                            alignItems: "center",
                          }}
                        >
                          {suit.Test_Suite.toUpperCase()}
                          <IconButton
                            sx={{ cursor: "pointer", paddingX: 0 }}
                            color="inherit"
                            onClick={() => {
                              handleClickOpenTestSuite(true);
                              setCurrentSuite(suit);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            style={{ cursor: "pointer" }}
                            color="inherit"
                            onClick={() => {
                              setCurrentSuite(suit);
                              deleteTestSuite();
                            }}
                          >
                            <DeleteIcon color="inherit" />
                          </IconButton>
                          <IconButton
                            style={{ cursor: "pointer" }}
                            color="inherit"
                            onClick={() => {
                              setCurrentSuite(suit);
                              handleClickOpenTestSuiteEdit();

                              setStateTestSuite((prev) => {
                                return {
                                  ...prev,
                                  SelectEnvironmentName: state.envDetails
                                    .filter((item) => item.envUrl === suit.envUrl)
                                    .map((item) => item.envname)[0],
                                  SelectEnvironmentUrl: suit.envurl,
                                  SelectBrowserName: suit.browser,
                                  RerunFailTest: suit.rerunfailedtests,
                                  StopTestAfterFailue: suit.stopafterfailure,
                                  Height: suit.height,
                                  Width: suit.width,
                                };
                              });
                            }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </Typography>
                        <GridDropZone
                          className="dropzone-right"
                          id={suit.idtest_suite}
                          boxesPerRow={1}
                          rowHeight={64}
                          style={{
                            position: "static",
                            alignItems: "center",
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: "column",
                          }}
                        >
                          {suit.list.map((item) => (
                            <GridItem
                              key={item.idtest_case}
                              style={{
                                transform: "none",
                                zIndex: 0,
                                position: "static",
                                opacity: 1,
                                boxSizing: "border-box",
                                maxWidth: "16rem",
                                margin: "0.5rem",
                              }}
                            >
                              <div className="grid-item">
                                <Typography
                                  paddingBottom={0}
                                  variant="body1"
                                  component="div"
                                  sx={{
                                    fontWeight: 700,
                                    fontSize: "1rem",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    ":hover": {
                                      textDecoration: "underline",
                                    },
                                  }}
                                  onClick={() => {
                                    navigate("/dashboard/recording", {
                                      state: {
                                        fromRecordingPage: true,
                                        idproducts: state.idproducts,
                                        idmodules: state?.idmodules,
                                        idtest_case: item.idtest_case,
                                        Test_Case: item.idtest_case,
                                        details: item,
                                      },
                                    });
                                  }}
                                >
                                  {item.Test_Case}
                                </Typography>
                                <IconButton
                                  sx={{ cursor: "pointer", paddingX: 0 }}
                                  color="inherit"
                                  onClick={() => {
                                    deleteTestCasefromSuite(suit.idtest_suite, suit.list, item);
                                  }}
                                >
                                  <DeleteIcon color="inherit" />
                                </IconButton>
                              </div>
                            </GridItem>
                          ))}
                        </GridDropZone>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </div>
            </GridContextProvider>
          </Box>
        )}
      </Box>
    </>
  );
};

export default CreateCollection;
