import { Box, Button, styled, TextField, Typography } from "@mui/material";
import { CustomSelect } from "../../components-new/common";
import { RunTimeOptions } from "../Studio/constants";
import { TimeSeriesChart } from "../../components-new/charts";
import { useState, useEffect } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';
import { useExecutionThreadChartQuery } from "../../useQuery";
import { ExecutionThreadChartData } from "./Dummydata";

const ChartContainer = styled(Box)`
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0px -3px 17.1px 0px rgba(0, 0, 0, 0.07);
    margin-bottom: 36px;
    padding: 30px;
`;

const ChartWrapper = styled(Box)`
  .custom-data-label {
    position: relative;
    background-color: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #304758;
    border: 2px solid #304758;
  }

  .arrow_box {
    position: relative;
    border-radius: 9px;
    padding: 15px;
    box-shadow: 0px 2px 17.1px 0px #D1DBFF;
    font-size: 12px;
    font-weight: 400;
  }

  .time-data { 
    color: #FF5336;
  }

  .arrow_box:after, .arrow_box:before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .arrow_box:after {
    border-color: rgba(85, 85, 85, 0);
    border-right-color: #D1DBFF;
    border-width: 10px;
    margin-top: -10px;
  }
  .arrow_box:before {
    border-color: rgba(0, 0, 0, 0);
    border-right-color: #D1DBFF;
    border-width: 13px;
    margin-top: -13px;
  }

  .apexcharts-tooltip {
    transform: translateX(10px) translateY(10px);
    overflow: visible !important;
    white-space: normal !important;
  }
`;

const ResetBtn = styled(Button)`
  color: #2B3539;
  font-size: 13px;
  font-weight: 300;
  text-decoration: underline;
`;

const ExecutionTestcaseRunChart = () => {
  const [timeopt, setTimeopt] = useState('03:00 - 04:00');
  const [fromDate, setFromDate] = useState(dayjs());
  const [toDate, setToDate] = useState(dayjs());
  const [formattedData, setFormattedData] = useState([])

  // Hook to fetch chart data
  const { data, refetch, isSuccess } = useExecutionThreadChartQuery({ fromDate: fromDate?.format('YYYY-MM-DD HH:mm:ss'), toDate: toDate.format('YYYY-MM-DD HH:mm:ss') });
  useEffect(() => {
    if (isSuccess ) {

      const tempData = [
        {
          name: 'Test Cases',
          data: data
        }
      ]
      setFormattedData(tempData)
    }
  }, [data, isSuccess])

  // Set default values on component mount
  useEffect(() => {
    const now = dayjs();
    const currentHour = now.hour();
    const currentTimeRange = `${String(currentHour).padStart(2, '0')}:00 - ${String(currentHour + 1).padStart(2, '0')}:00`;
    setTimeopt(currentTimeRange);
    setFromDate(now.startOf('day').hour(parseInt(currentTimeRange.split(' - ')[0].split(':')[0])).minute(parseInt(currentTimeRange.split(' - ')[0].split(':')[1])).second(0));
    setToDate(now.startOf('day').hour(parseInt(currentTimeRange.split(' - ')[1].split(':')[0])).minute(parseInt(currentTimeRange.split(' - ')[1].split(':')[1])).second(59));
  }, []);

  // Update query parameters when date or time range changes
  useEffect(() => {
    if (fromDate && toDate && fromDate.format('HH:mm') !== toDate.format('HH:mm')) refetch();
  }, [fromDate, toDate, timeopt, refetch]);

  // Function to handle date change
  const handleDateChange = (date) => {
    const newDate = dayjs(date);
    setFromDate(newDate.startOf('day').hour(parseInt(timeopt.split(' - ')[0].split(':')[0])).minute(parseInt(timeopt.split(' - ')[0].split(':')[1])).second(0));
    setToDate(newDate.startOf('day').hour(parseInt(timeopt.split(' - ')[1].split(':')[0])).minute(parseInt(timeopt.split(' - ')[1].split(':')[1])).second(59));
  };

  // Function to handle time range change
  const handleTimeRangeChange = (event) => {
    const newTimeRange = event.target.value;
    setTimeopt(newTimeRange);
    const [startTime, endTime] = newTimeRange.split(' - ');
    const updatedFromDate = fromDate.hour(parseInt(startTime.split(':')[0])).minute(parseInt(startTime.split(':')[1])).second(0);
    const updatedToDate = fromDate.hour(parseInt(endTime.split(':')[0])).minute(parseInt(endTime.split(':')[1])).second(59);
    setFromDate(updatedFromDate);
    setToDate(updatedToDate);
  };

  return (
    <ChartContainer my={3}>
      <Box display="flex" alignItems="center">
        <Box display="flex" justifyContent={"space-between"} alignItems="center" ml={2}>
          <Box display="flex" columnGap={3} alignItems={"center"}>
            <Box>
              <Typography fontSize={'13px'} fontWeight={500} color='#8089A8' mb={'6px'}>Date</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  inputFormat="MM/DD/YYYY"
                  value={fromDate}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{ backgroundColor: '#fff', boxShadow: '0px 17px 25px 0px rgba(0, 0, 0, 0.05)' }}
                />
              </LocalizationProvider>
            </Box>

            <Box marginLeft={'17px'} minWidth={'170px'}>
              <CustomSelect
                options={RunTimeOptions}
                label="Select Test Cases Running Time"
                width={"100%"}
                background={"#ffffff"}
                value={timeopt}
                onChange={handleTimeRangeChange}
              />
            </Box>
            <Box mt={'26px'}><ResetBtn variant="text">Reset</ResetBtn></Box>
          </Box>
        </Box>
      </Box>
      <ChartWrapper>
        <TimeSeriesChart series={formattedData} fromDate={fromDate} toDate={toDate} />
      </ChartWrapper>
    </ChartContainer>
  );
};

export default ExecutionTestcaseRunChart;
