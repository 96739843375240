import React from "react";

const NlpIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="14"
    viewBox="0 0 17 14"
    fill="currentColor"
  >
    <path
      d="M6.33231 3.72639L10.7353 14L13.4204 7.73383H16.6059V6.26617H12.4532L10.7353 10.2736L6.33231 0L3.64722 6.26617H0.46167V7.73383H4.61441L6.33231 3.72639Z"
      fill="currentColor"
    />
  </svg>
);

export default NlpIcon;
