import { Box, Typography, Button, IconButton, styled } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { RightArrowIcon, LeftArrowIcon, ContextIcon } from "../../components-new/icons";
import { useEffect, useState } from "react";
import { IconContainerCircle } from "../../components-new/common";
import CustomMenu from "./CustomMenu";

const StyledIconBtn = styled(IconButton)`
  border-radius: 0;
  padding: 10px;

  &:disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }
`;

const StepText = styled("span")`
  padding-left: 30px;
  padding-right: 30px;
`;

const EditHeader = ({
  editItemIndex: currentEditItemIndex,
  setEditItemIndex,
  EditData: testCasesList,
  fromStepHistory,
}) => {
  // const testCasesList = useSelector((state) => state.executionSlice.testCaseResults);
  const editItem = testCasesList[currentEditItemIndex];
  const [disableIncrement, setDisableIncrement] = useState(false);
  const [disableDecrement, setDisableDecrement] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    updateButtonDisableStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEditItemIndex, testCasesList]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (event) => {
    setAnchorEl(null);
  };

  const updateButtonDisableStatus = () => {
    if (currentEditItemIndex !== -1) {
      setDisableIncrement(currentEditItemIndex === testCasesList?.length - 1);
      setDisableDecrement(currentEditItemIndex === 0);
    } else {
      setDisableIncrement(true);
      setDisableDecrement(true);
    }
  };

  const handleIncrementStep = () => {
    if (currentEditItemIndex !== -1) {
      if (currentEditItemIndex + 1 < testCasesList.length) {
        setEditItemIndex(currentEditItemIndex + 1);
      } else {
        console.log("End of test cases list reached");
      }
    }
  };

  const handleDecrementStep = () => {
    if (currentEditItemIndex !== -1) {
      if (currentEditItemIndex - 1 >= 0) {
        setEditItemIndex(currentEditItemIndex - 1);
      } else {
        console.log("Beginning of test cases list reached");
      }
    }
  };

  const handleBack = () => {
    setEditItemIndex(null);
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box>
        <Typography color="#000000" fontSize="25px" fontWeight={600}>
          <StyledIconBtn
            variant="text"
            aria-label="Previous step"
            disabled={disableDecrement}
            onClick={handleDecrementStep}
          >
            <LeftArrowIcon />
          </StyledIconBtn>
          <StepText>
            Step {editItem.Step_Number} {fromStepHistory && `@ ${editItem.Executed_Date}`}
          </StepText>
          <StyledIconBtn
            aria-label="Next step"
            disabled={disableIncrement}
            onClick={handleIncrementStep}
          >
            <RightArrowIcon />
          </StyledIconBtn>
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" columnGap="12px" textAlign="right">
        <Button
          variant="outlined"
          sx={{
            textTransform: "capitalize",
            padding: "15px 28px",
            borderRadius: "8px",
            fontSize: "16px",
          }}
          startIcon={<ArrowBackIcon />}
          onClick={handleBack}
        >
          Exit Edit
        </Button>
        {!fromStepHistory && (
          <>
            <Box onClick={handleMenuClick}>
              <IconContainerCircle>
                <ContextIcon />
              </IconContainerCircle>
            </Box>
            <CustomMenu
              anchorEl={anchorEl}
              handleClose={handleMenuClose}
              handleExitEdit={handleBack}
              editItem={editItem}
            />
          </>
        )}
      </Box>
    </Box>
  );
};
export default EditHeader;
