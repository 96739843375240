export {default as DesignSection} from './DesignSection';
export {default as DesignAuthoredChart} from './DesignAuthoredChart';
export {default as DesignAuthoredTop5Chart} from './DesignAuthoredTop5Chart';
export {default as ExecutionSection} from './ExecutionSection';
export {default as ExecutionTestcaseRunChart} from './ExecutionTestcaseRunChart';
export {default as ExecutionGuageChart} from './ExecutionTestcaseRunChart';
export {default as ExecutionLast5ExeTrends} from './ExecutionTestcaseRunChart';
export {default as OptimizationSection} from './OptimizationSection';




