import { Box, styled, Typography } from "@mui/material"
import { TestCaseNotExeIcon, TestCasesFailIcon, TestCasesIcon, TestCasesPassIcon } from "../../components-new/icons"
import { useGetTop4CardsDashboardQuery } from "../../useQuery";
import { useEffect } from "react";
import { toNumber } from "lodash";

const StyledCard = styled(Box)`
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0px 17px 25px 0px rgba(0, 0, 0, 0.05), 0px -3px 17.1px 0px rgba(0, 0, 0, 0.07);
    display: flex;
    flex: 1;
    justify-content: space-between;
    min-height: 130px;
    align-items: center;
    border-bottom: 5px solid ${props => {
        switch (props.type) {
            case 'authored':
                return '#E7E7E7';
            case 'pass':
                return '#30CA17';
            case 'fail':
                return '#FF5336';
            case 'notExecuted':
                return '#8C9EB8';
            default:
                return 'transparent';
        }
    }};
`

const DesignCard = ({ val, text1, text2, icon, type }) => {
    return (<StyledCard p={'25px'} type={type}>
        <Box display={'flex'} flexDirection={'column'}>
            <Typography fontSize={'24px'} fontWeight={700} color={'#2B3539'}>{val}</Typography>
            <Typography fontSize={'14px'} fontWeight={400} color={'#2B3539'}>{text1}</Typography>
            <Typography fontSize={'14px'} fontWeight={400} color={'#2B3539'}>{text2}</Typography>

        </Box>
        <Box>
            {icon}
        </Box>
    </StyledCard>)
}

const DesignCardSection = () => {

    const { data: cardData, refetch: refetchTop4Cards, isSuccess } = useGetTop4CardsDashboardQuery();

    useEffect(() => {
        refetchTop4Cards()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Box display={'flex'} columnGap={2} my={3}>
            {isSuccess && <>
            <DesignCard type={'authored'} val={cardData?.[0]?.authored ?? '-'} text1={'Total Test Cases '} text2={'authored'} icon={<TestCasesIcon />} />
            <DesignCard type={'pass'} val={cardData?.[0]?.pass ?? '-'} text1={'Test Cases'} text2={'passed'} icon={<TestCasesPassIcon />} />
            <DesignCard type={'fail'} val={cardData?.[0]?.fail ?? '-'} text1={'Test Cases'} text2={'failed'} icon={<TestCasesFailIcon />} />
            <DesignCard type={'notExecuted'} val={toNumber(cardData?.[0]?.stopped) + toNumber(cardData?.[0]?.aborted) ?? '-'} text1={'Test Cases not'} text2={'Executed'} icon={<TestCaseNotExeIcon />} />
            </>}
        </Box>
    )
}

export default DesignCardSection