import React, { useState } from "react";
import { TextField, CircularProgress, IconButton } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import SearchIcon from "@mui/icons-material/Search";

const AutocompleteSearch = () => {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState(["option 11"]);
  const [loading, setLoading] = useState(false);
  const [optionSelected, setOptionSelected] = useState(false);

  const handleSearch = async () => {
    setLoading(true);
    // Simulate an API call
    setTimeout(() => {
      setOptions(["Option 1", "Option 2", "Option 3"]);
      setLoading(false);
    }, 2000);
  };

  const handleInputChange = (event, value) => {
    setInputValue(value);
    if (!value) {
      setOptions([]);
    }
    setOptionSelected(false);
  };

  const handleOptionChange = (event, value) => {
    if (value) {
      setOptionSelected(true);
    } else {
      setOptionSelected(false);
    }
  };

  const renderInput = (params) => (
    <TextField
      {...params}
      label="Search"
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {loading ? <CircularProgress color="inherit" size={20} /> : null}
            {inputValue && !loading && !optionSelected && (
              <IconButton onClick={handleSearch}>
                <SearchIcon />
              </IconButton>
            )}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
    />
  );

  return (
    <Autocomplete
      freeSolo
      sx={{ width: "300px" }}
      options={options}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={handleOptionChange}
      renderInput={renderInput}
    />
  );
};

export default AutocompleteSearch;
