// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%; /* Use full container width */
  max-width: 800px; /* Adjust based on your preference */
  margin: 50px auto 0 auto;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.messages {
  width: 100%;
  margin-bottom: 20px;
  overflow-y: auto; /* Allow scrolling for overflow */
  max-height: 400px; /* Adjust based on your preference */
}

.message-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message-form textarea {
  width: 90%; /* Adjust based on your preference */
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px; /* Make text easily readable */
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none; /* Prevent manual resize */
}

.message-form button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.message-form button:hover {
  background-color: #0056b3;
}`, "",{"version":3,"sources":["webpack://./src/pages/ChatBot/ChatBot.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA,EAAA,6BAAA;EACA,gBAAA,EAAA,oCAAA;EACA,wBAAA;EACA,aAAA;EACA,wCAAA;EACA,kBAAA;AACJ;;AAEA;EACI,WAAA;EACA,mBAAA;EACA,gBAAA,EAAA,iCAAA;EACA,iBAAA,EAAA,oCAAA;AACJ;;AAEA;EACI,WAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,UAAA,EAAA,oCAAA;EACA,mBAAA;EACA,aAAA;EACA,eAAA,EAAA,8BAAA;EACA,sBAAA;EACA,kBAAA;EACA,YAAA,EAAA,0BAAA;AACJ;;AAEA;EACI,kBAAA;EACA,eAAA;EACA,YAAA;EACA,yBAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;AACJ;;AAEA;EACI,yBAAA;AACJ","sourcesContent":[".chat-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    width: 100%; /* Use full container width */\n    max-width: 800px; /* Adjust based on your preference */\n    margin: 50px auto 0 auto;\n    padding: 20px;\n    box-shadow: 0 4px 6px rgba(0,0,0,0.1);\n    border-radius: 8px;\n}\n\n.messages {\n    width: 100%;\n    margin-bottom: 20px;\n    overflow-y: auto; /* Allow scrolling for overflow */\n    max-height: 400px; /* Adjust based on your preference */\n}\n\n.message-form {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.message-form textarea {\n    width: 90%; /* Adjust based on your preference */\n    margin-bottom: 10px;\n    padding: 10px;\n    font-size: 16px; /* Make text easily readable */\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    resize: none; /* Prevent manual resize */\n}\n\n.message-form button {\n    padding: 10px 20px;\n    font-size: 16px;\n    color: white;\n    background-color: #007bff;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n}\n\n.message-form button:hover {\n    background-color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
