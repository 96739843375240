import React from "react";

const BookmarkIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none">
    <path
      d="M1.10076 0.5H11.2734C11.4661 0.5 11.6509 0.576554 11.7872 0.712821C11.9235 0.849088 12 1.03391 12 1.22662V15.1362C12.0001 15.2012 11.9828 15.265 11.9498 15.321C11.9168 15.377 11.8695 15.4231 11.8126 15.4546C11.7558 15.486 11.6916 15.5017 11.6266 15.4999C11.5617 15.4981 11.4984 15.4789 11.4434 15.4443L6.18708 12.1477L0.930734 15.4436C0.875778 15.4781 0.812577 15.4973 0.7477 15.4991C0.682824 15.501 0.618642 15.4854 0.561827 15.454C0.505012 15.4226 0.457639 15.3766 0.424633 15.3207C0.391627 15.2648 0.374194 15.2011 0.374146 15.1362V1.22662C0.374146 1.03391 0.4507 0.849088 0.586967 0.712821C0.723234 0.576554 0.908051 0.5 1.10076 0.5ZM10.5468 1.95323H1.82738V13.1664L6.18708 10.4336L10.5468 13.1664V1.95323Z"
      fill="currentColor"
    />
  </svg>
);

export default BookmarkIcon;
