import React, { useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { styled } from '@mui/system';

const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: 'transparent',
  width: 'auto',
  '& .MuiTabs-flexContainer': {
    columnGap: '2px',
  },
  '& .MuiTabs-indicator': {
    background: `#ffffff`,
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  borderRadius: `5px 5px 0px 0px`,
  background: 'rgba(0, 0, 0, 0.02)',
  '&.Mui-selected': {
    color: theme.palette.primary.main,
    border: 0,
    borderRadius: `5px 5px 0px 0px`,
    background: `#ffffff`,
    boxShadow: ` 0px -1px 5.4px 0px rgba(0, 0, 0, 0.08)`,
  },
  '&:hover': {
    backgroundColor: '#ffffff',
  },
}));

const CustomTab = styled(Box)`
  display: flex;
  column-gap: 7px;
  color: #03053d;
  font-size: 24px;
  font-weight: 500;
  text-transform: capitalize;
  align-items: center;
`;

const CustomTabPanel = ({ children, value, index, ...other }) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </Box>
  );
};

const TabComponent = ({ tabs }) => {
  const [value, setValue] = useState(0);

  return (
    <Box>
      <StyledTabs value={value} onChange={(event, newValue) => setValue(newValue)}>
        {tabs.map((tab, index) => (
          <StyledTab
            key={index}
            label={
              <CustomTab>
                {tab.icon}
                <Typography>{tab.label}</Typography>
              </CustomTab>
            }
          />
        ))}
      </StyledTabs>
      {tabs.map((tab, index) => (
        <CustomTabPanel key={index} value={value} index={index}>
          {tab.content}
        </CustomTabPanel>
      ))}
    </Box>
  );
};

export default TabComponent;
