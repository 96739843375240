import React, { forwardRef, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Slide,
  styled,
  Switch,
  Typography,
} from "@mui/material";
import { CustomInput, IconContainer, PageTitle, PillRadio } from "../../components-new/common";
import { ScenarioIcon } from "../../components-new/icons";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import {
  BrowserOptions,
  EnvOptions,
  ResolutionOptions,
} from "../../components-new/utils/constants";
import { useSelector } from "react-redux";
import useSaveTestCaseSettingsQuery from "../../useQuery/useSaveTestCaseSettingsQuery";
import { useNotification } from "../../hooks";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "600px",
    height: "760px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    textAlign: "auto",
    padding: "14px",
  },
}));

const StyledLabel = styled(Typography)`
  color: #8089a8;
  font-size: 13px;
  font-weight: 500;
`;

const StyledButton = styled(Button)`
  padding: 15px 28px;
  border-radius: 8px;
  text-transform: capitalize;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  line-height: 20px;
`;

const Settings = () => {
  const selectedTestCase = useSelector((state) => state.authoringSlice.selectedTestcaseItem);
  const { mutate: saveTestCaseSettings, isSuccess } = useSaveTestCaseSettingsQuery();
  const notify = useNotification();

  const [open, setOpen] = useState(false);
  const [selectedEnv, setSelectedEnv] = useState(selectedTestCase.envname);
  const [selectedBrowser, setSelectedBrowser] = useState(selectedTestCase.browser);
  const [selectedResolution, setSelectedResolution] = useState("");
  const [enableAudio, setEnableAudio] = useState(false);
  const [continueTest, setContinueTest] = useState(false);
  const [runOnce, setRunOnce] = useState(false);
  const [threshold, setThreshold] = useState("");
  const [proxyUrl, setProxyUrl] = useState("");
  const [envUrl, setEnvUrl] = useState("");

  useEffect(() => {
    if (selectedTestCase) {
      setSelectedEnv(selectedTestCase?.envname ?? "");
      setSelectedBrowser(selectedTestCase?.browser ?? "");
      setEnableAudio(selectedTestCase?.enableaudio ?? false);
      setContinueTest(selectedTestCase?.continuetest ?? false);
      setRunOnce(selectedTestCase?.multirun === 0 ? true : false);
      setThreshold(selectedTestCase?.testcasethreshold ?? "");
      setProxyUrl(selectedTestCase?.proxyurl ?? "");
      setEnvUrl(selectedTestCase?.envUrl ?? "");
    }
  }, [selectedTestCase]);

  useEffect(() => {
    if (isSuccess) {
      notify("Settings changes saved successfully", "success");
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEnvChange = (event) => {
    setSelectedEnv(event.target.value);
  };

  const handleBrowserChange = (event) => {
    setSelectedBrowser(event.target.value);
  };

  const handleResolutionChange = (event) => {
    setSelectedResolution(event.target.value);
  };

  const handleSave = () => {
    const payload = {
      EnvironmentName: selectedEnv,
      Browser: selectedBrowser,
      Resolution: selectedResolution,
      RunOnce: runOnce ? 0 : 1,
      Threshold: threshold,
      Proxy: proxyUrl,
      testCaseId: selectedTestCase.idtest_case,
      enablevideo: enableAudio,
      continuetest: continueTest,
    };

    saveTestCaseSettings({ testCaseId: selectedTestCase.idtest_case, payload });
  };

  return (
    <Box>
      <IconContainer handleClick={handleClickOpen}>
        <ScenarioIcon color="#444141" />
      </IconContainer>
      <CustomDialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <PageTitle title={"Test Scenario Settings"} variant={"h5"} />
        <DialogContent pt={2} sx={{ width: "100%" }}>
          <Box>
            <StyledLabel>Environment</StyledLabel>
            <Box>
              {EnvOptions.map((option) => (
                <PillRadio
                  key={option.value}
                  value={option.value}
                  label={option.label}
                  checked={selectedEnv === option.value}
                  onChange={handleEnvChange}
                />
              ))}
            </Box>
            <Box pt={2}>
              <CustomInput value={envUrl} onChange={(e) => setEnvUrl(e.target.value)} />
            </Box>
            <Box pt={2}>
              <StyledLabel>Browser</StyledLabel>
              <Box>
                {BrowserOptions.map((option) => (
                  <PillRadio
                    key={option.value}
                    value={option.value}
                    label={option.label}
                    checked={selectedBrowser === option.value}
                    onChange={handleBrowserChange}
                  />
                ))}
              </Box>
            </Box>
            <Box pt={2}>
              <StyledLabel>Screen Resolution</StyledLabel>
              <Box>
                {ResolutionOptions.map((option) => (
                  <PillRadio
                    key={option.value}
                    value={option.value}
                    label={option.label}
                    checked={selectedResolution === option.value}
                    onChange={handleResolutionChange}
                  />
                ))}
              </Box>
            </Box>
            <Box pt={2}>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={runOnce}
                    onChange={() => setRunOnce((prev) => !prev)}
                  />
                }
                label="Run Once"
                labelPlacement="end"
              />
            </Box>
            <Box pt={2} display={"flex"}>
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={continueTest}
                      onChange={() => setContinueTest((prev) => !prev)}
                    />
                  }
                  label="Continue Previous Scenario"
                  labelPlacement="end"
                />
              </Box>
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={enableAudio}
                      onChange={() => setEnableAudio((prev) => !prev)}
                    />
                  }
                  label="Enable Audio/Video"
                  labelPlacement="end"
                />
              </Box>
            </Box>
            <Box pt={2}>
              <CustomInput
                labelinput="Threshold"
                value={threshold}
                onChange={(e) => setThreshold(e.target.value)}
              />
            </Box>
            <Box pt={2}>
              <CustomInput
                labelinput="Proxy URL"
                value={proxyUrl}
                onChange={(e) => setProxyUrl(e.target.value)}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions display={"flex"} sx={{ width: "100%" }}>
          <StyledButton
            sx={{
              color: "#ff5336",
            }}
            variant="text"
            color="warning"
            onClick={handleClose}
          >
            Cancel
          </StyledButton>
          <StyledButton
            variant="contained"
            color="warning"
            sx={{
              backgroundColor: "#ff5336",
              color: "#ffffff",
              boxShadow: "0px 16px 30.6px 0px rgba(255, 83, 54, 0.27)",
            }}
            onClick={handleSave}
            endIcon={<BookmarkBorderIcon />}
          >
            Save
          </StyledButton>
        </DialogActions>
      </CustomDialog>
    </Box>
  );
};

export default Settings;
