import React, { useState, useEffect } from "react";

import { useNavigate, useParams, useLocation, useSearchParams } from "react-router-dom";

import { Avatar, TextField, Link, Grid, Typography } from "@mui/material";

import {
  ColorButton,
  PrimaryColor,
  SecondaryColor,
} from "./../../components/common/common.Component";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import { sendUserInfo } from "./../../API/UserApi";
import { getCompanyName } from "../../API/CreateCollectionAPI";
import { CommonMethods } from "./../../components/common/CommonMethods";
const SignUpPage = () => {
  const [account, setAccount] = useState({
    status: 0,
    password: "",
    email: "",
    firstname: "",
    lastname: "",
    company: "",
    phonenumber: "",
    confirmpassword: "",
    product: "",
    module: "",
  });
  const navigate = useNavigate();
  const [ searchParams ] = useSearchParams();
  const [companyName, setCompanyName] = useState([]);
  const [showModuleProduct, setShowModuleProduct] = useState(true)
  const location = useLocation();
  const cname = searchParams.get("cname");
  const codegen = searchParams.get("codegen");
  useEffect(() => {
    if (cname !== null) {
      console.log("cname", cname)
      getCompanyNameFromUrl(cname);
    } 
    else if (codegen) {
      console.log("codegen", codegen)
    }
  }, [cname, codegen])

  const getCompanyNameFromUrl = async (id) => {
    await getCompanyName(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === 'fail') {
            CommonMethods(navigate);
          } else {
            setCompanyName(response.data);
            setShowModuleProduct(false);
          }

        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handelAccount = (property, event) => {
    setAccount({ ...account, [property]: event.target.value });
  };
  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const validatePassword = (password) => {
    return password.match(
      /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{8,16}$/
    );
  };

  const validateName = (name) => {
    return name.match(/^[^<>]*$/);
  };
  const validatePhoneNumber = (phonenumber) => {
    return phonenumber.match(/^(\d{3})[- ]?(\d{3})[- ]?(\d{4})$/);
  };

  const createUser = async (account) => {
    if (
      account.password === "" ||
      account.email === "" ||
      account.firstname === "" ||
      account.lastname === "" ||
      
      account.phonenumber === "" ||
      account.confirmpassword === ""       
    ) {
      console.log("field cant be empty");
    } else if(
      (cname === null && codegen === null) && 
      (account.company === "" || 
      account.product === "" ||
      account.module === "")
    ){
      console.log("field cant be empty");
    } else {
      if (account.confirmpassword !== account.password) {
        console.log("confirm password and password should be same");
      } else if (!validateName(account.firstname)) {
        console.log("Enter a valid Firstname");
      } else if (!validateName(account.lastname)) {
        console.log("Enter a valid Lastname");
      } else if (!validateEmail(account.email)) {
        console.log("Enter a valid Email");
      } else if (!validatePassword(account.password)) {
        console.log("Enter a strong password");
      } else {
        await sendUserInfo({
          status: account.status,
          password: account.password,
          email: account.email,
          firstname: account.firstname,
          lastname: account.lastname,
          company: account.company,
          phonenumber: account.phonenumber,
          product: account.product,
          module: account.module,
        })
          .then((response) => {
            if (response.status === 200) {
              let user = {
                email: account.email,
                firstname: account.firstname,
                lastname: account.lastname,
                company: account.company,
                phonenumber: account.phonenumber,
              };

              // storing input from register-form
              let json = JSON.stringify(user);
              localStorage.setItem("userDetail", json);
              console.log("USER ADDED");
              setAccount({
                status: 0,
                password: "",
                email: "",
                firstname: "",
                lastname: "",
                company: "",
                phonenumber: "",
                confirmpassword: "",
                product: "",
                module: "",
              });
              navigate("/LoginPage");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const createUserFromurl = async (account) => {

    if (
      account.password === "" ||
      account.email === "" ||
      account.firstname === "" ||
      account.lastname === "" ||
      // account.company === "" ||
      account.phonenumber === "" ||
      account.confirmpassword === ""
      // account.product === "" ||
      // account.module === ""
    ) {
      console.log("field cant be emptys");
    } else {
      if (account.confirmpassword !== account.password) {
        console.log("confirm password and password should be same");
      } else if (!validateName(account.firstname)) {
        console.log("Enter a valid Firstname");
      } else if (!validateName(account.lastname)) {
        console.log("Enter a valid Lastname");
      } else if (!validateEmail(account.email)) {
        console.log("Enter a valid Email");
      } else if (!validatePassword(account.password)) {
        console.log("Enter a strong password");
      } else {
        await sendUserInfo({
          status: account.status,
          password: account.password,
          email: account.email,
          firstname: account.firstname,
          lastname: account.lastname,
          company: companyName.companyname,
          phonenumber: account.phonenumber,
          // product: account.product,
          // module: account.module,
        })
          .then((response) => {
            if (response.status === 200) {
              let user = {
                email: account.email,
                firstname: account.firstname,
                lastname: account.lastname,
                company: companyName.companyname,
                phonenumber: account.phonenumber,
              };

              // storing input from register-form
              let json = JSON.stringify(user);
              localStorage.setItem("userDetail", json);
              console.log("USER ADDED");
              setAccount({
                status: 0,
                password: "",
                email: "",
                firstname: "",
                lastname: "",
                company: "",
                phonenumber: "",
                confirmpassword: "",
                // product: "",
                // module: "",
              });
              navigate("/LoginPage");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const createCodegenUser = async (account) => {

    if (
      account.password === "" ||
      account.email === "" ||
      account.firstname === "" ||
      account.lastname === "" ||
      account.company === "" ||
      account.phonenumber === "" ||
      account.confirmpassword === ""
    ) {
      console.log("field cant be emptys");
    } else {
      if (account.confirmpassword !== account.password) {
        console.log("confirm password and password should be same");
      } else if (!validateName(account.firstname)) {
        console.log("Enter a valid Firstname");
      } else if (!validateName(account.lastname)) {
        console.log("Enter a valid Lastname");
      } else if (!validateEmail(account.email)) {
        console.log("Enter a valid Email");
      } else if (!validatePassword(account.password)) {
        console.log("Enter a strong password");
      } else {
        await sendUserInfo({
          status: account.status,
          password: account.password,
          email: account.email,
          firstname: account.firstname,
          lastname: account.lastname,
          company: account.company,
          phonenumber: account.phonenumber,
          gentype: "code",
          codegen:codegen
        })
          .then((response) => {
            if (response.status === 200) {
              let user = {
                email: account.email,
                firstname: account.firstname,
                lastname: account.lastname,
                company: account.company,
                phonenumber: account.phonenumber,
              };

              // storing input from register-form
              let json = JSON.stringify(user);
              localStorage.setItem("userDetail", json);
              console.log("USER ADDED");
              setAccount({
                status: 0,
                password: "",
                email: "",
                firstname: "",
                lastname: "",
                company: "",
                phonenumber: "",
                confirmpassword: "",
              });
              navigate("/LoginPage");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  return (
    <Grid
      container
      component="main"
      style={{
        marginLeft: 0,
        marginTop: 64,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundColor: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
        item
        xs={12}
        sm={8}
        md={5}
        // component={Paper}
        elevation={1}
        square="true"
      >
        <Typography
          variant="h4"
          nowrap
          component="div"
          sx={{ marginBottom: 4, display: "flex" }}
        >
          Nogrunt
          <Avatar
            style={{
              marginLeft: 4,
              color: SecondaryColor,
              backgroundColor: PrimaryColor,
            }}
          >
            <LockOutlinedIcon color="inherit" />
          </Avatar>
        </Typography>
        <Typography component="h1" variant="h5" marginBottom={4}>
          Sign up &
          <Link
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.open(
                "https://chrome.google.com/webstore/detail/nogrunt-qa-automation/neankkcidljlljifcclgcecadeimmolp",
                "_blank"
              );
            }}
            variant="body2"
          >
            <Typography
              component="h1"
              variant="h5"
              marginBottom={2}
              style={{ fontWeight: "bold" }}
            >
              {"Get The Extension "}
            </Typography>
          </Link>
          <Link
            style={{ cursor: "pointer" }}
            onClick={() => {
              window.open(
                "https://www.loom.com/share/c8941d2c1d4b428dabcb30374219124f",
                "_blank"
              );
            }}
            variant="body2"
          >
            <Typography
              component="h1"
              variant="h5"
              marginBottom={2}
              style={{ fontWeight: "bold" }}
            >
              {"See Nogrunt in Action"}
            </Typography>
          </Link>
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              onChange={(event) => handelAccount("firstname", event)}
              autoComplete="fname"
              name="firstname"
              variant="outlined"
              required
              fullWidth
              id="firstname"
              label="First Name"
              autoFocus
              error={
                !validateName(account.firstname) && account.firstname.length > 0
              }
              helperText={
                !validateName(account.firstname) && account.firstname.length > 0
                  ? "Invalid FirstName"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              onChange={(event) => handelAccount("lastname", event)}
              variant="outlined"
              required
              fullWidth
              id="lastname"
              label="Last Name"
              name="lastname"
              autoComplete="lname"
              error={
                !validateName(account.lastname) && account.lastname.length > 0
              }
              helperText={
                !validateName(account.lastname) && account.lastname.length > 0
                  ? "Invalid LastName"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {
              cname != undefined ? (
                <TextField
                  onChange={(event) => handelAccount("company", event)}
                  autoComplete="Company Name"
                  name="CompanyName"
                  variant="outlined"
                  value={companyName.companyname}
                  required
                  fullWidth
                  readOnly
                  id="company"
                  label="Company Name"
                />
              ) : (
                <TextField
                  onChange={(event) => handelAccount("company", event)}
                  autoComplete="Company Name"
                  name="CompanyName"
                  variant="outlined"
                  required
                  fullWidth
                  id="company"
                  label="Company Name"
                />
              )
            }
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              onChange={(event) => handelAccount("phonenumber", event)}
              variant="outlined"
              required
              fullWidth
              id="phonenumber"
              label="Phone Number"
              name="phonenumber"
              error={
                !validatePhoneNumber(account.phonenumber) &&
                account.phonenumber.length > 0
              }
              helperText={
                !validatePhoneNumber(account.phonenumber) &&
                  account.phonenumber.length > 0
                  ? "Invalid Phone number"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={(event) => handelAccount("email", event)}
              variant="outlined"
              required
              fullWidth
              id="email"
              label="Email Address "
              name="email"
              // autoComplete="email"

              error={!validateEmail(account.email) && account.email.length > 0}
              helperText={
                !validateEmail(account.email) && account.email.length > 0
                  ? "Invalid Email"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={(event) => handelAccount("password", event)}
              variant="outlined"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              // autoComplete="current-password"

              error={
                !validatePassword(account.password) &&
                account.password.length > 0
              }
              helperText={
                !validatePassword(account.password) &&
                  account.password.length > 0
                  ? "Invalid Password"
                  : ""
              }
            />
          </Grid>
          <Grid item xs={10} ml={5}>
            <p>
              - Password should contain 1 Uppercase, 1 Lowercase, 1 Digit, 1
              Special Character and 8 to 16 Characters long.{" "}
            </p>
          </Grid>
          <Grid item xs={10} ml={5}>
            <p>- Do not include &lt; and &gt; in Firstname and Lastname </p>
          </Grid>
          <Grid item xs={12}>
            <TextField
              onChange={(event) => handelAccount("confirmpassword", event)}
              variant="outlined"
              required
              fullWidth
              name="confirmpassword"
              label="Confirm Password"
              type="password"
              id="confirmpassword"
            />
          </Grid>
          {
            cname === null && codegen === null ? (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    onChange={(event) => handelAccount("product", event)}
                    autoComplete="Product Name"
                    name="product"
                    variant="outlined"
                    required
                    fullWidth
                    id="product"
                    label="Product Name"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    onChange={(event) => handelAccount("module", event)}
                    variant="outlined"
                    required
                    fullWidth
                    id="module"
                    label="Module Name"
                    name="module"
                  />
                </Grid>
              </>
            ) : null
          }
        </Grid>
        {
          codegen === null ? (
            <ColorButton
              size="small"
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ marginY: 3 }}
              onClick={() => {
                createUser(account);
              }}
            >
              Sign Up
            </ColorButton>
          ) : (
            <ColorButton
              size="small"
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ marginY: 3 }}
              onClick={() => {
                if(cname === undefined ){
                createUserFromurl(account);
                } else {
                  createCodegenUser(account);
                }
              }}
            >
              Sign Up
            </ColorButton>
          )
        }

        <Link
          style={{ marginTop: 2, cursor: "pointer" }}
          onClick={() => {
            navigate("/LoginPage");
          }}
          variant="body2"
        >
          {"Already have an account? Sign in"}
        </Link>
      </Grid>
    </Grid>
  );
};
export default SignUpPage;
