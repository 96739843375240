import React from "react";
import { Box } from "@mui/material";
import ExecutionTestcaseRunChart from "./ExecutionTestcaseRunChart";
import ExecutionGuageChart from "./ExecutionGuageChart";
import ExecutionLast5ExeTrends from "./ExecutionLast5ExeTrends";

const ExecutionSection = () => {
    return (
        <Box mt={'36px'}>
            <ExecutionTestcaseRunChart />
            <Box display={'flex'} columnGap={3}>
                <ExecutionGuageChart />
                <ExecutionLast5ExeTrends/>
            </Box>
        </Box>
    )
};

export default ExecutionSection;