import { Box, Link, styled, Typography } from "@mui/material";
import { StatusCard } from "../../components-new/common";
import { CalendarIcon, InfoIcon, TimeIcon } from "../../components-new/icons";
import ChromeIcon from "../../components-new/icons/ChromeIcon";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import LabelIcon from '@mui/icons-material/Label';

const StatusContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  border: 1px solid #eeee;
  padding: 15px;
  background: #ffff;
}
`

const StatusBar = (item) => {
  const data = item.data
  return (
    <StatusContainer >
      <Box display="flex" columnGap={5} flex={2}>
        <StatusCard
          icon={<CalendarIcon />}
          title={"Run Date"}
          value={data?.Executed_Date}
        />
        <StatusCard
          icon={<LabelIcon style={{ color: "#FF5336" }} />}
          title={"Name"}
          value={data?.testcasename}
        />
        <StatusCard icon={<TimeIcon />} title={"Run Time"} value={data?.DurationSum} />
        <StatusCard icon={<InfoIcon />} title={"Status"} value={data?.Status} />
        <StatusCard icon={<ChromeIcon />} title={"Browser Type"} value={data?.Browser} />
        <StatusCard icon={<FilePresentIcon />} title={"LogFile"} value={<Typography
          component={Link}
          href={data?.logFile}
          underline="always"
          color="inherit"
          target="_blank"
        >
          Log File
        </Typography>} />
      </Box>
    </StatusContainer>
  );
};

export default StatusBar;
