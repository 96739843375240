import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

const fetchTop5Authors = async (dateRange) => {
    const userDetails = localStorage.getItem("userDetail");
    if (!userDetails) throw new Error("No user details found in localStorage");
    const user = JSON.parse(userDetails);
  
    // Build the query string conditionally based on the dateRange
    let queryParams = `action=getTop5&companyid=${user.companyid}&token=${user.token}`;
    if (dateRange.fromDate && dateRange.toDate) {
      queryParams += `&fromDate=${dateRange.fromDate}&toDate=${dateRange.toDate}`;
    }
  
    const response = await axios.get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?${queryParams}`
    );
  
    return response.data;
};

const useGetTop5AuthorsQuery = (dateRange, options) => {
  return useQuery({
    queryKey: ["getTop5"],
    queryFn: () => fetchTop5Authors(dateRange),
    retry: true,
    enabled: false,
    cacheTime: 0,
    refetchIntervalInBackground: false,
    staleTime: 0,
  });
};

export default useGetTop5AuthorsQuery;
