// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-color .css-v4u5dn-MuiInputBase-root-MuiInput-root {
  font-size: 18 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/ReportPage/Report.scss"],"names":[],"mappings":"AAEI;EAEA,wBAAA;AAFJ","sourcesContent":["\n.input-color {\n    .css-v4u5dn-MuiInputBase-root-MuiInput-root{ \n    // color: #0fc6ee !important; \n    font-size: 18 !important;\n}   \n  }\n\n// .date-picker{\n//     .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root,\n//     .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root,.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{\n//     // color: #0fc6ee !important;\n//     }\n//     .css-1d3z3hw-MuiOutlinedInput-notchedOutline{\n//         border-color: #0fc6ee !important;\n//     }\n// }  \n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
