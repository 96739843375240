import React, { useEffect } from "react";
import "./UserPage.style.scss";

import { Box, Card, Typography, FormControl, InputLabel, Select, MenuItem } from "@mui/material";

import {
  PrimaryColor,
  SecondaryColor,
  BootstrapTooltip,
  ColorButton,
} from "./../../components/common/common.Component";
import { Button, TextField, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { CommonMethods } from "./../../components/common/CommonMethods";
import { useNavigate } from "react-router-dom";

import Dialog from "@mui/material/Dialog";
import { useState } from "react";

import { changePassword } from "./../../API/UserApi";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const UserPage = () => {
  const [ProductOpenDialog, setProductOpenDialog] = useState(false);

  const handleProductClickOpen = () => {
    setProductOpenDialog(true);
  };
  const handleProductClose = () => {
    setProductOpenDialog(false);
  };

  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const [account, setAccount] = useState({
    Username: temp.username,
    CurrentPassword: "",
    NewPassword: "",
    RENewPassword: "",
  });

  const navigate = useNavigate();

  const handelAccount = (property, event) => {
    setAccount({ ...account, [property]: event.target.value });
  };

  const handelLogin = async () => {
    if (account.NewPassword === account.RENewPassword) {
      await changePassword(account)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.message === "fail") {
              CommonMethods(navigate);
            } else {
              if (response.data.message === "success") {
                let user = {
                  email: "",
                  firstname: "",
                  lastname: "",
                  company: "",
                  phonenumber: "",
                };

                // storing input from register-form
                let json = JSON.stringify(user);
                localStorage.setItem("userDetail", json);
                console.log("USER remove");
                navigate("/LoginPage");
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const AddProduct = () => (
    <>
      <ColorButton size="small" variant="contained" sx={{ mb: 2 }} onClick={handleProductClickOpen}>
        Change Password
      </ColorButton>
      <BootstrapDialog
        onClose={handleProductClose}
        aria-labelledby="customized-dialog-title"
        open={ProductOpenDialog}
        className="custom-dialog"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleProductClose}>
          Change Password
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <form
            style={{
              width: "100%", // Fix IE 11 issue.
              marginTop: 1,
            }}
            noValidate
          >
            <TextField
              // onChange={(event) => handelAccount("Username", event)}
              disabled={true}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="Username"
              label="Username / Email"
              name="email"
              autoFocus
              value={temp.username}
            />
            <TextField
              onChange={(event) => handelAccount("CurrentPassword", event)}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="CurrentPassword"
              label="Current Password"
              id="CurrentPassword"
              autoComplete="current-password"
              type="password"
            />
            <TextField
              onChange={(event) => handelAccount("NewPassword", event)}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="NewPassword"
              label="New Password"
              id="NewPassword"
              autoComplete="current-NewPassword"
              type="password"
            />
            <TextField
              onChange={(event) => handelAccount("RENewPassword", event)}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="RENewPassword"
              label="Re Enter Password"
              type="password"
              id="NewPassword"
              autoComplete="current-NewPassword"
            />

            <ColorButton
              size="small"
              // type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ marginY: 3 }}
              onClick={handelLogin}
            >
              Change Password
            </ColorButton>
          </form>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleProductClose}>
            Cancel
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        alignItem: "center",
        justifyContent: "space-evenly",
        paddingTop: "2rem",
        flexDirection: "column",
        paddingX: "2rem",
      }}
    >
      <Card
        sx={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          paddingY: "1.5rem",
          backgroundColor: SecondaryColor,
          color: PrimaryColor,
        }}
      >
        <Typography paddingBottom={0} variant="h6" component="h2">
          User Page
        </Typography>
      </Card>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          flexDirection: "column",
        }}
      >
        {AddProduct()}
      </Box>
    </Box>
  );
};

export default UserPage;
