import React, { useEffect, useState } from "react";
import "./TopCards.style.scss";

import { Box } from "@mui/material";

import GraphCard from "./GraphCards.component";
import { API_BASE_PATH, API_BASE_RELATIVE_PATH } from "./../../Config/Config";

const TopCards = () => {
  const [state, setState] = useState({
    testCasesCount: 0,
    testStepExecuted: 0,
    stepsPassCount: 0,
    totalDuration: 0,
  });
  
const userDetails = localStorage.getItem("userDetail");    
const temp = JSON.parse(userDetails);

  const fetchTopCardsData = () => {
    document.title = "Nogrunt - Test Automation";
    let apicall = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=dashboard&companyid=${temp?.companyid}&token=${temp?.token}`;
    fetch(apicall)
      .then((results) => {
        return results.json();
      })
      .then((data) => {
        setState({
          testCasesCount: data.testCaseCount,
          testStepExecuted: data.testStepsCount,
          stepsPassCount: data.stepsPassCount,
          totalDuration: data.totalDuration,
          testSuiteCount: data.testSuiteCount,
          totalSuiteRuns: data.totalSuiteRuns,
          suitesPassCount: data.suitesPassCount,
        });
      });
  };
  useEffect(() => {
    fetchTopCardsData();
  }, []);

  // const result = (
  //   this.state.totalDuration /
  //   (this.state.testStepExecuted * 1000)
  // ).toFixed(1);
  // const fail = this.state.testStepExecuted - this.state.stepsPassCount;

  return (
    <>
    <Box
      sx={{
        display: "flex",
        flex: 1,
        alignItem: "center",
        justifyContent: "space-between",
      }}
    >
    <GraphCard title="TOTAL TEST SUITES" count={state.testSuiteCount} />
    <GraphCard title="TEST SUITE RUNS" count={state.totalSuiteRuns} />
    <GraphCard title="TEST SUITES PASSED" count={state.suitesPassCount} />
    <GraphCard title="AVG STEP DURATION" count={state.testStepExecuted ? (state.totalDuration/state.testStepExecuted).toFixed(2) : '0.00'}  />
     </Box>  <Box
      sx={{
        display: "flex",
        flex: 1,
        alignItem: "center",
        justifyContent: "space-between",
        marginTop:"1rem",
      }}
    >
    <GraphCard title="TOTAL TEST CASES" count={state.testCasesCount} />
    <GraphCard title="TOTAL STEPS EXECUTED" count={state.testStepExecuted} />
    <GraphCard title="STEPS PASS COUNT" count={state.stepsPassCount} />
    <GraphCard title="AVG STEP DURATION" count={state.testStepExecuted ? (state.totalDuration/state.testStepExecuted).toFixed(2) : '0.00'} />
     </Box></>
  );
};

export default TopCards;
