import React from "react";

const AnalysisIcon = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="17" height="14" viewBox="0 0 17 14" fill="none">
  <path d="M5.87064 3.72639L10.2736 14L12.9587 7.73383H16.1443V6.26617H11.9915L10.2736 10.2736L5.87064 0L3.18555 6.26617H0V7.73383H4.15274L5.87064 3.72639Z" fill="#242F3F"/>
</svg>

);

export default AnalysisIcon;
