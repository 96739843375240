// File to house the Form that will contain the inputs for sending to the endpoint.

import React, { useState, useEffect } from "react";
import CodeMirror from "react-codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/abcdef.css";
import "codemirror/theme/eclipse.css";
import "codemirror/lib/codemirror";
import "codemirror/theme/mbo.css";
import "codemirror/mode/javascript/javascript";
import "codemirror/addon/hint/show-hint";
import "codemirror/addon/hint/javascript-hint";
import "codemirror/addon/hint/show-hint.css";
import "codemirror/keymap/sublime";
import "codemirror/addon/edit/closebrackets";
import "codemirror/addon/edit/closetag";
import "codemirror/addon/fold/foldcode";
import "codemirror/addon/fold/foldgutter";
import "codemirror/addon/fold/brace-fold";
import "codemirror/addon/fold/comment-fold";
import "codemirror/addon/fold/foldgutter.css";
import "codemirror/addon/lint/lint.css";
import "codemirror/addon/lint/lint";
import "codemirror/addon/lint/javascript-lint";
import "codemirror/addon/runmode/colorize.js";
import { JSHINT } from 'jshint';

import "codemirror/mode/javascript/javascript";


const Form = (props) => {
  const [jsontoSend, setjsontoSend] = useState(props.paneValue);
  const handleChange = (newJSON) => {
    setjsontoSend(newJSON);
    props.setPaneValue(newJSON)
  };
  useEffect(()=>{
    setjsontoSend(props.paneValue)
  },[props.paneValue])

  const textboxOptions = {
    mode: {
      name: "javascript",
      json: true,
      statementIndent: 2
    },
    lineNumbers: true,
    smartIndent: true,
    indentWithTabs: true,
    tabindex: 4,
    autoCloseBrackets: true,
    linerWrapping: true,
    lint: true,
    theme: "material",
    lineWrapping: true,
    foldGutter: true,
    gutters: [
      "CodeMirror-linenumbers",
      "CodeMirror-foldgutter",
      "CodeMirror-lint-markers",
    ],
    autoCloseTags: true,
    keyMap: "sublime",
    matchBrackets: true,
  };

 return (
    <CodeMirror
      options={textboxOptions}
      value={jsontoSend}
      onChange={handleChange}
      autoSave
    />
  );
};

Form.defaultProps = {
  handleSubmit: () => {},
};

export default Form;
