import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_BASE_PATH, API_BASE_RELATIVE_PATH } from "../Config/Config";
import axios from "axios";
import { useNotification } from "../hooks";

const deleteTestCaseFromTestSuite = async ({ id, selectedcases }) => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetails);

  const response = await axios
    .post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=deleteTCfromTSuite&companyid=${user.companyid}&token=${user.token}&idtest_suite=${id}`,
      selectedcases,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });

  return response;
};

const useDeleteTestCaseFromTestSuiteQuery = () => {
  const queryClient = useQueryClient();
  const notify = useNotification();

  return useMutation({
    mutationFn: deleteTestCaseFromTestSuite,
    onSuccess: () => {
      queryClient.invalidateQueries([""]);
      notify("Test case deleted", "success");
    },
    onError: (error) => {
      console.log("Error deleting", error.message);
    },
  });
};

export default useDeleteTestCaseFromTestSuiteQuery;
