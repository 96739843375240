import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { ScreenshotIcon, EditIcon, SynopsisIcon } from "../../components-new/icons";
import EditTabContainer from "./EditTabContainer";
import Synopsis from "./Edit/Synopsis";
import { fetchResultInRecording } from "../../API/RecordingTableAPI";
import Screenshot from "./Edit/Screenshot";
import TabComponent from "../../components-new/common/TabComponent";

const EditTabs = ({ editItem }) => {
  const [valuesSavedResult, setValuesSavedResult] = useState([]);

  const getResults = async (api_input) => {
    await fetchResultInRecording(api_input)
      .then((response) => {
        if (response && response.data) {
          if (response.status === 200) {
            if (response.data.message === "fail") {
              console.log("error");
            } else {
              setValuesSavedResult(Object.values(response.data));
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getResults(editItem.Test_Case_Id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tabs = [
    {
      icon: <ScreenshotIcon />,
      label: "Screenshot",
      content: <Screenshot />,
    },
    {
      icon: <EditIcon />,
      label: "Edit",
      content: <EditTabContainer item={editItem} />,
    },
    {
      icon: <SynopsisIcon />,
      label: "Synopsis",
      content: (
        <Synopsis
          valuesSavedResult={valuesSavedResult.filter(
            (values) => values.Test_Case_Results_Id === editItem.Test_Case_Id,
          )}
        />
      ),
    },
  ];

  return (
    <Box sx={{ width: "100%" }} mt={5}>
      <TabComponent tabs={tabs} />
    </Box>
  );
};

export default EditTabs;
