import { Box, styled, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { CustomSelect } from "../../components-new/common";
import { DurationOptions } from "../Studio/constants";
import { BarChart } from "../../components-new/charts";
import { lastWeekData } from "./Dummydata";
import { useGetAllAuthorsQuery, useGetCountByAuthorQuery } from "../../useQuery";

const ChartContainer = styled(Box)`
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0px -3px 17.1px 0px rgba(0, 0, 0, 0.07);
    margin-bottom: 36px;
    padding: 30px;
    flex: 1;
`;

const ChartWrapper = styled(Box)`
  .custom-data-label {
    position: relative;
    background-color: #fff;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #304758;
    border: 2px solid #304758;
  }

  .arrow_box {
    position: relative;
    border-radius: 9px;
    padding: 15px;
    box-shadow: 0px 2px 17.1px 0px #D1DBFF;
    font-size: 12px;
    font-weight: 400;
  }

  .pass-data { 
    color: #30CA17;
  }

  .fail-data { 
    color: #FF5336;
  }

  .arrow_box:after, .arrow_box:before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .arrow_box:after {
    border-color: rgba(85, 85, 85, 0);
    border-right-color: #D1DBFF;
    border-width: 10px;
    margin-top: -10px;
  }

  .arrow_box:before {
    border-color: rgba(0, 0, 0, 0);
    border-right-color: #D1DBFF;
    border-width: 13px;
    margin-top: -13px;
  }

  .apexcharts-tooltip {
    transform: translateX(10px) translateY(10px);
    overflow: visible !important;
    white-space: normal !important;
  }
`;

const DesignAuthoredChart = () => {
    const [user, setUser] = useState('');
    const [duration, setDuration] = useState('last week');
    const [dateRange, setDateRange] = useState({ fromDate: '', toDate: '' });

    const { data: allAuthors } = useGetAllAuthorsQuery();
    const { data, refetch, isSuccess } = useGetCountByAuthorQuery({ dateRange, author: user });

    useEffect(() => {
        if (allAuthors?.length > 0) {
            setUser(allAuthors[0]?.value);
            handleChangeDuration({ target: { value: 'last week' } });
        }
    }, [allAuthors]);

    useEffect(() => {
        if (user && dateRange.fromDate && dateRange.toDate) {
            refetch();
        }
    }, [user, dateRange, refetch]);

    const handleChangeUser = (event) => {
        setUser(event.target.value);
    };

    const handleChangeDuration = (event) => {
        const selectedDuration = event.target.value;
        const today = new Date();
        let fromDate;
        let toDate = today.toISOString().split('T')[0]; // Format: YYYY-MM-DD
    
        switch (selectedDuration) {
          case 'last week':
            fromDate = new Date();
            fromDate.setDate(today.getDate() - 7);
            fromDate = fromDate.toISOString().split('T')[0];
            break;
          case 'last 15 days':
            fromDate = new Date();
            fromDate.setDate(today.getDate() - 15);
            fromDate = fromDate.toISOString().split('T')[0];
            break;
          case 'last month':
            fromDate = new Date();
            fromDate.setMonth(today.getMonth() - 1);
            fromDate = fromDate.toISOString().split('T')[0];
            break;
          default:
            fromDate = '';
            toDate = '';
            break;
        }
    
        setDateRange({ fromDate, toDate });
        setDuration(selectedDuration);
    };

    const ChartOptions = {
        xaxis: {
            labels: {
                style: {
                    fontSize: '14px',
                    colors: '#8C9EB8',
                    fontWeight: '400',
                },
            },
        },
        yaxis: {
            labels: {
                show: false,
            },
        },
    };

    console.log(data, lastWeekData)

    return (
        <ChartContainer>
            <Box display="flex" alignItems="center">
                <Box display="flex" columnGap={3} alignItems={"center"}>
                    <Typography fontSize={'16px'} fontWeight={500} mt={'26px'}>
                        Test Case authored by
                    </Typography>
                </Box>
                <Box marginLeft={'17px'} minWidth={'330px'}>
                    <CustomSelect
                        label={"Select User"}
                        options={allAuthors ?? []}
                        width={"100%"}
                        background={"#ffffff"}
                        value={user}
                        onChange={handleChangeUser}
                    />
                </Box>
                <Box marginLeft={'17px'} minWidth={'170px'}>
                    <CustomSelect
                        label={"Duration"}
                        options={DurationOptions}
                        width={"100%"}
                        background={"#ffffff"}
                        value={duration}
                        onChange={handleChangeDuration}
                    />
                </Box>
            </Box>
            <ChartWrapper>
                {isSuccess && <BarChart
                    series={[{ name: 'Total', data: data?.map(d => d.pass + d.fail) }]}
                    categories={data?.map(item => item.day)}
                    title="Last Week Data"
                    height={350}
                    tooltipFormatter={(series, seriesIndex, dataPointIndex, w) => {
                        const { pass, fail } = data?.[dataPointIndex];
                        return `<div class="arrow_box">
                                <span class='pass-data'>Pass: ${pass}</span><br>
                                <span class='fail-data'>Fail: ${fail}</span>
                            </div>`;
                    }}
                    chartOptions={ChartOptions}
                />}
            </ChartWrapper>
        </ChartContainer>
    );
};

export default DesignAuthoredChart;
