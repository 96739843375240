import axios from "axios";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "./../Config/Config";

export const getTestData = async (id,product,moduleid,tcid,tsid) => {
    const userDetails = localStorage.getItem("userDetail");
    const temp = JSON.parse(userDetails);
    const Response = await axios
        .get(
       `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getFileUploads&companyid=${temp.companyid}&token=${temp.token}&company=${id}&prodid=${product}&modid=${moduleid}&tcid=${tcid}&tsid=${tsid}`
    ).then((response) => {
        return response;
    }).catch((error) => {
        console.log(error.response.data.error);
    })
    return Response;
}

export const getDownloadData = async () => {
    const userDetails = localStorage.getItem("userDetail");
    const temp = JSON.parse(userDetails);
    const Response = await axios
        .get(
       `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getListofCodes&companyid=${temp.companyid}&token=${temp.token}`
    ).then((response) => {
        return response;
    }).catch((error) => {
        console.log(error.response.data.error);
    })
    return Response;
}

export const refreshZip = async () => {
    const userDetails = localStorage.getItem("userDetail");
    const temp = JSON.parse(userDetails);
    const Response = await axios
        .get(
            `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=refreshzip&companyid=${temp.companyid}&token=${temp.token}`
        ).then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error.response.data.error);
        });
    return Response;
}
export const getFileData = async (productid) => {
     const userDetails = localStorage.getItem("userDetail");
    const temp = JSON.parse(userDetails);
    const Response = await axios
        .get(
            `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getfiledata&companyid=${temp.companyid}&token=${temp.token}&productid=${productid}`
        ).then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error.response.data.error);
        });
    return Response;
}

export const getFileCustomData = async () => {
    const userDetails = localStorage.getItem("userDetail");
   const temp = JSON.parse(userDetails);
   const Response = await axios
       .get(
           `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getListofScriptsFunction&companyid=${temp.companyid}&token=${temp.token}`
       ).then((response) => {
           return response;
       })
       .catch((error) => {
           console.log(error.response.data.error);
       });
   return Response;
}

export const deleteProductFIle = async (obj) => {
    const userDetails = localStorage.getItem("userDetail");
    const temp = JSON.parse(userDetails);
    const Response = await axios
        .post(
            `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=deleteProductFile&companyid=${temp.companyid}&token=${temp.token}`,
            obj
        ).then((response) => {
            return response;
        }).catch((error) => {
            console.log(error.response.data.error)
        });
    return Response;
}

export const scriptFileUpdated = async (obj) => {
    const userDetails = localStorage.getItem("userDetail");
    const temp = JSON.parse(userDetails);
    const Response = await axios
        .post(
            `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateScript&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${obj.testCaseId}&teststepid=${obj.teststepid}&name=${obj.scriptfile}&script=${obj.script}`,
            obj
        ).then((response) => {
            return response;
        }).catch((error) => {
            console.log(error.response.data.error)
        });
    return Response;
}

export const getCommandLineFilesData = async () => {
    const userDetails = localStorage.getItem("userDetail");
   const temp = JSON.parse(userDetails);
   const Response = await axios
       .get(
           `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getListofBatchFiles&companyid=${temp.companyid}&token=${temp.token}`
       ).then((response) => {
           return response;
       })
       .catch((error) => {
           console.log(error.response.data.error);
       });
   return Response;
}

export const commandLineUpdated = async (obj) => {
    const userDetails = localStorage.getItem("userDetail");
    const temp = JSON.parse(userDetails);
    const Response = await axios
        .post(
            `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateBatchFile&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${obj.testCaseId}&teststepid=${obj.teststepid}&name=${obj.cmdfile}`,
            obj
        ).then((response) => {
            return response;
        }).catch((error) => {
            console.log(error.response.data.error)
        });
    return Response;
}