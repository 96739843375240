import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

const fetchLatestResult = async () => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetails);


  const response = await axios.get(
    `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=latestResult&companyid=${user.companyid}&token=${user.token}`,
  );

  return response.data;
};

const useLastestResultQuery = (options) => {
  return useQuery({
    queryKey: ["latestResult"],
    queryFn: () => fetchLatestResult(),
    retry: true,
    enabled: options.enabled,
    refetchInterval: options.refetchInterval,
    cacheTime: 0,
    refetchIntervalInBackground: false,
    staleTime: 0,
  });
};

export default useLastestResultQuery;
