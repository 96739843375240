import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Tooltip, IconButton } from '@mui/material';
import { colorExport } from './../common/common.Component';
import { useParams, useNavigate } from 'react-router-dom';
import { CommonMethods } from '../common/CommonMethods';

import { getTreeStructurePageData } from '../../API/CoveragePageApi';
import { Network } from 'vis-network/standalone/esm/vis-network';

function CoveragePage() {
  const colors = colorExport();
  const navigate = useNavigate();
  const { product } = useParams();
  const [redirectToUrl, setRedirectToUrl] = useState(null);
  const [graph, setGraph] = useState({
    nodes: [],
    edges: [],
  });
  const domNode = useRef(null);
  const network = useRef(null);

  useEffect(() => {
    getSecondPageData(product);
  }, [product]);

  const handleBackClick = () => {
    navigate(`/dashboard/CoverageDashboard?product=${product}`);
  };


  const getSecondPageData = async (product) => {
    await getTreeStructurePageData(product)
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
          const nodesWithCustomLayout = calculateCustomLayout(response.data.nodes);
          // const edgesWithTooltips = dummyData.edges.map(edge => {
          //   const fromNode = nodesWithCustomLayout.find(node => node.id === edge.from);
          //   const toNode = nodesWithCustomLayout.find(node => node.id === edge.to);
            
          //   // Use the last line of the 'from' node as the tooltip for the edge
          //   const edgeTooltip = fromNode ? fromNode.arrowHover : '';
    
          //   return {
          //     ...edge,
          //     title: edgeTooltip // Add the last line as tooltip content
          //   };
          // });
          const edgesWithTooltips = response.data.edges.map(edge => {
            // Use the 'api' field from each edge as the tooltip
            const edgeTooltip = edge.api || 'No API data'; // Fallback text if API data is missing
  
            return {
              ...edge,
              title: edgeTooltip
            };
          });
          setGraph({
            nodes: nodesWithCustomLayout,
            edges: edgesWithTooltips,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Sample function to calculate custom positions for each node

  function htmlTitle(html) {
    const container = document.createElement("div");
    container.innerHTML = html;
    return container;
  }

  function replaceNewLinesWithBR(str) {
    return str.replace(/\n/g, "<br/>");
}

  function calculateCustomLayout(nodes) {
    let yPos = 0;
    let xPos = 0;

    return nodes.map((node, index) => {
      if (index % 2 === 0) {
        yPos = 100; // Even nodes at x = 100
      } else {
        yPos = 250; // Odd nodes at x = 300
      }
      xPos += 200; // Increment y position for each node

      // const title = node.label; // Full label for the tooltip
      // console.log("title",title)
      const processedLabel = replaceNewLinesWithBR(node.label);
      let title;
      let shape;
      let image;
  
      if (node.screenshotpath) {
        shape = 'image';
        image = node.screenshotpath;
        title = ` <div>
                    <strong>${processedLabel}</strong><br/>
                    <img src="${node.screenshotpath}" alt="Screenshot" style="max-width: 500px; max-height: 500px; border: 2px solid black;" />
                  </div>`;
      } else {
        shape = 'box';
        title = `<div><strong>${processedLabel}</strong></div>`;
      }
      let trimmedLabel = node.label;
      if (trimmedLabel.length > 80) {
        trimmedLabel = trimmedLabel.substring(0, 80) + '...';
      }
      return {
        ...node,
        label: trimmedLabel,
        title: htmlTitle(title),
        // arrowHover,
        x: xPos,
        y: yPos,
        shape: shape,
        image: image,
        size: 40,
        // color: {
        //   border: index % 2 === 0 ? 'blue' : 'green',
        // }, 
        // color: {
        //   border: index % 2 === 0 ? '#ADD8E6' : 'yellow',
        //   background: index % 2 === 0 ? '#ADD8E6' : 'yellow',
        // },
      };
    });
  }

  const options = {
    autoResize: true,
    height: '800px',
    width: '100%',
    nodes: {
      shape: 'image', // Specify that nodes should use images
      size: 40,   
      borderWidth: 2,
      color:{
        border: 'black'
      }
    },
    edges: {
      color: {
        color: 'red', 
        highlight: 'red', 
        hover: 'red', 
        inherit: false,
        opacity: 1.0
      },
      width: 2,
      arrows: {
        to: { enabled: true, scaleFactor: 0.4, type: 'arrow' }
      },
    },
    physics: {
      enabled: false,
    },
  };

  useEffect(() => {
    if (domNode.current) {
      network.current = new Network(domNode.current, graph, options);
      network.current.on("click", handleNodeClick);
    }

    return () => {
      if (network.current) {
        network.current.off("click", handleNodeClick);
      }
    };
  }, [domNode, network, graph, options]);

  const handleNodeClick = (event) => {
    const { nodes } = event;
    if (nodes.length > 0) {
      const selectedNodeId = nodes[0];
      const selectedNodeData = graph.nodes.find(
        (node) => node.id === selectedNodeId
      );
      if (selectedNodeData && selectedNodeData) {
        const screenshotPath = selectedNodeData.id;
        if (screenshotPath) {
        const baseUrl = "/dashboard/Screenshot/";
        const finalUrl = `${baseUrl}${screenshotPath}/${product}`;
        setRedirectToUrl(finalUrl);
        }
      }
    }
  };

  useEffect(() => {
    if (redirectToUrl) {
      window.location.href = redirectToUrl;
    }
  }, [redirectToUrl]);

  return (
    <Box>
      <Box style={{
        display: "flex",
        justifyContent: "flex-start",
        marginLeft: 10,
        padding: 10
      }}>
        <Tooltip title={"Back"}>
          <IconButton
            size="small"
            variant="contained"
            style={{
              color: colors.primaryColor,
              backgroundColor: colors.secondaryColor,
            }}
            onClick={handleBackClick}
          >
            <ArrowBackIcon style={{ fontSize: 25 }} />
          </IconButton>
        </Tooltip>
      </Box>
      <div ref={domNode} style={{ height: '800px' }} />
    </Box>
  );
}

export default CoveragePage;