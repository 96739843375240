import React from "react";
import { InputLabel, FormControl, Button, TextField } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { ColorButton, colorExport } from "./../common/common.Component";
import { CommonMethods } from "./../common/CommonMethods";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_BASE_PATH, API_BASE_RELATIVE_PATH } from "./../../Config/Config";
import { useNavigate } from "react-router-dom";
import "./RecordingTable.style.scss";
import {
    handleTDChange,
} from "./../../API/RecordingTableAPI";
import { getCommandLineFilesData, commandLineUpdated } from '../../API/TestDataApi';


const CommandLine = ({ setTestDataDetails, TestDataDetails, handleClose, actionValue, newTestStep, setNewTestStep }) => {

    const scriptListData = [
        { value: "selectCommandLine", name: "Select CommandLine" },
        { value: "uploadCommandLine", name: "Upload CommandLine" },
    ];

    const colors = colorExport();
    const navigate = useNavigate();
    const userDetails = localStorage.getItem("userDetail");
    const temp = JSON.parse(userDetails);
    const handleFileChange = (e) => {
        if (e.target.files) {
            setTestDataDetails((prevState) => ({
                ...prevState,
                file: e.target.files,
                filename: e.target.files[0].name,
            }));
        }
    };
    const [uploadFileName, setUploadFileName] = useState([]);

    useEffect(() => {
        getFileNewCustomData()
    }, [])

// Get List fo files    
    const getFileNewCustomData = async () => {
        await getCommandLineFilesData()
            .then((response) => {
                if (response.status === 200 && response.data.message === "fail") {
                    CommonMethods(navigate);
                } else {
                    setUploadFileName(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }
// Update db with batch file detail
    const getcommandLineUpdated = async (input) => {
        await commandLineUpdated(input)
            .then((response) => {
                if (response.status === 200 && response.data.message === "fail") {
                    CommonMethods(navigate);
                } else {
                    console.log("response.data.", response.data)
                    setUploadFileName(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

// Add batch file 
    const handleCommandlineUploadClick = (fromtestdatasource) => {
        if (!TestDataDetails.file) {
            return;
        }

        fetch(`${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=addBatchFile&companyid=${temp.companyid}&token=${temp.token}&testCaseId=${newTestStep.Test_Case_Id}&teststepid=${newTestStep.tsid}&name=${TestDataDetails.file[0].name}`, {
            method: "POST",
            body: TestDataDetails.file[0],
            headers: {
                "content-type": TestDataDetails.file[0].type,
                "content-length": `${TestDataDetails.file[0].size}`, // 👈 Headers need to be a string
            },
        })
            .then((res) => res.json())
            .then((data) => {
                if (fromtestdatasource) {
                    ChangeTableRowData(TestDataDetails);
                }
            })
            .catch((err) => console.error(err));
    };

    const ChangeTableRowData = async (api_input) => {

        await handleTDChange(api_input)
            .then((response) => {
                if (response.status === 200) {
                    // setloader(false);
                    if (response.data.message === 'fail') {
                        CommonMethods(navigate);
                    } else {

                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <>
            <FormControl>
                <InputLabel id={`cmd Option`}>
                    Command Line Options
                </InputLabel>
                <Select
                    labelId={`cmd Option`}
                    id={`cmd Option`}
                    value={newTestStep.script}
                    label="cmd Option"
                    name="cmd Option"
                    variant="standard"
                    onChange={(event) => {
                        setNewTestStep((prev) => {
                            return {
                                ...prev,
                                script: event.target.value,
                            };
                        });
                        if (event.target.value === "selectCommandLine") {
                            getFileNewCustomData()
                        }
                    }}
                    style={{ minWidth: 220, marginRight: "2rem", alignItems: "center" }}
                >

                    {scriptListData.map((item, index) => (
                        <MenuItem key={index} value={item.value}>
                            {item.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {newTestStep.script === "selectCommandLine" && (
                <>
                    <FormControl>
                        <InputLabel id={`Select Command Line`}>Select Cmd File</InputLabel>
                        <Select
                            variant="standard"
                            labelId={`Select cmd`}
                            id={`Select cmd`}
                            label="Select cmd"
                            value={newTestStep.cmdfile}
                            onChange={(event) => {
                                setNewTestStep((prev) => {
                                    return {
                                        ...prev,
                                        cmdfile: event.target.value,
                                    };
                                });
                            }}
                            sx={{ width: 180 }}

                        >
                            {uploadFileName.map((item, index) => (
                                <MenuItem key={index} value={item.FileName} >
                                    {item.FileName}
                                </MenuItem>
                            ))}

                        </Select>
                    </FormControl>

                    <Button
                        component="label"
                        size="medium"
                        onClick={() => {
                            console.log("________NewtestStep________________", newTestStep)
                            getcommandLineUpdated({
                                teststepid: newTestStep.tsid,
                                testCaseId: newTestStep.Test_Case_Id,
                                script: 'selectCmdFile',
                                cmdfile: newTestStep.cmdfile,
                            });
                            handleClose("Save");
                        }}
                        sx={{ marginLeft: 4, marginTop: 2 }}
                    >
                        Select
                    </Button>
                </>
            )}

            {newTestStep.script === "uploadCommandLine" && (
                <>
                    {!TestDataDetails.file && (
                        <TextField
                            sx={{ marginX: "2rem", width: "15%" }}
                            label="File Name"
                            variant="standard"
                            value={newTestStep.scriptfile}
                            disabled={true}
                        />
                    )}
                    <TextField
                        type="file"
                        id="commandline-file-upload"
                        onChange={(event) => {
                            handleFileChange(event)
                        }}
                        variant="standard"
                        sx={{ width: "20%", marginTop: "1rem" }}
                    />

                    <Button
                        component="label"
                        size="medium"
                        onClick={() => {
                            handleCommandlineUploadClick(true);
                            handleClose("Save");
                        }}
                        sx={{ marginLeft: 4, marginTop: 2 }}
                    >
                        Upload
                    </Button>
                </>
            )}
        </>
    );
}

export default CommandLine