import { useState, useEffect } from "react";

const useItemWidth = (containerRef, itemsPerRow, gap) => {
  const [itemWidth, setItemWidth] = useState(0);

  useEffect(() => {
    const updateItemWidth = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;

        const maxWidth = (containerWidth - (itemsPerRow - 1) * gap) / itemsPerRow;
        setItemWidth(maxWidth);
      }
    };

    updateItemWidth();
    window.addEventListener("resize", updateItemWidth);

    return () => {
      window.removeEventListener("resize", updateItemWidth);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef, containerRef.current, itemsPerRow, gap]);

  return itemWidth;
};

export default useItemWidth;
