import React from "react";

const ElementIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M16.95 8.4638L18.364 7.0498L23.314 11.9998L18.364 16.9498L16.95 15.5358L20.485 11.9998L16.95 8.4638ZM7.05004 8.4638L3.51504 11.9998L7.05004 15.5358L5.63604 16.9498L0.686035 11.9998L5.63604 7.0498L7.05004 8.4638Z"
      fill="currentColor"
    />
  </svg>
);

export default ElementIcon;
