import React, { useState, useEffect } from "react";
import LoaderSpinner from "./../common/LoaderSpinner";
import Box from "@mui/material/Box";
import { CommonMethods } from "../common/CommonMethods";
import { useLocation, useNavigate } from "react-router-dom";
import { colorExport, ColorButton } from "./../common/common.Component";
import { fetchProducts, fetchModules } from "../../API/CreateCollectionAPI";
import { getNewTestCase } from "../../API/TestCaseApi";
import { API_BASE_PATH, API_BASE_RELATIVE_PATH } from "../../Config/Config";
import { Grid } from "@mui/material"; // Import Grid component from Material-UI
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  IconButton,
  Typography,
  TableBody,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Alert from "@mui/material/Alert";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  height: "100%",
  "& .MuiInputBase-root": {
    height: "100%",
    alignItems: "flex-start", // Align text at the top
  },
  "& .MuiInputBase-inputMultiline": {
    height: "100%",
  },
  "& .MuiOutlinedInput-root": {
    height: "100%",
  },
}));

const getString = (obj) => {
  let result = "";
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      let value = obj[key].replace(/\\\//g, "/");
      result += value + "\n";
    }
  }
  return result;
};

const CreateTestCase = () => {
  const colors = colorExport();
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);

  const [productList, setProductList] = useState([]);
  const [ModuleList, setModuleList] = useState([]);
  const [product, setProduct] = useState("");
  const [module, setModule] = useState("");
  const [createNewTestCase, setCreateNewTestCase] = useState("");
  const [createNewTestCaseShow, setCreateNewTestCaseShow] = useState("");
  const [stepDetails, setStepDetails] = useState("");
  const [stepResults, setStepResults] = useState("");
  const [formattedResults, setFormattedResults] = useState("");
  const [loading, setLoading] = useState(false);
  const [enteredValue, setEnteredValue] = useState("");
  const [uploadnew, setUploadNew] = useState({
    file: "",
    filename: "",
  });
  const [openAlertForZip, setOpenAlertForZip] = useState(false);
  const [openAlertSave, setOpenAlertSave] = useState(false);
  const [toastState, setToastState] = useState(false);
  const [displayedValue, setDisplayedValue] = useState("");
  const [testCaseType, setTestCaseType] = useState("");

  useEffect(() => {
    getProduct();
  }, []);

  useEffect(() => {
    const fullText = formattedResults;
    let index = 0;
    let displayedText = "";

    const intervalId = setInterval(() => {
      if (index < fullText.length) {
        displayedText += fullText[index];
        setDisplayedValue(displayedText);
        index++;
      } else {
        clearInterval(intervalId);
      }
    }, 20);

    return () => clearInterval(intervalId);
  }, [formattedResults]);

  const handleClickOpenAlert = () => {
    setOpenAlertForZip(false);
  };

  const handleClickOpenSave = () => {
    setOpenAlertSave(false);
  };
  const getProduct = async () => {
    await fetchProducts()
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
          setProductList(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getModules = async (id) => {
    await fetchModules(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setModuleList(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChange = (event) => {
    setFormattedResults(event.target.value);
  };

  const submitWithStepDetails = async (product, module, createNewTestCase, stepDetails) => {
    setLoading(true); // Set loading to true when API call starts
    try {
      const response = await getNewTestCase(product, module, createNewTestCase, stepDetails);
      if (response.status === 200) {
        if (response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
          console.log("New Test Case Created");
          setStepDetails(stepDetails); // Update stepDetails with response data
          const entitiesObj = JSON.parse(response.data.nlp);
          setStepResults(entitiesObj);
          setCreateNewTestCase(response.data.testcasename);

          // const formattedData = Object.entries(entitiesObj).map(([stepIndex, stepData]) => {
          //   let formattedStepData = `Step ${stepIndex}\n`;

          //   if (Array.isArray(stepData)) {
          //     // Handle case when stepData is an array
          //     formattedStepData += stepData
          //       .map((entry) => {
          //         return `Sequence: ${entry.seq}, URL: ${entry.URL}`;
          //       })
          //       .join("\n");
          //   } else {
          //     // Handle case when stepData is an object
          //   //   formattedStepData += `Entities:\n${formatStepEntities(stepData.entities)}\n`;
          //   //   formattedStepData += `Transitions:\n${formatStepTransitions(stepData.transistions)}\n`;
          //   //   formattedStepData += `Actions:\n${formatStepActions(stepData.actions)}\n`;
          //     // formattedStepData += `{stepData}`;
          //     formattedStepData = getString(entitiesObj);
          //   }

          //   return formattedStepData;
          // });
          // formattedResults = getString(entitiesObj);
          // const formattedText = formattedData.join("\n\n");
          // console.log(formattedText);
          setFormattedResults(getString(entitiesObj));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // Set loading to false when API call completes (whether success or error)
    }
  };

  function formatStepTransitions(transitions) {
    if (!transitions || transitions.length === 0) {
      return "No transitions";
    }

    return transitions
      .map((transition) => {
        return `${transition.frompage} => ${transition.destpage}`;
      })
      .join("\n");
  }

  // Function to format actions for a given step
  function formatStepActions(actions) {
    if (!actions || actions.length === 0) {
      return "No actions";
    }

    return actions
      .map((action) => {
        return `${action.action} - ${action.label}`;
      })
      .join("\n");
  }

  // Function to format entities for a given step
  function formatStepEntities(entities) {
    if (!entities || entities.length === 0) {
      return "No entities";
    }

    return entities
      .map((entity) => {
        const attributes = entity.attributes
          .map((attr) => `${attr.name}: ${attr.value}`)
          .join(", ");
        return `${entity.entity} - ${attributes}`;
      })
      .join("\n");
  }

  const selectedProduct = (event) => {
    setProduct(event.target.value);
    getModules(event.target.value);
  };
  const selectedModule = (event) => {
    setModule(event.target.value);
  };

  const handleUploadNew = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileName = file.name;
      const extension = fileName.split(".").pop().toLowerCase();
      if (extension === "xlsx" || extension === "xls" || extension === "csv") {
        setUploadNew({
          file: e.target.files,
          filename: fileName,
        });
      } else {
        setOpenAlertForZip(true);
        e.target.value = null;
      }
    }
  };

  const getUploadFile = () => {
    fetch(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=uploadExcelFile&companyid=${temp.companyid}&token=${temp.token}&product=${product}&module=${module}&newTestCase=${createNewTestCase}&fileName=${uploadnew.file[0].name}`,
      {
        method: "POST",
        body: uploadnew.file[0],
        headers: {
          "content-type": uploadnew.file[0].type,
          "content-length": `${uploadnew.file[0].size}`,
        },
      },
    )
      .then((res) => {
        res.json();
        setUploadNew({
          file: "",
          filename: "",
        });
        console.log("137 selected-->", uploadnew.file[0].name);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Box>
      <Box
        sx={{
          justifyContent: "flex-start",
          alignItems: "center",
          backgroundColor: colors?.secondaryColor,
          color: colors?.primaryColor,
          marginTop: 10,
          height: 150,
          marginLeft: 4,
          marginRight: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h5" nowrap component="div" sx={{ marginTop: 1 }}>
            Create Test Case
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "row",
            marginLeft: 4,
            marginTop: 2,
          }}
        >
          <FormControl sx={{ width: 240, color: colors.PrimaryColor }}>
            <InputLabel
              style={{
                color: colors.primaryColor,
                backgroundColor: colors.secondaryColor,
                padding: 5,
              }}
              id={"product"}
            >
              Select AI / Manual
            </InputLabel>
            <Select
              id={"product Select"}
              value={testCaseType}
              onChange={(e) => setTestCaseType(e.target.value)}
              name={"Test Case Type"}
              IconComponent={(props) => (
                <ArrowDropDownSharpIcon
                  style={{ color: colors.primaryColor, fontSize: 40 }}
                  {...props}
                />
              )}
              sx={{
                color: colors.primaryColor,
                border: `2px solid ${colors.primaryColor}`,
                fontSize: 16,
                "&:focus": {
                  borderRadius: 4,
                  border: `4px solid ${colors.primaryColor}`,
                },
              }}
              style={{
                minWidth: 100,
                marginRight: "2rem",
              }}
            >
              <MenuItem value={"ai"}>AI/BDD test case</MenuItem>
              <MenuItem value={"manual"}>Manual test case</MenuItem>
            </Select>
          </FormControl>
          {testCaseType && (
            <FormControl sx={{ width: 240, color: colors.PrimaryColor }}>
              <InputLabel
                style={{
                  color: colors.primaryColor,
                  backgroundColor: colors.secondaryColor,
                  padding: 5,
                }}
                id={"product"}
              >
                Product
              </InputLabel>
              <Select
                labelId={"product"}
                id={"product Select"}
                value={product}
                onChange={selectedProduct}
                name={"product"}
                label="product"
                IconComponent={(props) => (
                  <ArrowDropDownSharpIcon
                    style={{ color: colors.primaryColor, fontSize: 40 }}
                    {...props}
                  />
                )}
                sx={{
                  color: colors.primaryColor,
                  border: `2px solid ${colors.primaryColor}`,
                  fontSize: 16,
                  "&:focus": {
                    borderRadius: 4,
                    border: `4px solid ${colors.primaryColor}`,
                  },
                }}
                style={{
                  minWidth: 100,
                  marginRight: "2rem",
                }}
              >
                {productList.map((product, index) => (
                  <MenuItem key={index} value={product.idproducts}>
                    {product.productname}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {product && (
            <FormControl sx={{ width: 240, color: colors.PrimaryColor }}>
              <InputLabel
                style={{
                  color: colors.primaryColor,
                  backgroundColor: colors.secondaryColor,
                  padding: 5,
                }}
                id={"module"}
              >
                Module
              </InputLabel>
              <Select
                labelId={"module"}
                id={"module Select"}
                value={module}
                onChange={selectedModule}
                name={"module"}
                label="module"
                IconComponent={(props) => (
                  <ArrowDropDownSharpIcon
                    style={{ color: colors.primaryColor, fontSize: 40 }}
                    {...props}
                  />
                )}
                sx={{
                  color: colors.primaryColor,
                  border: `2px solid ${colors.primaryColor}`,
                  fontSize: 16,
                  "&:focus": {
                    borderRadius: 4,
                    border: `4px solid ${colors.primaryColor}`,
                  },
                }}
                style={{
                  minWidth: 100,
                  marginRight: "2rem",
                }}
              >
                {ModuleList.map((module, index) => (
                  <MenuItem key={index} value={module.idmodules}>
                    {module.modulename}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {module && (
            <FormControl>
              <TextField
                variant="filled"
                label="New Test Case"
                fullWidth
                value={createNewTestCase}
                onChange={(event) => {
                  event.preventDefault();
                  setCreateNewTestCase(event.target.value);
                  setCreateNewTestCaseShow(event.target.value);
                }}
                sx={{ backgroundColor: "white" }}
              />
            </FormControl>
          )}
          {module && (
            <FormControl>
              <ColorButton
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
                sx={{ marginLeft: 4 }}
                disabled={stepDetails ? true : false}
              >
                Upload new
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleUploadNew}
                  accept=".xlsx, .xls, .csv"
                />
              </ColorButton>
            </FormControl>
          )}
          {module && (
            <FormControl>
              {uploadnew.file[0] && (
                <Typography variant="body2" sx={{ marginLeft: 2 }}>
                  {uploadnew.file[0].name}
                </Typography>
              )}
            </FormControl>
          )}
          {module && (
            <FormControl>
              <ColorButton
                component="label"
                variant="contained"
                sx={{ marginLeft: 4 }}
                disabled={createNewTestCase && (stepDetails || uploadnew.file[0]) ? false : true}
                onClick={() => {
                  if (stepDetails) {
                    submitWithStepDetails(product, module, createNewTestCase, stepDetails);
                    setCreateNewTestCase("");
                    setStepDetails("");
                    setOpenAlertSave(true);
                    setUploadNew({
                      file: "",
                      filename: "",
                    });
                  } else if (uploadnew.file[0]) {
                    getUploadFile();
                    setCreateNewTestCase("");
                    setStepDetails("");
                    setOpenAlertSave(true);
                    setUploadNew({
                      file: "",
                      filename: "",
                    });
                  }
                }}
              >
                Submit
              </ColorButton>
            </FormControl>
          )}
        </Box>
      </Box>

      <Box
        display={"flex"}
        flexDirection={"column"}
        padding={4}
        rowGap={4}
        height={"calc(100vh - 230px)"}
        maxWidth={"900px"}
        margin={"auto"}
      >
        {module && testCaseType === "ai" && (
          <Box flex={1}>
            {loading && <LoaderSpinner />}
            <StyledTextField
              multiline
              variant="outlined"
              label="AI Generated Automation Sequence"
              value={displayedValue}
              onChange={handleChange}
              sx={{
                width: "100%",
                height: "100%",
                backgroundColor: "white",
                color: colors?.secondaryColor,
              }}
              // disabled // Disable editing
            />
          </Box>
        )}
        <Box flex={testCaseType !== "ai" ? 1 : 0}>
          {module && (
            <TextField
              minRows={testCaseType === "ai" ? 1 : 12}
              maxRows={testCaseType === "ai" ? 4 : 12}
              multiline
              variant="outlined"
              label="Please enter the BDD syntax here"
              value={enteredValue}
              onChange={(event) => {
                event.preventDefault();
                const lines = event.target.value.split("\n");
                let processedValue = "";
                lines.forEach((line, index) => {
                  processedValue += line + (index < lines.length - 1 ? "\n" : "");
                });
                setStepDetails(processedValue);
                setEnteredValue(processedValue); // Store entered value
              }}
              sx={{
                backgroundColor: "white",
                color: colors?.secondaryColor,
                width: "100%",
              }}
              disabled={uploadnew.file[0] ? true : false}
            />
          )}
        </Box>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          {module && stepResults && (
            <ColorButton
              component="label"
              variant="contained"
              sx={{ marginLeft: 4 }}
              // disabled={createNewTestCase && (stepDetails || uploadnew.file[0]) ? false : true}
              //   onClick={() => {
              //     setToastState(true);
              //   }}
              onClick={() => {
                if (stepDetails) {
                  submitWithStepDetails(product, module, createNewTestCase, formattedResults);
                  setCreateNewTestCase("");
                  setStepDetails("");
                  setOpenAlertSave(true);
                  setUploadNew({
                    file: "",
                    filename: "",
                  });
                } else if (uploadnew.file[0]) {
                  getUploadFile();
                  setCreateNewTestCase("");
                  setStepDetails("");
                  setOpenAlertSave(true);
                  setUploadNew({
                    file: "",
                    filename: "",
                  });
                }
              }}
            >
              Create Test Case
            </ColorButton>
          )}
        </Grid>
      </Box>

      {openAlertForZip && (
        <Alert
          variant="filled"
          severity="warning"
          onClose={handleClickOpenAlert}
          open={openAlertForZip}
          className="alert"
        >
          Please select a Excel, Csv or Xls file.
        </Alert>
      )}
      {openAlertSave && !loading && (
        <Alert
          varient="filled"
          severity="success"
          onClose={handleClickOpenSave}
          open={openAlertSave}
          className="alert"
        >
          {`${createNewTestCaseShow} test case created successfully`}
        </Alert>
      )}
    </Box>
  );
};
export default CreateTestCase;
