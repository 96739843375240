import React from "react";

const SuiteFolderIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="260" height="16" viewBox="0 0 260 16" fill="none">
    <path
      d="M45.1039 14.5H0.519179C0.517344 14.4017 0.51566 14.2952 0.514135 14.1808C0.500011 13.1225 0.5 11.5015 0.5 10V4V3V2C0.5 1.419 1.15932 0.5 3.15589 0.5H30.5069C31.4633 0.5 31.8545 0.720927 32.1349 0.987391C32.2888 1.13371 32.4209 1.30506 32.5943 1.53009C32.6047 1.54354 32.6152 1.55719 32.6259 1.57103C32.807 1.80582 33.0218 2.07938 33.3109 2.35533L45.1039 14.5Z"
      fill="white"
      stroke="#D1DBFF"
      strokeLinejoin="round"
    />
  </svg>
);

export default SuiteFolderIcon;
