// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  touch-action: none;
  margin: 1rem auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-user-select: none; /* Safari */ /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.container .left-box {
  flex: 2.3 1;
  margin-right: 1rem;
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 64vh;
  border: 1px solid #032f40;
  border-radius: 1rem;
}
.container .right-box {
  flex: 5 1;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 64vh;
}
.container .right-box .suite-box {
  height: 45vh;
  display: flex;
  margin-right: 1rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #032f40;
  border-radius: 1rem;
}
.container .right-box .suite-box .dropzone-right {
  height: 40vh;
  overflow-y: scroll;
  min-width: 18rem;
}

.grid-item-api {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/ApiCall/ApiCall.style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,yBAAA,EAAA,WAAA,EACA,oBAAA;EACA,iBAAA,EAAA,oBAAA;AACF;AACE;EACE,WAAA;EACA,kBAAA;EACA,mBAAA;EACA,aAAA;EACA,8BAAA;EACA,sBAAA;EACA,YAAA;EACA,yBAAA;EACA,mBAAA;AACJ;AAEE;EACE,SAAA;EACA,mBAAA;EACA,aAAA;EACA,2BAAA;EACA,sBAAA;EACA,YAAA;AAAJ;AACI;EACE,YAAA;EACA,aAAA;EACA,kBAAA;EACA,sBAAA;EACA,2BAAA;EACA,mBAAA;EACA,yBAAA;EACA,mBAAA;AACN;AACM;EACE,YAAA;EACA,kBAAA;EAGA,gBAAA;AADR;;AAOA;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;AAJF","sourcesContent":[".container {\n  display: flex;\n  touch-action: none;\n  margin: 1rem auto;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  -webkit-user-select: none; /* Safari */\n  -ms-user-select: none; /* IE 10 and IE 11 */\n  user-select: none; /* Standard syntax */\n\n  .left-box {\n    flex: 2.3;\n    margin-right: 1rem;\n    align-items: center;\n    display: flex;\n    justify-content: space-between;\n    flex-direction: column;\n    height: 64vh;\n    border: 1px solid #032f40;\n    border-radius: 1rem;\n \n  }\n  .right-box {\n    flex: 5;\n    align-items: center;\n    display: flex;\n    justify-content: flex-start;\n    flex-direction: column;\n    height: 64vh;\n    .suite-box {\n      height: 45vh;\n      display: flex;\n      margin-right: 1rem;\n      flex-direction: column;\n      justify-content: flex-start;\n      align-items: center;\n      border: 1px solid #032f40;\n      border-radius: 1rem;\n\n      .dropzone-right {\n        height: 40vh;\n        overflow-y: scroll;\n\n        // max-width: 18rem;\n        min-width: 18rem;\n      }\n    }\n  }\n}\n\n.grid-item-api {\n  display: flex;\n  align-items: center;\n  font-weight: 600;\n  font-size: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
