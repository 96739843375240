import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

const fetchFileDataName = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetails);

  const response = await axios.get(
    `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getfiledatanames&companyid=${user.companyid}&token=${user.token}&productid=${id}`,
  );

  return response.data;
};

const useFileDataNameQuery = (id, options) => {
  return useQuery({
    queryKey: ["fileDataName", id],
    queryFn: () => fetchFileDataName(id),
    retry: true,
    cacheTime: 90000,
    enabled: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    staleTime: Infinity,
  });
};

export default useFileDataNameQuery;
