import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

const fetchPomFiles = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetails);

  const response = await axios.get(
    `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=gpcTesting&companyid=${user.companyid}&token=${user.token}&pid=${id}`,
  );

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${id}${user.token}`); // Adjust filename as needed
  document.body.appendChild(link);
  link.click();

  link.remove();
  window.URL.revokeObjectURL(url);

  return response.data;
};

const useDownloadPomFilesQuery = (id) => {
  return useQuery({
    queryKey: ["downloadpom", id],
    queryFn: () => fetchPomFiles(id),
    retry: true,
    enabled: false,
    cacheTime: 90000,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    staleTime: Infinity,
  });
};

export default useDownloadPomFilesQuery;
