import { Toolbar, Typography, Box, Link, IconButton } from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import SwitchAccessShortcutOutlinedIcon from "@mui/icons-material/SwitchAccessShortcutOutlined";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { logout } from "../../API/RecordingTableAPI";

const AppToolbar = (props) => {
  const userDetails = localStorage.getItem("userDetail");
  const user = JSON.parse(userDetails);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    localStorage.removeItem("userDetail");
    navigate("/LoginPage");
  };

  return (
    <Toolbar
      sx={{
        padding: "0",
        backgroundColor: "#f5f7f9",
        "@media (min-width:600px)": { paddingLeft: 0, paddingRight: 0 },
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ backgroundColor: "#242f3f", height: 64, width: props.drawerWidth }}
        >
          <img src="/logo.png" alt="Nogrunt" />
        </Box>
        <Box
          flex={1}
          height={64}
          color="#000"
          display="flex"
          alignItems="center"
          pl={3}
          pr={2}
          border="2px solid #e2e6f3"
        >
          {/* <Box flex={1}>
            <EditableText initialText="test" />
          </Box> */}
          <Box
            flex={1}
            columnGap={1}
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Link component={RouterLink} to="/dashboard/app" underline="none">
              <Box
                color={"#000000"}
                display="flex"
                border="1px solid #000"
                p={1}
                borderRadius={2}
                sx={{ cursor: "pointer" }}
              >
                <SwitchAccessShortcutOutlinedIcon />
                Switch to old
              </Box>
            </Link>
            <Typography color={"#444141"} fontSize="14px">
              {user?.email}
            </Typography>
            <IconButton onClick={() => handleLogout()}>
              <PowerSettingsNewIcon sx={{ color: "red" }} />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Toolbar>
  );
};

export default AppToolbar;
