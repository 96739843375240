import React, { useEffect, useState } from "react";
import "./TCTable.style.scss";

import {
  Table,
  Box,
  Link,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Typography,
  Tooltip,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { fetchEnvironments, userexpreince } from "./../../API/NewCreateCollectionAPI";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import SyncDisabledIcon from "@mui/icons-material/SyncDisabled";
import LoopIcon from "@mui/icons-material/Loop";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TroubleshootIcon from "@mui/icons-material/Troubleshoot";
import { IconButton } from "@mui/material";
import { API_BASE_PATH, API_BASE_RELATIVE_PATH } from "./../../Config/Config";
import { CommonMethods } from "./../common/CommonMethods";
import { useNavigate, useParams } from "react-router-dom";
import "./TCTable.style.scss";
import Alert from "@mui/material/Alert";
import Execute from "./../NewCreateCollection/Execute";
import { useLocation } from "react-router-dom";
// import AnalyticsRoundedIcon from '@mui/icons-material/AnalyticsRounded';
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DownloadIcon from "@mui/icons-material/Download";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import HistoryRoundedIcon from "@mui/icons-material/HistoryRounded";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import VerifiedSharpIcon from "@mui/icons-material/VerifiedSharp";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import DoDisturbAltRoundedIcon from "@mui/icons-material/DoDisturbAltRounded";
import DifferenceIcon from "@mui/icons-material/Difference";

import {
  StyledTableRow,
  StyledTableCell,
  PrimaryColor,
  SecondaryColor,
  colorExport,
  ColorButton,
  BootstrapTooltip,
} from "./../common/common.Component";

import {
  fetchProducts,
  fetchModules,
  fetchTestCasesfromModule,
  fetchTestCasesfromTestSuite,
  fetchSuiteCase,
  fetchSuiteCaseDownload,
  fetchCaseResultDownload,
  fetchTestCasesResult,
  fetchTestSuitesResult,
  fetchTestStepResult,
  fetchTestStepFailResult,
  fetchTestCaseFromTestSuiteResult,
  fetchTestCaseResultFromTestCase,
  addStopTestSuiteCache,
  fetchScreenShotDiff,
  fetchCurrentExtension,
} from "./../../API/CreateCollectionAPI";
import ScreenshotModal from "./ScreenshotModal";
import ResultScreenShotModal from "./ResultScreenShotModal";
import ScreenshotComparison from "./ScreenshotComparison";
import { fetchRecordingInResult } from "./../../API/RecordingTableAPI";

import { deleteStep, handleTestCaseSearchByMid } from "./../../API/RecordingTableAPI";
const TCTable = (props) => {
  const { pcid, tsid, tsrid } = useParams();

  const [state, setState] = useState({
    file: "",
    xls: [],
    tabsdata: [],
    productname: "",
    modulename: "",
    idproducts: pcid ? pcid : "",
    idmodules: "",
    idtest_suite: tsid ? tsid : "",
    idtest_case: "",
    Test_Case: "",
    showResult: false,
    Moduless: "",
    Productss: "",
    Suitesss: "",
  });

  const modaltcid = "";
  const modaltcname = "";
  const modallogfile = "";

  const [SelectedRow, setSelectedRow] = useState(null);
  const [SelectedFailRow, setSelectedFailRow] = useState(null);
  const [SelectedTestCaseFromTestSuiteResult, setSelectedTestCaseFromTestSuiteResult] =
    useState(null);
  const [ShowFailTest, setShowFailTest] = useState(false);
  const [urlValueCheck, setUrlValueCheck] = useState(true);
  const [SuiteCaseList, setSuiteCaseList] = useState([]);
  const [productList, setproductList] = useState([]);
  const [ModuleList, setModuleList] = useState([]);
  const [screenshotList, setScreenshotList] = useState([]);
  const [TestCaseList, setTestCaseList] = useState([]);
  const [TestCaseResults, setTestCaseResults] = useState([]);
  const [TestStepResults, setTestStepResults] = useState([]);
  const [TestStepResultsfromTestStep, setTestStepResultsfromTestStep] = useState([]);
  const [TestStepFailResults, setTestStepFailResults] = useState([]);
  const [testStepName, settTestStepName] = useState();
  const [refreshFlag, setRefreshFlag] = useState(true);
  const [nameChange, setNameChange] = useState(false);
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const [TestStepsResults, setTestStepsResults] = useState([]);
  const [currentExecution, setCurrentExecution] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogResult, setOpenDialogResult] = useState(false);
  const [openDialogScreenShotCompare, setOpenDialogScreenShotCompare] = useState(false);
  const [valuesSaved, setValuesSaved] = useState([]);
  const [valuesSavedResult, setValuesSavedResult] = useState([]);
  const [indexValue, setIndexValue] = useState();
  const [deleteIconValueTestStep, setDeleteIconValueTestStep] = useState();
  const [deleteIconValueTestCase, setDeleteIconValueTestCase] = useState();
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [testStepIdResult, setTestStepIdResult] = useState();
  const [valueForValidation, setValueForValidation] = useState(false);
  const [latestResultMesssage, setLatestResultMesssage] = useState(false);

  const [executePage, chageexecutePage] = useState(false);
  const [stopButtonHandler, setStopButtonHandler] = useState(false);
  const [suiteforexeute, setsuiteforexeute] = useState([]);
  const [execuitesuite, setexecuitesuite] = useState([]);
  const [environments, setEnvironments] = useState([]);
  const [resolution, setResolution] = useState([]);
  const browser = ["Chrome", "Edge", "Firefox", "Safari", "All"];
  const [statusTypeValue, setStatusTypeValue] = useState("ALL");
  const [testSuiteResultId, setTestSuiteResultId] = useState();
  const [testCaseSearchValue, setTestCaseSearchValue] = useState(false);
  const [oneTimeTestCaseObject, setOneTimeTestCaseObject] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});
  const [stopSuitePopup, setStopSuitePopup] = useState(false);
  const [stopSuiteid, setStopSuiteid] = useState(0);
  const [stopType, setStopType] = useState("");
  const [handleDataClear, setHandleDataclear] = useState(false);
  const [handleHeaderValue, setHandleHeaderValue] = useState(false);
  const [resultExecute, setResultExecute] = useState(false);
  const location = useLocation();
  const suiteId = location.state?.selectedSuit;
  const productSuite = location.state?.product;
  const handleValueChange = (event, index) => {
    const { value } = event.target;
    setSelectedValues((prevSelectedValues) => ({
      ...prevSelectedValues,
      [index]: value,
    }));
  };
  const colors = colorExport();
  const closePopUp = () => {
    chageexecutePage(false);
  };
  const openPopUp = () => {
    chageexecutePage(true);
  };

  const buttonShow = () => {
    setStopButtonHandler(true);
  };

  useEffect(() => {
    // if (pcid !== undefined && tsid !== undefined && tsrid !== undefined) {
    if (pcid && tsid && urlValueCheck) {
      getSuiteCase(pcid);
      getTestSuiteResult(tsid, tsrid);
      resultShowChange();
    }
  }, [pcid, tsid, state.showResult, tsrid]);

  const handleCloseDeleteAlert = () => {
    setOpenDeleteAlert(false);
  };
  const [openAlert, setOpenAlert] = useState(false);

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const resultShowChange = () => {
    setState({
      ...state,
      showResult: true,
    });
  };
  const getenvi = async (id) => {
    await fetchEnvironments(id)
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
          // setLicenseKey(response.data.map((ele) => ele.licensekey));
          setEnvironments(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const userData = async (id) => {
    await userexpreince(id)
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
          setResolution(response.data["ResolutionList"]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const findthesuite = (id) => {
    let suite = SuiteCaseList.find((suite) => {
      return suite.idtest_suite === id;
    });
    setsuiteforexeute([[suite]]);
    setexecuitesuite([suite]);
  };
  const handleClickOpen = (allRows, index) => {
    setValuesSaved(
      allRows.xls.map((rr) => {
        return {
          stepNumber: rr.Step_Number,
          page_Description: rr.Page_Description,
          keyword: rr.Keyword,
          action: rr.Action,
          testData: rr.TestData,
          duration: rr.test_step_duration,
          status: rr.test_step_status,
          screenShot: rr.Failure_Screenshot_Location,
          screenShotOne: rr.screenshot1,
          device: rr.ValDevice,
          issues: rr.issues,
          flow: rr.Flow,
          objectXpath: rr.Object_Xpath,
          page_Name: rr.Page_Name,
          testStepId: rr.Test_Step,
          Test_Case_Results_Id: rr.Test_Case_Results_Id,
          subAction: rr.subAction,
          testStepResultId: rr.idtest_step_result,
        };
      }),
    );
    setIndexValue(index);
    setOpenDialog(true);
  };

  const handleClickOpenTwo = (allRows, index) => {
    setRefreshFlag(false);
    clearInterval(window.ExecuteTimer);
    setValuesSaved(
      allRows.map((rr) => {
        return {
          stepNumber: rr.Step_Number,
          page_Description: rr.Page_Description,
          keyword: rr.Keyword,
          action: rr.Action,
          testData: rr.TestData,
          duration: rr.test_step_duration,
          status: rr.test_step_status,
          screenShot: rr.Failure_Screenshot_Location,
          screenShotOne: rr.screenshot1,
          device: rr.ValDevice,
          issues: rr.issues,
          flow: rr.Flow,
          objectXpath: rr.Object_Xpath,
          page_Name: rr.Page_Name,
          subAction: rr.subAction,
          testStepId: rr.idtest_step ? rr.idtest_step : rr.Test_Step,
          tcrid: rr.Test_Case_Results_Id,
          Test_Case_Results_Id: rr.Test_Case_Results_Id,
          testStepResultId: rr.idtest_step_result,
        };
      }),
    );
    setIndexValue(index);
    setOpenDialog(true);
  };

  const handleClose = (input, stringOne, StringTwo, stringThree, stringFour) => {
    setValuesSaved([]);
    setIndexValue();
    setOpenDialog(false);
    if (input === "Save") {
      setOpenAlert(true);
    }
    if (input === "Delete") {
      setDeleteIconValueTestStep(stringOne);
      setDeleteIconValueTestCase(StringTwo);
      setOpenDeleteAlert(true);
    }
    if (input === "Add Step") {
      setOpenAlert(true);
    }
    if (input === "Compare Screenshot") {
      getScreenShotDiff({
        tcid: state.idtest_case,
        tcrid: stringOne,
        failScreenshot: encodeURIComponent(StringTwo),
        tsid: stringThree,
        stepNumber: stringFour,
      });
      setOpenDialogScreenShotCompare(true);
    }
  };

  const handleClickOpenThree = (allRows, index) => {
    setRefreshFlag(false);
    clearInterval(window.ExecuteTimer);
    setValuesSavedResult(
      allRows.map((rr) => {
        return {
          stepNumber: rr.Step_Number,
          page_Description: rr.Page_Description,
          keyword: rr.Keyword,
          action: rr.Action,
          testData: rr.TestData,
          duration: rr.test_step_duration,
          status: rr.test_step_status,
          screenShot: rr.Failure_Screenshot_Location,
          device: rr.ValDevice,
          issues: rr.issues,
          flow: rr.Flow,
          objectXpath: rr.Object_Xpath,
          page_Name: rr.Page_Name,
          executedDate: rr.Executed_Date,
        };
      }),
    );
    setIndexValue(index);
    setOpenDialogResult(true);
  };

  const handleCloseResult = () => {
    setValuesSavedResult([]);
    setIndexValue();
    setOpenDialogResult(false);
  };

  const handleCloseScreenShotCompare = () => {
    setOpenDialogScreenShotCompare(false);
  };

  const navigate = useNavigate();

  const getProducts = async (api_input) => {
    await fetchProducts(api_input)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setproductList(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getModules = async (id) => {
    await fetchModules(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setModuleList(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTestCasefromModule = async (id) => {
    await fetchTestCasesfromModule(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setTestCaseList(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getScreenShotDiff = async (data) => {
    await fetchScreenShotDiff(data)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setScreenshotList(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTestCasefromTestSuite = async (id) => {
    await fetchTestCasesfromTestSuite(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setTestCaseList(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getTestCaseResult = async (id) => {
    await fetchTestCasesResult(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setTestCaseResults(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getCurrentExecutions = async (id) => {
    await fetchCurrentExtension(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setTestCaseResults(response.data);
            setCurrentExecution(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getTestSuiteResult = async (tsid, tsrid, idproducts) => {
    await fetchTestSuitesResult(tsid, tsrid, idproducts)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setTestCaseResults(response.data);
          }
          navigate("/dashboard/app", { state: { selectedSuit: tsid, product: idproducts } });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteTableField = async (id, tcid) => {
    await deleteStep(id, tcid)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTestCaseResultFromTestCase = async (id) => {
    await fetchTestCaseResultFromTestCase(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setTestStepResultsfromTestStep(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getTestCaseFromTestSuiteResult = async (id, criteria) => {
    await fetchTestCaseFromTestSuiteResult(id, criteria)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setTestStepsResults(response.data.testcaseresult);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getTestStepResult = async (id) => {
    await fetchTestStepResult(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setTestStepResults(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getTestStepFailResult = async (id) => {
    await fetchTestStepFailResult(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setTestStepFailResults(Object.values(response.data[0].data));
            settTestStepName(response.data[0].TestCase);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const postStopTestSuiteCache = async (tsrid, type) => {
    await addStopTestSuiteCache(tsrid, type)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchTC = async (tcrid) => {
    await fetchRecordingInResult(tcrid)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          }
        }
        setOneTimeTestCaseObject(response.data[0].testcase);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchTableData = () => {
    let apicall = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=latestResult&companyid=${temp?.companyid}&token=${temp?.token}`;
    fetch(apicall, {
      method: "POST",
      body: state.file,
      headers: {
        "content-type": state.file.type,
        "content-length": `${state.file.size}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          setState({
            ...state,
            xls: Object.values(data.data),
            result: data.result,
            modaltcid: data.result.Test_Case_Id,
            modaltcname: data.result.testcasename,
            modallogfile: data.result.logfile,
            ...(suiteId
              ? { showResult: true, Productss: productSuite, Suitesss: suiteId }
              : refreshFlag
                ? { showResult: false, Productss: data.Product, Moduless: data.Modules }
                : {
                    showResult: false,
                    idproducts: data.Product,
                    idmodules: data.Modules,
                    idtest_case: state?.result?.Test_Case_Id,
                  }),
          });
          setOneTimeTestCaseObject(data.testcase);
          if (valueForValidation === false) {
            if (productSuite && suiteId) {
              setState((prev) => {
                getSuiteCase(productSuite);
                getTestSuiteResult(suiteId, "");
                return {
                  ...prev,
                  idproducts: productSuite,
                  idtest_suite: suiteId,
                };
              });
            } else {
              setState((prev) => {
                getModules(data.Product);
                getTestCasefromModule(data.Modules);
                // setTestCaseNameChange(RouteState.state.details.Test_Case)
                return {
                  ...prev,
                  idproducts: data.Product,
                  idmodules: data.Modules,
                  idtest_case: data.Testcase,
                  Test_Case: data.Testcase,
                };
              });
            }
            setValueForValidation(true);
          }
        }
      })
      .catch((err) => console.error(err));
  };
  const getSearchValue = async (mid, key) => {
    await handleTestCaseSearchByMid(mid, key)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            setTestCaseSearchValue(true);
            setHandleDataclear(true);
            setHandleHeaderValue(true);
            setTestCaseList(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getSuiteCase = async (id) => {
    await fetchSuiteCase(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            formateSuiteprops(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSuiteCaseDownload = async (id) => {
    await fetchSuiteCaseDownload(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            formateSuiteprops(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCaseResultDownload = async (id) => {
    await fetchCaseResultDownload(id)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.message === "fail") {
            CommonMethods(navigate);
          } else {
            formateSuiteprops(response.data);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formateSuiteprops = (data) => {
    setSuiteCaseList(
      data.map((item) => {
        return {
          ...item[0],
          list: item[0].list.map((subitem) => subitem.testcases[0]),
        };
      }),
    );
  };

  useEffect(() => {
    getProducts();
    fetchTableData();
  }, []);

  useEffect(() => {
    if ((props.isExecuteClick || resultExecute) && !nameChange) {
      if (
        (state?.result?.Status === "STARTED" ||
          state?.result?.Status === "RUNNING" ||
          state?.result?.Status === undefined ||
          resultExecute) &&
        suiteId === undefined
      ) {
        window.ExecuteTimer = setInterval(() => {
          fetchTableData();
        }, 5000);
      } else if (suiteId !== undefined) {
        window.ExecuteTimer = setInterval(() => {
          getTestSuiteResult(suiteId, "");
        }, 5000);
      } else {
      }
    }
    return () => clearInterval(window.ExecuteTimer);
  }, [valueForValidation, nameChange, state?.result?.Status, resultExecute, props.isExecuteClick]);

  useEffect(() => {
    let arr = TestCaseList;
    if (
      !TestCaseList.find((val) => val.idtest_case === oneTimeTestCaseObject?.idtest_case) &&
      !testCaseSearchValue
    ) {
      arr = [...arr, oneTimeTestCaseObject];
      setTestCaseList(arr);
    }
  }, [TestCaseList, testCaseSearchValue]);

  const [SearchString, setSearchString] = useState("");

  const handleExtClick = () => {
    window.open(
      "https://chrome.google.com/webstore/detail/nogrunt-qa-automation/neankkcidljlljifcclgcecadeimmolp",
      "_blank",
    );
  };

  const [showVideo, setShowVideo] = useState(false);

  const handleVideoClick = () => {
    setShowVideo(true);
  };

  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      autoplay: 1,
    },
  };

  if (!state.result) {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            justifyContent: "flex-start",
          }}
        >
          <h1>Looks like you haven't authored any scripts yet</h1>
          <p>Follow the 3 easy steps below:</p>
          <ColorButton
            variant="contained"
            color="primary"
            onClick={handleExtClick}
            style={{ width: "fit-content" }}
          >
            Step 1 - Get the Chrome Extension - click HERE!
          </ColorButton>
          <ColorButton
            variant="contained"
            color="secondary"
            style={{ width: "fit-content" }}
            onClick={handleVideoClick}
          >
            Step 2 - Open a Chrome Browser to begin authoring your tests
          </ColorButton>
          <ColorButton variant="contained" color="secondary" style={{ width: "fit-content" }}>
            <a
              href="https://www.youtube.com/watch?v=h-LnHNZK1Mo&t=20s"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              Watch the tutorial video
            </a>
          </ColorButton>
          <ColorButton variant="contained" color="warning" style={{ width: "fit-content" }}>
            Step 3 - Go to the Test Authoring Page to execute your tests
          </ColorButton>
          <ColorButton variant="contained" color="warning" style={{ width: "fit-content" }}>
            <a
              href="https://automate.nogrunt.com/WelcomeKit.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Step 4 - Download the user manual
            </a>
          </ColorButton>
        </div>
        {/* } */}
      </>
    );
  }

  const executeTest = ({
    id,
    SelectEnvironmentName,
    SelectEnvironmentUrl,
    SelectBrowserName,
    SelectResolution,
    SelectRunonce,
    testcase,
  }) => {
    let apicall = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=execute&companyid=${temp.companyid}&token=${temp.token}&testcaseid=${id}&testcase=${testcase}&envName=${SelectEnvironmentName}&envUrl=${SelectEnvironmentUrl}&browsername=${SelectBrowserName}&resolution=${SelectResolution}&runonce=${SelectRunonce}`;
    fetch(apicall, {
      method: "POST",
      body: state.file,
      headers: {
        "content-type": state.file.type,
        "content-length": `${state.file.size}`, // 👈 Headers need to be a string
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data === null) {
        } else {
          setState((prev) => {
            return {
              ...prev,
              xls: Object.values(data.data),
            };
          });
        }
        navigate("/dashboard/app", { state: { isExecuteClick: true } });
      })
      .catch((err) => console.error(err));
    // setState({ ...state, display: "execute" });
  };

  const executeAnalysis = ({
    id,
    SelectEnvironmentName,
    SelectEnvironmentUrl,
    SelectBrowserName,
    SelectResolution,
    SelectRunonce,
    testcase,
  }) => {
    let apicall = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=analysis&companyid=${temp.companyid}&token=${temp.token}&testcaseid=${id}&testcase=${testcase}&envName=${SelectEnvironmentName}&envUrl=${SelectEnvironmentUrl}&browsername=${SelectBrowserName}&resolution=${SelectResolution}&runonce=${SelectRunonce}`;
    fetch(apicall, {
      method: "POST",
      body: state.file,
      headers: {
        "content-type": state.file.type,
        "content-length": `${state.file.size}`, // 👈 Headers need to be a string
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data === null) {
        } else {
          setState((prev) => {
            return {
              ...prev,
              xls: Object.values(data.data),
            };
          });
        }
        navigate("/dashboard/app", { state: { isExecuteClick: true } });
      })
      .catch((err) => console.error(err));
    // setState({ ...state, display: "execute" });
  };

  const filteredTestCase = TestCaseList.find((item) => state?.idtest_case === item.idtest_case);

  return (
    <div className="results-mui">
      <TableContainer component={Paper} sx={{ marginTop: "2rem", width: "100%" }}>
        <Table aria-label="a dense table" sx={{ minWidth: 650 }} size="small">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell colSpan={16}>
                <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                    paddingX: "1.5rem",
                    paddingTop: "1rem",
                    backgroundColor: SecondaryColor,
                    color: PrimaryColor,
                  }}
                >
                  {state?.result?.Executed_Date !== "" && (
                    <Typography>
                      Executed_Date:{" "}
                      {suiteId === undefined && handleHeaderValue === false
                        ? state?.result?.Executed_Date
                        : ""}
                    </Typography>
                  )}
                  {handleHeaderValue === false
                    ? state?.result?.Executed_Date !== "" &&
                      suiteId === undefined && (
                        <Typography>
                          <Typography
                            component={Link}
                            href={state?.result?.logFile}
                            underline="always"
                            color="inherit"
                            target="_blank"
                          >
                            Log File
                          </Typography>
                        </Typography>
                      )
                    : ""}
                  {state.idtest_suite === "" ? (
                    state?.result?.testcasename !== "" && (
                      <Typography>
                        Name:{" "}
                        {!nameChange && state?.Test_Case === ""
                          ? state?.result?.testcasename
                          : TestCaseList.map((value, key) => {
                              if (value.idtest_case === state?.idtest_case) {
                                return value.Test_Case;
                              }
                            })}
                      </Typography>
                    )
                  ) : (
                    <Typography>
                      Name:{" "}
                      {SuiteCaseList.map((value, key) => {
                        if (value.idtest_suite === state?.idtest_suite) {
                          return value.Test_Suite;
                        }
                      })}
                    </Typography>
                  )}
                  {state?.result?.Executed_By !== "" && (
                    <Typography>
                      Executed_By:{handleHeaderValue === false ? state?.result?.Executed_By : ""}
                    </Typography>
                  )}
                  {state?.result?.DurationSum !== "" && (
                    <Typography>
                      Duration:
                      {suiteId === undefined && handleHeaderValue === false
                        ? state?.result?.DurationSum
                        : ""}
                    </Typography>
                  )}
                  {state?.result?.Status !== "" && (
                    <Typography>
                      Status:
                      {suiteId === undefined && handleHeaderValue === false
                        ? state?.result?.Status
                        : ""}
                    </Typography>
                  )}
                  {state?.result?.Browser !== "" && (
                    <Typography>
                      Browser:
                      {suiteId === undefined && handleHeaderValue === false
                        ? state?.result?.Browser
                        : ""}
                    </Typography>
                  )}
                  <>
                    {refreshFlag ? (
                      <IconButton
                        size="large"
                        variant="contained"
                        onClick={() => {
                          setRefreshFlag(false);
                          clearInterval(window.ExecuteTimer);
                        }}
                        style={{
                          color: colors.primaryColor,
                          backgroundColor: colors.secondaryColor,
                          marginLeft: "16px",
                        }}
                      >
                        <SyncDisabledIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        size="large"
                        variant="contained"
                        onClick={() => {
                          setRefreshFlag(true);
                          setState({
                            ...state,
                            ...(suiteId
                              ? {
                                  showResult: true,
                                  idproducts: productSuite,
                                  idtest_suite: suiteId,
                                }
                              : {
                                  showResult: false,
                                  idproducts: state?.Productss,
                                  idmodules: state?.Moduless,
                                  idtest_case: state?.result?.Test_Case_Id,
                                }),
                          });
                          if (suiteId === undefined) {
                            window.ExecuteTimer = setInterval(() => {
                              fetchTableData();
                            }, 5000);
                          } else {
                            setHandleDataclear(false);
                            window.ExecuteTimer = setInterval(() => {
                              getTestSuiteResult(suiteId, "", state.idproducts);
                            }, 5000);
                          }
                        }}
                        style={{
                          color: colors.primaryColor,
                          backgroundColor: colors.secondaryColor,
                          marginLeft: "16px",
                        }}
                      >
                        <LoopIcon />
                      </IconButton>
                    )}
                  </>
                </Box>
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell colSpan={16}>
                <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-end",
                    alignItems: "center",
                    flexDirection: "row",
                    paddingX: "1.5rem",
                    backgroundColor: SecondaryColor,
                    color: PrimaryColor,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flex: 1,
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <FormControl sx={{ width: 240, color: PrimaryColor }}>
                      <InputLabel
                        style={{
                          color: colors.primaryColor,
                          backgroundColor: colors.secondaryColor,
                          padding: 5,
                        }}
                        id={"product name"}
                      >
                        Product
                      </InputLabel>
                      <Select
                        labelId={"product name"}
                        id={"product name Select"}
                        value={state.idproducts}
                        name={"product"}
                        label="Product"
                        IconComponent={(props) => (
                          <ArrowDropDownSharpIcon
                            style={{ color: colors.primaryColor, fontSize: 40 }}
                            {...props}
                          />
                        )}
                        sx={{
                          color: colors.primaryColor,
                          border: `2px solid ${colors.primaryColor}`,
                          fontSize: 16,
                          "&:focus": {
                            borderRadius: 4,
                            border: `4px solid ${colors.primaryColor}`,
                          },
                        }}
                        onChange={(event) => {
                          setState({
                            ...state,
                            idproducts: event.target.value,
                            idmodules: "",
                            idtest_suite: "",
                            idtest_case: "",
                          });
                          setRefreshFlag(false);
                          clearInterval(window.ExecuteTimer);
                          getSuiteCase(event.target.value);
                          getModules(event.target.value);
                          getenvi(event.target.value);
                          userData(event.target.value);
                        }}
                        style={{
                          minWidth: 100,
                          marginRight: "2rem",
                        }}
                      >
                        {productList.map((item, index) => (
                          <MenuItem key={index} value={item.idproducts}>
                            {item.productname}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {state.idproducts !== "" && (
                      <Box
                        style={{
                          marginRight: "2rem",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {state.idtest_suite === "" && (
                          <FormControl sx={{ width: 240 }}>
                            <InputLabel
                              style={{
                                color: colors.primaryColor,
                                backgroundColor: colors.secondaryColor,
                                padding: 5,
                              }}
                              id={"module name"}
                            >
                              Module
                            </InputLabel>
                            <Select
                              labelId={"module name"}
                              IconComponent={(props) => (
                                <ArrowDropDownSharpIcon
                                  style={{ color: colors.primaryColor, fontSize: 40 }}
                                  {...props}
                                />
                              )}
                              sx={{
                                color: colors.primaryColor,
                                border: `2px solid ${colors.primaryColor}`,
                                fontSize: 16,
                                "&:focus": {
                                  borderRadius: 4,
                                  border: `4px solid ${colors.primaryColor}`,
                                },
                              }}
                              id={"module name Select"}
                              value={state.idmodules}
                              label="Module"
                              onChange={(event) => {
                                getTestCasefromModule(event.target.value);
                                setState({
                                  ...state,
                                  idmodules: event.target.value,
                                  idtest_case: "",
                                });
                              }}
                              style={{
                                minWidth: 100,
                              }}
                            >
                              {ModuleList.map((item, index) => (
                                <MenuItem key={index} value={item.idmodules}>
                                  {item.modulename}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                        {state.idmodules === "" && state.idtest_suite === "" && (
                          <Box
                            sx={{
                              marginX: "1rem",
                            }}
                          >
                            or
                          </Box>
                        )}
                        {state.idmodules === "" && (
                          <FormControl sx={{ width: 240 }}>
                            <InputLabel
                              style={{
                                color: colors.primaryColor,
                                backgroundColor: colors.secondaryColor,
                                padding: 5,
                              }}
                              //pallavi
                              id={"Test Suite"}
                            >
                              Test Suite
                            </InputLabel>
                            <Select
                              labelId={"Test Suite name"}
                              IconComponent={(props) => (
                                <ArrowDropDownSharpIcon
                                  style={{ color: colors.primaryColor, fontSize: 40 }}
                                  {...props}
                                />
                              )}
                              sx={{
                                color: colors.primaryColor,
                                border: `2px solid ${colors.primaryColor}`,
                                fontSize: 16,
                                "&:focus": {
                                  borderRadius: 4,
                                  border: `4px solid ${colors.primaryColor}`,
                                },
                              }}
                              id={"Test Suite name Select"}
                              value={state.idtest_suite}
                              label="Test Suite"
                              onChange={(event) => {
                                findthesuite(event.target.value);
                                //getTestCasefromTestSuite(event.target.value);
                                getTestSuiteResult(event.target.value, "", state.idproducts);
                                setState({
                                  ...state,
                                  idtest_suite: event.target.value,
                                  idtest_case: "",
                                  showResult: true,
                                });
                                setSelectedValues({ index: "ALL" });
                                closePopUp();
                                setRefreshFlag(false);
                                clearInterval(window.ExecuteTimer);
                              }}
                              style={{
                                minWidth: 100,
                              }}
                            >
                              {SuiteCaseList.map((item, index) => (
                                <MenuItem key={index} value={item.idtest_suite}>
                                  {item.Test_Suite}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      </Box>
                    )}
                    {state.idmodules !== "" && (
                      <FormControl
                        sx={{
                          width: 240,
                        }}
                      >
                        <InputLabel
                          style={{
                            color: colors.primaryColor,
                            backgroundColor: colors.secondaryColor,
                            padding: 5,
                          }}
                          id={"Test Case name"}
                        >
                          Test Case
                        </InputLabel>
                        <Select
                          labelId={"Test Case name"}
                          IconComponent={(props) => (
                            <ArrowDropDownSharpIcon
                              style={{ color: colors.primaryColor, fontSize: 40 }}
                              {...props}
                            />
                          )}
                          sx={{
                            color: colors.primaryColor,
                            border: `2px solid ${colors.primaryColor}`,
                            fontSize: 16,
                            "&:focus": {
                              borderRadius: 4,
                              border: `4px solid ${colors.primaryColor}`,
                            },
                          }}
                          id={"Test Case name Select"}
                          value={state.idtest_case}
                          label="Test Case"
                          onChange={(event) => {
                            setState({
                              ...state,
                              idtest_case: event.target.value,
                              showResult: true,
                            });
                            setRefreshFlag(false);
                            setNameChange(true);
                            setHandleDataclear(false);
                            setHandleHeaderValue(true);
                            setSearchString("");
                            getTestCaseResult(event.target.value);
                          }}
                          style={{
                            minWidth: 100,
                          }}
                        >
                          {TestCaseList.map((item, index) => (
                            <MenuItem key={index} value={item.idtest_case}>
                              {item.Test_Case}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    {(state.idtest_case !== "" ||
                      state.idtest_suite !== "" ||
                      state.idmodules !== "") && (
                      <>
                        <Tooltip title={"Clear Filter"}>
                          <IconButton
                            size="small"
                            variant="contained"
                            onClick={(event) => {
                              setState({
                                ...state,
                                productname: "",
                                modulename: "",
                                idproducts: "",
                                idmodules: "",
                                idtest_case: "",
                                showResult: false,
                                Test_Case: "",
                                idtest_suite: "",
                              });
                              setShowFailTest(false);
                              setUrlValueCheck(false);
                              setTestStepFailResults([]);
                              setHandleHeaderValue(false);
                              setSearchString("");
                              setRefreshFlag(false);
                              setNameChange(true);
                              if (suiteId !== undefined) {
                                setHandleDataclear(true);
                              }
                            }}
                            style={{
                              color: colors.primaryColor,
                              backgroundColor: colors.secondaryColor,
                              marginLeft: 20,
                              // fontSize: '32px',
                            }}
                          >
                            <ClearAllIcon style={{ fontSize: 50 }} />
                          </IconButton>
                        </Tooltip>
                        {state.idproducts !== "" &&
                          state.idmodules !== "" &&
                          state.idtest_case !== "" && (
                            <Tooltip title={"View Test Case"}>
                              <IconButton
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  navigate("/dashboard/recording", {
                                    state: {
                                      toRecordingPage: true,
                                      idproducts: state.idproducts,
                                      idmodules: state.idmodules,
                                      idtest_case: state.idtest_case,
                                      Test_Case: state.idtest_case,
                                      // details: "testcase",
                                    },
                                  });
                                }}
                                // sx={{ marginLeft: 4 }}
                                style={{
                                  color: colors.primaryColor,
                                  backgroundColor: colors.secondaryColor,
                                  marginLeft: 10,
                                  // fontSize: '32px',
                                }}
                              >
                                <RemoveRedEyeIcon style={{ fontSize: 40 }} />
                              </IconButton>
                            </Tooltip>
                          )}
                        {state.idtest_case !== "" && (
                          <Tooltip title={"Execute"}>
                            <IconButton
                              size="small"
                              variant="contained"
                              onClick={(event) => {
                                event.preventDefault();
                                setNameChange(false);
                                setRefreshFlag(true);
                                setResultExecute(true);
                                if (filteredTestCase) {
                                  executeTest({
                                    id: state?.idtest_case,
                                    SelectEnvironmentName: "",
                                    SelectEnvironmentUrl: "",
                                    SelectBrowserName: "",
                                    SelectResolution: "",
                                    SelectRunonce: "",
                                    testcase: encodeURIComponent(filteredTestCase.Test_Case),
                                  });
                                } else {
                                  console.log("abcd");
                                }
                              }}
                              style={{
                                color: colors.primaryColor,
                                backgroundColor: colors.secondaryColor,
                                marginLeft: 20,
                                // fontSize: '32px',
                              }}
                            >
                              <VerifiedSharpIcon style={{ fontSize: 40 }} />
                            </IconButton>
                          </Tooltip>
                        )}
                        {state.idtest_case !== "" && (
                          <Tooltip title={"History"}>
                            <IconButton
                              size="small"
                              variant="contained"
                              onClick={(event) => {
                                setState({
                                  ...state,
                                  idtest_suite: "",
                                  idproducts: state?.Productss,
                                  idmodules: state?.Moduless,
                                  idtest_case: state?.result?.Test_Case_Id,
                                  showResult: true,
                                });
                                getTestCaseResult(state?.result?.Test_Case_Id);
                                setRefreshFlag(false);
                                setNameChange(true);
                              }}
                              style={{
                                color: colors.primaryColor,
                                backgroundColor: colors.secondaryColor,
                                marginLeft: 20,
                                // fontSize: '32px',
                              }}
                            >
                              <HistoryRoundedIcon style={{ fontSize: 40 }} />
                            </IconButton>
                          </Tooltip>
                        )}
                        {state.idtest_case !== "" && (
                          <Tooltip title={"Analysis Co Pilot"}>
                            <IconButton
                              size="small"
                              variant="contained"
                              onClick={(event) => {
                                event.preventDefault();
                                setNameChange(false);
                                setRefreshFlag(true);
                                setResultExecute(true);
                                if (filteredTestCase) {
                                  executeAnalysis({
                                    id: state?.idtest_case,
                                    SelectEnvironmentName: "",
                                    SelectEnvironmentUrl: "",
                                    SelectBrowserName: "",
                                    SelectResolution: "",
                                    SelectRunonce: "",
                                    testcase: encodeURIComponent(filteredTestCase.Test_Case),
                                  });
                                } else {
                                  console.log("abcd");
                                }
                              }}
                              style={{
                                color: colors.primaryColor,
                                backgroundColor: colors.secondaryColor,
                                marginLeft: 20,
                                // fontSize: '32px',
                              }}
                            >
                              <DifferenceIcon style={{ fontSize: 40 }} />
                            </IconButton>
                          </Tooltip>
                        )}
                        {state.idtest_case !== "" && (
                          <Tooltip title={"Current Executions"}>
                            <IconButton
                              size="small"
                              variant="contained"
                              onClick={(event) => {
                                setState({
                                  ...state,
                                  idtest_suite: "",
                                  idproducts: state?.Productss,
                                  idmodules: state?.Moduless,
                                  idtest_case: state?.result?.Test_Case_Id,
                                  showResult: true,
                                });
                                getCurrentExecutions(state?.result?.Test_Case_Id);
                                setRefreshFlag(false);
                                setNameChange(true);
                              }}
                              style={{
                                color: colors.primaryColor,
                                backgroundColor: colors.secondaryColor,
                                marginLeft: 20,
                                // fontSize: '32px',
                              }}
                            >
                              <DirectionsRunIcon style={{ fontSize: 40 }} />
                            </IconButton>
                          </Tooltip>
                        )}
                        {(state?.result?.Status === "STARTED" ||
                          state?.result?.Status === "RUNNING") &&
                        state.idtest_case !== "" ? (
                          <StyledTableCell align="center">
                            <Tooltip title={"Stop"}>
                              <IconButton
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  setStopType("case");
                                  setStopSuitePopup(!stopSuitePopup);
                                  setStopSuiteid(state?.result?.idtest_case_results);
                                  setStopButtonHandler(false);
                                  setRefreshFlag(false);
                                  closePopUp();
                                }}
                                style={{
                                  color: colors.primaryColor,
                                  backgroundColor: colors.secondaryColor,
                                  marginLeft: 10,
                                  // fontSize: '32px',
                                }}
                              >
                                <DoDisturbAltRoundedIcon style={{ fontSize: 40 }} />
                              </IconButton>
                            </Tooltip>
                          </StyledTableCell>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                    {ShowFailTest && (
                      <Tooltip title={"Clear Result"}>
                        <IconButton
                          size="small"
                          variant="contained"
                          onClick={(event) => {
                            setShowFailTest(false);
                            setTestStepFailResults([]);
                          }}
                          style={{
                            color: colors.primaryColor,
                            backgroundColor: colors.secondaryColor,
                            marginLeft: 20,
                            // fontSize: '32px',
                          }}
                        >
                          <ClearRoundedIcon style={{ fontSize: 40 }} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                  {state.idproducts !== "" && state.idmodules !== "" && (
                    <Box
                      sx={{
                        display: "flex",
                        ml: "5rem",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        flexDirection: "row",
                        paddingY: "1.5rem",
                        backgroundColor: SecondaryColor,
                        color: PrimaryColor,
                      }}
                    >
                      <Paper
                        component="form"
                        sx={{
                          p: "2px 4px",
                          marginLeft: "0rem",
                          display: "flex",
                          alignItems: "center",
                          width: 400,
                        }}
                      >
                        <InputBase
                          sx={{ ml: 1, flex: 1 }}
                          placeholder="Search Test Case here..."
                          inputProps={{ "aria-label": "Search Test Case here..." }}
                          value={SearchString}
                          onChange={(e) => {
                            e.preventDefault();
                            setSearchString(e.target.value);
                          }}
                        />
                        <IconButton
                          type="button"
                          sx={{ p: "10px" }}
                          aria-label="search"
                          onClick={() => {
                            getSearchValue(state.idmodules, SearchString);
                          }}
                        >
                          <SearchIcon />
                        </IconButton>
                      </Paper>
                    </Box>
                  )}
                </Box>
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
        </Table>
        <div style={{ overflow: "auto", height: "600px" }}>
          <Table stickyHeader aria-label="a dense table" sx={{ minWidth: 650 }} size="small">
            {ShowFailTest ? (
              <>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>Results Id</StyledTableCell>
                    <StyledTableCell>Executed Date</StyledTableCell>
                    <StyledTableCell align="center">Keyword</StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                    <StyledTableCell align="center">Flow</StyledTableCell>
                    <StyledTableCell align="center">TestData</StyledTableCell>
                    <StyledTableCell align="center">Duration(s)</StyledTableCell>
                    <StyledTableCell align="center">Status</StyledTableCell>
                    <StyledTableCell align="center">Screenshot</StyledTableCell>
                    <StyledTableCell align="center">Validate In</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {/*used for the result to show, which is when you click on the search icon in the steps*/}
                  {TestStepFailResults.map((subitem, index) => (
                    <StyledTableRow
                      key={subitem.action}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <StyledTableCell component="th" scope="row">
                        {subitem.Test_Case_Results_Id}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {subitem.Executed_Date}
                      </StyledTableCell>
                      <StyledTableCell align="center">{subitem.Keyword}</StyledTableCell>
                      <StyledTableCell align="center">{subitem.Action}</StyledTableCell>
                      <StyledTableCell align="center">{subitem.Flow}</StyledTableCell>
                      <StyledTableCell align="center">
                        {subitem.TestData?.length <= 40 ? (
                          subitem.TestData
                        ) : (
                          <BootstrapTooltip
                            title={
                              <React.Fragment>
                                <Typography color="inherit">{subitem.TestData}</Typography>
                              </React.Fragment>
                            }
                            arrow
                          >
                            <div>{subitem.TestData?.slice(0, 40) + "..."}</div>
                          </BootstrapTooltip>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">{subitem.Duration}</StyledTableCell>
                      <StyledTableCell align="center">{subitem.Status}</StyledTableCell>
                      <StyledTableCell align="center">
                        {subitem.Failure_Screenshot_Location && (
                          <Link
                            onClick={(event) => {
                              handleClickOpenThree(TestStepFailResults, index);
                            }}
                            underline="hover"
                          >
                            Screenshot
                          </Link>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">{subitem.ValDevice}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </>
            ) : !state.showResult ? (
              <>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>Step Number</StyledTableCell>
                    <StyledTableCell>Element Description</StyledTableCell>
                    <StyledTableCell align="center">Keyword</StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                    <StyledTableCell align="center">Flow</StyledTableCell>
                    <StyledTableCell align="center">TestData</StyledTableCell>
                    <StyledTableCell align="center">Duration</StyledTableCell>
                    <StyledTableCell align="center">Status</StyledTableCell>
                    <StyledTableCell align="center">Screenshot</StyledTableCell>
                    <StyledTableCell align="center">Validate In</StyledTableCell>
                    <StyledTableCell align="center" />
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {/*by defualt table - latestResult&companyid*/}
                  {handleDataClear === false
                    ? state.xls.map((row, index) => (
                        <React.Fragment key={index}>
                          <StyledTableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <StyledTableCell component="th" scope="row">
                              {row.Step_Number}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {row?.Page_Description?.length <= 40 ? (
                                row.Page_Description
                              ) : (
                                <BootstrapTooltip
                                  title={
                                    <React.Fragment>
                                      <Typography color="inherit">
                                        {row.Page_Description}
                                      </Typography>
                                    </React.Fragment>
                                  }
                                  arrow
                                >
                                  <div>{row.Page_Description?.slice(0, 40) + "..."}</div>
                                </BootstrapTooltip>
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">{row.Keyword}</StyledTableCell>
                            <StyledTableCell align="center">{row.Action}</StyledTableCell>
                            <StyledTableCell align="center">{row.Flow}</StyledTableCell>
                            <StyledTableCell align="center">
                              {row.TestData?.length <= 40 ? (
                                row.TestData
                              ) : (
                                <BootstrapTooltip
                                  title={
                                    <React.Fragment>
                                      <Typography color="inherit">{row.TestData}</Typography>
                                    </React.Fragment>
                                  }
                                  arrow
                                >
                                  <div>{row.TestData?.slice(0, 40) + "..."}</div>
                                </BootstrapTooltip>
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">{row.Duration}</StyledTableCell>
                            <StyledTableCell align="center">{row.Status}</StyledTableCell>
                            <StyledTableCell align="center">
                              {row.Failure_Screenshot_Location && (
                                <Link
                                  onClick={(event) => {
                                    handleClickOpen(state, index);
                                    setTestStepIdResult(row.idtest_step_result);
                                  }}
                                  underline="hover"
                                >
                                  Screenshot
                                </Link>
                              )}
                            </StyledTableCell>
                            <StyledTableCell align="center">{row.ValDevice}</StyledTableCell>
                            <StyledTableCell align="right">
                              {!(row.Status === "PASS") && (
                                <Box Box style={{ display: "flex" }}>
                                  <IconButton
                                    color="inherit"
                                    aria-label="expand more"
                                    component="label"
                                    onClick={(event) => {
                                      event.persist();
                                      setRefreshFlag(false);
                                      clearInterval(window.ExecuteTimer);
                                      setShowFailTest(true);
                                      getTestStepFailResult(row.idtest_step_result);
                                    }}
                                  >
                                    <TroubleshootIcon />
                                  </IconButton>
                                </Box>
                              )}
                            </StyledTableCell>
                          </StyledTableRow>
                          {SelectedFailRow === row && (
                            <StyledTableRow
                              key={row.action}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <StyledTableCell align="left" colSpan={12}>
                                <Typography
                                  sx={{
                                    color: "#f00",
                                    ":hover": {
                                      textDecoration: "underline",
                                    },
                                  }}
                                >
                                  {"Issue: "}
                                </Typography>
                                <Typography
                                  sx={{
                                    ":hover": {
                                      textDecoration: "underline",
                                    },
                                  }}
                                >
                                  {SelectedFailRow?.issues}
                                </Typography>
                              </StyledTableCell>
                            </StyledTableRow>
                          )}
                        </React.Fragment>
                      ))
                    : ""}
                </TableBody>
              </>
            ) : (
              <>
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>Executed_Date</StyledTableCell>
                    <StyledTableCell align="center">Log File</StyledTableCell>
                    <StyledTableCell align="center">Executed_By</StyledTableCell>
                    <StyledTableCell align="center">Duration(s)</StyledTableCell>
                    {state.idtest_suite === "" ? (
                      <StyledTableCell align="center">Browser</StyledTableCell>
                    ) : (
                      ""
                    )}
                    {state.idtest_suite === "" ? (
                      <StyledTableCell align="center">Environment Url</StyledTableCell>
                    ) : (
                      ""
                    )}
                    {state.idtest_suite === "" ? (
                      <StyledTableCell align="center">Resolution</StyledTableCell>
                    ) : (
                      ""
                    )}
                    <StyledTableCell align="center">Status</StyledTableCell>
                    {state.idtest_suite !== "" ? (
                      <StyledTableCell align="center">Total/Pass/Fail count</StyledTableCell>
                    ) : (
                      ""
                    )}
                    {state.idtest_suite !== "" ? (
                      <StyledTableCell align="center">Status Type</StyledTableCell>
                    ) : (
                      ""
                    )}
                    {state.idtest_suite !== "" ? (
                      <StyledTableCell align="center"></StyledTableCell>
                    ) : (
                      ""
                    )}
                    {state.idtest_suite !== "" ? (
                      <StyledTableCell align="left"></StyledTableCell>
                    ) : (
                      ""
                    )}
                    <StyledTableCell align="center"></StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {/*test case result for test suite(product->test suite) and test case(product->module->testcase)*/}
                  {handleDataClear === false && TestCaseResults.length > 0 ? (
                    TestCaseResults.map((row, index) => (
                      <React.Fragment key={index}>
                        <StyledTableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                          <StyledTableCell component="th" scope="row">
                            {row.Executed_Date}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <Typography
                              component={Link}
                              href={row.logfile}
                              underline="always"
                              color="inherit"
                              target="_blank"
                            >
                              {row.testcase ? row.testcase : "Log File"}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell align="center">{row.Executed_By}</StyledTableCell>
                          <StyledTableCell align="center">{row.Duration}</StyledTableCell>
                          {state.idtest_suite === "" ? (
                            <StyledTableCell align="center">{row.Browser}</StyledTableCell>
                          ) : (
                            ""
                          )}
                          {state.idtest_suite === "" ? (
                            <StyledTableCell align="center">{row?.envurl}</StyledTableCell>
                          ) : (
                            ""
                          )}
                          {state.idtest_suite === "" ? (
                            <StyledTableCell align="center">
                              {row?.Width !== "" &&
                                row?.Width !== undefined &&
                                row?.Height !== "" &&
                                row?.Height !== undefined &&
                                `${row?.Width}X${row?.Height}`}
                            </StyledTableCell>
                          ) : (
                            ""
                          )}
                          <StyledTableCell align="center">{row.Status}</StyledTableCell>
                          {state.idtest_suite !== "" ? (
                            <StyledTableCell align="center">
                              {row.totalCount}/{row.passCount}/{row.failCount}
                            </StyledTableCell>
                          ) : (
                            ""
                          )}
                          {state.idtest_suite !== "" ? (
                            <StyledTableCell align="center">
                              <FormControl>
                                <Select
                                  variant="standard"
                                  value={selectedValues[index] || "ALL"}
                                  onChange={(event) => {
                                    handleValueChange(event, index);
                                    setSelectedRow(null);
                                    setStatusTypeValue(event.target.value);
                                    closePopUp();
                                  }}
                                >
                                  <MenuItem value="ALL">All</MenuItem>
                                  <MenuItem value="FAIL">Only Fail</MenuItem>
                                  <MenuItem value="STARTED">In-Complete</MenuItem>
                                  <MenuItem value="BOTH">Both Failed and InComplete</MenuItem>
                                </Select>
                              </FormControl>
                            </StyledTableCell>
                          ) : (
                            ""
                          )}
                          {row.Status !== "STARTED" &&
                          row.Status !== "RUNNING" &&
                          state.idtest_suite !== "" ? (
                            <StyledTableCell align="center">
                              <Tooltip title={"Execute"}>
                                <IconButton
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    openPopUp();
                                    setTestSuiteResultId(row.idtest_suite_results);
                                    setStopSuitePopup(false);
                                    setNameChange(false);
                                    setRefreshFlag(true);
                                  }}
                                  style={{
                                    color: colors.primaryColor,
                                    backgroundColor: colors.secondaryColor,
                                    // fontSize: '32px',
                                  }}
                                >
                                  <VerifiedSharpIcon style={{ fontSize: 25 }} />
                                </IconButton>
                              </Tooltip>
                            </StyledTableCell>
                          ) : (
                            ""
                          )}
                          {(row.Status === "STARTED" || row.Status === "RUNNING") &&
                          state.idtest_suite !== "" ? (
                            <StyledTableCell align="center">
                              <Tooltip title={"Stop"}>
                                <IconButton
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    setStopType("suite");
                                    setStopSuitePopup(!stopSuitePopup);
                                    setStopSuiteid(row.idtest_suite_results);
                                    setStopButtonHandler(false);
                                    closePopUp();
                                  }}
                                  style={{
                                    color: colors.primaryColor,
                                    backgroundColor: colors.secondaryColor,
                                    // fontSize: '32px',
                                  }}
                                >
                                  <DoDisturbAltRoundedIcon style={{ fontSize: 25 }} />
                                </IconButton>
                              </Tooltip>
                            </StyledTableCell>
                          ) : (
                            ""
                          )}
                          {state.idtest_suite !== "" && (
                            <Tooltip title={"Download Test Suite"}>
                              <IconButton
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  getSuiteCaseDownload(row.idtest_suite_results);
                                }}
                                style={{
                                  color: colors.primaryColor,
                                  backgroundColor: colors.secondaryColor,
                                  marginTop: "10px",
                                  // fontSize: '32px',
                                }}
                              >
                                <DownloadIcon style={{ fontSize: 20 }} />
                              </IconButton>
                            </Tooltip>
                          )}
                          <StyledTableCell align="center">
                            <IconButton
                              color="inherit"
                              aria-label="expand more"
                              component="label"
                              onClick={() => {
                                setRefreshFlag(false);
                                clearInterval(window.ExecuteTimer);
                                if (row.idtest_suite_results) {
                                  getTestCaseFromTestSuiteResult(
                                    row.idtest_suite_results,
                                    selectedValues[index] || "ALL",
                                  );
                                } else {
                                  setState({
                                    ...state,
                                    modaltcid: row.Test_Case_Id,
                                    modaltcname: row.testcase,
                                    modallogfile: row.logfile,
                                  });
                                  getTestStepResult(row.idtest_case_results);
                                  fetchTC(row.idtest_case_results);
                                }
                                if (SelectedRow !== row) {
                                  setSelectedRow(row);
                                } else setSelectedRow(null);
                              }}
                            >
                              <ExpandMoreIcon />
                            </IconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                        {SelectedRow === row && (
                          <>
                            {row.idtest_suite_results ? (
                              <StyledTableRow>
                                <StyledTableCell
                                  align="center"
                                  colSpan={8}
                                  style={{ height: "5rem" }}
                                >
                                  <Box style={{ margin: 1 }}>
                                    <Table>
                                      <TableHead>
                                        <StyledTableRow>
                                          <StyledTableCell>Executed_Date</StyledTableCell>
                                          <StyledTableCell align="center">Log File</StyledTableCell>
                                          <StyledTableCell align="center">
                                            Executed_By
                                          </StyledTableCell>
                                          <StyledTableCell align="center">
                                            Duration(s)
                                          </StyledTableCell>
                                          <StyledTableCell align="center">Browser</StyledTableCell>
                                          <StyledTableCell align="center">
                                            Environment Url
                                          </StyledTableCell>
                                          <StyledTableCell align="center">
                                            Resolution
                                          </StyledTableCell>
                                          <StyledTableCell align="center">Status</StyledTableCell>
                                          <StyledTableCell align="center"></StyledTableCell>
                                        </StyledTableRow>
                                      </TableHead>
                                      <TableBody>
                                        {/*when you click on fropdown inside test suite*/}
                                        {TestStepsResults.map((row, index) => (
                                          <React.Fragment key={index}>
                                            <StyledTableRow
                                              sx={{
                                                "&:last-child td, &:last-child th": {
                                                  border: 0,
                                                },
                                              }}
                                            >
                                              <StyledTableCell component="th" scope="row">
                                                {row.Executed_Date}
                                              </StyledTableCell>
                                              <StyledTableCell align="center">
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <Typography
                                                    component={Link}
                                                    href={row.logfile}
                                                    underline="always"
                                                    color="inherit"
                                                    target="_blank"
                                                  >
                                                    {row.testcase}
                                                  </Typography>
                                                  <Tooltip title={"View Test Case"}>
                                                    <ColorButton
                                                      size="small"
                                                      variant="contained"
                                                      onClick={() => {
                                                        navigate("/dashboard/recording", {
                                                          state: {
                                                            toRecordingPage: true,
                                                            idproducts: state.idproducts,
                                                            idmodules: row.ModuleId,
                                                            idtest_case: row.Test_Case_Id,
                                                            Test_Case: row.Test_Case_Id,
                                                            // details: "testcase",
                                                          },
                                                        });
                                                      }}
                                                      style={{
                                                        color: colors.primaryColor,
                                                        backgroundColor: colors.secondaryColor,
                                                        minWidth: 0,
                                                      }}
                                                    >
                                                      <RemoveRedEyeIcon style={{ fontSize: 20 }} />
                                                    </ColorButton>
                                                  </Tooltip>
                                                </div>
                                              </StyledTableCell>
                                              <StyledTableCell
                                                align="center"
                                                style={{
                                                  paddingRight: "50px",
                                                  paddingLeft: "50px",
                                                }}
                                              >
                                                {row.Executed_By}
                                              </StyledTableCell>
                                              <StyledTableCell align="center">
                                                {row.Duration}
                                              </StyledTableCell>
                                              <StyledTableCell align="center">
                                                {row.Browser}
                                              </StyledTableCell>
                                              <StyledTableCell align="center">
                                                {row?.envurl}
                                              </StyledTableCell>
                                              <StyledTableCell align="center">
                                                {row?.Width !== "" &&
                                                  row?.Width !== undefined &&
                                                  row?.Height !== "" &&
                                                  row?.Height !== undefined &&
                                                  `${row?.Width}X${row?.Height}`}
                                              </StyledTableCell>
                                              <StyledTableCell align="center">
                                                {row.Status}
                                              </StyledTableCell>
                                              <StyledTableCell align="center">
                                                {state.idtest_suite !== "" && (
                                                  <Tooltip title={"Download Test Case"}>
                                                    <IconButton
                                                      size="small"
                                                      variant="contained"
                                                      onClick={() => {
                                                        getCaseResultDownload(
                                                          row.idtest_case_results,
                                                        );
                                                      }}
                                                      style={{
                                                        color: colors.primaryColor,
                                                        backgroundColor: colors.secondaryColor,
                                                        marginTop: "10px",
                                                        // fontSize: '32px',
                                                      }}
                                                    >
                                                      <DownloadIcon style={{ fontSize: 20 }} />
                                                    </IconButton>
                                                  </Tooltip>
                                                )}
                                              </StyledTableCell>
                                              <StyledTableCell align="center">
                                                <IconButton
                                                  color="inherit"
                                                  aria-label="expand more"
                                                  component="label"
                                                  onClick={() => {
                                                    setState({
                                                      ...state,
                                                      modaltcid: row.Test_Case_Id,
                                                      modaltcname: row.testcase,
                                                      modallogfile: row.logfile,
                                                    });
                                                    getTestStepResult(row.idtest_case_results);

                                                    if (
                                                      SelectedTestCaseFromTestSuiteResult !== row
                                                    ) {
                                                      setSelectedTestCaseFromTestSuiteResult(row);
                                                    } else
                                                      setSelectedTestCaseFromTestSuiteResult(null);
                                                  }}
                                                >
                                                  <ExpandMoreIcon />
                                                </IconButton>
                                              </StyledTableCell>
                                            </StyledTableRow>
                                            {SelectedTestCaseFromTestSuiteResult === row && (
                                              <StyledTableRow>
                                                <StyledTableCell
                                                  align="center"
                                                  colSpan={8}
                                                  style={{ height: "5rem" }}
                                                >
                                                  <Box style={{ margin: 1 }}>
                                                    <Table>
                                                      <TableHead>
                                                        <StyledTableRow>
                                                          <StyledTableCell>
                                                            Step Number
                                                          </StyledTableCell>
                                                          <StyledTableCell>
                                                            Element Description
                                                          </StyledTableCell>
                                                          <StyledTableCell align="center">
                                                            Keyword
                                                          </StyledTableCell>
                                                          <StyledTableCell align="center">
                                                            Action
                                                          </StyledTableCell>
                                                          <StyledTableCell align="center">
                                                            Flow
                                                          </StyledTableCell>
                                                          <StyledTableCell align="center">
                                                            TestData
                                                          </StyledTableCell>
                                                          <StyledTableCell align="center">
                                                            Duration(s)
                                                          </StyledTableCell>
                                                          <StyledTableCell align="center">
                                                            Status
                                                          </StyledTableCell>
                                                          <StyledTableCell align="center">
                                                            Screenshot
                                                          </StyledTableCell>
                                                          <StyledTableCell align="center" />
                                                        </StyledTableRow>
                                                      </TableHead>
                                                      <TableBody>
                                                        {console.log("tbdata....", TestStepResults)}
                                                        {/*when you click inside test case gropdown*/}
                                                        {TestStepResults.map((subitem, index) => (
                                                          <React.Fragment key={index}>
                                                            <StyledTableRow
                                                              key={subitem.action}
                                                              sx={{
                                                                "&:last-child td, &:last-child th":
                                                                  {
                                                                    border: 0,
                                                                  },
                                                              }}
                                                            >
                                                              <StyledTableCell
                                                                component="th"
                                                                scope="row"
                                                              >
                                                                {subitem.Step_Number}
                                                              </StyledTableCell>
                                                              <StyledTableCell
                                                                component="th"
                                                                scope="row"
                                                              >
                                                                {subitem.Page_Description?.length <=
                                                                40 ? (
                                                                  subitem.Page_Description
                                                                ) : (
                                                                  <BootstrapTooltip
                                                                    title={
                                                                      <React.Fragment>
                                                                        <Typography color="inherit">
                                                                          {subitem.Page_Description}
                                                                        </Typography>
                                                                      </React.Fragment>
                                                                    }
                                                                    arrow
                                                                  >
                                                                    <div>
                                                                      {subitem.Page_Description?.slice(
                                                                        0,
                                                                        40,
                                                                      ) + "..."}
                                                                    </div>
                                                                  </BootstrapTooltip>
                                                                )}
                                                              </StyledTableCell>
                                                              <StyledTableCell align="center">
                                                                {subitem.Keyword}
                                                              </StyledTableCell>
                                                              <StyledTableCell align="center">
                                                                {subitem.Action}
                                                              </StyledTableCell>
                                                              <StyledTableCell align="center">
                                                                {subitem.Flow}
                                                              </StyledTableCell>
                                                              <StyledTableCell align="center">
                                                                {subitem.TestData?.slice(0, 40) +
                                                                  "..."}
                                                              </StyledTableCell>
                                                              <StyledTableCell align="center">
                                                                {subitem.test_step_duration}
                                                              </StyledTableCell>
                                                              <StyledTableCell align="center">
                                                                {subitem.test_step_status}
                                                              </StyledTableCell>
                                                              <StyledTableCell align="center">
                                                                {subitem.Failure_Screenshot_Location && (
                                                                  <Link
                                                                    onClick={(event) => {
                                                                      handleClickOpenTwo(
                                                                        TestStepResults,
                                                                        index,
                                                                      );
                                                                      setTestStepIdResult(
                                                                        subitem.idtest_step_result,
                                                                      );
                                                                    }}
                                                                    underline="hover"
                                                                  >
                                                                    Screenshot
                                                                  </Link>
                                                                )}
                                                              </StyledTableCell>

                                                              <StyledTableCell align="right">
                                                                {!(
                                                                  subitem.test_step_status ===
                                                                  "PASS"
                                                                ) && (
                                                                  <Box
                                                                    Box
                                                                    style={{
                                                                      display: "flex",
                                                                    }}
                                                                  >
                                                                    {/* <IconButton
                                                                        color="inherit"
                                                                        aria-label="expand more"
                                                                        component="label"
                                                                        onClick={() => {
                                                                          if (
                                                                            SelectedFailRow !==
                                                                            subitem
                                                                          ) {
                                                                            setSelectedFailRow(
                                                                              subitem
                                                                            );
                                                                          } else
                                                                            setSelectedFailRow(
                                                                              null
                                                                            );
                                                                        }}
                                                                      >
                                                                        <ExpandMoreIcon />
                                                                      </IconButton> */}
                                                                    <IconButton
                                                                      color="inherit"
                                                                      aria-label="expand more"
                                                                      component="label"
                                                                      onClick={(event) => {
                                                                        event.persist();
                                                                        setShowFailTest(true);
                                                                        getTestStepFailResult(
                                                                          subitem.idtest_step_result,
                                                                        );
                                                                      }}
                                                                    >
                                                                      <TroubleshootIcon />
                                                                    </IconButton>
                                                                  </Box>
                                                                )}
                                                              </StyledTableCell>
                                                            </StyledTableRow>
                                                            {SelectedFailRow?.idtest_step_result ===
                                                              subitem?.idtest_step_result && (
                                                              <StyledTableRow
                                                                key={row.action}
                                                                sx={{
                                                                  "&:last-child td, &:last-child th":
                                                                    {
                                                                      border: 0,
                                                                    },
                                                                }}
                                                              >
                                                                <StyledTableCell
                                                                  align="left"
                                                                  colSpan={12}
                                                                >
                                                                  <Typography
                                                                    sx={{
                                                                      color: "#f00",
                                                                      ":hover": {
                                                                        textDecoration: "underline",
                                                                      },
                                                                    }}
                                                                  >
                                                                    {"Issue: "}
                                                                  </Typography>
                                                                  <Typography
                                                                    sx={{
                                                                      ":hover": {
                                                                        textDecoration: "underline",
                                                                      },
                                                                    }}
                                                                  >
                                                                    {SelectedFailRow?.issues}
                                                                  </Typography>
                                                                </StyledTableCell>
                                                              </StyledTableRow>
                                                            )}
                                                          </React.Fragment>
                                                        ))}
                                                      </TableBody>
                                                    </Table>
                                                  </Box>
                                                </StyledTableCell>
                                              </StyledTableRow>
                                            )}
                                          </React.Fragment>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </Box>
                                </StyledTableCell>
                              </StyledTableRow>
                            ) : (
                              <StyledTableRow>
                                <StyledTableCell
                                  align="center"
                                  colSpan={8}
                                  style={{ height: "5rem" }}
                                >
                                  <Box style={{ margin: 1 }}>
                                    <Table>
                                      <TableHead>
                                        <StyledTableRow>
                                          <StyledTableCell>Step Number</StyledTableCell>
                                          <StyledTableCell>Element Description</StyledTableCell>
                                          <StyledTableCell align="center">Keyword</StyledTableCell>
                                          <StyledTableCell align="center">Action</StyledTableCell>
                                          <StyledTableCell align="center">Flow</StyledTableCell>
                                          <StyledTableCell align="center">TestData</StyledTableCell>
                                          <StyledTableCell align="center">
                                            Duration(s)
                                          </StyledTableCell>
                                          <StyledTableCell align="center">Status</StyledTableCell>
                                          <StyledTableCell align="center">
                                            Screenshot
                                          </StyledTableCell>
                                          <StyledTableCell align="center">
                                            Validate In
                                          </StyledTableCell>
                                          <StyledTableCell align="center" />
                                        </StyledTableRow>
                                      </TableHead>
                                      <TableBody>
                                        {/*when you click inside test case gropdown*/}
                                        {TestStepResults.map((subitem, index) => (
                                          <React.Fragment key={index}>
                                            <StyledTableRow
                                              key={subitem.action}
                                              sx={{
                                                "&:last-child td, &:last-child th": {
                                                  border: 0,
                                                },
                                              }}
                                            >
                                              <StyledTableCell component="th" scope="row">
                                                {subitem.Step_Number}
                                              </StyledTableCell>
                                              <StyledTableCell component="th" scope="row">
                                                {subitem.Page_Description}
                                              </StyledTableCell>
                                              <StyledTableCell align="center">
                                                {subitem.Keyword}
                                              </StyledTableCell>
                                              <StyledTableCell align="center">
                                                {subitem.Action}
                                              </StyledTableCell>
                                              <StyledTableCell align="center">
                                                {subitem.Flow}
                                              </StyledTableCell>
                                              <StyledTableCell align="center">
                                                {subitem.TestData?.length <= 40 ? (
                                                  subitem.TestData
                                                ) : (
                                                  <BootstrapTooltip
                                                    title={
                                                      <React.Fragment>
                                                        <Typography color="inherit">
                                                          {subitem.TestData}
                                                        </Typography>
                                                      </React.Fragment>
                                                    }
                                                    arrow
                                                  >
                                                    <div>
                                                      {subitem.TestData?.slice(0, 40) + "..."}
                                                    </div>
                                                  </BootstrapTooltip>
                                                )}
                                              </StyledTableCell>
                                              <StyledTableCell align="center">
                                                {subitem.test_step_duration}
                                              </StyledTableCell>
                                              <StyledTableCell align="center">
                                                {subitem.test_step_status}
                                              </StyledTableCell>
                                              <StyledTableCell align="center">
                                                {subitem.Failure_Screenshot_Location && (
                                                  <Link
                                                    onClick={(event) => {
                                                      handleClickOpenTwo(TestStepResults, index);
                                                      setTestStepIdResult(
                                                        subitem.idtest_step_result,
                                                      );
                                                      // if (subitem.test_step_status === "FAIL") {
                                                      //   getScreenShotDiff({
                                                      //     tcid: state.idtest_case,
                                                      //     tcrid: subitem.Test_Case_Results_Id,
                                                      //     failScreenshot: encodeURIComponent(subitem.Failure_Screenshot_Location),
                                                      //     tsid: subitem.Test_Step,
                                                      //     stepNumber: subitem.Step_Number
                                                      //   })
                                                      // }
                                                    }}
                                                    underline="hover"
                                                  >
                                                    Screenshot
                                                  </Link>
                                                )}
                                              </StyledTableCell>
                                              <StyledTableCell align="center">
                                                {subitem.ValDevice}
                                              </StyledTableCell>
                                              <StyledTableCell align="right">
                                                {!(subitem.test_step_status === "PASS") && (
                                                  <Box Box style={{ display: "flex" }}>
                                                    <IconButton
                                                      color="inherit"
                                                      aria-label="expand more"
                                                      component="label"
                                                      onClick={(event) => {
                                                        event.persist();
                                                        setShowFailTest(true);
                                                        getTestStepFailResult(
                                                          subitem.idtest_step_result,
                                                        );
                                                      }}
                                                    >
                                                      <TroubleshootIcon />
                                                    </IconButton>
                                                  </Box>
                                                )}
                                              </StyledTableCell>
                                            </StyledTableRow>
                                            {SelectedFailRow?.idtest_step_result ===
                                              subitem?.idtest_step_result && (
                                              <StyledTableRow
                                                key={row.action}
                                                sx={{
                                                  "&:last-child td, &:last-child th": {
                                                    border: 0,
                                                  },
                                                }}
                                              >
                                                <StyledTableCell align="left" colSpan={12}>
                                                  <Typography
                                                    sx={{
                                                      color: "#f00",
                                                      ":hover": {
                                                        textDecoration: "underline",
                                                      },
                                                    }}
                                                  >
                                                    {"Issue: "}
                                                  </Typography>
                                                  <Typography
                                                    sx={{
                                                      ":hover": {
                                                        textDecoration: "underline",
                                                      },
                                                    }}
                                                  >
                                                    {SelectedFailRow?.issues}
                                                  </Typography>
                                                </StyledTableCell>
                                              </StyledTableRow>
                                            )}
                                          </React.Fragment>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </Box>
                                </StyledTableCell>
                              </StyledTableRow>
                            )}
                          </>
                        )}
                      </React.Fragment>
                    ))
                  ) : (
                    <StyledTableRow>
                      <StyledTableCell align="center" colSpan={10} style={{ fontWeight: "bold" }}>
                        <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                          No Data Available to show
                        </Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </>
            )}
          </Table>
        </div>
      </TableContainer>
      {openAlert && (
        <Alert
          variant="filled"
          severity="success"
          onClose={() => {
            handleCloseAlert();
          }}
          open={openAlert}
          className="alert"
        >
          The data is successfully saved.
        </Alert>
      )}
      {openDeleteAlert && (
        <Alert
          variant="filled"
          severity="warning"
          onClose={handleCloseDeleteAlert}
          open={openDeleteAlert}
          className="delete-alert"
        >
          <span className="delete-text">Are you sure you want to delete this step.?</span>
          <div>
            <ColorButton
              className="delete-button-yes"
              onClick={(event) => {
                deleteTableField(deleteIconValueTestStep, deleteIconValueTestCase);
                handleCloseDeleteAlert();
              }}
            >
              Yes
            </ColorButton>
            <ColorButton className="delete-button-no" onClick={handleCloseDeleteAlert}>
              No
            </ColorButton>
          </div>
        </Alert>
      )}

      {openDialog && (
        <ScreenshotModal
          className={"custom-dialog"}
          handleClose={handleClose}
          valuesSaved={valuesSaved}
          oneTimeTestCaseObject={oneTimeTestCaseObject}
          indexValue={indexValue}
          logFile={state?.modallogfile}
          tcid={state?.modaltcid}
          tcname={state?.modaltcname}
          testStepIdResult={testStepIdResult}
          productId={state.idproducts}
        />
      )}

      {openDialogResult && (
        <ResultScreenShotModal
          handleCloseResult={handleCloseResult}
          valuesSavedResult={valuesSavedResult}
          indexValue={indexValue}
          testStepName={testStepName}
        />
      )}

      {openDialogScreenShotCompare && (
        <ScreenshotComparison Values={screenshotList} handleClose={handleCloseScreenShotCompare} />
      )}

      {stopSuitePopup && (
        <Alert
          variant="filled"
          severity="warning"
          className="stop-suite"
          style={{
            backgroundColor: "rgb(195, 210, 235) ",
            border: " 4px black solid",
          }}
        >
          <span className="stop-text">Are you sure you want to stop execution of this Suite?</span>
          <div>
            <ColorButton
              size="small"
              variant="contained"
              sx={{ margin: "10px 5px 10px 140px" }}
              onClick={() => {
                postStopTestSuiteCache(stopSuiteid, stopType);
                setStopSuitePopup(false);
              }}
            >
              yes
            </ColorButton>
            <ColorButton
              size="small"
              variant="contained"
              sx={{ margin: "10px 140px 10px 5px" }}
              onClick={() => setStopSuitePopup(!stopSuitePopup)}
            >
              no
            </ColorButton>
          </div>
        </Alert>
      )}

      {executePage && (
        <Execute
          suitesList={suiteforexeute}
          suiteexecute={execuitesuite}
          environments={environments}
          cancelFunc={closePopUp}
          className="exicutepage"
          browsers={browser}
          resolutions={resolution}
          statustype={statusTypeValue}
          tsrId={testSuiteResultId}
          product={state.idproducts}
        />
      )}
    </div>
  );
};

export default TCTable;
