import React from "react";

const RenameIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="currentColor"
  >
    <path
      d="M2.85497 9.79386L9.96475 2.68407L8.9735 1.69282L1.86372 8.80261V9.79386H2.85497ZM3.43611 11.1959H0.46167V8.22146L8.47788 0.20525C8.60934 0.0738287 8.78762 0 8.9735 0C9.15939 0 9.33766 0.0738287 9.46913 0.20525L11.4523 2.18845C11.5837 2.31991 11.6576 2.49818 11.6576 2.68407C11.6576 2.86996 11.5837 3.04823 11.4523 3.17969L3.43611 11.1959ZM0.46167 12.598H13.0801V14H0.46167V12.598Z"
      fill="currentColor"
    />
  </svg>
);

export default RenameIcon;
