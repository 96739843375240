import { Box, Button, Pagination } from "@mui/material";
import React, { useRef, useState } from "react";
import { useItemWidth } from "../../hooks";
import TestSuiteCard from "./TestSuiteCard";
import useDeleteTestCaseFromTestSuiteQuery from "../../useQuery/useDeleteTestCaseFromTestSuiteQuery";
import CustomDialog from "../../components-new/common/CustomDialog";

export default function TestSuiteCases({
  data,
  itemsPerPage,
  showTestCases,
  itemsPerRow,
  testSuiteId = null,
}) {
  const containerRef = useRef(null);
  const gap = 16;
  const itemWidth = useItemWidth(containerRef, itemsPerRow, gap);

  const [testSuitePage, setTestSuitePage] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [caseToBeDeleted, setCaseToBeDeleted] = useState(null);
  const { mutate: deleteTestCase } = useDeleteTestCaseFromTestSuiteQuery();

  const paginatedData = (data ?? []).slice(
    (testSuitePage - 1) * itemsPerPage,
    testSuitePage * itemsPerPage,
  );

  const handleDeleteClick = (e, suite) => {
    e.stopPropagation();
    setShowDeleteModal(true);
    setCaseToBeDeleted([suite]);
  };

  const DeleteAction = () => {
    return (
      <>
        <Button
          sx={{ color: "#ff5336" }}
          onClick={() => {
            setCaseToBeDeleted(null);
            setShowDeleteModal(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ background: "#ff5336" }}
          onClick={() => {
            deleteTestCase({ id: testSuiteId, selectedcases: caseToBeDeleted });
            setShowDeleteModal(false);
            setCaseToBeDeleted(null);
          }}
        >
          Delete
        </Button>
      </>
    );
  };

  return (
    <>
      <CustomDialog
        title={"Are you sure you want to delete this Test Case?"}
        open={showDeleteModal}
        actions={<DeleteAction />}
      />
      <Box
        width={"100%"}
        py={1}
        ref={containerRef}
        display="flex"
        columnGap={2}
        rowGap={2}
        flexWrap="wrap"
      >
        {paginatedData?.length > 0
          ? paginatedData?.map((suite) => (
              <TestSuiteCard
                item={suite}
                width={itemWidth}
                isFolder={!showTestCases}
                key={suite.idtest_case}
                testSuiteId={testSuiteId}
                handleDeleteClick={(e) => handleDeleteClick(e, suite)}
              />
            ))
          : null}
        {data?.length < 1 && <Box>No Test Case Available</Box>}
      </Box>
      {data?.length > itemsPerPage && (
        <Box py={2} display="flex" justifyContent="center">
          <Pagination
            count={Math.ceil(data.flat().length / itemsPerPage)}
            page={testSuitePage}
            onChange={(e, newPage) => setTestSuitePage(newPage)}
            color="primary"
            variant="outlined"
            shape="rounded"
          />
        </Box>
      )}
    </>
  );
}
