import React, { useState } from "react";
import { Box, Tabs, Tab, Button } from "@mui/material";
import { CustomSelect, FileUpload } from "../../../components-new/common";
import { styled } from "@mui/system";
import { useListOfScriptsQuery, useUploadFileQuery } from "../../../useQuery";

const StyledButton = styled(Button)(() => ({
  textTransform: "capitalize",
  background: "#FF5336",
  borderRadius: "8px",
  padding: "11px 35px",
  width: "125px",
  height: "42px",
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: "transparent",
  width: "auto",
  "& .MuiTabs-flexContainer": {
    columnGap: "2px",
  },
  "& .MuiTabs-indicator": {
    background: `#FF5336`,
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  background: "#ffffff",
  borderBottom: "1px solid #E2E6F3",
  color: "#605555",
  textTransform: "capitalize",
  fontSize: "14px",
  "&.Mui-selected": {
    border: 0,
    background: `#ffffff`,
    color: "#000000",
  },
  "&:hover": {
    backgroundColor: "#ffffff",
  },
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <>{children}</>}
    </div>
  );
}

const Script = (item) => {
  const [value, setValue] = useState(0);
  const { data: scriptsList } = useListOfScriptsQuery();
  const { mutate: uploadFile } = useUploadFileQuery();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleUpload = (selectedFile) => {
    if (selectedFile) {
      uploadFile({
        file: selectedFile,
        testStepId: item.item.idtest_step,
        testCaseId: item.item.Test_Case_Id,
      });
    } else {
      console.log("No file selected");
    }
  };

  return (
    <Box pr={3}>
      <Box sx={{ borderBottom: 1, borderColor: "#d1dbff" }}>
        <StyledTabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <StyledTab label="Select Script" />
          <StyledTab label="Upload Script" />
        </StyledTabs>
      </Box>
      <Box>
        <CustomTabPanel value={value} index={0}>
          <Box mt={3} display="flex" alignItems="center" columnGap={3}>
            <CustomSelect
              width="318px"
              label="Select File"
              options={(scriptsList ?? [{ label: "", value: "" }]).map((item) => ({
                label: item.FileName,
                value: item.FileName,
              }))}
            />
            <StyledButton variant="contained" color="warning" sx={{ mt: 2 }}>
              Select
            </StyledButton>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <FileUpload handleUpload={handleUpload} />
        </CustomTabPanel>
      </Box>
    </Box>
  );
};

export default Script;
