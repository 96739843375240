import { Box, Button, IconButton, InputAdornment } from "@mui/material";
import { CustomInput, CustomSelect } from "../../../components-new/common";
import { AssertionTargetOptions, scopeOptionList } from "../constants";
import { useDispatch, useSelector } from "react-redux";
import { setEditStep } from "../../../Redux/TestAuthoring/TestAuthoring";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  useAllApiQuery,
  useModuleQuery,
  useProductQuery,
  useDownloadedFileQuery,
  useRecordedTestDataListQuery,
  useUpdateAssertionQuery,
  useFileDataNameQuery,
  useTestCaseQuery,
} from "../../../useQuery";
import { useEffect, useState } from "react";
import { useNotification } from "../../../hooks";
import { SearchIcon } from "../../../components-new/icons";

const AssertionSetup = ({ item }) => {
  const dispatch = useDispatch();
  const notify = useNotification();
  const [testCaseSearchKeyword, setTestCaseSearchKeyword] = useState("");
  const [selectedTestCase, setSelectedTestCase] = useState(item.DependentTC || "");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedModule, setSelectedModule] = useState("");
  const [fileNameType, setFileNameType] = useState("");

  const selectedProductID = useSelector((state) => state.authoringSlice.selectedProduct);

  const { data: products } = useProductQuery();
  const { data: downloadedFiles } = useDownloadedFileQuery(item.Test_Case_Id);
  const {
    data: refFiles,
    refetch: refetchFiles,
    isSuccess: isRefFilesSuccess,
  } = useFileDataNameQuery(selectedProductID);
  const { data: recordedTestDataList, refetch: refetchRecordedTestDataList } =
    useRecordedTestDataListQuery(item.idtest_step, selectedTestCase);

  const productKey = item.apiproduct === 0 ? item.DependentProduct : item.apiproduct;

  const {
    data,
    mutate: updateAssertionData,
    isSuccess: isUpdateSuccess,
    isError,
  } = useUpdateAssertionQuery();

  const { data: modules, refetch: refetchModules } = useModuleQuery(productKey, {
    enabled: item.apiproduct !== 0 || !item.DependentProduct,
  });

  const { data: allApi, refetch: refetchAllApi } = useAllApiQuery(
    item.apimodule || item.DependentModule,
    {
      enabled: item.apimodule !== 0 || !item.DependentModule,
    },
  );

  const { data: testCases, refetch: refetchTestCases } = useTestCaseQuery(
    item.apimodule || item.DependentModule,
    testCaseSearchKeyword,
    {
      enabled: item.apimodule !== 0 || item.DependentModule !== 0,
    },
  );

  useEffect(() => {
    refetchModules();
  }, [refetchModules, selectedProduct]);

  useEffect(() => {
    refetchTestCases();
  }, [refetchTestCases, selectedModule]);

  useEffect(() => {
    if (item.ValDevice === "DB" && isError) {
      notify("Please enter valid query", "error");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (item.ValDevice === "InRefrenceFile") {
      if (item.filename) {
        const simulatedEvent = {
          target: {
            value: item.filename,
          },
        };
        handleRefFileChange(simulatedEvent);
      }
      if (!item.scope) {
        handleChange("Single Run", "scope");
      }
    }

    if (item.ValDevice === "InFile") {
      if (item.filename) {
        const simulatedEvent = {
          target: {
            value: item.filename,
          },
        };
        handleRefFileChange(simulatedEvent);
      }
      if (!item.scope) {
        handleChange("Single Run", "scope");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefFilesSuccess, item.filename, item.ValDevice, item.scope]);

  useEffect(() => {
    if (selectedTestCase !== "" && selectedTestCase !== 0) {
      refetchRecordedTestDataList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTestCase]);

  useEffect(() => {
    if (item.ValDevice === "UseVariableFromTheScenario") {
      setSelectedTestCase(item.Test_Case_Id);
    }
    if (item.ValDevice === "InRefrenceFile") {
      refetchFiles();
    }
  }, [item.DependentTC, item.Test_Case_Id, item.ValDevice, refetchFiles]);

  useEffect(() => {
    if (isUpdateSuccess) {
      const updatedData = data?.data?.data;
      if (updatedData && Object.values(updatedData)?.length) {
        const findStepByNumber = Object.values(updatedData).find(
          (step) => step.Step_Number === item.Step_Number,
        );

        dispatch(setEditStep({ ...item, ...findStepByNumber }));
      }

      notify("The item has been updated successfully.", "success");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateSuccess]);

  const handleChange = (value, field) => {
    dispatch(setEditStep({ ...item, [field]: value }));
  };
  // const handleOptionChange = (event, value) => {
  //   const tempTC = testCases.filter((item) => item.Test_Case === value);
  //   setFilteredTestDataList([]);
  //   setSelectedTestCase(tempTC?.[0]?.idtest_case);
  //   handleChangeMultiple({
  //     idtest_case_One: tempTC?.[0]?.idtest_case,
  //     useVar: tempTC?.[0]?.Test_Case,
  //   });
  //   setTestCaseSearchKeyword(value);
  //   refetchRecordedTestDataList();
  // };

  // const handleSearchClick = () => {
  //   handleChange(selectedTestCase, "DependentTC");
  //   refetchRecordedTestDataList();
  // };

  const handleDateChange = (value, field, testdata) => {
    const parts = testdata.split("~");
    const index = field === "date" ? 2 : 3;
    parts[index] = value;
    const updatedTestData = parts.join("~");

    dispatch(setEditStep({ ...item, TestData: updatedTestData }));
  };

  const handleChangeMultiple = (value) => {
    dispatch(setEditStep({ ...item, ...value }));
  };

  const handleRefFileChange = (event) => {
    const filename = event.target.value;
    if (filename) {
      const parts = filename.split(".");
      const fileExtension = parts.pop();
      handleChangeMultiple({ filename: filename, fromFileType: fileExtension });
      setFileNameType(fileExtension);
    }
  };

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      const filename = event.target.files[0].name;
      const parts = filename.split(".");
      const fileExtension = parts.pop();
      handleChangeMultiple({ filename: event.target.files[0].name, fromFileType: fileExtension });
      setFileNameType(fileExtension);
    }
  };

  const handleSubmit = () => {
    updateAssertionData(item);
  };

  const handleTestCaseSearch = () => {
    refetchTestCases();
  };

  return (
    <Box>
      <Box mb={3}>
        <CustomSelect
          label={"Assertion Target"}
          options={AssertionTargetOptions}
          width={"100%"}
          background={"#ffffff"}
          value={item.ValDevice}
          onChange={(e) => handleChange(e.target.value, "ValDevice")}
        />
      </Box>
      {item.ValDevice === "FromEmail" && (
        <Box display="flex" columnGap={2}>
          <CustomInput
            onChange={(e) => handleChange(e.target.value, "customerEmail")}
            placeholder="Enter email"
            labelinput="Email"
            value={item.customerEmail || ""}
            flex={1}
          />
          <CustomInput
            type="password"
            placeholder="Enter password"
            labelinput="Password"
            value={item.customerPassword || ""}
            onChange={(e) => handleChange(e.target.value, "customerPassword")}
            flex={1}
          />
          <CustomInput
            placeholder="Enter criterion"
            labelinput="Selection Criterion"
            value={item.EmailSelectionCriteria || ""}
            onChange={(e) => handleChange(e.target.value, "EmailSelectionCriteria")}
            flex={1}
          />
          <CustomInput
            placeholder="Enter filter"
            labelinput="Filter"
            value={item.EmailFilter || ""}
            onChange={(e) => handleChange(e.target.value, "EmailFilter")}
            flex={1}
          />
        </Box>
      )}
      {item.ValDevice === "ChangeTestData" && (
        <CustomInput
          placeholder="Enter new data"
          labelinput="New Data"
          value={item.TestData || ""}
          onChange={(e) => handleChange(e.target.value, "TestData")}
          flex={1}
        />
      )}
      {item.ValDevice === "Date" && (
        <Box display="flex" columnGap={2}>
          <CustomInput
            placeholder="Enter date"
            labelinput="Date"
            value={item.TestData.split("~")[2] || ""}
            onChange={(e) => handleDateChange(e.target.value, "date", item.TestData)}
            flex={1}
          />
          <CustomInput
            placeholder="Enter date format"
            labelinput="Date format"
            value={item.TestData.split("~")[3] || ""}
            onChange={(e) => handleDateChange(e.target.value, "dateformat", item.TestData)}
            flex={1}
          />
        </Box>
      )}
      {item.ValDevice === "InApi" && (
        <>
          <Box display="flex" columnGap={2} mb={2}>
            <CustomSelect
              label={"Product"}
              options={products.map((prodItem) => ({
                label: prodItem.productname,
                value: prodItem.idproducts,
              }))}
              width={"100%"}
              background={"#ffffff"}
              value={item.apiproduct}
              onChange={(e) => {
                setSelectedProduct(e.target.value);
                handleChange(e.target.value, "apiproduct");
              }}
            />
            {item.apiproduct !== "" && item.apiproduct !== 0 && (
              <CustomSelect
                label={"Module"}
                options={(Array.isArray(modules) ? modules : []).map((modItem) => ({
                  label: modItem.modulename,
                  value: modItem.idmodules,
                }))}
                width={"100%"}
                background={"#ffffff"}
                value={item.apimodule}
                onChange={(e) => {
                  setSelectedModule(e.target.value);
                  handleChange(e.target.value, "apimodule");
                  refetchAllApi();
                }}
              />
            )}
            {item.apimodule !== "" && item.apimodule !== 0 && (
              <CustomSelect
                label={"Api name"}
                options={(Array.isArray(allApi) ? allApi : []).map((apiItem) => ({
                  label: apiItem.name,
                  value: apiItem.idapi,
                }))}
                width={"100%"}
                background={"#ffffff"}
                value={item.apiid}
                onChange={(e) => {
                  handleChange(e.target.value, "apiid");
                }}
                disabled={!item.apimodule}
              />
            )}
            <Button
              variant="text"
              sx={{ marginTop: "24px" }}
              onClick={() => {
                handleChangeMultiple({
                  apimodule: "",
                  apiName: "",
                  apiproduct: "",
                  apiId: "",
                  apiQuery: "",
                  apiParam: "",
                });
              }}
            >
              Clear
            </Button>
          </Box>
          <Box display="flex" columnGap={2}>
            <CustomInput
              placeholder="Enter api Param"
              labelinput="Api Param"
              value={item.apiparam || ""}
              onChange={(e) => handleChange(e.target.value, "apiparam")}
              flex={1}
            />
            <CustomInput
              placeholder="Enter api query"
              labelinput="Api Query"
              value={item.DBQuery || ""}
              onChange={(e) => handleChange(e.target.value, "DBQuery")}
              flex={1}
            />
          </Box>
        </>
      )}
      {item.ValDevice === "DB" && (
        <CustomInput
          placeholder="Enter DB query"
          labelinput="DB Query"
          value={item.DBQuery || ""}
          onChange={(e) => handleChange(e.target.value, "DBQuery")}
          flex={1}
        />
      )}
      {item.ValDevice === "Regex" && (
        <CustomInput
          placeholder="Enter Regex"
          labelinput="Regex"
          value={item.regex || ""}
          onChange={(e) => handleChange(e.target.value, "regex")}
          flex={1}
        />
      )}
      {item.ValDevice === "InDownloadedFile" && (
        <Box display="flex" columnGap={2}>
          <CustomSelect
            label={"Downloaded Files"}
            options={(downloadedFiles ?? [{ label: "", item: "" }]).map((fileItem) => ({
              label: fileItem,
              value: fileItem,
            }))}
            width={"100%"}
            background={"#ffffff"}
            value={item?.filename?.split("~")[0]}
            onChange={(e) => handleChange(e.target.value, "filename")}
          />
          <CustomInput
            placeholder="Enter assertion value"
            labelinput="Assertion value"
            value={item?.TestData}
            onChange={(e) => handleChange(e.target.value, "TestData")}
            flex={1}
          />
        </Box>
      )}
      {item.ValDevice === "UseVariableFromTheScenario" && (
        <CustomSelect
          label={"Use var from this scenario"}
          options={(recordedTestDataList?.list ?? [{ label: "", item: "" }]).map((fileItem) => ({
            label: fileItem.label || fileItem,
            value: fileItem.value || fileItem,
          }))}
          width={"100%"}
          background={"#ffffff"}
          value={item.TestData}
          onChange={(e) => handleChange(e.target.value, "TestData")}
        />
      )}
      {item.ValDevice === "InFile" && (
        <Box display="flex" columnGap={2}>
          <Button
            sx={{ height: " 50px", marginTop: "20px" }}
            size="medium"
            variant="contained"
            component={"label"}
            startIcon={<CloudUploadIcon />}
          >
            Upload file
            <input type="file" hidden onChange={handleFileChange} />
          </Button>
          <CustomInput
            labelinput="File Name"
            value={item.filename}
            variant="outlined"
            disabled
            InputProps={{
              readOnly: true,
            }}
          />
          {(fileNameType === "xlsx" || fileNameType === "csv") && (
            <>
              <CustomInput
                placeholder="Sheet"
                labelinput="Sheet"
                value={item.sheet}
                onChange={(e) => handleChange(e.target.value, "sheet")}
              />
              <CustomInput
                placeholder="Cell"
                labelinput="Cell"
                value={item.cell}
                onChange={(e) => handleChange(e.target.value, "cell")}
              />
              <CustomSelect
                label={"Scope"}
                options={(scopeOptionList ?? [{ label: "", item: "" }]).map((item) => ({
                  label: item.value,
                  value: item.label,
                }))}
                width={"100%"}
                background={"#ffffff"}
                value={item.scope || "Single Run"}
                onChange={(e) => handleChange(e.target.value, "scope")}
              />
              {item?.scope === scopeOptionList[0].value && (
                <CustomInput
                  placeholder="End Range"
                  labelinput="End Range"
                  value={item.endRange}
                  onChange={(e) => handleChange(e.target.value, "endRange")}
                />
              )}
            </>
          )}
          {fileNameType === "json" && (
            <>
              <CustomInput
                placeholder="Query"
                labelinput="Query"
                value={item.filter}
                onChange={(e) => handleChange(e.target.value, "filter")}
              />
              <CustomSelect
                label={"Scope"}
                options={(scopeOptionList ?? [{ label: "", item: "" }]).map((item) => ({
                  label: item.value,
                  value: item.label,
                }))}
                width={"100%"}
                background={"#ffffff"}
                value={item.scope || "Single Run"}
                onChange={(e) => handleChange(e.target.value, "scope")}
              />
              {item?.scope === scopeOptionList[0].value && (
                <CustomInput
                  placeholder="End Range"
                  labelinput="End Range"
                  value={item.endRange}
                  onChange={(e) => handleChange(e.target.value, "endRange")}
                />
              )}
            </>
          )}
        </Box>
      )}
      {item.ValDevice === "InGoogleSheet" && (
        <Box display="flex" columnGap={2}>
          <CustomInput
            placeholder="Sheet Id"
            labelinput="Sheet Id"
            value={item.filename}
            onChange={(e) => handleChange(e.target.value, "filename")}
          />
          <CustomInput
            placeholder="Sheet"
            labelinput="Sheet"
            value={item.sheet}
            onChange={(e) => handleChange(e.target.value, "sheet")}
          />
          <CustomInput
            placeholder="Cell"
            labelinput="Cell"
            value={item.cell}
            onChange={(e) => handleChange(e.target.value, "cell")}
          />
          <CustomSelect
            label={"Scope"}
            options={(scopeOptionList ?? [{ label: "", item: "" }]).map((item) => ({
              label: item.value,
              value: item.label,
            }))}
            width={"100%"}
            background={"#ffffff"}
            value={item.scope || "Single Run"}
            onChange={(e) => handleChange(e.target.value, "scope")}
          />
          {item?.scope === scopeOptionList[0].value && (
            <CustomInput
              placeholder="End Range"
              labelinput="End Range"
              value={item.endRange}
              onChange={(e) => handleChange(e.target.value, "endRange")}
            />
          )}
        </Box>
      )}
      {item.ValDevice === "InRefrenceFile" && (
        <Box display="flex" columnGap={2}>
          <CustomSelect
            label={"Select file"}
            options={
              Array.isArray(refFiles?.list) && refFiles?.list.length > 0
                ? refFiles.list.map((fileItem) => ({
                    label: fileItem.label || fileItem,
                    value: fileItem.value || fileItem,
                  }))
                : [{ label: "No files available", value: "" }]
            }
            width={"100%"}
            background={"#ffffff"}
            value={item.filename}
            onChange={handleRefFileChange}
          />
          {(fileNameType === "xlsx" || fileNameType === "csv") && (
            <>
              <CustomInput
                placeholder="Sheet"
                labelinput="Sheet"
                value={item.sheet}
                onChange={(e) => handleChange(e.target.value, "sheet")}
              />
              <CustomInput
                placeholder="Cell"
                labelinput="Cell"
                value={item.cell}
                onChange={(e) => handleChange(e.target.value, "cell")}
              />
              <CustomSelect
                label={"Scope"}
                options={(scopeOptionList ?? [{ label: "", item: "" }]).map((item) => ({
                  label: item.value,
                  value: item.label,
                }))}
                width={"100%"}
                background={"#ffffff"}
                value={item.scope || "Single Run"}
                onChange={(e) => handleChange(e.target.value, "scope")}
              />
              {item?.scope === scopeOptionList[0].value && (
                <CustomInput
                  placeholder="End Range"
                  labelinput="End Range"
                  value={item.endRange}
                  onChange={(e) => handleChange(e.target.value, "endRange")}
                />
              )}
            </>
          )}
          {fileNameType === "json" && (
            <>
              <CustomInput
                placeholder="Query"
                labelinput="Query"
                value={item.filter}
                onChange={(e) => handleChange(e.target.value, "filter")}
              />
              <CustomSelect
                label={"Scope"}
                options={(scopeOptionList ?? [{ label: "", item: "" }]).map((item) => ({
                  label: item.value,
                  value: item.label,
                }))}
                width={"100%"}
                background={"#ffffff"}
                value={item.scope || "Single Run"}
                onChange={(e) => handleChange(e.target.value, "scope")}
              />
              {item?.scope === scopeOptionList[0].value && (
                <CustomInput
                  placeholder="End Range"
                  labelinput="End Range"
                  value={item.endRange}
                  onChange={(e) => handleChange(e.target.value, "endRange")}
                />
              )}
            </>
          )}
        </Box>
      )}
      {item.ValDevice === "FromFile" && (
        <Box display="flex" columnGap={2} alignItems={"center"}>
          <Button
            sx={{ height: " 50px", marginTop: "10px" }}
            size="medium"
            variant="contained"
            component={"label"}
            startIcon={<CloudUploadIcon />}
          >
            Upload file
            <input type="file" hidden onChange={handleFileChange} />
          </Button>
          <CustomInput
            label="File Name"
            value={item.filename}
            variant="outlined"
            disabled
            InputProps={{
              readOnly: true,
            }}
            sx={{ marginTop: "10px" }}
          />
          {(fileNameType === "xlsx" || fileNameType === "csv") && (
            <>
              <CustomInput
                placeholder="Sheet"
                labelinput="Sheet"
                value={item.sheet}
                onChange={(e) => handleChange(e.target.value, "sheet")}
              />
              <CustomInput
                placeholder="Cell"
                labelinput="Cell"
                value={item.cell}
                onChange={(e) => handleChange(e.target.value, "cell")}
              />
              <CustomSelect
                label={"Scope"}
                options={(scopeOptionList ?? [{ label: "", item: "" }]).map((item) => ({
                  label: item.value,
                  value: item.label,
                }))}
                width={"100%"}
                background={"#ffffff"}
                value={item.scope || "Single Run"}
                onChange={(e) => handleChange(e.target.value, "scope")}
              />
              {item?.scope === scopeOptionList[0].value && (
                <CustomInput
                  placeholder="End Range"
                  labelinput="End Range"
                  value={item.endRange}
                  onChange={(e) => handleChange(e.target.value, "endRange")}
                />
              )}
            </>
          )}
          {fileNameType === "json" && (
            <>
              <CustomInput
                placeholder="Query"
                labelinput="Query"
                value={item.filter}
                onChange={(e) => handleChange(e.target.value, "filter")}
              />
              <CustomSelect
                label={"Scope"}
                options={(scopeOptionList ?? [{ label: "", item: "" }]).map((item) => ({
                  label: item.value,
                  value: item.label,
                }))}
                width={"100%"}
                background={"#ffffff"}
                value={item.scope || "Single Run"}
                onChange={(e) => handleChange(e.target.value, "scope")}
              />
              {item?.scope === scopeOptionList[0].value && (
                <CustomInput
                  placeholder="End Range"
                  labelinput="End Range"
                  value={item.endRange}
                  onChange={(e) => handleChange(e.target.value, "endRange")}
                />
              )}
            </>
          )}
        </Box>
      )}
      {item.ValDevice === "DependentonOtherTestScenario" && (
        <Box display="flex" columnGap={2} alignItems={"center"} rowGap={2} flexWrap={"wrap"}>
          <CustomSelect
            label={"Product"}
            options={products.map((prodItem) => ({
              label: prodItem.productname,
              value: prodItem.idproducts,
            }))}
            width={"100%"}
            background={"#ffffff"}
            value={item.DependentProduct}
            onChange={(e) => {
              setSelectedProduct(e.target.value);
              handleChangeMultiple({
                DependentModule: 0,
                DependentTC: 0,
                DependentProduct: e.target.value,
                apiproduct: e.target.value,
              });
              refetchModules();
            }}
          />
          {item.DependentProduct ? (
            <CustomSelect
              label={"Module"}
              options={(Array.isArray(modules) ? modules : []).map((modItem) => ({
                label: modItem.modulename,
                value: modItem.idmodules,
              }))}
              width={"100%"}
              background={"#ffffff"}
              value={item.DependentModule}
              onChange={(e) => {
                setSelectedModule(e.target.value);
                handleChangeMultiple({
                  DependentModule: e.target.value,
                  apimodule: e.target.value,
                  DependentTC: "",
                });
                refetchTestCases();
              }}
            />
          ) : null}
          {item.DependentModule ? (
            <Box display="flex" columnGap={2} alignItems={"center"}>
              {/* <AutocompleteComponent
                options={(Array.isArray(testCases) ? testCases : []).map((item) => {
                  return {
                    label: item.Test_Case,
                    value: item.idtest_case,
                  };
                })}
                value={item.DependentTC}
                onChange={(e, selected) => {
                  setSelectedTestCase(selected?.value);
                  handleChangeMultiple({
                    idtest_case_One: selected?.value,
                    useVar: selected?.label,
                    DependentTC: selected?.value,
                  });
                  refetchRecordedTestDataList();
                }}
                isLoading={isLoading}
                // onInputChange={handleOptionChange}
                handleSearchClick={handleSearchClick}
                placeholder="Search Test Cases"
                getOptionLabel={(testCase) => {
                  return testCase.label ?? "";
                }}
              /> */}
              <CustomSelect
                label={"Select Test Cases"}
                options={(Array.isArray(testCases) ? testCases : []).map((tc) => {
                  return {
                    label: tc.Test_Case,
                    value: tc.idtest_case,
                  };
                })}
                width={"300px"}
                background={"#ffffff"}
                value={item.DependentTC}
                onChange={(e) => {
                  handleChangeMultiple({ DependentTC: e.target.value });
                  setSelectedTestCase(e.target.value);
                  refetchRecordedTestDataList();
                }}
              />
              <CustomInput
                placeholder="Enter Search Test Case here..."
                labelinput="Search Test Case"
                value={testCaseSearchKeyword}
                onChange={(e) => setTestCaseSearchKeyword(e.target.value)}
                sx={{ width: "300px" }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={handleTestCaseSearch}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Box>
          ) : null}

          {item.DependentTC !== "" && item.DependentTC !== 0 ? (
            <CustomSelect
              label={"Use var from this scenario"}
              options={
                Array.isArray(recordedTestDataList?.list) && recordedTestDataList?.list.length > 0
                  ? recordedTestDataList.list.map((fileItem) => ({
                      label: fileItem.label || fileItem,
                      value: fileItem.value || fileItem,
                    }))
                  : [{ label: "No data available", value: "" }]
              }
              width={"100%"}
              background={"#ffffff"}
              value={item.TestData}
              onChange={(e) => {
                handleChange(e.target.value, "TestData");
              }}
            />
          ) : null}
          {item.DependentModule ? (
            <Box mt={2}>
              <Button
                variant="contained"
                onClick={() => {
                  handleChangeMultiple({
                    DependentProduct: "",
                    DependentModule: "",
                    DependentTC: "",
                  });
                }}
              >
                Clear
              </Button>
            </Box>
          ) : null}
        </Box>
      )}

      <Box mt={2}>
        <Button variant="contained" onClick={handleSubmit}>
          Submit
        </Button>
      </Box>
    </Box>
  );
};
export default AssertionSetup;
