import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import ImageNotSupportedOutlinedIcon from "@mui/icons-material/ImageNotSupportedOutlined";
import { CircleTickIcon, CircleWarningIcon, EditIcon } from "../../components-new/icons";
import { EllipsisTooltipText } from "../../components-new/common";

const ActionButton = styled(Box)`
  width: 40px;
  height: 40px;
  border-radius: 88px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const styles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const TestItemCard = ({ item, handleEdit, index, width }) => {
  return (
    <Box
      flex={1}
      minWidth={`${width}px`}
      maxWidth={`${width}px`}
      gap={"12px"}
      alignItems={"flex-start"}
      padding={"20px"}
      display={"flex"}
      borderRadius={"6px"}
      bgcolor={"#ffffff"}
      boxShadow={"0px 43px 62.4px 0px rgba(0, 0, 0, 0.09)"}
      flexDirection={"column"}
    >
      <Box
        borderRadius={"4px"}
        height={220}
        width={"100%"}
        border={"1px solid rgba(0, 0, 0, 0.30)"}
        sx={styles}
      >
        {item?.Failure_Screenshot_Location ? (
          <img
            style={{ height: "100%", width: "100%" }}
            src={
              item?.Failure_Screenshot_Location || `https://via.placeholder.com/900?text=No+Image`
            }
            alt="testImage"
          />
        ) : (
          <ImageNotSupportedOutlinedIcon sx={{ color: "#8089A8" }} fontSize="large" />
        )}
      </Box>

      <Box width={"100%"} display={"flex"} justifyContent="space-between" alignItems={"center"}>
        <Box display={"flex"} columnGap={"8px"}>
          <Typography>{item?.Step_Number}</Typography>
          {item?.Status === "FAIL" ? <CircleWarningIcon /> : <CircleTickIcon />}
        </Box>
        {item?.Status !== "STARTED" && item?.Status !== "ABORTED" && (
          <Box display={"flex"} columnGap={"15px"}>
            <ActionButton border={"2px solid #547de8"} onClick={() => handleEdit(item)}>
              <EditIcon />
            </ActionButton>
          </Box>
        )}
      </Box>
      <Box
        width={"100%"}
        rowGap={2}
        columnGap={2}
        display={"flex"}
        flexWrap={"wrap"}
        justifyContent={"space-between"}
      >
        <Box>
          <Typography fontWeight={"bold"}>Status</Typography>
          <Typography>{item?.Status ? item?.Status : "-"}</Typography>
        </Box>
        <Box textAlign={"right"}>
          <Typography fontWeight={"bold"}>Duration</Typography>
          <Typography>{item?.test_step_duration ? item?.test_step_duration : "-"}</Typography>
        </Box>
      </Box>
      <Box
        width={"100%"}
        rowGap={2}
        columnGap={2}
        display={"flex"}
        flexWrap={"wrap"}
        justifyContent={"space-between"}
      >
        <Box>
          <Typography fontWeight={"bold"}>Keyword</Typography>
          <Typography>{item?.Keyword ? item?.Keyword : "-"}</Typography>
        </Box>
        <Box textAlign={"right"}>
          <Typography fontWeight={"bold"}>Action</Typography>
          <Typography>{item?.Action ? item?.Action : "-"}</Typography>
        </Box>
      </Box>
      <Box>
        <Box flex={1}>
          <Typography fontWeight={"bold"}>Test Data</Typography>
          <Typography sx={{ overflowWrap: "anywhere" }}>
            <EllipsisTooltipText
              text={item.TestData ? item.TestData : "-"}
              maxChars={80}
              mode="chars"
            />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TestItemCard;
