import { Box, Button, Pagination, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useItemWidth } from "../../hooks";
import TestSuiteCard from "./TestSuiteCard";
import useDeleteTestCaseFromTestSuiteQuery from "../../useQuery/useDeleteTestCaseFromTestSuiteQuery";
import CustomDialog from "../../components-new/common/CustomDialog";
import { CustomSelect } from "../../components-new/common";
import {
  useAddToSuiteMutation,
  useMoveProductsQuery,
  useMoveSuiteQuery,
  useMoveTestCaseMutation,
} from "../../useQuery";
import { MoveIcon, PlusIcon } from "../../components-new/icons";
import useMoveModuleQuery from "../../useQuery/useMoveModuleQuery";

export default function TestSuiteCases({
  data,
  itemsPerPage,
  showTestCases,
  itemsPerRow,
  testSuiteId = null,
  setShowTestCases,
  selectedProdId,
}) {
  const containerRef = useRef(null);
  const gap = 16;
  const itemWidth = useItemWidth(containerRef, itemsPerRow, gap);

  const [testSuitePage, setTestSuitePage] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddTestCaseModal, setShowAddTestCaseModal] = useState(false);
  const [caseToBeDeleted, setCaseToBeDeleted] = useState(null);
  const [casesToBeMoved, setCasesToBeMoved] = useState([]);

  const [testSuiteData, setTestSuiteData] = useState([]);
  const [moveProdID, setMoveProdId] = useState(null);
  const [moveModId, setMoveModId] = useState(null);
  const [moveSuiteId, setMoveSuiteId] = useState(null);
  const [caseToBeAdded, setCaseToBeAdded] = useState([]);
  const [title, setTitle] = useState("");

  const { data: products } = useMoveProductsQuery();
  const { refetchWithParams, data: modules } = useMoveModuleQuery();
  const { mutate: deleteTestCase } = useDeleteTestCaseFromTestSuiteQuery();
  const { data: testSuits, refetch } = useMoveSuiteQuery(moveProdID, !!moveProdID);
  const { mutate: postTestCase } = useAddToSuiteMutation();
  const { mutate: moveTestCase } = useMoveTestCaseMutation();

  useEffect(() => {
    if (!testSuits) return;
    setTestSuiteData(testSuits.flat());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testSuits]);

  useEffect(() => {
    refetch();
    refetchWithParams({
      prodid: moveProdID,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moveProdID]);

  const paginatedData = (data ?? []).slice(
    (testSuitePage - 1) * itemsPerPage,
    testSuitePage * itemsPerPage,
  );

  const handleDeleteClick = (e, suite) => {
    e.stopPropagation();
    setShowDeleteModal(true);
    setCaseToBeDeleted([suite]);
  };

  const handleMoveTestCase = (e, suite) => {
    e.stopPropagation();
  };

  const handleAddTestCase = (e, suite) => {
    e.stopPropagation();
    setShowAddTestCaseModal(true);
    setCasesToBeMoved((prevCases) => [...prevCases, suite]);
  };

  const addTestCase = (e, testCase) => {
    if (e.target.checked) {
      setCaseToBeAdded((prev) => [...prev, testCase]);
    } else {
      setCaseToBeAdded((prev) => prev.filter((item) => item !== testCase));
    }
  };

  const DeleteAction = () => {
    return (
      <>
        <Button
          sx={{ color: "#ff5336" }}
          onClick={() => {
            setCaseToBeDeleted(null);
            setShowDeleteModal(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ background: "#ff5336" }}
          onClick={() => {
            deleteTestCase({ id: testSuiteId, selectedcases: caseToBeDeleted });
            setShowDeleteModal(false);
            setCaseToBeDeleted(null);
          }}
        >
          Delete
        </Button>
      </>
    );
  };

  const AddTestCaseAction = ({ caseToBeAdded, setCaseToBeAdded }) => {
    return (
      <>
        <Button
          sx={{ color: "#ff5336" }}
          onClick={() => {
            setShowAddTestCaseModal(false);
          }}
        >
          Cancel
        </Button>
        <Button
          // disabled={!caseToBeAdded?.length}
          variant="contained"
          sx={{ background: "#ff5336" }}
          onClick={() => {
            if (title === "Add Test Case") {
              postTestCase({
                data: caseToBeAdded,
                params: {
                  idtest_suite: moveSuiteId,
                  pnum: 1,
                },
              });
              setCaseToBeAdded([]);
              setShowAddTestCaseModal(false);
            }
            if (title === "Move Test Case") {
              moveTestCase({
                data: caseToBeAdded,
                params: {
                  modid: moveModId,
                },
              });
              setCaseToBeAdded([]);
              setShowAddTestCaseModal(false);
            }
          }}
        >
          Save
        </Button>
      </>
    );
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography fontSize="21px" fontWeight={600}>
          All Test Cases
        </Typography>
        <Box>
          <Button
            sx={{ textTransform: "capitalize", p: `10px 15px` }}
            endIcon={<PlusIcon />}
            variant="contained"
            onClick={() => {
              setTitle("Add Test Case");
              setShowAddTestCaseModal(true);
            }}
            disabled={!caseToBeAdded.length}
          >
            Add to Test Suite
          </Button>
          <Button
            sx={{ textTransform: "capitalize", p: `10px 15px`, ml: 2 }}
            endIcon={<MoveIcon />}
            variant="contained"
            onClick={() => {
              setTitle("Move Test Case");
              setShowAddTestCaseModal(true);
            }}
            disabled={!caseToBeAdded.length}
          >
            Move Test Case Module
          </Button>
        </Box>
      </Box>
      <CustomDialog
        title={"Are you sure you want to delete this Test Case?"}
        open={showDeleteModal}
        actions={<DeleteAction />}
      />
      <CustomDialog
        title={title}
        open={showAddTestCaseModal}
        actions={
          <AddTestCaseAction
            casesToBeMoved={casesToBeMoved}
            caseToBeAdded={caseToBeAdded}
            setCaseToBeAdded={setCaseToBeAdded}
          />
        }
        width={"unset"}
        content={
          <Box display={"flex"} flexDirection={"row"} columnGap={2}>
            <CustomSelect
              label={"Products"}
              options={(Array.isArray(products) ? products : []).map((prodItem) => ({
                label: prodItem.productname,
                value: prodItem.idproducts,
              }))}
              width={"230px"}
              background={"#ffffff"}
              value={moveProdID || ""}
              onChange={(e) => {
                setMoveProdId(e.target.value);
                setMoveSuiteId(null);
              }}
            />
            {title === "Add Test Case" && (
              <CustomSelect
                label={"Suite"}
                options={(Array.isArray(testSuiteData) ? testSuiteData : []).map((suite) => ({
                  label: suite.Test_Suite,
                  value: suite.idtest_suite,
                }))}
                width={"230px"}
                background={"#ffffff"}
                value={moveSuiteId || ""}
                onChange={(e) => {
                  setMoveSuiteId(e.target.value);
                }}
              />
            )}
            {title === "Move Test Case" && (
              <CustomSelect
                label={"Module"}
                options={(Array.isArray(modules) ? modules : []).map((modItem) => ({
                  label: modItem.modulename,
                  value: modItem.idmodules,
                }))}
                width={"230px"}
                background={"#ffffff"}
                value={moveProdID || ""}
                onChange={(e) => {
                  setMoveModId(e.target.value);
                  // setTestCaseId(null);
                }}
              />
            )}
          </Box>
        }
      />
      <CustomDialog title={"Move Test Case"} open={false} actions={<DeleteAction />} />
      <Box
        width={"100%"}
        py={1}
        ref={containerRef}
        display="flex"
        columnGap={2}
        rowGap={2}
        flexWrap="wrap"
      >
        {paginatedData?.length > 0
          ? paginatedData.map((suite) => {
              return suite.list ? (
                <TestSuiteCard
                  item={suite}
                  width={itemWidth}
                  isFolder={true}
                  key={suite.idtest_suite}
                  handleSuiteClick={() => setShowTestCases(false)}
                  handleDeleteClick={(e) => handleDeleteClick(e, suite)}
                  handleSettings={(e) => setShowTestCases(false)}
                  handleReNameSuite={(e) => setShowTestCases(false)}
                  addTestCase={(e) => addTestCase(e, suite)}
                  suiteTitle={true}
                  caseToBeAdded={caseToBeAdded}
                  selectedProdId={selectedProdId}
                />
              ) : (
                <TestSuiteCard
                  item={suite}
                  width={itemWidth}
                  isFolder={!showTestCases}
                  key={suite.idtest_case}
                  testSuiteId={testSuiteId}
                  handleDeleteClick={(e) => handleDeleteClick(e, suite)}
                  handleMoveTestCase={(e) => handleMoveTestCase(e, suite)}
                  handleAddTestCase={(e) => handleAddTestCase(e, suite)}
                  addTestCase={(e) => addTestCase(e, suite)}
                  caseToBeAdded={caseToBeAdded}
                  selectedProdId={selectedProdId}
                />
              );
            })
          : null}

        {data?.length < 1 && <Box>No Test Case Available</Box>}
      </Box>
      {data?.length > itemsPerPage && (
        <Box py={2} display="flex" justifyContent="center">
          <Pagination
            count={Math.ceil(data.flat().length / itemsPerPage)}
            page={testSuitePage}
            onChange={(e, newPage) => setTestSuitePage(newPage)}
            color="primary"
            variant="outlined"
            shape="rounded"
          />
        </Box>
      )}
    </>
  );
}
