import React from "react";

const TestSuiteIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
    <path
      d="M13.6 0C14.0416 0 14.4 0.3584 14.4 0.8V3.8056L12.8 5.4056V1.6H1.6V14.4H12.8V12.1936L14.4 10.5936V15.2C14.4 15.6416 14.0416 16 13.6 16H0.8C0.3584 16 0 15.6416 0 15.2V0.8C0 0.3584 0.3584 0 0.8 0H13.6ZM15.0224 5.4464L16.1536 6.5776L9.9312 12.8L8.7984 12.7984L8.8 11.6688L15.0224 5.4464ZM8 8V9.6H4V8H8ZM10.4 4.8V6.4H4V4.8H10.4Z"
      fill="currentColor"
    />
  </svg>
);

export default TestSuiteIcon;
