import { styled } from "@mui/material";
import React from "react";
import Chart from "react-apexcharts";

const StyledChart = styled(Chart)`
  .apexcharts-xaxis-label:first-child {
    transform: translateX(-20px);
  }
`;

const NoDataMessage = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  font-size: 16px;
  color: #8089a8;
`;

const TimeSeriesChart = ({ series, fromDate, toDate }) => {
  // Check if the series data is empty
  if (!series || series.length === 0 || !series[0].data || series[0].data.length === 0) {
    return <NoDataMessage>No data available</NoDataMessage>;
  }

  const categories = series[0]?.data?.map((item) => item.x);
  console.log([...new Set(categories)]);

  // // Ensure timestamps are in milliseconds
  // const adjustTimestamps = (data) => {
  //     return data.map(item => ({
  //         ...item,
  //         y: item.y.map(timestamp => timestamp >= 1e10 ? timestamp : timestamp * 1000) // Handle seconds to milliseconds
  //     }));
  // };

  // const adjustedSeries = series.map(item => ({
  //     ...item,
  //     data: adjustTimestamps(item.data)
  // }));

  const startTimestamp = new Date(fromDate).getTime();
  const endTimestamp = new Date(toDate).getTime();

  const options = {
    chart: {
      type: "rangeBar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "50%",
      },
    },
    xaxis: {
      type: "datetime",
      min: startTimestamp,
      max: endTimestamp,
      tickAmount: 3,
      tickPlacement: "on",
      labels: {
        formatter: function (value) {
          const date = new Date(value);
          const hours = date.getHours().toString().padStart(2, "0");
          const minutes = date.getMinutes().toString().padStart(2, "0");
          return `${hours}:${minutes}`;
        },
      },
    },
    yaxis: {
      title: {
        text: "Parallel Threads",
      },
      categories: categories,
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const testCase = w.config.series[seriesIndex].data[dataPointIndex];
        const start = new Date(testCase.y[0]);
        const end = new Date(testCase.y[1]);
        return `
                    <div class="arrow_box">
                        <span><b>Test Case:</b> ${testCase.label}</span><br/>
                        <span><b>Start:</b> ${start.toLocaleTimeString()}</span><br/>
                        <span><b>End:</b> ${end.toLocaleTimeString()}</span>
                    </div>
                `;
      },
    },
  };

  return (
    <div>
      <StyledChart options={options} series={series} type="rangeBar" height={400} />
    </div>
  );
};

export default TimeSeriesChart;