import React, { useState,useEffect,useRef } from 'react';
import './MyCarousel.scss';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { colorExport} from '../common/common.Component';

const MyCarousel = ({firstImage,secondImage,indexValueChanges}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const previousValue = usePreviousValue(indexValueChanges);

  function usePreviousValue(indexValueChanges) {
    const ref = useRef();
    useEffect(() => {
      ref.current = indexValueChanges;
    });
    return ref.current;
  }

  const colors = colorExport();
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  
  useEffect(() => {
    if (previousValue !== indexValueChanges){
     setActiveIndex(0);
    }
  }, [indexValueChanges]);

  const handlePrevious = () => {
    setActiveIndex((prevIndex) => (prevIndex === 0 ? 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex === 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="carousel">
      <div className="carousel-inner">
        <div className={`carousel-item ${activeIndex === 0 ? 'active' : ''}`}>
          {firstImage ? (
            <img className="carousel-image" src={firstImage} />
          ) : indexValueChanges === 0 ? (
            <div className="carousel-placeholder">
              <span>No Screenshot, Intentional Left Blank</span>
            </div>
          ) : (
            <div className="carousel-placeholder">
              <span>Test Case Needs To Be Executed Successfully Once For The Screenshot To Be Shown</span>
            </div>)}
          <div className="carousel-caption">
            <h4 className="heading-five">Element Selected</h4>
          </div>
        </div>
        <div className={`carousel-item ${activeIndex === 1 ? 'active' : ''}`}>
          {secondImage ? (
            <img className="carousel-image" src={secondImage} />
          ) : (
            <div className="carousel-placeholder">
              <span>Test Case Needs To Be Executed Successfully Once For The Screenshot To Be Shown</span>
            </div>
          )}
          <div className="carousel-caption">
            <h4 className="heading-five">Action Completed</h4>
          </div>
        </div>
      </div>

      <a style={{ backgroundColor: colors.secondaryColor,color: colors.primaryColor,}} className="carousel-control-prev" onClick={handlePrevious}>
        <span className="sr-only"><ArrowBackIcon/></span>
      </a>
      <a style={{ backgroundColor: colors.secondaryColor,color: colors.primaryColor,}}  className="carousel-control-next" onClick={handleNext}>
        <span className="sr-only"><ArrowForwardIcon/></span>
      </a>
      <div className="carousel-indicators">
        <ul>
          <li onClick={handlePrevious} className={activeIndex === 0 ? 'active' : ''}></li>
          <li onClick={handleNext} className={activeIndex === 1 ? 'active' : ''}></li>
        </ul>
      </div>
    </div>
  );
};

export default MyCarousel;