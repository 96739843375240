import React from 'react';
import { Box } from '@mui/material';
import PageTitle from '../components-new/common/PageTitle';
import DesignIcon from '../components-new/icons/DesignIcon';
import ExecutionIcon from '../components-new/icons/ExecutionIcon';
import OptimizationIcon from '../components-new/icons/OptimizationIcon';
import TabComponent from '../components-new/common/TabComponent';
import {DesignSection, ExecutionSection, OptimizationSection} from './Dash';

const Dashboard = () => {
  const tabs = [
    {
      icon: <DesignIcon />,
      label: 'Design',
      content: <DesignSection />,
    },
    {
      icon: <ExecutionIcon />,
      label: 'Execution',
      content: <ExecutionSection />,
    },
    {
      icon: <OptimizationIcon />,
      label: 'Optimization',
      content: <OptimizationSection/>,
    },
  ];

  return (
    <Box sx={{ py: 4 }}>
      <PageTitle title="Dashboard" />
      <TabComponent tabs={tabs} />
    </Box>
  );
};

export default Dashboard;
