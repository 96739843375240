// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-content {
  position: relative;
  padding: 10px; /* Adjust padding as needed */
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 18px;
}

.video-container {
  width: 100%;
  height: 0;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  position: relative;
}

video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/RecordingTable/VideoPopup.scss"],"names":[],"mappings":"AAAA;EACA,kBAAA;EACE,aAAA,EAAA,6BAAA;AACF;;AAEA;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EACA,eAAA;EACA,eAAA;AACF;;AAEA;EACE,WAAA;EACA,SAAA;EACA,mBAAA,EAAA,sBAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;AACF","sourcesContent":[".popup-content {\nposition: relative;\n  padding: 10px; /* Adjust padding as needed */\n}\n\n.close-btn {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  cursor: pointer;\n  font-size: 18px;\n}\n\n.video-container {\n  width: 100%;\n  height: 0;\n  padding-top: 56.25%; /* 16:9 aspect ratio */\n  position: relative;\n}\n\nvideo {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
