import React, { useState } from "react";
import "./features/css/listfile.css";
import { IconButton, Tooltip } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Listbody({
  testcases,
  ind,
  addtoar,
  removetoar,
  product,
  moduleid,
  dragon,
  dropon,
  delButton,
  clickonSuites,
}) {
  let name = "";
  let nametool = "";
  let testcase;
  testcase = testcases;
  if (testcases?.hasOwnProperty("Test_Case")) {
    name = testcase["Test_Case"];
    if (testcase.Test_Case_Desc === "" || testcase.Test_Case_Desc === null) {
      nametool = testcase["Test_Case"];
    } else {
      nametool = `${testcase["Test_Case"]}-${testcase.Test_Case_Desc}`;
    }
  } else if (testcases?.hasOwnProperty("testcases")) {
    testcase = testcases["testcases"]["0"];
    name = testcase["Test_Case"];
    nametool = testcase["Test_Case"];
  } else if (testcase[0]?.hasOwnProperty("Test_Suite")) {
    name = testcase[0]["Test_Suite"];
    nametool = testcase[0]["Test_Suite"];
    testcase = testcase[0];
  }
  // testcase = testcase["testcase"]
  const [select, setselect] = useState(true);
  const navigate = useNavigate();

  // let select = true;
  useEffect(() => {
    setselect(true);
  }, [testcase]);
  function selected() {
    setselect((prevselect) => !prevselect);
    // select = !select;

    if (select) addtoar(testcase);
    if (!select) removetoar(testcase);
  }
  // if (name === testcase["Test_Case"]) setselect(false);
  // let name = testcase["Test_Case"];
  function drag() {
    if (delButton)
      dragon(ind, testcases.TestcaseIndex, testcases.testcases[0].idtest_case);
  }
  function drop() {
    if (delButton) dropon(ind, testcases.TestcaseIndex);
  }
  return (
    <div
      className="listbody"
      draggable={true}
      id={ind}
      onDragOver={(ev) => ev.preventDefault()}
      onDragStart={drag}
      onDrop={drop}
    >
      <Tooltip title={<h2>{nametool}</h2>} arrow>
        <div className="sp">
          <div hidden={!select} className="listbody-left">
            <IconButton onClick={selected}>
              <CheckBoxOutlineBlankIcon />
            </IconButton>
          </div>
          <div hidden={select} className="listbody-left">
            <IconButton onClick={selected}>
              <CheckBoxIcon />
            </IconButton>
          </div>
          <div
            onClick={() => {
              // navigate("/dashboard/recording", {
              //   state: {
              //     fromRecordingPage: true,
              //     idproducts: product,
              //     idmodules: moduleid,
              //     idtest_case: testcase["idtest_case"],
              //     Test_Case: testcase["idtest_case"],
              //     details: testcase,
              //   },
              // });
              if (testcase?.hasOwnProperty("Test_Suite")) {
                clickonSuites(testcase["idtest_suite"]);
              } else {
                navigate("/dashboard/recording", {
                  state: {
                    fromRecordingPage: true,
                    idproducts: product,
                    idmodules: moduleid,
                    idtest_case: testcase["idtest_case"],
                    Test_Case: testcase["idtest_case"],
                    details: testcase,
                  },
                });
              }
            }}
            className="listbody-right"
          >
            <p>{name}</p>
          </div>
          <div className="save">
            <IconButton>
              <MoreVertIcon />
            </IconButton>
          </div>
        </div>
      </Tooltip>
    </div>
  );
}

export default Listbody;
