import React,{useState,useEffect} from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CommonMethods } from '../common/CommonMethods';
import {useLocation, useNavigate } from "react-router-dom";
import {
  colorExport,
} from "./../common/common.Component";
import { fetchProducts } from '../../API/CreateCollectionAPI';
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  IconButton,
  Typography,
  TableBody,
} from "@mui/material";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import { getFirstPageData } from "../../API/CoveragePageApi";
const drawerWidth = 240;

const CoverageDashboard = () => {
    const colors = colorExport();
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const initialProduct = params.get("product") || "";
    const [productList, setProductList] = useState([]);
  const [product, setProduct] = useState("");
  const [pageData, setPageData] = useState([]);
    const selectedProduct = (event) => {
      setProduct(event.target.value);
      getPageData(event.target.value);
  }

     const getProduct = async () => {
      await fetchProducts()
        .then((response) => {
           if (response.status === 200 && response.data.message === "fail") {
             CommonMethods(navigate);
         } else {
             setProductList(response.data);
        }
        })
        .catch((error) => {
            console.log(error);
         })
     }
    useEffect(() => {
        getProduct();
    }, [])
    useEffect(() => {
      if(initialProduct) {
        setProduct(initialProduct)
        getPageData(initialProduct)
      }
  }, [initialProduct]);
    
const getPageData = async (product) => {
    await getFirstPageData(product)
      .then((response) => {
        if (response.status === 200 && response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
          setPageData(response.data);
          console.log(response);
          console.log("126 page data", pageData);
      }
      }).catch((error) => {
        console.log(error);
    })
  }

    return (
        <Box >
            <Box
                sx={{
                      justifyContent: "flex-start",
                      alignItems: "center",
                      backgroundColor: colors?.secondaryColor,
                      color: colors?.primaryColor,
                      marginTop:10,
                      height:150,
                      marginLeft: 4,
                      marginRight:4,
                     }}
               >
                      <Box  sx={{
                          display: "flex",
                          alignItems: "center",
                         justifyContent: "center",
                          
                         }}>
           
                     <Typography variant="h5" nowrap component="div"  sx={{marginTop:1,}}>
                         Coverage Page
                      </Typography>
         
                  </Box>
                       <Box
                          sx={{
                            display: "flex",
                            flex: 1,
                            justifyContent: "flex-start",
                            alignItems: "center",
                            flexDirection: "row",
                           marginLeft: 4,
                            marginTop:2,
                          }}
                        >
                          <FormControl sx={{ width: 240, color: colors.PrimaryColor }}>
                             <InputLabel
                                style={{
                                  color: colors.primaryColor,
                                  backgroundColor: colors.secondaryColor,
                                 padding: 5,
                                 }}
                                id={"product"}
                                 >
                                Product
                             </InputLabel>
                                <Select
                                    labelId={"product"}
                                    id={"product Select"}
                                    value={product}
                                    onChange={selectedProduct}
                                    name={"product"}
                                     label="product"
                                    IconComponent={(props) => (
                                     <ArrowDropDownSharpIcon
                                         style={{ color: colors.primaryColor, fontSize: 40 }}
                                          {...props}
                                         />
                                       )}
                                        sx={{
                                            color: colors.primaryColor,
                                            border: `2px solid ${colors.primaryColor}`,
                                            fontSize: 16,
                                            "&:focus": {
                                             borderRadius: 4,
                                             border: `4px solid ${colors.primaryColor}`,
                                           },
                                         }}
                                       style={{
                                          minWidth: 100,
                                          marginRight: "2rem",
                                         }}
                                         >
                                        {productList.map((product, index) => (
                                           <MenuItem key={index} value={product.idproducts}>
                                              {product.productname}
                                             </MenuItem>
                                            ))}
                                  </Select>
                              </FormControl>
                          </Box>
                     </Box>
                         {product && (
                           <Box
                              sx={{
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "row",
                                display: "flex",
                                marginTop:15,
                               }}
                            >
                            <Card sx={{
                                  width: 350,
                                  height: "237px",
                                  // width:  '300px',
                                  background: Object.keys(pageData).length> 0 ? colors.secondaryColor : "rgba(0, 0, 0, 0.12)",
                                  color:colors.primaryColor,
                                   pointerEvents: Object.keys(pageData).length > 0 ? 'auto' : 'none',
                                    }}
                                  onClick={() => {
                                    navigate(`/dashboard/CoveragePage/${product} `)
                                    }}
                                 >
                                   <CardContent sx={{
                                       marginTop: 7,
                                       color:Object.keys(pageData).length> 0 ? colors.primaryColor : "rgba(0, 0, 0, 0.26)",
                                       }}
                                     >
                                         <Typography gutterBottom variant="h5" component="div" sx={{justifyContent:"center",alignItems:"center",display:"flex",fontSize:30}}>
                                             Total Pages in Product
                                          </Typography>
                                          <Typography variant="body2" sx={{justifyContent:"center",alignItems:"center",display:"flex",fontSize:30}}>
                                          {Object.keys(pageData).length > 0 ? pageData.totalpages : 0}
                                          </Typography>
                                     </CardContent>
                                  </Card>
                               <Card sx={{
                                     width: 350,
                                      height: "237px",
                                     //  width:  '300px',
                                      background: Object.keys(pageData).length> 0 ? colors.secondaryColor : "rgba(0, 0, 0, 0.12)",
                                      color: colors.primaryColor,
                                      marginLeft:"150px",
                                     pointerEvents: Object.keys(pageData).length > 0 ? 'auto' : 'none',
                                     }}
                                    onClick={() => {
                                      navigate(`/dashboard/CoveragePage/${product} `)
                                      }}
                                   >
                                     <CardContent
                                       sx={{
                                      marginTop: 7,
                                       color:Object.keys(pageData).length> 0 ? colors.primaryColor : "rgba(0, 0, 0, 0.26)"
                                       }}
                                     >
                                           <Typography gutterBottom variant="h5" component="div" sx={{justifyContent:"center",alignItems:"center",display:"flex",fontSize:30}}>
                                             Total Elements
                                           </Typography>
                                          <Typography variant="body2" sx={{justifyContent:"center",alignItems:"center",display:"flex",fontSize:30}}>
                                          {Object.keys(pageData).length > 0 ? pageData.totalelement : 0}
                                           </Typography>
                                       </CardContent>
                                 </Card>
                                  <Card sx={{
                                       width: 350,
                                       height: "237px",
                                        // width:  '300px',
                                        background: Object.keys(pageData).length> 0 ? colors.secondaryColor : "rgba(0, 0, 0, 0.12)",
                                       color: colors.primaryColor,
                                       marginLeft:"150px",
                                        pointerEvents: Object.keys(pageData).length > 0 ? 'auto' : 'none',
                                      }}
                                      onClick={() => {
                                        navigate(`/dashboard/CoveragePage/${product} `)
                                       }}
                                     >
                                     <CardContent
                                       sx={{
                                      marginTop: 7,
                                       color:Object.keys(pageData).length> 0 ? colors.primaryColor : "rgba(0, 0, 0, 0.26)"
                                       }}
                                     >
                                         <Typography gutterBottom variant="h5" component="div" sx={{justifyContent:"center",alignItems:"center",display:"flex",fontSize:30}}>
                                            Elements Tested
                                           </Typography>
                                          <Typography variant="body2" sx={{justifyContent:"center",alignItems:"center",display:"flex",fontSize:30}}>
                                          {Object.keys(pageData).length > 0 ? pageData.usedelement : 0}
                                           </Typography>
                                      </CardContent>
                                   </Card>
                           </Box>
                         )}
                 </Box>
            )
           }
export default CoverageDashboard;