import { ListItemIcon, Menu, MenuItem, styled, Typography } from "@mui/material";
import { AnalysisIcon, CurrentExecutionIcon, HistoryIcon } from "../../components-new/icons";
import { useSelector } from "react-redux";


const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
    "&:hover": {
        backgroundColor: theme.palette.action.hover,
    },
    "&.MuiButtonBase-root.MuiMenuItem-root": {
        display: "flex",
        padding: "12px 25px",
    },
    "& .MuiListItemIcon-root": {
        minWidth: "36px",
        textAlign: "center",
        color: "#242F3F",
        "& svg": {
            width: "20px",
            height: "20px",
        },
    },
    "& .MuiTypography-root": {
        fontSize: "14px",
        fontWeight: 500,
        color: "#2B3539",
    },
}));

const ExeMenu = ({ anchorEl, handleClose, handleAnalysis, setRefreshFlag, handleCurrentExecution }) => {

    const selectedTestCase = useSelector((state) => state.executionSlice.selectedTestCase);
    const handleHistoryClick = (e) => {
        setRefreshFlag(false)
        const url = `/new/reports/history?tcid=${selectedTestCase}`;
        window.open(url, '_blank');  // Open the link in a new tab
        handleClose()
    };
    return (
        <Menu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <CustomMenuItem onClick={(e) => handleHistoryClick(e)}>
                <ListItemIcon>
                    <HistoryIcon />
                </ListItemIcon>
                <Typography>History</Typography>
            </CustomMenuItem>
            <CustomMenuItem onClick={handleAnalysis}>
                <ListItemIcon>
                    <AnalysisIcon />
                </ListItemIcon>
                <Typography>Analysis Co-pilot</Typography>
            </CustomMenuItem>
            <CustomMenuItem onClick={handleCurrentExecution}>
                <ListItemIcon>
                    <CurrentExecutionIcon />
                </ListItemIcon>
                <Typography>Current Executions</Typography>
            </CustomMenuItem>
        </Menu>
    )
}


export default ExeMenu;