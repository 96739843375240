import React, { useState } from "react";

import { useNavigate } from "react-router-dom";

import {
  Avatar,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Paper,
  Grid,
  Typography,
  Box,
} from "@mui/material";

import {
  ColorButton,
  StyledTableRow,
  StyledTableCell,
  PrimaryColor,
  SecondaryColor,
  BootstrapTooltip,
} from "./../../components/common/common.Component";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import { fetchLoginDetails } from "./../../API/UserApi";

const LoginPage = (props) => {
  const navigate = useNavigate();
  const [account, setAccount] = useState({
    email: "",
    password: "",
    // company: "",
  });

  const handelAccount = (property, event) => {
    setAccount({ ...account, [property]: event.target.value });
  };

  const [errorMessage, setErrorMessage] = useState('');

  const handelLogin = async () => {
    await fetchLoginDetails({
      pwd: account.password,
      uname: account.email,
      // company: account.company,
    })
      .then((response) => {
         if (response.status === 200) {
           if (response.data.message==="success") {
            localStorage.setItem(
              "userDetail",
              JSON.stringify({
                ...account,
                ...response.data,
                companyid: response.data?.company?.idcompany,
                usertype:response.data?.usertype,
                token:response.data?.randomkey,
                
              })
            );
            localStorage.setItem(
             
              "colorcode",
              JSON.stringify({
                primaryColor:(response.data?.company?.primaryColor),
                secondaryColor:(response.data?.company?.secondaryColor),
                tertiaryColor:(response.data?.company?.tertiaryColor),
                brandLogo:(response.data?.company.brandLogo),
                companyurl:(response.data?.company.companyurl)
              })
            )
            navigate("/");
           } else {
            setErrorMessage('Invalid Username or Password!, Please try Again.');
           }
         }
      })
      .catch((error) => {
        console.log(error);
      });
  };

return (
    <Grid
      container
      component="main"
      style={{
        // height: "100vh",
        marginLeft: 0,
        marginTop: 64,
        // backgroundImage: `url(${image})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundColor: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
        item
        xs={12}
        sm={8}
        md={5}
        // component={Paper}
        elevation={1}
        square="true"
      >
        <Typography
          variant="h4"
          noWrap
          component="div"
          sx={{ marginBottom: 4, display: "flex" }}
        >
          Nogrunt
          <Avatar
            style={{
              marginLeft: 4,
              color: SecondaryColor,
              backgroundColor: PrimaryColor,
            }}
          >
            <LockOutlinedIcon color="inherit" />
          </Avatar>
        </Typography>

        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Link
  style={{ cursor: 'pointer' }}
  onClick={() => {
    window.open("https://chrome.google.com/webstore/detail/nogrunt-qa-automation/neankkcidljlljifcclgcecadeimmolp", "_blank");
  }}
  variant="body2"
>
<Typography component="h1" variant="h5" marginBottom={2} style={{fontWeight: 'bold'}}>
  {"Get The Extension "}
  </Typography>
</Link>

<Link
          style={{ cursor: 'pointer' }}
          onClick={() => {
            window.open("https://www.loom.com/share/c8941d2c1d4b428dabcb30374219124f", "_blank");
          }}
          variant="body2"
        >
          <Typography component="h1" variant="h5" marginBottom={2} style={{fontWeight: 'bold'}}>
            {"See Nogrunt In Action "}
          </Typography>
        </Link>
        <form
          style={{
            width: "100%", // Fix IE 11 issue.
            marginTop: 1,
          }}
          noValidate
        >
          {errorMessage && <Typography variant="body2" color="error">{errorMessage}</Typography>}   
          <TextField
            onChange={(event) => handelAccount("email", event)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Username / Email"
            name="email"
            autoFocus
          />
          <TextField
            onChange={(event) => handelAccount("password", event)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault(); 
                handelLogin(); 
              }
            }}
          />
          <ColorButton size='small'
            // type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ marginY: 2 }}
            onClick={handelLogin}
          >
            Sign In
          </ColorButton>

          <Box sx={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
          <Link
            style={{ marginTop: 2, cursor: 'pointer' }}
            onClick={() => {
              navigate("/SignUpPage");
            }}
            variant="body2"
          >
            {"Don't have an account? Sign Up"}
          </Link>          <Link
            style={{ marginTop: 2, cursor: 'pointer' }}
            onClick={() => {
              navigate("/ForgotPasswordPage");
            }}
            variant="body2"
          >
            {"Forgot Password"}
          </Link>
       </Box>
        </form>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
