import React, { useEffect, useRef } from 'react';

function ShadowPage() {
  const shadowHostRef = useRef(null);

  useEffect(() => {
    const shadowRoot = shadowHostRef.current.attachShadow({ mode: 'open' });
    
    // Create input element
    const input = document.createElement('input');
    input.type = 'text';

    // Create button element
    const button = document.createElement('button');
    button.textContent = 'Print in Shadow DOM';
    
    // Handle button click
    button.addEventListener('click', () => {
      const textNode = document.createTextNode(input.value);
      // shadowRoot.innerHTML = ''; // Clear previous content
      shadowRoot.appendChild(textNode);
    });

    // Append input and button to the Shadow DOM
    shadowRoot.appendChild(input);
    shadowRoot.appendChild(button);
  }, []);

  return <div ref={shadowHostRef}></div>;
}

export default ShadowPage;