import { Box, InputBase, Typography } from "@mui/material";
import { styled } from "@mui/system";

const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: "10px",
  borderRadius: "5px",
  background: "#ffffff",
  border: "1px solid #D1DBFF",
  color: "#2B3539",
  "&::placeholder": {
    color: "#2B3539",
  },
  "&:focus": {
    borderColor: "#D1DBFF",
    boxShadow: `0 0 0 2px ${theme.palette.primary.light}`,
  },
}));

const CustomInput = (props) => {
  const {
    placeholder,
    onChange,
    labelinput,
    value,
    type = "text",
    flex,
    isDisabled = false,
    pattern,
  } = props;
  return (
    <Box flex={flex}>
      <Typography color="#8089A8" fontSize="14px" fontWeight={500}>
        {labelinput}
      </Typography>
      <StyledInput
        sx={{ width: "100%" }}
        onChange={onChange}
        type={type}
        placeholder={placeholder}
        value={value ?? ""}
        disabled={isDisabled}
        pattern={pattern}
        {...props}
      />
    </Box>
  );
};

export default CustomInput;
