import React from "react";
import AppRouting from "./pages/routes";
import { useSelector } from "react-redux";
import { BrowserRouter, useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import Navbar from "./components/Navbar/Navbar.componet";

import "./App.style.scss";
import Notification from "./components-new/notification/Notification";

const AppContent = () => {
  const location = useLocation();
  const isUsingOldLayout = !location.pathname.startsWith("/new");
  const isSidebarOpen = useSelector((state) => state.SiteSettings.issidebaropen);

  const dynamicStyles = (theme) => ({
    marginTop: "64px",
    marginLeft: isUsingOldLayout ? "64px" : "0",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(isSidebarOpen &&
      isUsingOldLayout && {
        marginLeft: "248px",
        width: `calc(100% - 248px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }),
  });

  return (
    <>
      {isUsingOldLayout && <Navbar />}
      <Box className={"test"} sx={(theme) => dynamicStyles(theme)} open={isSidebarOpen}>
        <AppRouting />
      </Box>
    </>
  );
};

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <AppContent />
        <Notification />
      </BrowserRouter>
    </div>
  );
};

export default App;
