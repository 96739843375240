import axios from "axios";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

const fetchStopTestSuiteCache = async (id, type) => {
  const userDetail = localStorage.getItem("userDetail");
  if (!userDetail) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetail);

  const response = await axios.get(
    `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=stopTestSuite&companyid=${user.companyid}&token=${user.token}&id=${id}&type=${type}`,
  );

  return response.data;
};

const useStopTestSuiteQuery = (id, type, options) => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ["stopTestSuiteCache", id, type],
    queryFn: () => fetchStopTestSuiteCache(id, type),
    enabled: false,
    retry: true,
    cacheTime: 90000,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    staleTime: Infinity,
    onSuccess: () => {
      // Invalidate a specific query after success
      queryClient.invalidateQueries(["latestResult", "testSuiteResult"]);
    },
    ...options,
  });
};

export default useStopTestSuiteQuery;
