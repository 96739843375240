import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

const fetchLicenceKey = async () => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetails);

  const response = await axios.get(
    `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getlicencekey&companyid=${user.companyid}&token=${user.token}`,
  );

  return response.data;
};

const useLicenceKeyQuery = () => {
  return useQuery({
    queryKey: ["ExceLicenceKey"],
    queryFn: () => fetchLicenceKey(),
    retry: true,
    cacheTime: 90000,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    staleTime: Infinity,
  });
};

export default useLicenceKeyQuery;
