import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

const updateTestSuiteStopAfterFailure = async (id, bool) => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetails);

  const response = await axios.get(
    `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateTestSuiteStopAfterFailure&companyid=${user.companyid}&token=${user.token}&tsuid=${id}&value=${bool}`
  );

  return response.data;
};

const useUpdateTestSuiteStopAfterFailureQuery = (id, bool, options) => {
  return useQuery({
    queryKey: ["updateTestSuiteStopAfterFailure", id],
    queryFn: () => updateTestSuiteStopAfterFailure(id, bool),
    enabled: false, // Adjust as per your use case
    retry: true,
    cacheTime: 90000,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    staleTime: Infinity,
    ...options, // You can pass additional options here
  });
};

export default useUpdateTestSuiteStopAfterFailureQuery;
