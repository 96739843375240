import { useDispatch } from "react-redux";
import { showNotification } from "../Redux/Notification/NotificationSlice";

const useNotification = () => {
  const dispatch = useDispatch();

  const notify = (message, type = "info") => {
    dispatch(showNotification({ message, type }));
  };

  return notify;
};

export default useNotification;
