import React from "react";

const ScenarioIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" fill="none">
    <path
      d="M8.00002 0.200195L15.6 4.6002V13.4002L8.00002 17.8002L0.400024 13.4002V4.6002L8.00002 0.200195ZM8.00002 2.049L2.00002 5.5226V12.4778L8.00002 15.9514L14 12.4778V5.5226L8.00002 2.049ZM8.00002 12.2002C7.15133 12.2002 6.3374 11.8631 5.73728 11.2629C5.13717 10.6628 4.80002 9.84889 4.80002 9.0002C4.80002 8.1515 5.13717 7.33757 5.73728 6.73745C6.3374 6.13734 7.15133 5.8002 8.00002 5.8002C8.84872 5.8002 9.66265 6.13734 10.2628 6.73745C10.8629 7.33757 11.2 8.1515 11.2 9.0002C11.2 9.84889 10.8629 10.6628 10.2628 11.2629C9.66265 11.8631 8.84872 12.2002 8.00002 12.2002ZM8.00002 10.6002C8.42437 10.6002 8.83134 10.4316 9.1314 10.1316C9.43145 9.83151 9.60002 9.42454 9.60002 9.0002C9.60002 8.57585 9.43145 8.16888 9.1314 7.86882C8.83134 7.56877 8.42437 7.4002 8.00002 7.4002C7.57568 7.4002 7.16871 7.56877 6.86865 7.86882C6.5686 8.16888 6.40002 8.57585 6.40002 9.0002C6.40002 9.42454 6.5686 9.83151 6.86865 10.1316C7.16871 10.4316 7.57568 10.6002 8.00002 10.6002Z"
      fill="#444141"
      fillOpacity="0.67"
    />
  </svg>
);

export default ScenarioIcon;
