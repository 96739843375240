import { Box, Typography, ToggleButtonGroup, ToggleButton, Button } from "@mui/material";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";

import { makeStyles } from "@mui/styles";
import { CustomInput, CustomSelect } from "../../../components-new/common";
import { useDispatch } from "react-redux";
import { setEditStep } from "../../../Redux/TestAuthoring/TestAuthoring";
import { ElementOptions, strategyItems } from "../constants";
import { useUpdateTestDataQuery } from "../../../useQuery";
import { useEffect, useState } from "react";
import { useNotification } from "../../../hooks";

const useStyles = makeStyles((theme) => ({
  toggleButton: {
    border: "1px solid #D1DBFF !important",
    padding: "12px 14px",
    borderRadius: "50px !important",
    minWidth: "120px",
    textTransform: "capitalize",
    "&.Mui-selected": {
      backgroundColor: "#2550d1",
      color: "#ffffff !important",
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
  toggleButtonGroup: {
    columnGap: "10px",
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    "& .MuiToggleButton-root": {
      color: theme.palette.text.primary,
    },
  },
}));

const ElementLocator = ({ item }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const notify = useNotification();
  const [chooseData, setChooseData] = useState(
    item.strategy === "" || item.strategy === null || item.strategy === undefined
      ? "X-Path"
      : item.strategy,
  );

  const { mutate: updateTestData, isSuccess: isUpdateSuccess } = useUpdateTestDataQuery();

  const handleChange = (value, field) => {
    dispatch(setEditStep({ ...item, [field]: value }));
  };

  const handleSubmit = () => {
    updateTestData({ api_input: item, fromStrategy: true });
  };

  const handleChangeMultiple = (value) => {
    dispatch(setEditStep({ ...item, ...value }));
  };

  useEffect(() => {
    setChooseData(
      item.strategy === "" || item.strategy === null || item.strategy === undefined
        ? "X-Path"
        : item.strategy,
    );
  }, [item.strategy]);

  useEffect(() => {
    handleChangeMultiple({
      strategy: item.strategy,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.strategy]);

  useEffect(() => {
    if (isUpdateSuccess) {
      notify("The item has been updated successfully.", "success");
    }
  }, [dispatch, isUpdateSuccess, notify]);

  return (
    <Box pr={3} display="flex" flexDirection="column" rowGap={4}>
      <Box>
        <Typography color="#8089A8" fontSize="14px" fontWeight={500}>
          Strategy
        </Typography>
        <ToggleButtonGroup
          className={classes.toggleButtonGroup}
          value={chooseData}
          exclusive
          onChange={(e, value) => {
            if (value === "Search" || value === "SameRow" || value === "SameRowElement") {
              handleChangeMultiple({
                strategy: value,
                testdata_source: value,
              });
            } else {
              dispatch(setEditStep({ ...item, strategy: value }));
            }
          }}
        >
          {strategyItems.map((strItem) => (
            <ToggleButton
              key={strItem.value}
              className={classes.toggleButton}
              value={strItem.value}
            >
              {strItem.label}
              {strItem.value === chooseData && <CheckOutlinedIcon sx={{ ml: 1 }} />}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>
      {(chooseData === "Search" || chooseData === "SameRowElement") && (
        <Box mt={2}>
          <Button
            variant="contained"
            onClick={() => {
              handleChangeMultiple({
                strategy: "X-Path",
              });
              handleSubmit();
            }}
          >
            Submit
          </Button>
        </Box>
      )}
      {chooseData === "X-Path" && (
        <Box display="flex" columnGap={2} alignItems={"center"}>
          <CustomInput
            labelinput={"Object Identifier"}
            onChange={(e) => handleChange(e.target.value, "Object_Xpath")}
            placeholder={"Enter Object Identifier"}
            value={item.Object_Xpath}
            flex={1}
          />
          <Box mt={2}>
            <Button variant="contained" onClick={handleSubmit}>
              Submit
            </Button>
          </Box>
        </Box>
      )}
      {chooseData === "XY" && (
        <Box display="flex" columnGap={2} alignItems={"center"}>
          <CustomInput
            labelinput="X Coordinate"
            onChange={(e) => handleChange(e.target.value, "xpos")}
            placeholder={"Enter X Coordinate"}
            value={item.xpos}
            type="number"
          />
          <CustomInput
            labelinput="Y Coordinate"
            onChange={(e) => handleChange(e.target.value, "ypos")}
            placeholder={"Enter Y Coordinate"}
            value={item.ypos}
            type="number"
          />
          <Box mt={2}>
            <Button variant="contained" onClick={handleSubmit}>
              Submit
            </Button>
          </Box>
        </Box>
      )}
      {chooseData === "SameRow" && (
        <Box display="flex" columnGap={2} alignItems="center">
          <CustomSelect
            label={"Element Type"}
            options={ElementOptions}
            width={"100%"}
            background={"#ffffff"}
            value={item.samerowtype}
            onChange={(e) => handleChange(e.target.value, "samerowtype")}
          />
          <CustomInput
            labelinput={"Element Index"}
            placeholder={"Enter elment index"}
            value={item.samerowindex}
            onChange={(e) => handleChange(e.target.value, "samerowindex")}
          />
          <Box mt={2}>
            <Button variant="contained" onClick={handleSubmit}>
              Submit
            </Button>
          </Box>
        </Box>
      )}
      <CustomInput
        labelinput={"Iframexpath"}
        placeholder={"Enter Iframexpath"}
        value={item.iframexpath}
        onChange={(e) => handleChange(e.target.value, "iframexpath")}
      />

      <CustomSelect
        options={[
          { label: "Tab 1", value: "tab1" },
          { label: "Tab 2", value: "tab2" },
        ]}
        value={"tab1"}
        label={"Tabs"}
      />
    </Box>
  );
};

export default ElementLocator;
