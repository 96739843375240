import React from "react";

const TestCasesIcon = (props) => (
    <svg width="28" height="34" viewBox="0 0 28 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.1719 34H1.40086C0.668637 34 0.072998 33.4044 0.072998 32.6721V1.32786C0.072998 0.595639 0.668637 0 1.40086 0H26.1722C26.9045 0 27.5001 0.595639 27.5001 1.32786V32.6718C27.4997 33.404 26.9041 34 26.1719 34ZM1.40086 1.13816C1.29652 1.13816 1.21116 1.22353 1.21116 1.32786V32.6718C1.21116 32.7761 1.29652 32.8615 1.40086 32.8615H26.1722C26.2766 32.8615 26.3619 32.7761 26.3619 32.6718V1.32786C26.3619 1.22353 26.2766 1.13816 26.1722 1.13816H1.40086Z" fill="#253040"/>
    <path d="M11.3837 10.3258H4.52245C4.20832 10.3258 3.95337 10.0708 3.95337 9.7567V3.21605C3.95337 2.90192 4.20832 2.64697 4.52245 2.64697H11.3837C11.6978 2.64697 11.9528 2.90192 11.9528 3.21605V9.7567C11.9528 10.0708 11.6978 10.3258 11.3837 10.3258ZM5.09153 9.18762H10.8146V3.78514H5.09153V9.18762Z" fill="#253040"/>
    <path d="M7.73991 8.41877C7.62913 8.41877 7.51759 8.38652 7.42008 8.32013L6.34755 7.59094C6.08767 7.41415 6.02052 7.06056 6.19732 6.8003C6.37449 6.53966 6.7277 6.47365 6.98796 6.65006L7.66517 7.11064L9.47182 5.25164C9.69186 5.0259 10.0519 5.02135 10.2765 5.23988C10.5022 5.45916 10.5072 5.8192 10.2883 6.04456L8.14851 8.24653C8.03811 8.35996 7.88977 8.41877 7.73991 8.41877Z" fill="#253040"/>
    <path d="M23.0494 5.82469H14.8049C14.4908 5.82469 14.2358 5.56974 14.2358 5.25561C14.2358 4.94147 14.4908 4.68652 14.8049 4.68652H23.0494C23.3635 4.68652 23.6185 4.94147 23.6185 5.25561C23.6185 5.56974 23.3635 5.82469 23.0494 5.82469Z" fill="#253040"/>
    <path d="M23.0494 8.60252H14.8049C14.4908 8.60252 14.2358 8.34757 14.2358 8.03344C14.2358 7.7193 14.4908 7.46436 14.8049 7.46436H23.0494C23.3635 7.46436 23.6185 7.7193 23.6185 8.03344C23.6185 8.34757 23.3635 8.60252 23.0494 8.60252Z" fill="#253040"/>
    <path d="M11.3837 20.8365H4.52245C4.20832 20.8365 3.95337 20.5816 3.95337 20.2674V13.7268C3.95337 13.4127 4.20832 13.1577 4.52245 13.1577H11.3837C11.6978 13.1577 11.9528 13.4127 11.9528 13.7268V20.2674C11.9528 20.5816 11.6978 20.8365 11.3837 20.8365ZM5.09153 19.6984H10.8146V14.2959H5.09153V19.6984Z" fill="#253040"/>
    <path d="M7.73991 18.9294C7.62913 18.9294 7.51759 18.8971 7.42008 18.8307L6.34755 18.1015C6.08767 17.9248 6.02052 17.5712 6.19732 17.3109C6.37449 17.0503 6.7277 16.9842 6.98796 17.1607L7.66517 17.6212L9.47182 15.7622C9.69186 15.5369 10.0519 15.532 10.2765 15.7505C10.5022 15.9698 10.5072 16.3298 10.2883 16.5552L8.14851 18.7571C8.03811 18.8702 7.88977 18.9294 7.73991 18.9294Z" fill="#253040"/>
    <path d="M23.0494 16.3354H14.8049C14.4908 16.3354 14.2358 16.0805 14.2358 15.7663C14.2358 15.4522 14.4908 15.1973 14.8049 15.1973H23.0494C23.3635 15.1973 23.6185 15.4522 23.6185 15.7663C23.6185 16.0805 23.3635 16.3354 23.0494 16.3354Z" fill="#253040"/>
    <path d="M23.0494 19.1133H14.8049C14.4908 19.1133 14.2358 18.8583 14.2358 18.5442C14.2358 18.23 14.4908 17.9751 14.8049 17.9751H23.0494C23.3635 17.9751 23.6185 18.23 23.6185 18.5442C23.6185 18.8583 23.3635 19.1133 23.0494 19.1133Z" fill="#253040"/>
    <path d="M11.3839 31.3546H4.5227C4.20856 31.3546 3.95361 31.0996 3.95361 30.7855V24.2449C3.95361 23.9307 4.20856 23.6758 4.5227 23.6758H11.3839C11.6981 23.6758 11.953 23.9307 11.953 24.2449V30.7855C11.953 31.0996 11.6981 31.3546 11.3839 31.3546ZM5.09178 30.2164H10.8148V24.8139H5.09178V30.2164Z" fill="#253040"/>
    <path d="M7.74013 29.4476C7.62935 29.4476 7.51781 29.4153 7.42069 29.3489L6.34816 28.6201C6.0879 28.4437 6.02075 28.0897 6.19754 27.8299C6.37471 27.5688 6.72906 27.5025 6.98781 27.6793L7.66539 28.1394L9.47204 26.2804C9.69208 26.0547 10.0521 26.0502 10.2767 26.2687C10.5025 26.488 10.5074 26.848 10.2885 27.0734L8.14873 29.2753C8.03833 29.3884 7.88999 29.4476 7.74013 29.4476Z" fill="#253040"/>
    <path d="M23.0494 26.854H14.8049C14.4908 26.854 14.2358 26.599 14.2358 26.2849C14.2358 25.9708 14.4908 25.7158 14.8049 25.7158H23.0494C23.3635 25.7158 23.6185 25.9708 23.6185 26.2849C23.6185 26.599 23.3635 26.854 23.0494 26.854Z" fill="#253040"/>
    <path d="M23.0494 29.6357H14.8049C14.4908 29.6357 14.2358 29.3808 14.2358 29.0666C14.2358 28.7525 14.4908 28.4976 14.8049 28.4976H23.0494C23.3635 28.4976 23.6185 28.7525 23.6185 29.0666C23.6185 29.3808 23.3635 29.6357 23.0494 29.6357Z" fill="#253040"/>
    </svg>
);

export default TestCasesIcon;
