import { Box, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border: 2px solid #e2e6f3;
  border-radius: 8px;
  cursor: pointer;
`;

const IconContainer = ({
  children,
  background = "transparent",
  handleClick,
  color,
  tooltipText,
}) => {
  return (
    <Tooltip title={tooltipText} arrow>
      <StyledBox onClick={handleClick} bgcolor={background} color={color}>
        {children}
      </StyledBox>
    </Tooltip>
  );
};

export default IconContainer;
