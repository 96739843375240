import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import ImageNotSupportedOutlinedIcon from "@mui/icons-material/ImageNotSupportedOutlined";
import { CircleTickIcon, CircleWarningIcon, EditIcon } from "../../components-new/icons";

const ActionButton = styled(Box)`
  width: 40px;
  height: 40px;
  border-radius: 88px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const styles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};

const TestItemCard = ({ item, handleEdit, index }) => {
    return (
        <Box
            width={"260px"}
            gap={"12px"}
            alignItems={"flex-start"}
            padding={"20px"}
            display={"flex"}
            borderRadius={"6px"}
            bgcolor={"#ffffff"}
            boxShadow={"0px 43px 62.4px 0px rgba(0, 0, 0, 0.09)"}
            flexDirection={"column"}
        >
            <Box
                borderRadius={"4px"}
                height={160}
                width={"100%"}
                border={"1px solid rgba(0, 0, 0, 0.30)"}
                sx={styles}
            >
                {item.Failure_Screenshot_Location ? (
                    <img
                        style={{ height: "100%", width: "100%" }}
                        src={item.Failure_Screenshot_Location || `https://via.placeholder.com/900?text=No+Image`}
                        alt="testImage"
                    />
                ) : (
                    <ImageNotSupportedOutlinedIcon sx={{ color: "#8089A8" }} fontSize="large" />
                )}
            </Box>

            <Box width={"100%"} display={"flex"} justifyContent="space-between" alignItems={"center"}>
                <Box display={"flex"} columnGap={"8px"}>
                    <Typography>{item.Step_Number}</Typography>
                    {item.issues ? <CircleWarningIcon /> : <CircleTickIcon />}
                </Box>
                <Box display={"flex"} columnGap={"15px"}>
                    <ActionButton border={"2px solid #547de8"} onClick={() => handleEdit(item)}>
                        <EditIcon />
                    </ActionButton>
                </Box>
            </Box>
        </Box>
    );
};

export default TestItemCard;
