import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_BASE_PATH, API_BASE_RELATIVE_PATH } from "../Config/Config";

const uploadFile = async ({ file, testStepId, testCaseId }) => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetails);

  const response = await axios.post(
    `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=addscript&companyid=${user.companyid}&token=${user.token}&testCaseId=${testCaseId}&teststepid=${testStepId}&name=${file.name}`,
    file,
    {
      headers: {
        "content-type": file.type,
        "content-length": `${file.size}`,
      },
    },
  );

  return response.data;
};

const useUploadFileQuery = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: uploadFile,
    onSuccess: () => {
      queryClient.invalidateQueries(["testCaseResults", "allApi"]);
    },
    onError: (error) => {
      console.log("Error uploading file", error.message);
    },
  });
};

export default useUploadFileQuery;
