import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

const deleteTestSuite = async (suiteid) => {
  const userDetail = localStorage.getItem("userDetail");
  if (!userDetail) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetail);

  const response = await axios.get(
    `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=delTestSuites&companyid=${user.companyid}&token=${user.token}&idtest_suite=${suiteid}`,
  );

  return response.data;
};

// &pnum=${pgno}

const useDeleteTestSuiteQuery = (suiteid, enabled) => {
  return useQuery({
    queryKey: ["deleteTestSuite", suiteid],
    queryFn: () => deleteTestSuite(suiteid),
    enabled,
    retry: true,
    cacheTime: 90000,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    staleTime: Infinity,
  });
};

export default useDeleteTestSuiteQuery;
