import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Tooltip, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CommonMethods } from '../common/CommonMethods';
import { getSSPageData } from '../../API/CoveragePageApi'
import { colorExport } from "./../common/common.Component";

const TreeStructure = () => {
  const [imageURL, setImageURL] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  let { ssid, product } = useParams();
  const colors = colorExport();
  const navigate = useNavigate();

  const ScreenShotPage = async (ssid, product) => {
    setIsLoading(true);
    try {
      const response = await getSSPageData(ssid, product);
      if (response.status === 200) {
        if (response.data.message === "fail") {
          CommonMethods(navigate);
        } else {
          setImageURL(response.data.screenshotpath);
          console.log('Image URL set to:', response.data.screenshotpath);
        }
      }
    } catch (error) {
      console.error('Error fetching image URL:', error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    ScreenShotPage(ssid, product);
  }, [ssid, product]);

  return (
    <>
      <Box style={{
        display: "flex",
        justifyContent: "flex-start",
        marginLeft: 10,
        padding: 10,
      }}
      >
        <Tooltip title={"Back"}>
          <IconButton
            size="small"
            variant="contained"
            style={{
              color: colors.primaryColor,
              backgroundColor: colors.secondaryColor,
            }}
            onClick={() => {
              navigate(`/dashboard/CoveragePage/${product}`)
            }}
          >
            <ArrowBackIcon style={{ fontSize: 25 }} />
          </IconButton>
        </Tooltip>
      </Box>

      {isLoading ? (
        <p>Loading...</p> 
      ) : imageURL ? (
        <img
          style={{ width: '94vw', height: '83vh', marginTop: '20px', marginLeft: '23px' }}
          src={imageURL}
          alt="Image"
        />
      ) : (
        <p>Image not available</p>
      )}
    </>
  )
}

export default TreeStructure;