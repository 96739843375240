import React from "react";
import "./VideoPopup.scss"; // Import CSS for styling
import Typography from "@mui/material/Typography";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const VideoPopup = ({ videoUrl, onClose, open, tcnameForvideo }) => {
  return (
    <BootstrapDialog
      className="custom-dialog"
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <DialogContent>
        <Typography paddingBottom={0} variant="h6" component="h2">
          Test Case Name: {tcnameForvideo}
        </Typography>
        <IconButton
          aria-label="close"
          className="close-btn"
          onClick={onClose}
          style={{ position: "absolute", right: 0 }}
        >
          <CloseIcon />
        </IconButton>
        <div className="video-container">
          <video controls autoPlay>
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default VideoPopup;
