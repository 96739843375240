import { Box } from "@mui/material";
import { CustomSelect } from "../../../components-new/common";
import {
  AssertSubOptions,
  AssertTextOptions,
  CheckBoxOptions,
  DropDownOptions,
  KeywordData,
} from "../constants";
import { getActionOptions } from "./helper";
import { useDispatch } from "react-redux";
import { setEditStep } from "../../../Redux/TestAuthoring/TestAuthoring";
import AssertionSetup from "./AssertionSetup";
import TestDataSetup from "./TestDataSetup";

const ElementDetails = ({ item }) => {
  const dispatch = useDispatch();

  const handleChange = (value, field) => {
    dispatch(setEditStep({ ...item, [field]: value }));
  };

  return (
    <Box>
      <Box bgcolor="#E2E6F3" p="20px" borderRadius="13px" mb={3}>
        <Box display="flex" columnGap={3}>
          <Box mb={3} flex={1}>
            <CustomSelect
              label={"Element"}
              options={
                item.Step_Number === 0
                  ? KeywordData.filter((item) => item.value === "Url")
                  : KeywordData
              }
              width={"100%"}
              background={"#ffffff"}
              value={
                item.Step_Number === 0
                  ? "Url"
                  : item.Keyword === "Validation"
                    ? "Assertion"
                    : item?.Keyword
              }
              onChange={(e) => handleChange(e.target.value, "Keyword")}
            />
          </Box>
          <Box flex={1}>
            <CustomSelect
              label={"Action"}
              options={getActionOptions(item.Keyword)}
              width={"100%"}
              background={"#ffffff"}
              value={item.Action}
              onChange={(e) => handleChange(e.target.value, "Action")}
            />
          </Box>
          {item.Keyword === "Assertion" && item.Action === "Properties" && (
            <Box flex={1}>
              <CustomSelect
                label={"Options"}
                options={AssertSubOptions}
                width={"100%"}
                background={"#ffffff"}
                value={item.subAction || "validatePartial"}
                onChange={(e) => handleChange(e.target.value, "subAction")}
              />
            </Box>
          )}
          {(item.Keyword === "Assertion" || item.Keyword === "Validation") &&
            item.Action === "validatePartial" && (
              <Box flex={1}>
                <CustomSelect
                  label={"Options"}
                  options={AssertTextOptions}
                  width={"100%"}
                  background={"#ffffff"}
                  value={item.subAction || "validatePartial"}
                  onChange={(e) => handleChange(e.target.value, "subAction")}
                />
              </Box>
            )}
          {item.Keyword === "DropDown" && item.Action === "Select" && (
            <Box flex={1}>
              <CustomSelect
                label={"Options"}
                options={DropDownOptions}
                width={"100%"}
                background={"#ffffff"}
                value={item.subAction}
                onChange={(e) => handleChange(e.target.value, "subAction")}
              />
            </Box>
          )}
          {item.Keyword === "Check Box" && item.Action === "Checkbox" && (
            <Box flex={1}>
              <CustomSelect
                label={"Options"}
                options={CheckBoxOptions}
                width={"100%"}
                background={"#ffffff"}
                value={item.subAction}
                onChange={(e) => handleChange(e.target.value, "subAction")}
              />
            </Box>
          )}
        </Box>
      </Box>
      {(item.Keyword === "Assertion" || item.Keyword === "Validation") && (
        <AssertionSetup item={item} />
      )}
      {item.Keyword !== "Assertion" && item.Keyword !== "Validation" && (
        <TestDataSetup item={item} />
      )}
    </Box>
  );
};

export default ElementDetails;
