export const lastWeekData = [
    { "day": "Mon", "pass": 60, "fail": 13 },
    { "day": "Tue", "pass": 57, "fail": 4 },
    { "day": "Wed", "pass": 78, "fail": 2 },
    { "day": "Thu", "pass": 65, "fail": 3 },
    { "day": "Fri", "pass": 73, "fail": 5 },
    { "day": "Sat", "pass": 0, "fail": 0 },
    { "day": "Sun", "pass": 0, "fail": 0 }
];

export const testCaseAuthoredChartData = [
    { author: "Aditi", value: 167 },
    { author: "Salman", value: 184 },
    { author: "Kishore", value: 174 },
    { author: "Satish", value: 199 },
    { author: "Ashitha", value: 160 }
]

export const Last5ExeTestSuites = [
    {
        testSuiteName: 'User Scenarios',
        last5Run: [
            { type: 'pass', total: '1050', data: '947' },
            { type: 'pass', total: '1100', data: '846' },
            { type: 'fail', total: '1000', data: '723' },
            { type: 'notExecuted', total: '0', data: '0' },
            { type: 'pass', total: '1200', data: '912' }
        ]
    },
    {
        testSuiteName: 'Payment Scenarios',
        last5Run: [
            { type: 'pass', total: '1250', data: '889' },
            { type: 'notExecuted', total: '0', data: '0' },
            { type: 'pass', total: '1300', data: '758' },
            { type: 'fail', total: '1100', data: '623' },
            { type: 'pass', total: '1400', data: '831' }
        ]
    },
    {
        testSuiteName: 'Catalog Scenarios',
        last5Run: [
            { type: 'pass', total: '1180', data: '906' },
            { type: 'pass', total: '1200', data: '888' },
            { type: 'fail', total: '1100', data: '734' },
            { type: 'pass', total: '1220', data: '899' },
            { type: 'notExecuted', total: '0', data: '0' }
        ]
    },
    {
        testSuiteName: 'Order Placement',
        last5Run: [
            { type: 'pass', total: '1280', data: '925' },
            { type: 'pass', total: '1350', data: '812' },
            { type: 'fail', total: '1120', data: '691' },
            { type: 'notExecuted', total: '0', data: '0' },
            { type: 'pass', total: '1400', data: '888' }
        ]
    },
    {
        testSuiteName: 'Regression',
        last5Run: [
            { type: 'notExecuted', total: '0', data: '0' },
            { type: 'pass', total: '1100', data: '897' },
            { type: 'pass', total: '1150', data: '835' },
            { type: 'fail', total: '1000', data: '734' },
            { type: 'pass', total: '1200', data: '888' }
        ]
    }
];

export const testcaseData = [
    { time: '3:00:00', duration: null, testcaseName: null },
    { time: '3:01:00', duration: null, testcaseName: null },
    { time: '3:02:00', duration: null, testcaseName: null },
    { time: '3:03:00', duration: null, testcaseName: null },
    { time: '3:04:00', duration: null, testcaseName: null },
    { time: '3:05:00', duration: null, testcaseName: null },
    { time: '3:06:00', duration: null, testcaseName: null },
    { time: '3:07:00', duration: null, testcaseName: null },
    { time: '3:08:00', duration: null, testcaseName: null },
    { time: '3:09:00', duration: null, testcaseName: null },
    { time: '3:10:00', duration: '05:30', testcaseName: 'TestCase A' },
    { time: '3:11:00', duration: null, testcaseName: null },
    { time: '3:12:00', duration: null, testcaseName: null },
    { time: '3:13:00', duration: null, testcaseName: null },
    { time: '3:14:00', duration: null, testcaseName: null },
    { time: '3:15:00', duration: null, testcaseName: null },
    { time: '3:16:00', duration: null, testcaseName: null },
    { time: '3:17:00', duration: null, testcaseName: null },
    { time: '3:18:00', duration: null, testcaseName: null },
    { time: '3:19:00', duration: null, testcaseName: null },
    { time: '3:20:00', duration: null, testcaseName: null },
    { time: '3:21:00', duration: null, testcaseName: null },
    { time: '3:22:00', duration: null, testcaseName: null },
    { time: '3:23:00', duration: null, testcaseName: null },
    { time: '3:24:00', duration: null, testcaseName: null },
    { time: '3:25:00', duration: '06:15', testcaseName: 'TestCase B' },
    { time: '3:26:00', duration: null, testcaseName: null },
    { time: '3:27:00', duration: null, testcaseName: null },
    { time: '3:28:00', duration: null, testcaseName: null },
    { time: '3:29:00', duration: null, testcaseName: null },
    { time: '3:30:00', duration: null, testcaseName: null },
    { time: '3:31:00', duration: null, testcaseName: null },
    { time: '3:32:00', duration: null, testcaseName: null },
    { time: '3:33:00', duration: null, testcaseName: null },
    { time: '3:34:00', duration: null, testcaseName: null },
    { time: '3:35:00', duration: null, testcaseName: null },
    { time: '3:36:00', duration: null, testcaseName: null },
    { time: '3:37:00', duration: null, testcaseName: null },
    { time: '3:38:00', duration: '08:03', testcaseName: 'TestCase C' },
    { time: '3:39:00', duration: null, testcaseName: null },
    { time: '3:40:00', duration: null, testcaseName: null },
    { time: '3:41:00', duration: null, testcaseName: null },
    { time: '3:42:00', duration: null, testcaseName: null },
    { time: '3:43:00', duration: null, testcaseName: null },
    { time: '3:44:00', duration: null, testcaseName: null },
    { time: '3:45:00', duration: '07:20', testcaseName: 'TestCase D' },
    { time: '3:46:00', duration: null, testcaseName: null },
    { time: '3:47:00', duration: null, testcaseName: null },
    { time: '3:48:00', duration: null, testcaseName: null },
    { time: '3:49:00', duration: null, testcaseName: null },
    { time: '3:50:00', duration: null, testcaseName: null },
    { time: '3:51:00', duration: null, testcaseName: null },
    { time: '3:52:00', duration: null, testcaseName: null },
    { time: '3:53:00', duration: null, testcaseName: null },
    { time: '3:54:00', duration: null, testcaseName: null },
    { time: '3:55:00', duration: '04:45', testcaseName: 'TestCase E' },
    { time: '3:56:00', duration: null, testcaseName: null },
    { time: '3:57:00', duration: null, testcaseName: null },
    { time: '3:58:00', duration: null, testcaseName: null },
    { time: '3:59:00', duration: null, testcaseName: null },
    { time: '4:00:00', duration: null, testcaseName: null },
];

const generateRandomData = (numEntries) => {
    const data = [];
    for (let i = 0; i < numEntries; i++) {
        const testSteps = Math.floor(Math.random() * 20) + 1;
        const testCases = Math.floor(Math.random() * 20) + 1;
        const matchPercentage = `${Math.floor(Math.random() * 101)}%`;

        data.push({ testSteps, testCases, matchPercentage });
    }
    return data;
};

export const optimizationTableData = generateRandomData(10);

export const TestcaseColumn = [
    { headerName: 'Test case Name', field: 'testCaseName' },
];

export const TestcaseColumnData = [
    { testCaseName: 'User Scenarios' },
    { testCaseName: 'Payment Scenarios' },
    { testCaseName: 'Catalog Scenarios' },
    { testCaseName: 'Order Placement' },
    { testCaseName: 'Regression' },
];

export const TestStepColumn = [
    { headerName: 'Step Number', field: 'stepNumber' },
    { headerName: 'Keyword', field: 'keyword' },
    { headerName: 'Action', field: 'action' },
    { headerName: 'Test Data', field: 'testData' },
]

export const TestStepColumnData = [
    {stepNumber: 10, keyword: 'URL',action: 'Get',  testData: 'https://www.amazon.in/?&tag=googhydrabk1...'},
    {stepNumber: 12, keyword: 'URL',action: 'Get',  testData: 'https://www.amazon.in/?&tag=googhydrabk1...'},
    {stepNumber: 18, keyword: 'URL',action: 'Get',  testData: 'https://www.amazon.in/?&tag=googhydrabk1...'},
    {stepNumber: 21, keyword: 'URL',action: 'Get',  testData: 'https://www.amazon.in/?&tag=googhydrabk1...'},
    {stepNumber: 26, keyword: 'URL',action: 'Get',  testData: 'https://www.amazon.in/?&tag=googhydrabk1...'},
]

    
export const ExecutionThreadChartData = [
    {
      name: 'Test Cases',
      data: [
        { x: 'Thread 1', y: [new Date('2023-07-13T03:00:00').getTime(), new Date('2023-07-13T03:08:00').getTime()], label: 'Twitter' },
        { x: 'Thread 1', y: [new Date('2023-07-13T03:10:00').getTime(), new Date('2023-07-13T03:22:00').getTime()], label: 'Facebook' },
        { x: 'Thread 2', y: [new Date('2023-07-13T03:05:00').getTime(), new Date('2023-07-13T03:10:00').getTime()], label: 'Instagram' },
        { x: 'Thread 3', y: [new Date('2023-07-13T03:02:00').getTime(), new Date('2023-07-13T03:12:00').getTime()], label: 'LinkedIn' },
        { x: 'Thread 4', y: [new Date('2023-07-13T03:15:00').getTime(), new Date('2023-07-13T03:25:00').getTime()], label: 'WhatsApp' },
        { x: 'Thread 5', y: [new Date('2023-07-13T03:12:00').getTime(), new Date('2023-07-13T03:28:00').getTime()], label: 'Snapchat' },
        { x: 'Thread 6', y: [new Date('2023-07-13T03:22:00').getTime(), new Date('2023-07-13T03:30:00').getTime()], label: 'Pinterest' },
        { x: 'Thread 6', y: [new Date('2023-07-13T03:42:00').getTime(), new Date('2023-07-13T03:49:00').getTime()], label: 'Pinterest' },
        { x: 'Thread 7', y: [new Date('2023-07-13T03:32:00').getTime(), new Date('2023-07-13T03:43:00').getTime()], label: 'Reddit' },
        { x: 'Thread 8', y: [new Date('2023-07-13T03:25:00').getTime(), new Date('2023-07-13T03:35:00').getTime()], label: 'Tumblr' },
        { x: 'Thread 2', y: [new Date('2023-07-13T03:27:00').getTime(), new Date('2023-07-13T03:37:00').getTime()], label: 'TikTok' },
        { x: 'Thread 3', y: [new Date('2023-07-13T03:30:00').getTime(), new Date('2023-07-13T03:40:00').getTime()], label: 'YouTube' },
        { x: 'Thread 3', y: [new Date('2023-07-13T03:42:00').getTime(), new Date('2023-07-13T03:52:00').getTime()], label: 'Google' },
        { x: 'Thread 4', y: [new Date('2023-07-13T03:55:00').getTime(), new Date('2023-07-13T03:58:00').getTime()], label: 'Bing' },
        { x: 'Thread 8', y: [new Date('2023-07-13T03:05:00').getTime(), new Date('2023-07-13T03:13:00').getTime()], label: 'Tumblr' },
      ]
    }
  ];