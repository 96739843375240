import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

const fetchProducts = async () => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const temp = JSON.parse(userDetails);

  try {
    const response = await axios.get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=searchProdDropDown&companyid=${temp.companyid}&token=${temp.token}`,
    );
    return response.data;
  } catch (error) {
    console.error(error.response.data.error);
    throw new Error(error.response.data.error);
  }
};

const useProductQuery = () => {
  return useQuery({
    queryKey: ["products"],
    queryFn: fetchProducts,
    config: {
      retry: true,
      cacheTime: 90000,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      staleTime: Infinity,
      select: (data) => data.products,
    },
  });
};

export default useProductQuery;
