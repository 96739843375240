import React, { useState, useEffect } from "react";
import "./ResolutionPage.style.scss";

import {
  Box,
  List,
  Card,
  Typography,
  FormControl,
  InputLabel,
  Select,
  ListItem,
  MenuItem,
  IconButton,
  TextField,
  ListItemText,
} from "@mui/material";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import DeleteIcon from "@mui/icons-material/Delete";
import { ColorButton,colorExport,  StyledTableRow,StyledTableCell,} from "../../components/common/common.Component";
import {CommonMethods} from "./../../components/common/CommonMethods";
import { useNavigate } from "react-router-dom";

import {
  getResolution,
  sendResolutionaddCredential,
  sendResolutionaddResolution,
  sendResolutionaddURL,sendResolutionExecute
} from "../../API/adminAPI";

import { fetchProducts, fetchModules } from "../../API/CreateCollectionAPI";

const ResolutionPage = () => {
  const [state, setState] = useState({
    ResolutionList: [],
    URLList: [],
    idproducts: "",
    idmodules: "",
    email: "",
    password: "",
    company: "",
  });
  const [tempResolution, setTempResolution] = useState({
    Width: "",
    idresolutions:"",
    Height: "",
  });
  const [productList, setproductList] = useState([]);
  const [ModuleList, setModuleList] = useState([]);
  const [tempUrl, settempUrl] = useState([
    {
      urlName: "",
      urlId: "",
    },
  ]);

  const navigate = useNavigate();
  const colors = colorExport();
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);

  const getProducts = async (api_input) => {
    await fetchProducts(api_input)
      .then((response) => {
        if (response.status === 200) {
          if(response.data.message === 'fail'){
            CommonMethods(navigate);
          }else{
          setproductList(response.data);
        }
      }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getResolutionData = async (id) => {
    await getResolution(id)
      .then((response) => {
        if (response.status === 200) {
          if(response.data.message === 'fail'){
            CommonMethods(navigate);
          }else{
          setState(response.data);
        }
      }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const sendResolutionData = async (data) => {
    await sendResolutionaddURL(data)
      .then((response) => {
        if (response.status === 200) {
          if(response.data.message === 'fail'){
            CommonMethods(navigate);
          }else{
          console.log("sendResolutionaddURL", response);
        }
      }
      })
      .catch((error) => {
        console.log(error);
      });
    await sendResolutionaddCredential(data)
      .then((response) => {
        if (response.status === 200) {
          if(response.data.message === 'fail'){
            CommonMethods(navigate);
          }else{
          console.log("sendResolutionaddCredential", response);
        }
      }
      })
      .catch((error) => {
        console.log(error);
      });
    await sendResolutionaddResolution(data)
      .then((response) => {
        if (response.status === 200) {
          if(response.data.message === 'fail'){
            CommonMethods(navigate);
          }else{
          console.log("sendResolutionaddResolution", response);
        }
      }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const sendResolutionDataExecute = async (data) => {
    await sendResolutionExecute(data)
      .then((response) => {
        if (response.status === 200) {
          if(response.data.message === 'fail'){
            CommonMethods(navigate);
          }else{
          console.log("sendResolutionaddURL", response);
        }
      }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getModules = async (id) => {
    await fetchModules(id)
      .then((response) => {
        if (response.status === 200) {
          if(response.data.message === 'fail'){
            CommonMethods(navigate);
          }else{
          setModuleList(response.data);
        }
      }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const DeletedUrl = (item) => {    
    setState({
      ...state,
      URLList:state.URLList.filter(temp=>temp.urlName!==item.urlName),
    });
  };

   const DeletedResolution = (item) => {    
    setState({
      ...state,
      ResolutionList:state.ResolutionList.filter(temp=>temp.Width!==item.Width),
    });
  };
  useEffect(() => {
    getProducts();
  }, []);

  const AddResolutionSide = () => (
    <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
      <Typography paddingY={1} variant="h6" component="h2">
        Add Resolution
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <TextField
          variant="outlined"
          margin="normal"
          required
          id="Width"
          label="Width"
          name="Width"
          sx={{ mr: "1rem", width: 90 }}
          autoFocus
          type="number"
          value={tempResolution.Width}
          onChange={(event) => {
            setTempResolution({
              ...tempResolution,
              Width: event.target.value,              
              idresolutions: (state.ResolutionList.length + 1) * -1,
            });
          }}
        />
        <TextField
          variant="outlined"
          sx={{ mr: "1rem", width: 90 }}
          margin="normal"
          required
          type="number"
          id="Height"
          label="Height"
          name="Height"
          autoFocus
          value={tempResolution.Height}
          onChange={(event) => {
            setTempResolution({
              ...tempResolution,
              Height: event.target.value,
            });
          }}
        />
        <ColorButton size='small'
          onClick={() => {
            if (tempResolution.Height !== 0 && tempResolution.Width !== 0) {
              setState({
                ...state,
                ResolutionList: [...state.ResolutionList, tempResolution],
              });
              setTempResolution({
                ...tempResolution,
                Height: "",
                Width: "",
              });
            }
          }}
        >
          Add
        </ColorButton>
      </Box>
      <List dense={true}>
        {state.ResolutionList.map((item, index) => (
          <ListItem key={index}     secondaryAction={
            <IconButton
              edge="start"
              aria-label="delete"
              onClick={() => {
                DeletedResolution(item);
              }}
            >
              <DeleteIcon />
            </IconButton>
          }>
            <ListItemText primary={`${item.Width} X ${item.Height}`} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const AddUrlSide = () => (
    <Box
      sx={{
        display: "flex",
        flex: 4,
        flexDirection: "column",
        marginRight: "4rem",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <Typography paddingY={1} variant="h6" component="h2">
            URLs
          </Typography>
          <ColorButton size='small' variant="contained"
            style={{ marginBottom: "1rem", alignSelf: "center" }}
            onClick={() => {
              if (tempUrl.urlName !== "" && tempUrl.urlId !== "") {
                setState({ ...state, URLList: [...state.URLList, tempUrl] });
                settempUrl({ urlName: "", urlId: "" });
              }
            }}
          >
            add url
          </ColorButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TextField
            sx={{ marginRight: "2rem", marginBottom: "1rem" }}
            label={"URL"}
            variant="standard"
            value={tempUrl.urlName}
            fullWidth
            onChange={(event) => {
              settempUrl({
                urlName: event.target.value,
                urlId: (state.URLList.length + 1) * -1,
              });
            }}
          />
          <List dense={true} sx={{ p: 0 }}>
            {state.URLList.map((item, index) => (
              <ListItem
                key={index}
                secondaryAction={
                  <IconButton
                    edge="start"
                    aria-label="delete"
                    onClick={() => {
                      DeletedUrl(item);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                }
              >
                <ListItemText primary={item.urlName} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Box>
  );

  return (
   
    <Box
      sx={{
        display: "flex",
        flex: 1,
        alignItem: "center",
        justifyContent: "space-evenly",
        paddingTop: "2rem",
        flexDirection: "column",
        paddingX: "2rem",
      }}
    >
     
     
       
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: colors.secondaryColor,
          color: colors.primaryColor,
          height:173
        }}
        >
      
           <FormControl sx={{ width: 240,marginLeft:'2rem',color: colors.primaryColor, }}>
                          <InputLabel
                            style={{
                              color: colors.primaryColor,
                              backgroundColor: colors.secondaryColor,
                              padding: 5,
                            }}
                            id={"product name"}
                          >
                            Product
                          </InputLabel>
                          <Select
                            labelId={"product name"}
                            id={"product name Select"}
                            value={state.idproducts}
                            name={"product"}
                            label="Product"
                            IconComponent={(props) => (
                              <ArrowDropDownSharpIcon
                                style={{ color: colors.primaryColor, fontSize: 40 }}
                               
                                {...props}
                              />
                            )}
                            sx={{
                             
                              color:colors.primaryColor,
                              border: `2px solid ${colors.primaryColor}`,
                              fontSize: 16,
                              "&:focus": {
                                borderRadius: 4,
                                border: `4px solid ${colors.primaryColor}`,
                              },
                            }}
                            onChange={(event) => {
                              setState({ ...state, idproducts: event.target.value });
                              getModules(event.target.value);
                              getResolutionData(event.target.value);
                            }}
                            style={{
                              minWidth: 100,
                              marginRight: "2rem",
                            }}
                          >
                            {productList.map((item, index) => (
                              <MenuItem key={index} value={item.idproducts}>
                                {item.productname}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
        {state.idproducts!==""&&<>
        {/* <FormControl sx={{ width: 240 }}>
          <InputLabel id={"module name"}>Module Name</InputLabel>
          <Select
            labelId={"module name"}
            variant="standard"
            id={"module name Select"}
            value={state.idmodules}
            name={"module"}
            onChange={(event) => {
              setState({ ...state, idmodules: event.target.value });
            }}
            style={{
              minWidth: 100,
              marginRight: "2rem",
              alignItems: "center",
            }}
            label="Module"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {ModuleList.map((item, index) => (
              <MenuItem key={index} value={item.idmodules}>
                {item.modulename}
              </MenuItem>
            ))}
          </Select>
        </FormControl>*/}
        <TextField
          variant="outlined"
          InputProps={{
            style: {
              color: colors.primaryColor, 
              // backgroundColor: colors.secondaryColor,
              // border: `2px solid ${colors.primaryColor  }`
              // Change the color to the desired color
            },
          }}
          margin="normal"
          required
          id="email"
          label="Username / Email"
          name="email"
          autoFocus
          defaultValue={state.email}
          onChange={(event) => {
            setState({ ...state, email: event.target.value });
          }}
        />
        <TextField
          variant="outlined"
          InputProps={{
            style: {
              color: colors.primaryColor, 
              // backgroundColor: colors.secondaryColor,
              // border: `2px solid ${colors.primaryColor  }`
              // Change the color to the desired color
            },
          }}
          margin="normal"
          required
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          defaultValue={state.password}
          onChange={(event) => {
            setState({ ...state, password: event.target.value });
          }}
          // style={{ color: colors.primaryColor}}
        />
        <TextField
          variant="outlined"
          InputProps={{
            style: {
              color: colors.primaryColor, 
              // backgroundColor: colors.secondaryColor,
              // border: `2px solid ${colors.primaryColor  }`
              // Change the color to the desired color
            },
          }}
          margin="normal"
          required
          name="company"
          label="company name"
          type="company"
          id="company"
          autoComplete="current-company"
          defaultValue={state.company}
          onChange={(event) => {
            setState({ ...state, company: event.target.value });
          }}
        />
        <ColorButton size='small' variant="contained"
          style={{ width: 180, marginRight: "1rem", color: colors.primaryColor,
          backgroundColor: colors.secondaryColor }}
          onClick={() => {
            sendResolutionData(state);
          }}
        >
          Submit
        </ColorButton>
        <ColorButton size='small' variant="contained"
          style={{ width: 180, color: colors.primaryColor,
            backgroundColor: colors.secondaryColor}}
          onClick={() => {
            sendResolutionDataExecute(state);
          }}
        >
          Execute
        </ColorButton>
        </>}
      </Box>
      {state.idproducts!==""&&<Box
        sx={{
          mt: 2,
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          flexDirection: "row",
        }}
      >
        {AddUrlSide()}
        {AddResolutionSide()}
      </Box>}
    </Box>
  );
};

export default ResolutionPage;
