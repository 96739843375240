import React, { useState } from "react";
import { TextField, Typography } from "@mui/material";

const EditableText = ({ initialText }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(initialText);

  const handleTextClick = () => {
    setIsEditing(true);
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  return (
    <div>
      {isEditing ? (
        <TextField value={text} onChange={handleTextChange} onBlur={handleBlur} autoFocus />
      ) : (
        <Typography onClick={handleTextClick}>{text}</Typography>
      )}
    </div>
  );
};

export default EditableText;
