import { Box, Link, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useFetchStepHistory } from "../../useQuery";
import { PageTitle } from "../../components-new/common";
import EditHeader from "./EditHeader";
import EditTabs from "../Studio/EditTabs";
import { useDispatch } from "react-redux";
import { setTestCaseResults } from "../../Redux/ExecutionReport/ExecutionReport";

const StyledTableCell = styled(TableCell)`
    color: #232D3D;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 1px solid #E2E6F3;
`;

const StyledTableBodyCell = styled(TableCell)`
    padding: 8px 20px;
    border-bottom: 1px solid #eee;
    p {
        color: #2B3539;
        font-size: 16px;
        font-weight: 500;
        padding-right: 15px;
        min-width: 60px;
    }
`;


const StepHistoryPage = () => {
    const RouteState = useParams();
    const { tcid } = RouteState;
    const dispatch = useDispatch()
    const [TestStepFailResults, setTestStepFailResults] = useState([]);
    const [testStepName, settTestStepName] = useState();
    const [stepNumber, setStepNumber] = useState(null);
    const [editItemIndex, setEditItemIndex] = useState(null)
    const [currentEditItem, setCurrentEditItem] = useState(null)
    // const queryParams = new URLSearchParams(RouteState.search);
    const { data, isSuccess } = useFetchStepHistory(tcid, {
        enabled: tcid !== "",
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(()=> {
        if(TestStepFailResults) setCurrentEditItem(TestStepFailResults[editItemIndex])
    },[TestStepFailResults, editItemIndex])

    const handleEditModal = (data, index) => {
        setEditItemIndex(index)
        setCurrentEditItem(data[index])
    }

    useEffect(() => {
        if (isSuccess && data) {
            setTestStepFailResults(Object.values(data[0].data));
            dispatch(setTestCaseResults(Object.values(data[0].data)));
            settTestStepName(data[0].TestCase)
            setStepNumber(data[0].data[2].Step_Number);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess])

    return (
        <Box flex={1}>
            {editItemIndex === null ? <>
            <PageTitle mb={2} variant={'h5'} title={`Test Step - ${testStepName} || Step Number - ${stepNumber}`} />
            {isSuccess && (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Results Id</StyledTableCell>
                                <StyledTableCell>Executed Date</StyledTableCell>
                                <StyledTableCell>Keyword</StyledTableCell>
                                <StyledTableCell>Action</StyledTableCell>
                                <StyledTableCell>Flow</StyledTableCell>
                                <StyledTableCell>TestData</StyledTableCell>
                                <StyledTableCell>Duration</StyledTableCell>
                                <StyledTableCell >Status</StyledTableCell>
                                <StyledTableCell >Screenshot</StyledTableCell>
                                <StyledTableCell >Validate In</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {TestStepFailResults?.length ?
                                TestStepFailResults.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                                    <TableRow key={index}>
                                        <StyledTableBodyCell>{item.Test_Case_Results_Id}</StyledTableBodyCell>
                                        <StyledTableBodyCell>{item.Executed_Date}</StyledTableBodyCell>
                                        <StyledTableBodyCell>{item.Keyword}</StyledTableBodyCell>
                                        <StyledTableBodyCell>{item.Action}</StyledTableBodyCell>
                                        <StyledTableBodyCell>{item.Flow}</StyledTableBodyCell>
                                        <StyledTableBodyCell>{item.TestData.length > 40 ? <Tooltip title={item.TestData}>{item.TestData?.slice(0, 40) + "..."}</Tooltip> : item.TestData}</StyledTableBodyCell>
                                        <StyledTableBodyCell>{item.Duration}</StyledTableBodyCell>
                                        <StyledTableBodyCell>{item.Status}</StyledTableBodyCell>
                                        <StyledTableBodyCell>{item.Failure_Screenshot_Location && (
                                                <Link onClick={(event) => {

                                                    handleEditModal(TestStepFailResults, index);
                                                }}
                                                    underline="hover"
                                                >
                                                    Screenshot
                                                </Link>
                                            )
                                        }</StyledTableBodyCell>
                                        <StyledTableBodyCell>{item.ValDevice}</StyledTableBodyCell>

                                    </TableRow>
                                ))
                                : <TableRow> <StyledTableBodyCell align="center" colSpan={7}>No data available</StyledTableBodyCell></TableRow>
                            }
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={TestStepFailResults.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
            )}
            </> :  <Box pb={2}>
          <EditHeader
            editItemIndex={editItemIndex}
            editItem={currentEditItem}
            setEditItemIndex={setEditItemIndex}
            fromStepHistory={true}
          />
          <EditTabs editItem={currentEditItem} fromExcReports={true} />
        </Box>}
        </Box>
    );
};

export default StepHistoryPage;
