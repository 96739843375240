import React from "react";

const SearchIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path
      d="M13.6992 12.5676L17.1256 15.9932L15.9936 17.1252L12.568 13.6988C11.2934 14.7206 9.70799 15.2763 8.07439 15.274C4.09999 15.274 0.87439 12.0484 0.87439 8.07402C0.87439 4.09962 4.09999 0.874023 8.07439 0.874023C12.0488 0.874023 15.2744 4.09962 15.2744 8.07402C15.2767 9.70762 14.721 11.293 13.6992 12.5676ZM12.0944 11.974C13.1097 10.9299 13.6767 9.53037 13.6744 8.07402C13.6744 4.97962 11.168 2.47402 8.07439 2.47402C4.97999 2.47402 2.47439 4.97962 2.47439 8.07402C2.47439 11.1676 4.97999 13.674 8.07439 13.674C9.53073 13.6763 10.9303 13.1093 11.9744 12.094L12.0944 11.974Z"
      fill="#444141"
      fillOpacity="0.67"
    />
  </svg>
);

export default SearchIcon;
