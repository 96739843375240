import { Box, Typography } from "@mui/material";
import IconContainer from "./IconContainer";

const StatusCard = ({ icon, title, value }) => {
  return (
    <Box display="flex" columnGap="15px" alignItems="center">
      <IconContainer background="#ffffff">{icon}</IconContainer>
      <Box>
        <Typography color={"#6a6a6a"} fontSize={"12px"}>
          {title}
        </Typography>
        <Typography color={"#2b3539"} fontSize={"14px"}>
          {value}
        </Typography>
      </Box>
    </Box>
  );
};

export default StatusCard;
