import React, { useEffect, useState } from "react";
import {
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { styled } from "@mui/system";
import { useSelector } from "react-redux";
import useDeleteStepQuery from "../../useQuery/useDeleteStepQuery";
import { DeleteIcon } from "../icons";
import { useNotification } from "../../hooks";

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
  "&.MuiButtonBase-root.MuiMenuItem-root": {
    display: "flex",
    padding: "12px 25px",
  },
  "& .MuiListItemIcon-root": {
    minWidth: "36px",
    textAlign: "center",
    color: "#242F3F",
    "& svg": {
      width: "20px",
      height: "20px",
    },
  },
  "&:last-child .MuiListItemIcon-root": {
    color: "#FF310E",
  },
  "& .MuiTypography-root": {
    fontSize: "14px",
    fontWeight: 500,
    color: "#2B3539",
  },
  "&:last-child .MuiTypography-root": {
    color: "#FF310E",
  },
}));

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: "500px",
    height: "200px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const CustomMenu = ({ anchorEl, handleClose, handleExitEdit }) => {
  const stepDetails = useSelector((state) => state.authoringSlice.editStep);
  const [openDialog, setOpenDialog] = useState(false);

  const notify = useNotification();

  const { mutate: deleteStep, isSuccess } = useDeleteStepQuery();

  const handleDelete = () => {
    deleteStep({
      stepId: stepDetails.idtest_step,
      caseId: stepDetails.Test_Case_Id,
    });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDelete = () => {
    setOpenDialog(false);
    handleDelete();
  };

  useEffect(() => {
    if (isSuccess) {
      notify("The item has been successfully deleted.", "success");
      handleExitEdit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <Menu
      id="customized-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {/*<CustomMenuItem onClick={handleClose}>
         <ListItemIcon>
          <RenameIcon />
        </ListItemIcon>
        <Typography>Rename</Typography>
      </CustomMenuItem>
      <CustomMenuItem onClick={handleClose}>
        <ListItemIcon>
          <CopyIcon />
        </ListItemIcon>
        <Typography>Copy</Typography>
      </CustomMenuItem>
      <CustomMenuItem onClick={handleClose}>
        <ListItemIcon>
          <NlpIcon />
        </ListItemIcon>
        <Typography>NLP steps</Typography>
      </CustomMenuItem>
      <CustomMenuItem onClick={handleClose}>
        <ListItemIcon>
          <DownloadIcon />
        </ListItemIcon>
        <Typography>Download code</Typography>
      </CustomMenuItem>
      <CustomMenuItem onClick={handleClose}>
        <ListItemIcon>
          <ScreenshotIcon />
        </ListItemIcon>
        <Typography>Baseline images</Typography>
      </CustomMenuItem>
      <CustomMenuItem onClick={handleClose}>
        <ListItemIcon>
          <LogFileIcon />
        </ListItemIcon>
        <Typography>Log file</Typography>
      </CustomMenuItem>
      <CustomMenuItem onClick={handleClose}>
        <ListItemIcon>
          <InfoIcon color="#242F3F" />
        </ListItemIcon>
        <Typography>Info</Typography>
      </CustomMenuItem>
      <Divider /> */}
      <CustomMenuItem
        onClick={() => {
          handleClose();
          setOpenDialog(true);
        }}
      >
        <ListItemIcon>
          <DeleteIcon />
        </ListItemIcon>
        <Typography>Delete</Typography>
      </CustomMenuItem>
      <CustomDialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this step?"}
        </DialogTitle>
        <DialogContent>
          <Typography>This action cannot be undone.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            No
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </CustomDialog>
    </Menu>
  );
};

export default CustomMenu;
