import axios from "axios";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "./../Config/Config";
export const getFirstPageData = async (product) => {
     const userDetails = localStorage.getItem("userDetail");
    const temp = JSON.parse(userDetails);
    const Response = await axios
        .get(
            `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getPageElements&companyid=${temp.companyid}&token=${temp.token}&productid=${product}`
        )
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error.response.data.error);
        });
    return Response;
    }

    export const getTreeStructurePageData = async (product) => {
        const userDetails = localStorage.getItem("userDetail");
       const temp = JSON.parse(userDetails);
       const Response = await axios
           .get(
               `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getPages&companyid=${temp.companyid}&token=${temp.token}&productid=${product}`
           //http://localhost:9000/keyloggingv2/ReactApp?action=getPages&companyid=1&token=00622739&productid=1
               )
           .then((response) => {
               return response;
           })
           .catch((error) => {
               console.log(error.response.data.error);
           });
       return Response;
}

export const getSSPageData = async (ssid,product) => {
    const userDetails = localStorage.getItem("userDetail");
   const temp = JSON.parse(userDetails);
   const Response = await axios
       .get(
           `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getPageSS&companyid=${temp.companyid}&token=${temp.token}&pageid=${ssid}&prodid=${product}`
        //    http://localhost:9000/keyloggingv2/ReactApp?action=getPageSS&companyid=1&token=00622739&pageid=1
       )
        .then((response) => {
           return response;
       })
       .catch((error) => {
           console.log(error.response.data.error);
       });
   return Response;
}