import React from "react";
import { Box } from "@mui/material";
import DesignCardSection from "./DesignCardSection";
import DesignAuthoredChart from "./DesignAuthoredChart";
import DesignAuthoredTop5Chart from "./DesignAuthoredTop5Chart";

const DesignSection = () => {

    return (
        <Box mt={'36px'}>
            {/* <Box display="flex" justifyContent={"space-between"} alignItems="center">
                <Box display="flex" columnGap={3} alignItems={"center"}>
                    <Typography fontSize={'16px'} fontWeight={500} mt={'26px'}>Test Cases Authored</Typography>
                    <Box>
                        <Typography fontSize={'13px'} fontWeight={500} color='#8089A8' mb={'6px'}>From Date</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                inputFormat="MM/DD/YYYY"
                                value={value}
                                onChange={(e) => { setValue(e.target.value) }}
                                renderInput={(params) => <TextField {...params} />}
                                sx={{ backgroundColor: '#fff', boxShadow: '0px 17px 25px 0px rgba(0, 0, 0, 0.05)' }}
                            />
                        </LocalizationProvider>
                    </Box>
                    <Box>
                        <Typography fontSize={'13px'} fontWeight={500} color='#8089A8' mb={'6px'}>To Date</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                inputFormat="MM/DD/YYYY"
                                value={value}
                                onChange={() => { }}
                                renderInput={(params) => <TextField {...params} />}
                                sx={{ backgroundColor: '#fff', boxShadow: '0px 17px 25px 0px rgba(0, 0, 0, 0.05)' }}
                            />
                        </LocalizationProvider>
                    </Box>
                    <Box mt={'26px'}><ResetBtn variant="text">Reset</ResetBtn></Box>
                </Box>
            </Box> */}
            <DesignCardSection />
            <Box display={"flex"} columnGap={2}>
                <DesignAuthoredTop5Chart />
                <DesignAuthoredChart />
            </Box>
        </Box>
    )
};

export default DesignSection;