import React from "react";
import { Select, MenuItem, Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

const StyledSelect = styled(Select)(({ theme }) => ({
  borderRadius: "5px",
  border: "1px solid #D1DBFF",
  height: "54px",
  "& .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  minHeight: '40px',
}));

const CustomSelect = ({
  label,
  value,
  onChange,
  options,
  helperText,
  error,
  fullWidth,
  width,
  variant,
  disabled,
  background,
  ...otherProps
}) => {
  return (
    <Box>
      <Typography color="#8089A8" fontSize="14px" fontWeight={500}>
        {label}
      </Typography>
      <StyledSelect
        sx={{ minWidth: "120px", width: width, background: background }}
        value={value}
        onChange={onChange}
        disabled={disabled}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 250, 
              overflowY: 'auto',
            },
          },
        }}
      >
        {options.map((option) => (
          <StyledMenuItem key={option.value} value={option.value}>
            {option.label}
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </Box>
  );
};


export default CustomSelect;
