import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

const getCountByAuthor = async ({ dateRange, author }) => {
    const userDetails = localStorage.getItem("userDetail");
    if (!userDetails) throw new Error("No user details found in localStorage");
    const user = JSON.parse(userDetails);

    const response = await axios.get(
        `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getCountByAuthor&companyid=${user.companyid}&token=${user.token}&fromDate=${dateRange.fromDate}&toDate=${dateRange.toDate}&author=${author}`,
    );

    return response.data;
};

const useGetCountByAuthorQuery = ({ dateRange, author }) => {
    return useQuery({
        queryKey: ["CountByAuthor", dateRange, author],
        queryFn: () => getCountByAuthor({ dateRange, author }),
        enabled: !!dateRange.fromDate && !!dateRange.toDate && !!author,
        retry: true,
        cacheTime: 0,
        refetchIntervalInBackground: false,
        staleTime: 0,
    });
};

export default useGetCountByAuthorQuery;
