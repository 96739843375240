import { useState, forwardRef, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  MenuItem,
  OutlinedInput,
  Select,
  Slide,
  styled,
  Switch,
  Typography,
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { CustomInput, PageTitle, PillRadio } from "../../components-new/common";
import TabComponent from "../../components-new/common/TabComponent";
import {
  BrowserOptions,
  Days,
  EnvOptions,
  IntegrationOptions,
  ResolutionOptions,
  SchedulerOptions,
} from "../../components-new/utils/constants";
import { API_BASE_PATH, API_BASE_RELATIVE_PATH } from "../../Config/Config";
import {
  useExecuteTestSuitesMutation,
  useLicenceKeyQuery,
  useStaticIntegrationsQuery,
  useSuiteSchedulerQuery,
} from "../../useQuery";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { useNavigate } from "react-router-dom";
dayjs.extend(advancedFormat);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    textAlign: "auto",
    padding: theme.spacing(2),
  },
}));

const StyledLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: "13px",
  fontWeight: 500,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  padding: "15px 28px",
  borderRadius: "8px",
  textTransform: "capitalize",
  textAlign: "center",
  fontSize: "17px",
  fontWeight: 600,
  lineHeight: "20px",
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function stringToBoolean(str) {
  return str.toLowerCase() === "true";
}

export default function TestSuiteSettings({ open, setOpenSuiteSettings, suite }) {
  const navigate = useNavigate();
  const [selectedEnv, setSelectedEnv] = useState(EnvOptions[0].value);
  const [selectedBrowser, setSelectedBrowser] = useState(suite.browser);
  const [selectedIntegration, setSelectedIntegration] = useState(IntegrationOptions[0].value);
  const [selectedResolution, setSelectedResolution] = useState("");
  const [proxyUrl, setProxyUrl] = useState("");
  const [email, setEmail] = useState(suite.resultsemail);
  const [iterations, setIterations] = useState("");
  const [scheduler, setScheduler] = useState(SchedulerOptions[0].value);
  const [schedulerDay, setSchedulerDay] = useState([]);
  const [reRun, setReRun] = useState(stringToBoolean(suite.rerunfailedtests));
  const [isEmailReports, setIsEmailReports] = useState(suite.indEmail);
  const [isHeadless, setIsHeadless] = useState(suite.headless);
  const [isParallel, setIsParallel] = useState(stringToBoolean(suite.supports_parallel_execution));
  const [isTerminate, setIsTerminate] = useState(stringToBoolean(suite.stopafterfailure));
  const [isSync, setIsSync] = useState(suite.synchedscenarios);
  const [isScreenShot, setIsScreenShot] = useState(suite.ssonerror);
  const [exceUrl, setExceUrl] = useState("");
  const [time, setTime] = useState(dayjs());
  const [date, setDate] = useState(dayjs());

  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const temp = JSON.parse(userDetails);

  const { data } = useLicenceKeyQuery();
  const { data: staticIntegrations } = useStaticIntegrationsQuery();
  const { refetch } = useSuiteSchedulerQuery(
    scheduler,
    dayjs(time).format("hh:mm a"),
    schedulerDay.join(","),
    dayjs(date).format("DD/MM/YYYY"),
    suite.idtest_suite,
  );

  const { mutate: handleExecuteTestSuite, isSuccess: isExecuteTestSuiteSuccess } =
    useExecuteTestSuitesMutation();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSchedulerDay(typeof value === "string" ? value.split(",") : value);
  };

  const handleDeleteSchedule = () => {
    setSchedulerDay([]);
    setTime(dayjs());
  };

  const handleSubmitSchedule = () => {
    refetch();
  };

  const generateExceUrl = (integrationType, workItemId, jiraId) => {
    const baseURL = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=DLExecuteSuite&companyid=${temp.companyid}&token=${data}&prodid=${suite.productid}&SuiteId=${suite.idtest_suite}`;

    return `${baseURL}${integrationType ? `&inttype=${integrationType}` : ""}${workItemId ? `&WorkItemId=${workItemId}` : ""}${jiraId ? `&IssueId=${jiraId}` : ""}`;
  };

  useEffect(() => {
    const intType = staticIntegrations?.find((item) => item.intname === selectedIntegration);
    setExceUrl(generateExceUrl(intType?.staticintid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIntegration, data]);

  useEffect(() => {
    if (isExecuteTestSuiteSuccess) {
      navigate("/new/reports", {
        state: {
          isExecuteClick: true,
          selectedSuit: suite.idtest_suite,
          product: suite.productid,
          fetchTestSuite: true,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExecuteTestSuiteSuccess, navigate]);

  const handleRun = () => {
    const resolutionMap = {
      "1920x1080": { width: 1920, height: 1080 },
      "1440x780": { width: 1440, height: 780 },
      "1366X768": { width: 1366, height: 768 },
      "1280X780": { width: 1280, height: 780 },
    };
    const resolution = resolutionMap[selectedResolution] || {};

    const payload = {
      prodid: suite.productid,
      suitid: suite.idtest_suite,
      envname: selectedEnv,
      envUrl: "", // Add logic to retrieve the environment URL if necessary
      browser: selectedBrowser,
      stop: isTerminate,
      rerun: reRun,
      runparallel: isParallel,
      ssfail: isScreenShot,
      indEmail: isEmailReports,
      inputemail: email,
      height: resolution.height || "",
      width: resolution.width || "",
      iterations: iterations || "1",
      proxy: proxyUrl,
    };
    handleExecuteTestSuite(payload);
  };

  const tabs = [
    {
      icon: <></>,
      label: "Run Parameters",
      content: (
        <>
          <Box p={2} display={"flex"}>
            <Box flex={1}>
              <Box>
                <StyledLabel>Environment</StyledLabel>
                <Box>
                  {EnvOptions.map((option) => (
                    <PillRadio
                      key={option.value}
                      value={option.value}
                      label={option.label}
                      checked={selectedEnv === option.value}
                      onChange={(event) => setSelectedEnv(event.target.value)}
                    />
                  ))}
                </Box>
              </Box>
              <Box pt={2}>
                <StyledLabel>Screen Resolution</StyledLabel>
                <Box>
                  {ResolutionOptions.map((option) => (
                    <PillRadio
                      key={option.value}
                      value={option.value}
                      label={option.label}
                      checked={selectedResolution === option.value}
                      onChange={(event) => setSelectedResolution(event.target.value)}
                    />
                  ))}
                </Box>
              </Box>
              <Box pt={2}>
                <StyledLabel>Browser</StyledLabel>
                <Box>
                  {BrowserOptions.map((option) => (
                    <PillRadio
                      key={option.value}
                      value={option.value}
                      label={option.label}
                      checked={selectedBrowser === option.value}
                      onChange={(event) => setSelectedBrowser(event.target.value)}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
            <Box flex={1} p={2}>
              <FormGroup sx={{ display: "flex", rowGap: 2 }}>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={isTerminate}
                      onChange={() => setIsTerminate((prev) => !prev)}
                    />
                  }
                  label="Terminate test even if a single test case fails"
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={reRun}
                      onChange={() => setReRun((prev) => !prev)}
                    />
                  }
                  label="Re-run failed test cases"
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={isParallel}
                      onChange={() => setIsParallel((prev) => !prev)}
                    />
                  }
                  label="Runs Tests in Parallel"
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={isSync}
                      onChange={() => setIsSync((prev) => !prev)}
                    />
                  }
                  label="Synchronized Tests"
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={isHeadless}
                      onChange={() => setIsHeadless((prev) => !prev)}
                    />
                  }
                  label="Headless Execution"
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={isScreenShot}
                      onChange={() => setIsScreenShot((prev) => !prev)}
                    />
                  }
                  label="Capture screenshots only on test failure"
                />
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      checked={isEmailReports}
                      onChange={() => setIsEmailReports((prev) => !prev)}
                    />
                  }
                  label="Email individual test result"
                />
              </FormGroup>
            </Box>
          </Box>
          <Box>
            <CustomInput
              labelinput="Proxy Url"
              placeholder={"Enter proxy Url"}
              value={proxyUrl}
              onChange={(e) => setProxyUrl(e.target.value)}
            />
            <Box display="flex" pt={2} columnGap={2}>
              <CustomInput
                labelinput="Iterations"
                placeholder={"Enter iterations"}
                value={iterations}
                onChange={(e) => setIterations(e.target.value)}
              />
              <CustomInput
                labelinput="Email to receive reports "
                placeholder={"Enter email"}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                flex={1}
              />
            </Box>
          </Box>
        </>
      ),
    },
    {
      icon: <></>,
      label: "Integration",
      content: (
        <Box py={2}>
          <Box mb={2}>
            <StyledLabel>Integrations</StyledLabel>
            <Box>
              {IntegrationOptions.map((option) => (
                <PillRadio
                  key={option.value}
                  value={option.value}
                  label={option.label}
                  checked={selectedIntegration === option.value}
                  onChange={(event) => setSelectedIntegration(event.target.value)}
                />
              ))}
            </Box>
          </Box>
          <CustomInput
            labelinput="Suite Execution URL"
            placeholder={"Enter Suite Execution URL"}
            value={exceUrl}
          />
        </Box>
      ),
    },
    {
      icon: <></>,
      label: "Scheduler",
      content: (
        <Box py={2}>
          <Box mb={2}>
            <StyledLabel>Select Frequency</StyledLabel>
            <Box>
              {SchedulerOptions.map((option) => (
                <PillRadio
                  key={option.value}
                  value={option.value}
                  label={option.label}
                  checked={scheduler === option.value}
                  onChange={(event) => setScheduler(event.target.value)}
                />
              ))}
            </Box>
          </Box>
          <Box mb={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={["TimePicker", "DatePicker"]}
                sx={{ alignItems: "center" }}
              >
                <Box>
                  <StyledLabel>Time</StyledLabel>
                  <TimePicker
                    value={time}
                    onChange={(newValue) => {
                      if (newValue) {
                        setTime(newValue);
                      }
                    }}
                  />
                </Box>
                {scheduler === SchedulerOptions[2].value && (
                  <Box>
                    <StyledLabel>Date</StyledLabel>
                    <DatePicker value={date} onChange={(newValue) => setDate(newValue)} />
                  </Box>
                )}
                {scheduler === SchedulerOptions[1].value && (
                  <Box>
                    <StyledLabel>Select Day</StyledLabel>
                    <Select
                      id="demo-multiple-name"
                      multiple
                      value={schedulerDay}
                      onChange={handleChange}
                      input={<OutlinedInput />}
                      MenuProps={MenuProps}
                      sx={{ width: "250px" }}
                    >
                      {Days.map((day) => (
                        <MenuItem key={day} value={day}>
                          {day}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                )}
              </DemoContainer>
            </LocalizationProvider>
            <Box display="flex" mt={2} columnGap={2}>
              <StyledButton
                sx={{ height: "50px" }}
                variant="contained"
                onClick={handleDeleteSchedule}
              >
                Reset
              </StyledButton>
              <StyledButton
                sx={{ height: "50px" }}
                variant="contained"
                onClick={handleSubmitSchedule}
              >
                Submit
              </StyledButton>
            </Box>
          </Box>
        </Box>
      ),
    },
  ];

  return (
    <>
      <CustomDialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenSuiteSettings(false)}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: {
            width: "900px",
            maxWidth: "900px",
          },
        }}
      >
        <PageTitle title="Settings" variant="h5" />
        <DialogContent sx={{ width: "100%", px: 0 }}>
          <TabComponent tabs={tabs} />
        </DialogContent>
        <DialogActions sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
          <StyledButton
            sx={{ color: "#ff5336" }}
            variant="text"
            color="warning"
            onClick={() => setOpenSuiteSettings(false)}
          >
            Cancel
          </StyledButton>
          <StyledButton
            variant="text"
            color="warning"
            sx={{
              backgroundColor: "#ff5336",
              color: "#ffffff",
              boxShadow: "0px 16px 30.6px 0px rgba(255, 83, 54, 0.27)",
            }}
            onClick={() => {
              handleRun();
            }}
          >
            Run
          </StyledButton>
        </DialogActions>
      </CustomDialog>
    </>
  );
}
