import React, { useState } from "react";
import { Button, TextField } from "@mui/material";

const ColorForm = () => {
  const [color, setColor] = useState("");
  const [error, setError] = useState(false);

  const handleSave = () => {
    if (color) {
      console.log("Selected Color:", color);
      setError(false);
    } else {
      setError(true);
    }
  };

  return (
    <form noValidate autoComplete="off">
      <TextField
        label="Choose Color"
        type="color"
        value={color}
        onChange={(e) => setColor(e.target.value)}
        required
        error={error}
        helperText={error ? "This field is required" : ""}
        fullWidth
        margin="normal"
      />
      <Button variant="contained" color="primary" onClick={handleSave}>
        Save
      </Button>
    </form>
  );
};

export default ColorForm;
