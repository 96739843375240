// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-container {
  display: flex;
  justify-content: space-between;
}

.column {
  flex: 1 1;
  padding: 10px;
}

.card {
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

.card h3 {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 30px;
  font-weight: 600;
  background-image: linear-gradient(to left, #052037, #3c708e);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.input-group {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.input-group label {
  margin-right: 10px;
  min-width: 120px;
  font-weight: bold;
}

input[type=text-cred] {
  flex: 1 1;
  padding: 5px;
  border: 1px solid #999999;
  border-radius: 3px;
}

.subheads {
  margin-left: 26px !important;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}`, "",{"version":3,"sources":["webpack://./src/pages/AdminPage/AdminPage.style.scss"],"names":[],"mappings":"AAAE;EACE,aAAA;EACA,8BAAA;AACJ;;AAEE;EACE,SAAA;EACA,aAAA;AACJ;;AAEE;EAEE,kBAAA;EACA,aAAA;EACA,mBAAA;AAAJ;;AAGE;EACE,eAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,4DAAA;EACA,kBAAA;EACA,qBAAA;EACA,6BAAA;AAAJ;;AAGE;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;AAAJ;;AAGE;EACE,kBAAA;EACA,gBAAA;EACA,iBAAA;AAAJ;;AAGE;EACE,SAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;AAAJ;;AAGE;EACA,4BAAA;EACA,aAAA;EACA,mBAAA;EACA,oCAAA;AAAF","sourcesContent":["  .card-container {\n    display: flex;\n    justify-content: space-between;\n  }\n  \n  .column {\n    flex: 1;\n    padding: 10px;\n  }\n  \n  .card {\n   \n    border-radius: 5px;\n    padding: 10px;\n    margin-bottom: 10px;\n  }\n  \n  .card h3 {\n    margin-top: 5px;\n    margin-bottom: 5px;\n    font-size: 30px;\n    font-weight: 600;\n    background-image: linear-gradient(to left, #052037, #3c708e);\n    color: transparent;\n    background-clip: text;\n    -webkit-background-clip: text;\n  }\n  \n  .input-group {\n    display: flex;\n    align-items: center;\n    margin-bottom: 5px;\n  }\n  \n  .input-group label {\n    margin-right: 10px;\n    min-width: 120px;\n    font-weight: bold;\n  }\n  \n  input[type=\"text-cred\"]{\n    flex: 1;\n    padding: 5px;\n    border: 1px solid #999999;\n    border-radius: 3px;\n    \n  }\n  .subheads {\n  margin-left: 26px !important;\n  display: flex;\n  align-items: center;\n  /* justify-content: space-between; */\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
