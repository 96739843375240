import React from "react";

const ExportIcon = ({ color = "white" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
    <path
      d="M5.83321 0.5V2.16667H1.66654V13.8333H13.3332V9.66667H14.9999V14.6667C14.9999 14.8877 14.9121 15.0996 14.7558 15.2559C14.5995 15.4122 14.3876 15.5 14.1665 15.5H0.833211C0.612198 15.5 0.400236 15.4122 0.243956 15.2559C0.0876753 15.0996 -0.00012207 14.8877 -0.00012207 14.6667V1.33333C-0.00012207 1.11232 0.0876753 0.900358 0.243956 0.744078C0.400236 0.587797 0.612198 0.5 0.833211 0.5H5.83321ZM12.1549 2.16667H8.33321V0.5H14.9999V7.16667H13.3332V3.345L7.49988 9.17833L6.32154 8L12.1549 2.16667Z"
      fill={color}
    />
  </svg>
);

export default ExportIcon;
