import React from "react";

const ChromeIcon = ({ color = "#FF5336" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M10.365 19.833L12.295 16.491C11.4423 16.5471 10.5912 16.3592 9.84137 15.9492C9.09155 15.5392 8.47401 14.9242 8.061 14.176L4.794 8.52C4.26563 9.61449 3.99349 10.8149 3.9982 12.0303C4.00292 13.2456 4.28437 14.4439 4.82122 15.5343C5.35806 16.6247 6.13619 17.5784 7.09657 18.3233C8.05695 19.0681 9.17435 19.5844 10.364 19.833H10.365ZM12.59 19.979C13.8021 19.8898 14.9779 19.5256 16.0282 18.9141C17.0786 18.3025 17.9757 17.4597 18.6516 16.4496C19.3275 15.4395 19.7644 14.2887 19.9291 13.0845C20.0937 11.8803 19.9819 10.6545 19.602 9.5H15.742C16.2374 10.2395 16.5013 11.1099 16.5 12C16.5013 12.8167 16.2793 13.6183 15.858 14.318L12.59 19.978V19.979ZM14.143 13.288L14.165 13.25C14.3834 12.8717 14.4988 12.4428 14.4998 12.006C14.5009 11.5693 14.3875 11.1398 14.1709 10.7605C13.9543 10.3812 13.6421 10.0653 13.2654 9.84416C12.8888 9.62304 12.4607 9.50447 12.024 9.50026C11.5872 9.49605 11.157 9.60634 10.7761 9.82015C10.3952 10.034 10.077 10.3438 9.85314 10.7189C9.62929 11.0939 9.50761 11.5211 9.50021 11.9578C9.49282 12.3945 9.59997 12.8256 9.811 13.208L9.835 13.25C10.0523 13.6271 10.3644 13.9409 10.7403 14.1604C11.1162 14.3798 11.5429 14.4973 11.9781 14.5011C12.4134 14.5049 12.8421 14.395 13.2218 14.1823C13.6015 13.9695 13.919 13.6612 14.143 13.288ZM6.035 6.668L7.964 10.008C8.33573 9.25502 8.91073 8.62107 9.62398 8.17785C10.3372 7.73463 11.1603 7.49983 12 7.5H18.615C17.8818 6.42103 16.8956 5.53795 15.7425 4.92786C14.5894 4.31776 13.3045 3.99921 12 4C10.8737 3.99873 9.75986 4.2359 8.73177 4.69591C7.70367 5.15592 6.78461 5.82836 6.035 6.669V6.668ZM12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22Z"
      fill={color}
    />
  </svg>
);

export default ChromeIcon;
