import React from "react";
import { Outlet } from "react-router-dom";
import { AppBar, CssBaseline, Box, Drawer } from "@mui/material";
import Sidebar from "./Sidebar";
import AppToolbar from "./AppToolBar";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const drawerWidth = 244;

const theme = createTheme({
  typography: {
    fontFamily: "Epilogue, sans-serif",
  },
});

const DashboardLayout = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        fontFamily={"Epilogue, sans-serif"}
        data-testid="dashboard-layout"
        backgroundColor="#f5f7f9"
        display="flex"
        height={`calc(100vh - 64px)`}
        overflow="auto"
      >
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, boxShadow: "none" }}
        >
          <AppToolbar drawerWidth={drawerWidth} />
        </AppBar>
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
              backgroundColor: "#242f3f",
              color: "#ffffff",
            },
          }}
        >
          <Box
            sx={{ overflow: "auto", mt: "64px", p: "20px" }}
            flex={1}
            display={"flex"}
            flexDirection={"column"}
          >
            <Sidebar />
          </Box>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, bgcolor: "#f5f7f9", p: 3 }}>
          <Outlet />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default DashboardLayout;
