import { Typography } from "@mui/material";

const PageTitle = ({ title, variant = "h4" }) => {
  return (
    <Typography variant={variant} component={variant} gutterBottom fontWeight={600}>
      {title}
    </Typography>
  );
};

export default PageTitle;
