import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import { CommonMethods } from '../common/CommonMethods';
import { useLocation, useNavigate } from "react-router-dom";
import {
    colorExport, ColorButton
} from "./../common/common.Component";
import { getAllComments, getTestCasesFromComments, getRepos } from '../../API/TestCaseApi';
import {
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    IconButton,
    Typography,
    TableBody,
    Button
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import { fetchProducts, fetchSuiteCase } from '../../API/CreateCollectionAPI';
import { DatePicker } from '@mui/x-date-pickers';
import axios from "axios";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "./../../Config/Config";
import LoaderSpinner from "./../common/LoaderSpinner";


const ImpactAnalysis = ({ setValue, fromtheImpactAnalysis, setProduct, productImpact, setKnowledge, knowledge, setRepo, repo, setToDate, toDate, setFromDate, fromDate, setAllCommentInTextArea, allCommentInTextArea, setSuiteOption, suiteOption, setNewTestSuite, newTestSuite, setSuitet, suiteImpact }) => {
    const colors = colorExport();
    const navigate = useNavigate();
    const location = useLocation();
    const userDetails = localStorage.getItem("userDetail");
    const temp = JSON.parse(userDetails);
    const [isLoading, setIsLoading] = useState(false);

    const [productList, setProductList] = useState([]);
    const [repoList, setRepoList] = useState([]);
    // const [suiteOption, setSuiteOption] = useState("");
    // const [newTestSuite, setNewTestSuite] = useState("");
    // const [suiteImpact, setSuitet] = useState("");
    // const [allComment, setAllComment] = useState("");
    const [SuiteCaseList, setSuiteCaseList] = useState([]);

    useEffect(() => {
        getProduct();
        if (productImpact) {
            allRepos(productImpact)
            getSuiteCase(productImpact)
        }
    }, [])

    // useEffect(()=>{
    //     allRepos()
    // },[productImpact])

    const getProduct = async () => {
        await fetchProducts()
            .then((response) => {
                if (response.status === 200 && response.data.message === "fail") {
                    CommonMethods(navigate);
                } else {
                    setProductList(response.data);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const allComments = async (productImpact, repo, fromDate, toDate, knowledge) => {
        await getAllComments(productImpact, repo, fromDate, toDate, knowledge)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.message === "fail") {
                        CommonMethods(navigate);
                    } else {
                        // setAllComment(response.data)
                        setIsLoading(false);
                        texareaData(response.data)
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const allRepos = async (productImpact) => {
        await getRepos(productImpact)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.message === "fail") {
                        CommonMethods(navigate);
                    } else {
                        setRepoList(response.data)
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const onSubmitToGetComment = async (productImpact, comments, suiteOption, suiteImpact, newTestSuite) => {
        const commentsArray = comments.split('\n');
        //  const commentsArray = comments.split('\n');
        //  let commentsObjectsArray = commentsArray.map(comment => {
        //     // Extract the index and text.
        //     let [index, text] = comment.split(': ');
        //     // Remove quotes if present.
        //     text = text.replace(/^"|"$/g, '');
        //     // Construct the object.
        //     let commentObject = {};
        //     commentObject[index.trim()] = text;
        //     return commentObject;
        //   });
        // await getTestCasesFromComments(productImpact, commentsObjectsArray)
        await getTestCasesFromComments(productImpact, commentsArray, suiteOption, suiteImpact, newTestSuite)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.message === "fail") {
                        CommonMethods(navigate);
                    } else {
                        console.log("response", response.data);
                        fromtheImpactAnalysis({ product: productImpact, testcase: response.data, selected: suiteOption, suitid: suiteImpact });
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const texareaData = (comments) => {
        const commentsString = comments.map((comment, index) => {
            const displayComment = comment.replace(/\n/g, '\\n');
            return `${index}: "${displayComment}"`;
        }).join('\n');

        setAllCommentInTextArea(commentsString)

    }

    const getSuiteCase = async (id) => {
        await fetchSuiteCase(id)
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.message === 'fail') {
                        CommonMethods(navigate);
                    } else {
                        formateSuiteprops(response.data);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const formateSuiteprops = (data) => {
        setSuiteCaseList(
            data.map((item) => {
                return {
                    ...item[0],
                    list: item[0].list.map((subitem) => subitem.testcases[0]),
                };
            })
        );
    };

    return (
        <Box >
            <Box
                sx={{
                    justifyContent: "flex-start",
                    alignItems: "center",
                    backgroundColor: colors?.secondaryColor,
                    color: colors?.primaryColor,
                    marginTop: 2,
                    height: 150,
                    marginLeft: 4,
                    marginRight: 4,
                }}
            >
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",

                }}>

                    <Typography variant="h5" nowrap component="div" sx={{ marginTop: 1, }}>
                        All Repos
                    </Typography>

                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexDirection: "row",
                        marginLeft: 4,
                        marginTop: 2,
                    }}
                >
                    <FormControl sx={{ width: 240, color: colors.PrimaryColor }}>
                        <InputLabel
                            style={{
                                color: colors.primaryColor,
                                backgroundColor: colors.secondaryColor,
                                padding: 5,
                            }}
                            id={"Knowledge"}
                        >
                            Cognitive Source
                        </InputLabel>
                        <Select
                            labelId={"Knowledge"}
                            id={"Knowledge Select"}
                            value={knowledge}
                            onChange={(event) => {
                                setKnowledge(event.target.value);
                            }}
                            name={"Knowledge"}
                            label="Cognitive Source"
                            defaultValue="Open AI"
                            IconComponent={(props) => (
                                <ArrowDropDownSharpIcon
                                    style={{ color: colors.primaryColor, fontSize: 40 }}
                                    {...props}
                                />
                            )}
                            sx={{
                                color: colors.primaryColor,
                                border: `2px solid ${colors.primaryColor}`,
                                fontSize: 16,
                                "&:focus": {
                                    borderRadius: 4,
                                    border: `4px solid ${colors.primaryColor}`,
                                },
                            }}
                            style={{
                                minWidth: 100,
                                marginRight: "2rem",
                            }}
                        >
                            <MenuItem value="Open AI">Open AI</MenuItem>
                            <MenuItem value="Custom Models">Custom Models</MenuItem>
                            <MenuItem value="Hastags">Hastags</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl sx={{ width: 240, color: colors.PrimaryColor }}>
                        <InputLabel
                            style={{
                                color: colors.primaryColor,
                                backgroundColor: colors.secondaryColor,
                                padding: 5,
                            }}
                            id={"productImpact"}
                        >
                            Product
                        </InputLabel>
                        <Select
                            labelId={"productImpact"}
                            id={"productImpact Select"}
                            value={productImpact}
                            onChange={(event) => {
                                setProduct(event.target.value);
                                allRepos(event.target.value)
                                getSuiteCase(event.target.value);
                            }}
                            name={"productImpact"}
                            label="productImpact"
                            IconComponent={(props) => (
                                <ArrowDropDownSharpIcon
                                    style={{ color: colors.primaryColor, fontSize: 40 }}
                                    {...props}
                                />
                            )}
                            sx={{
                                color: colors.primaryColor,
                                border: `2px solid ${colors.primaryColor}`,
                                fontSize: 16,
                                "&:focus": {
                                    borderRadius: 4,
                                    border: `4px solid ${colors.primaryColor}`,
                                },
                            }}
                            style={{
                                minWidth: 100,
                                marginRight: "2rem",
                            }}
                        >
                            {productList.map((productImpact, index) => (
                                <MenuItem key={index} value={productImpact.idproducts}>
                                    {productImpact.productname}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {/* uncomment for testing with repo api */}
                    <FormControl sx={{ width: 240, color: colors.PrimaryColor }}>
                        <InputLabel
                            style={{
                                color: colors.primaryColor,
                                backgroundColor: colors.secondaryColor,
                                padding: 5,
                            }}
                            id={"Repo"}
                        >
                            Repos
                        </InputLabel>
                        <Select
                            labelId={"Repo"}
                            id={"Repo"}
                            value={repo}
                            onChange={(event) => {
                                setRepo(event.target.value);
                            }}
                            name={"Repo"}
                            label="Repo"
                            IconComponent={(props) => (
                                <ArrowDropDownSharpIcon
                                    style={{ color: colors.primaryColor, fontSize: 40 }}
                                    {...props}
                                />
                            )}
                            sx={{
                                color: colors.primaryColor,
                                border: `2px solid ${colors.primaryColor}`,
                                fontSize: 16,
                                "&:focus": {
                                    borderRadius: 4,
                                    border: `4px solid ${colors.primaryColor}`,
                                },
                            }}
                            style={{
                                minWidth: 100,
                                marginRight: "2rem",
                            }}
                        >
                            {repoList.map((repoName, index) => (
                                <MenuItem key={index} value={repoName}>
                                    {repoName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {/* <FormControl sx={{ width: 240, color: colors.PrimaryColor }}>
                        <InputLabel
                            style={{
                                color: colors.primaryColor,
                                backgroundColor: colors.secondaryColor,
                                padding: 5,
                            }}
                            id={"Repo"}
                        >
                            Repos
                        </InputLabel>
                        <Select
                            labelId={"Repo"}
                            id={"Repo"}
                            value={repo}
                            onChange={(event) => {
                                setRepo(event.target.value);
                            }}
                            name={"Repo"}
                            label="Repo"
                            IconComponent={(props) => (
                                <ArrowDropDownSharpIcon
                                    style={{ color: colors.primaryColor, fontSize: 40 }}
                                    {...props}
                                />
                            )}
                            sx={{
                                color: colors.primaryColor,
                                border: `2px solid ${colors.primaryColor}`,
                                fontSize: 16,
                                "&:focus": {
                                    borderRadius: 4,
                                    border: `4px solid ${colors.primaryColor}`,
                                },
                            }}
                            style={{
                                minWidth: 100,
                                marginRight: "2rem",
                            }}
                        >
                            <MenuItem value="keyloggingv2">keyloggingv2</MenuItem>
                            <MenuItem value="react-app">react-app</MenuItem>
                        </Select>
                    </FormControl> */}
                    <FormControl sx={{ width: 240, color: colors.primaryColor, marginLeft: 2 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                className="date-picker"
                                label="From Date"
                                value={fromDate}
                                onChange={(date) => setFromDate(date)}
                                sx={{
                                    '.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
                                        width: '250px',
                                        color: colors.primaryColor
                                    },
                                    '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                                        borderColor: colors.primaryColor
                                    },
                                    '.css-i4bv87-MuiSvgIcon-root': {
                                        fill: colors.primaryColor
                                    },
                                    '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
                                        color: colors.primaryColor
                                    },
                                    '.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root': {
                                        color: colors.primaryColor
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </FormControl>
                    <FormControl sx={{ width: 240, color: colors.primaryColor, marginLeft: 2 }}>
                        <>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Before Date"
                                    value={toDate}
                                    onChange={(date) => setToDate(date)}
                                    sx={{
                                        '.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root': {
                                            width: '250px',
                                            color: colors.primaryColor
                                        },
                                        '.css-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                                            borderColor: colors.primaryColor
                                        },
                                        '.css-i4bv87-MuiSvgIcon-root': {
                                            fill: colors.primaryColor
                                        },
                                        '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
                                            color: colors.primaryColor
                                        },
                                        '.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root': {
                                            color: colors.primaryColor
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </>
                    </FormControl>
                    {<FormControl>
                        <ColorButton
                            component="label"
                            variant="contained"
                            sx={{ marginLeft: 4 }}
                            disabled={toDate && fromDate && productImpact && repo && knowledge ? false : true}
                            onClick={() => {
                                allComments(productImpact, repo, fromDate, toDate, knowledge)
                                setIsLoading(true)
                            }}
                        >
                            Submit
                        </ColorButton>

                    </FormControl>}
                </Box>
            </Box>
            <Box>
      {isLoading ? <LoaderSpinner /> :
                
                <TextField
                    multiline
                    value={allCommentInTextArea}
                    rows={20}
                    variant="outlined"
                    label="All Comments"
                    onChange={(event) => {
                        setAllCommentInTextArea(event.target.value);
                    }}
                    sx={{
                        backgroundColor: "white",
                        color: colors?.secondaryColor,
                        marginTop: 4,
                        marginLeft: 4,
                        marginRight: 4,
                        width: "96.5%",
                        '.css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input': {
                            '::-webkit-scrollbar': {
                                display: "block"
                            },
                            '::-webkit-scrollbar-thumb': {
                                backgroundColor: colors.primaryColor
                            }
                        }
                    }}
                />}
            </Box>
            {allCommentInTextArea ? <Box
                sx={{
                    justifyContent: "flex-start",
                    alignItems: "center",
                    backgroundColor: colors?.primaryColor,
                    color: colors?.secondaryColor,
                    marginTop: 2,
                    height: 110,
                    marginLeft: 4,
                    marginRight: 4,
                }}
            >
                {allCommentInTextArea ?
                    <FormControl sx={{ width: 240, marginTop: "2%", marginLeft: "2%" }}>
                        <InputLabel
                            style={{
                                color: colors.secondaryColor,
                                backgroundColor: colors.primaryColor,
                                padding: 5,
                            }}
                            id={"Suite Option"}
                        >
                            Suite Option
                        </InputLabel>
                        <Select
                            labelId={"Suite Option"}
                            id={"Suite Option"}
                            value={suiteOption}
                            onChange={(event) => {
                                setSuiteOption(event.target.value);
                            }}
                            IconComponent={(props) => (
                                <ArrowDropDownSharpIcon
                                    style={{ color: colors.secondaryColor, fontSize: 40 }}
                                    {...props}
                                />
                            )}
                            sx={{
                                color: colors.secondaryColor,
                                border: `2px solid ${colors.secondaryColor}`,
                                fontSize: 16,
                                "&:focus": {
                                    borderRadius: 4,
                                    border: `4px solid ${colors.secondaryColor}`,
                                },
                            }}
                        >
                            <MenuItem value="NewTestSuite">New Test Suite</MenuItem>
                            <MenuItem value="ExistingTestSuite">Existing Test Suite</MenuItem>
                            <MenuItem value="NoSelection">No Selection</MenuItem>
                        </Select>
                    </FormControl> : ""
                }
                {suiteOption === "NewTestSuite" ?
                    <FormControl sx={{ width: 240, marginTop: "2.5%", marginLeft: "2%" }}>
                        <TextField
                            label="New Test Suite"
                            variant="outlined"
                            placeholder="Enter Test Suite"
                            value={newTestSuite}
                            onChange={(event) => {
                                setNewTestSuite(event.target.value)
                            }}
                            sx={{
                                '& .MuiInputBase-input': {
                                    color: colors.secondaryColor, // Text color inside the input
                                    fontSize: 16,
                                },
                                '& .MuiInputLabel-root': { // Label color in normal state
                                    color: colors.secondaryColor, // Replace with your default label color if needed
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: colors.secondaryColor,
                                    },
                                    '&:hover fieldset': {
                                        borderColor: colors.secondaryColor,
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: colors.secondaryColor,
                                        borderWidth: '2px',
                                    },
                                    '&.Mui-focused .MuiInputLabel-root': { // Label color when the TextField is focused
                                        color: colors.secondaryColor,
                                    },
                                },
                            }}
                        />
                    </FormControl> : ""
                }
                {suiteOption === "ExistingTestSuite" ?
                    <FormControl sx={{ width: 240, marginTop: "2%", marginLeft: "2%" }}>
                        <InputLabel
                            style={{
                                color: colors.secondaryColor,
                                backgroundColor: colors.primaryColor,
                                padding: 5,
                            }}
                            id={"Test Suite"}
                        >
                            Test Suite
                        </InputLabel>
                        <Select
                            labelId={"Test Suite name"}
                            id={"Test Suite name Select"}
                            value={suiteImpact}
                            label="Test Suite"
                            onChange={(event) => {
                                setSuitet(event.target.value)
                            }}
                            IconComponent={(props) => (
                                <ArrowDropDownSharpIcon
                                    style={{ color: colors.secondaryColor, fontSize: 40 }}
                                    {...props}
                                />
                            )}
                            sx={{
                                color: colors.secondaryColor,
                                border: `2px solid ${colors.secondaryColor}`,
                                fontSize: 16,
                                "&:focus": {
                                    borderRadius: 4,
                                    border: `4px solid ${colors.secondaryColor}`,
                                },
                            }}
                        >
                            {SuiteCaseList.map((item, index) => (
                                <MenuItem key={index} value={item.idtest_suite}>
                                    {item.Test_Suite}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl> : ""
                }
                <FormControl sx={{ marginTop: "2.5%", marginLeft: "2%" }}>
                    {(suiteOption === "NoSelection" || suiteImpact !== "" || newTestSuite !== "") &&

                        <ColorButton
                            component="label"
                            variant="contained"
                            // disable={(suiteOption === "NoSelection" || suiteImpact !== "" || newTestSuite !== "") ? true : false}
                            onClick={(event) => {
                                event.preventDefault()
                                onSubmitToGetComment(productImpact, allCommentInTextArea, suiteOption, suiteImpact, newTestSuite)
                                setValue("1")
                                console.log("Submitting comments", { productImpact, setProduct });
                            }}
                        >
                            Get Impacted Scenarios
                        </ColorButton>

                    }
                </FormControl>
            </Box> : ""
            }
        </Box>
    )
}
export default ImpactAnalysis;