import React from "react";
import "./CreateCollectionPage.style.scss";

import CreateCollection from "./../../components/CreateCollection/CreateCollection.component";

const CreateCollectionPage = () => {
  return <CreateCollection />;
};

export default CreateCollectionPage;
