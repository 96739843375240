import React from "react";

const TestStudioIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M4.936 1.6C5.10161 1.13194 5.40826 0.726736 5.81372 0.440192C6.21918 0.153647 6.70351 -0.000143143 7.2 9.99726e-08H8.8C9.8448 9.99726e-08 10.7336 0.668 11.064 1.6H15.2C15.4122 1.6 15.6157 1.68429 15.7657 1.83431C15.9157 1.98434 16 2.18783 16 2.4V15.2C16 15.4122 15.9157 15.6157 15.7657 15.7657C15.6157 15.9157 15.4122 16 15.2 16H0.8C0.587827 16 0.384344 15.9157 0.234315 15.7657C0.0842854 15.6157 0 15.4122 0 15.2V2.4C0 2.18783 0.0842854 1.98434 0.234315 1.83431C0.384344 1.68429 0.587827 1.6 0.8 1.6H4.936ZM1.6 3.2V14.4H14.4V3.2H11.064C10.8984 3.66806 10.5917 4.07326 10.1863 4.35981C9.78082 4.64635 9.29649 4.80014 8.8 4.8H7.2C6.70351 4.80014 6.21918 4.64635 5.81372 4.35981C5.40826 4.07326 5.10161 3.66806 4.936 3.2H1.6ZM7.2 1.6C6.98783 1.6 6.78434 1.68429 6.63432 1.83431C6.48429 1.98434 6.4 2.18783 6.4 2.4C6.4 2.61217 6.48429 2.81566 6.63432 2.96569C6.78434 3.11571 6.98783 3.2 7.2 3.2H8.8C9.01217 3.2 9.21566 3.11571 9.36569 2.96569C9.51571 2.81566 9.6 2.61217 9.6 2.4C9.6 2.18783 9.51571 1.98434 9.36569 1.83431C9.21566 1.68429 9.01217 1.6 8.8 1.6H7.2ZM6.4 6.4L10.4 8.8L6.4 11.2V6.4Z"
      fill="currentColor"
    />
  </svg>
);

export default TestStudioIcon;
