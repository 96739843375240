import React, { useState } from "react";
import "./ForgotPasswordPage.style.scss";
import {CommonMethods} from "./../../components/common/CommonMethods";
import { useNavigate } from "react-router-dom";

import { Avatar, TextField, Link, Grid, Typography, Box } from "@mui/material";

import {
  ColorButton,
  PrimaryColor,
  SecondaryColor,
} from "./../../components/common/common.Component";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import {
  fetchForgotPasswordToken,
  validateOTP,
  changepasswordFromForgotPassword,
} from "./../../API/UserApi";

const ForgotPasswordPage = (props) => {
  const navigate = useNavigate();
  const [account, setAccount] = useState({
    email: "",
    token: "",
    NewPassword: "",
    RENewPassword: "",
  });
  const [isTokenGenerated, setIsTokenGenerated] = useState(false);
  const [isTokenvalidated, setIsTokenvalidated] = useState(false);

  const handelAccount = (property, event) => {
    setAccount({ ...account, [property]: event.target.value });
  };

  const validatePassword = (password) => {
    return password.match(
      /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{8,16}$/
    );
  };

  const GetForgotPasswordToken = async () => {
    await fetchForgotPasswordToken(account.email)
      .then((response) => {
        if (response.status === 200) {
          if(response.data.message === 'fail'){
            CommonMethods(navigate);
          }else{
          setIsTokenGenerated(true);
        }
      }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const CheckOTP = async () => {
    await validateOTP(account.email, account.token)
      .then((response) => {
        if (response.status === 200) {
          if(response.data.message === 'fail'){
            CommonMethods(navigate);
          }else{
          if(response.data === "Token Verified"){
          setIsTokenvalidated(true);
        }
        }
      }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changePassword = async () => {
    if (account.RENewPassword !== account.NewPassword) {
      console.log("confirm password and password should be same")
    } else if (! validatePassword(account.NewPassword)){
      console.log("Enter a strong password")
    } else {
      await changepasswordFromForgotPassword({
        username: account.email,
        NewPassword: account.NewPassword,
        RENewPassword: account.RENewPassword,
      })
        .then((response) => {
          if (response.status === 200) {
            if(response.data.message === 'fail'){
              CommonMethods(navigate);
            }else{
            navigate("/LoginPage");
          }
        }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <Grid
      container
      component="main"
      style={{
        // height: "100vh",
        marginLeft: 0,
        marginTop: 64,
        // backgroundImage: `url(${image})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundColor: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
        item
        xs={12}
        sm={8}
        md={5}
        // component={Paper}
        elevation={1}
        square="true"
      >
        <Typography
          variant="h4"
          nowrap
          component="div"
          sx={{ marginBottom: 4, display: "flex" }}
        >
          Nogrunt
          <Avatar
            style={{
              marginLeft: 4,
              color: SecondaryColor,
              backgroundColor: PrimaryColor,
            }}
          >
            <LockOutlinedIcon color="inherit" />
          </Avatar>
        </Typography>

        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        <form
          style={{
            width: "100%", // Fix IE 11 issue.
            marginTop: 1,
          }}
          noValidate
        >
          <TextField
            onChange={(event) => handelAccount("email", event)}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Username / Email"
            name="email"
            autoFocus
            disabled={isTokenGenerated}
          />
          {!isTokenGenerated && (
            <>
              <ColorButton
                size="small"
                // type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ marginY: 2 }}
                onClick={GetForgotPasswordToken}
              >
                Get OTP
              </ColorButton>
            </>
          )}
          {isTokenGenerated && !isTokenvalidated && (
            <>
              <TextField
                onChange={(event) => handelAccount("token", event)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="token"
                label="Token"
                name="token"
                autoFocus
              />
              <ColorButton
                size="small"
                // type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ marginY: 2 }}
                onClick={CheckOTP}
              >
                Validate OTP
              </ColorButton>
            </>
          )}
          {isTokenvalidated && (
            <>
              <TextField
                onChange={(event) => handelAccount("NewPassword", event)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="NewPassword"
                label="New Password"
                name="NewPassword"
                type="password"
                autoFocus

                error = {! validatePassword(account.NewPassword) && account.NewPassword.length > 0 }
                helperText = {! validatePassword(account.NewPassword) && account.NewPassword.length > 0 ? "Invalid Password": "" }
              />{" "}
              <Grid item xs={10} ml={5}><p>- Password should contain 1 Uppercase, 1 Lowercase, 1 Digit, 1 Special Character and 8 to 16 Characters long. </p></Grid>
              <TextField
                onChange={(event) => handelAccount("RENewPassword", event)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="RENewPassword"
                label="confirm New Password"
                name="RENewPassword"
                type="password"
                autoFocus
              />
              <ColorButton
                size="small"
                // type="submit"
                fullWidth
                variant="contained"
                color="primary"
                sx={{ marginY: 2 }}
                onClick={changePassword}
              >
                Submit
              </ColorButton>
            </>
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Link
              style={{ marginTop: 2, cursor: "pointer" }}
              onClick={() => {
                navigate("/SignUpPage");
              }}
              variant="body2"
            >
              {"Don't have an account? Sign Up"}
            </Link>
            <Link
              style={{ marginTop: 2, cursor: "pointer" }}
              onClick={() => {
                navigate("/LoginPage");
              }}
              variant="body2"
            >
              {"Login"}
            </Link>
          </Box>
        </form>
      </Grid>
    </Grid>
  );
};

export default ForgotPasswordPage;
