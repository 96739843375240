import { useQuery } from "@tanstack/react-query";
import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "../Config/Config";

const downloadTestSuiteResult = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  if (!userDetails) throw new Error("No user details found in localStorage");
  const user = JSON.parse(userDetails);

  const downloadUrl = `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=downloadTestSuiteResults&companyid=${user.companyid}&token=${user.token}&suiteresultid=${id}`;

  window.location.href = downloadUrl;

};

const useDownloadTestSuiteQuery = (id) => {
  return useQuery({
    queryKey: ["downloadtestSuiteResult", id],
    queryFn: () => downloadTestSuiteResult(id),
    enabled: false,
    retry: true,
    cacheTime: 90000,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    staleTime: Infinity,

  });
};

export default useDownloadTestSuiteQuery;
