import { Box, Button, MenuItem, Select, Typography, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { useEffect, useState } from "react";
import { useTestSuiteQuery } from "../../useQuery";
import {
  resetExecutionReport,
  setSelectedComponent,
  setSelectedProduct,
  setSelectedTestCase,
  setSelectedTestCaseName,
  setSelectedTestSuite,
  setSelectedTestSuiteData,
} from "../../Redux/ExecutionReport/ExecutionReport";
import AutocompleteComponent from "../../components-new/common/AutoCompleteComponent";
import { DeleteIcon } from "../../components-new/icons";
import { useLocation, useNavigate } from "react-router-dom";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "300px",
    },
  },
};

const StyledSelect = styled(Select)`
  border-radius: 5px;
  border: 1px solid #d1dbff;
  background-color: #ffffff;
  font-size: 16px;
  color: #2b3539;
  min-width: 200px;
  max-height: 300px;

  .MuiSelect-select {
    padding: 13px 15px;
  }
`;

const ReportSelection = ({
  executionReportFetch,
  setIsFiltered,
  testStepData,
  refetchTestSuiteResults,
  setTestSuiteName,
  products,
  modules,
  refetchTestCases,
  testCaseSearchKeyword,
  setTestCaseSearchKeyword,
  testCases,
  setRefreshFlag,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [internalProduct, setInternalProduct] = useState("");
  const [testSuiteData, setTestSuiteData] = useState([]);

  const selectedProduct = useSelector((state) => state.executionSlice.selectedProduct);
  const selectedModule = useSelector((state) => state.executionSlice.selectedComponent);
  const selectedTestCase = useSelector((state) => state.executionSlice.selectedTestCase);
  const selectedTestSuite = useSelector((state) => state.executionSlice.selectedTestSuite);

  const queryParams = new URLSearchParams(location.search);

  const {
    data: testSuites,
    refetch: refetchTestSuite,
    isSuccess: isSuccessTestSuiteList,
  } = useTestSuiteQuery(internalProduct, {
    enabled: internalProduct !== "",
  });

  useEffect(() => {
    setInternalProduct(selectedProduct);
  }, [selectedProduct]);

  useEffect(() => {
    if (internalProduct) refetchTestSuite(internalProduct);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalProduct]);

  useEffect(() => {
    if (selectedProduct && isSuccessTestSuiteList) {
      let flattened = testSuites.flat();
      setTestSuiteData(flattened);
      const filteredTS = flattened.filter((item) => item.idtest_suite === selectedTestSuite);
      dispatch(setSelectedTestSuiteData(filteredTS));
      setTestSuiteName(filteredTS?.[0]?.Test_Suite);
    }
  }, [
    dispatch,
    isSuccessTestSuiteList,
    selectedProduct,
    selectedTestSuite,
    setTestSuiteName,
    testSuites,
  ]);

  const handleProductChange = (event) => {
    setRefreshFlag(false);
    setInternalProduct(event.target.value);
    setIsFiltered(false);
    Promise.resolve().then(() => {
      dispatch(setSelectedProduct(event.target.value));
    });
  };

  const handleSearchClick = async () => {
    refetchTestCases(selectedModule, testCaseSearchKeyword);
    executionReportFetch();
    setIsFiltered(true);
  };

  const handleOptionChange = (event, value) => {
    dispatch(setSelectedTestCaseName(value));
    setTestCaseSearchKeyword(value);
    if (!value) dispatch(setSelectedTestCase(""));
    else {
      const tempTC = testCases.filter((item) => item.Test_Case === value);
      dispatch(setSelectedTestCase(tempTC?.[0]?.idtest_case));
    }
  };

  const handleTestCaseChange = (event, newValue) => {
    setIsFiltered(true);
    if (newValue) dispatch(setSelectedTestCase(newValue.idtest_case));
    else dispatch(setSelectedTestCase(null));
    executionReportFetch();
  };

  const handleClearFilter = () => {
    setIsFiltered(false);
    setRefreshFlag(false);
    setTestCaseSearchKeyword("");
    queryParams.delete("tcrid");
    navigate(
      { hash: queryParams.toString() ? `#${queryParams.toString()}` : "" },
      { replace: true },
    );
    dispatch(resetExecutionReport());
  };

  return (
    <>
      <Box>
        <Box>
          <Typography color="#8089A8" fontSize="13px" fontWeight={500}>
            Application
          </Typography>
        </Box>
        <StyledSelect
          MenuProps={MenuProps}
          value={selectedProduct}
          onChange={handleProductChange}
          displayEmpty
        >
          <MenuItem disabled value="">
            Application
          </MenuItem>
          {products &&
            products.length > 0 &&
            (products ?? [{ label: "", value: "" }]).map((product) => (
              <MenuItem value={product.idproducts} key={product.idproducts}>
                {product.productname}
              </MenuItem>
            ))}
        </StyledSelect>
      </Box>
      {selectedProduct && !selectedTestSuite && (
        <Box>
          <Box>
            <Typography color="#8089A8" fontSize="13px" fontWeight={500}>
              Component
            </Typography>
          </Box>
          <StyledSelect
            MenuProps={MenuProps}
            value={selectedModule}
            displayEmpty
            onChange={(event) => {
              setRefreshFlag(false);
              dispatch(setSelectedTestSuite(""));
              dispatch(setSelectedComponent(event.target.value));
              setIsFiltered(false);
            }}
            disabled={!modules}
          >
            <MenuItem disabled value="">
              Component
            </MenuItem>
            {modules &&
              modules.length > 0 &&
              modules.map((module) => (
                <MenuItem key={module.idmodules} value={module.idmodules}>
                  {module.modulename}
                </MenuItem>
              ))}
          </StyledSelect>
        </Box>
      )}
      {selectedProduct && !selectedModule && (
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"} columnGap={2}>
          {!selectedModule && !selectedTestSuite && (
            <Box mt={2}>
              <Typography>Or</Typography>
            </Box>
          )}
          <Box>
            <Box>
              <Typography color="#8089A8" fontSize="13px" fontWeight={500}>
                Test Suite
              </Typography>
            </Box>
            <StyledSelect
              MenuProps={MenuProps}
              value={selectedTestSuite}
              onChange={(event) => {
                setRefreshFlag(false);
                dispatch(setSelectedComponent(""));
                dispatch(setSelectedTestSuite(event.target.value));
                setIsFiltered(true);
                refetchTestSuiteResults();
              }}
              disabled={!testSuites}
            >
              <MenuItem disabled value="">
                Test Suite
              </MenuItem>
              {testSuites &&
                testSuites.length > 0 &&
                testSuiteData.map((testSuite) => (
                  <MenuItem
                    key={`${testSuite.Test_Suite}-${testSuite.idtest_suite}`}
                    value={testSuite.idtest_suite}
                  >
                    {testSuite.Test_Suite}
                  </MenuItem>
                ))}
            </StyledSelect>
          </Box>
        </Box>
      )}
      {selectedModule && (
        <Box>
          <Box>
            <Typography color="#8089A8" fontSize="13px" fontWeight={500}>
              Test Scenario
            </Typography>
          </Box>
          <AutocompleteComponent
            options={testCases ?? []}
            value={
              selectedTestCase && testCases?.length
                ? (testCases ?? []).find((testCase) => testCase.idtest_case === selectedTestCase) ||
                  null
                : ""
            }
            onChange={handleTestCaseChange}
            onInputChange={handleOptionChange}
            handleSearchClick={handleSearchClick}
            getOptionLabel={(testCase) => testCase.Test_Case ?? ""}
            placeholder="Search Test Cases"
          />
        </Box>
      )}
      <Button
        variant="outlined"
        startIcon={<DeleteIcon />}
        size="medium"
        onClick={handleClearFilter}
        sx={{
          color: "#828286",
          background: "#ffffff",
          height: "44px",
          border: "1px solid #E2E6F3",
          outline: 0,
          textTransform: "capitalize",
          mt: 2.5,
        }}
      >
        Clear filter
      </Button>
    </>
  );
};

export default ReportSelection;
