// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-box {
  height: calc(100vh - 250px);
  display: flex;
}
.main-box .left-box {
  flex: 2 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  max-width: 350px;
  overflow-y: scroll;
  border-right: 2px solid rgba(0, 0, 0, 0.12);
}
.main-box .right-box {
  flex: 5 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem;
  overflow: scroll;
}`, "",{"version":3,"sources":["webpack://./src/pages/APIPage/APIPage.style.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;EACA,aAAA;AACF;AAAE;EACE,SAAA;EACA,aAAA;EACA,2BAAA;EACA,sBAAA;EACA,gBAAA;EACA,kBAAA;EACA,2CAAA;AAEJ;AAAE;EACE,SAAA;EACA,aAAA;EACA,2BAAA;EACA,sBAAA;EACA,aAAA;EACA,gBAAA;AAEJ","sourcesContent":[".main-box {\n  height: calc(100vh - 250px);\n  display: flex;\n  .left-box {\n    flex: 2;\n    display: flex;\n    justify-content: flex-start;\n    flex-direction: column;\n    max-width: 350px;\n    overflow-y: scroll;\n    border-right: 2px solid rgba(0, 0, 0, 0.12);\n  }\n  .right-box {\n    flex: 5;\n    display: flex;\n    justify-content: flex-start;\n    flex-direction: column;\n    padding: 1rem;\n    overflow: scroll;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
