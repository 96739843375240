import axios from "axios";

import { API_BASE_RELATIVE_PATH, API_BASE_PATH } from "./../Config/Config";

export const AddProductAPI = async (productname) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=addproduct&companyid=${temp.companyid}&token=${temp.token}&productname=${productname}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const AddCompanyURLAPI = async (companyurl) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=setcompanyurl&companyid=${temp.companyid}&token=${temp.token}&companyurl=${companyurl}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const AddModuleAPI = async (id, modulename) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=addmodule&companyid=${temp.companyid}&token=${temp.token}&prodid=${id}&modulename=${modulename}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const sendResolutionaddURL = async (data) => {
  
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .post(`${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=addURL&companyid=${temp.companyid}&token=${temp.token}`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};
export const sendResolutionaddResolution = async (data) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=addResolution&companyid=${temp.companyid}&token=${temp.token}`,
      data
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};
export const sendResolutionaddCredential = async (data) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=addCredential&companyid=${temp.companyid}&token=${temp.token}`,
      data
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const sendResolutionExecute = async (data) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .post(`${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=executeURL&companyid=${temp.companyid}&token=${temp.token}`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const getResolution = async (id) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getData&companyid=${temp.companyid}&token=${temp.token}&productid=${id}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const addEmailCredentialApi = async (data) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=setEmailDetails&companyid=${temp.companyid}&token=${temp.token}`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};
export const addDbCredentialApi = async (data) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=setDbDetails&companyid=${temp.companyid}&token=${temp.token}`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};
export const fetchEmailCredentialDetails = async (api_input) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);

  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getEmailConnectionDetails&companyid=${temp.companyid}&token=${temp.token}&idproducts=${api_input}`,
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};

export const clearStorage = async (api_input) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);

  const Response = await axios
    .post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=clearStorage&companyid=${temp.companyid}&token=${temp.token}&beforeDate=${api_input}`,
      api_input
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};
export const fetchDbCredentialDetails = async (api_input) => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);

  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getDbConnectionDetails&companyid=${temp.companyid}&token=${temp.token}&idproducts=${api_input}`
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
};
export const fetchUserDetails = async ()  => {
  const userDetails = localStorage.getItem("userDetail");
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=userStatus&companyid=${temp.companyid}&token=${temp.token}`
    ).then((response) => {
      return response;
    }).catch((error) => {
      console.log('fetch user details error', error.response.data.error);
    });
  return Response;
}

export const getUserApproved = async (id) => {
 
  const userDetails = localStorage.getItem('userDetail');
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=authorizedUser&companyid=${temp.companyid}&token=${temp.token}&iduserprofile=${id}`
  ).then((response) => {
      return response;
    }).catch((error) => {
      console.log('get approved api user', error.response.data.error)
    });
  return Response;
}
export const getuserReset = async (id) => {
  const userDetails = localStorage.getItem('userDetail');
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=resetStatus&companyid=${temp.companyid}&token=${temp.token}&iduserprofile=${id}`
    ).then((response) => {
      return response;
    }).catch((error) => {
      console.log('reset error', error.response.data.error)
    });
  return Response;
}
export const getStaticIntegrations = async () => {
  const userDetails = localStorage.getItem('userDetail');
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getStaticIntegrations&companyid=${temp.companyid}&token=${temp.token}`
    ).then((response) => {
      return response;
    }).catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
}
export const getIntegrations = async (product,name) => {
   const userDetails = localStorage.getItem('userDetail');
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getIntegrations&companyid=${temp.companyid}&token=${temp.token}&productid=${product}&name=${name}`
    ).then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
}
export const addIntegration = async (product,intName,adminprop) => {
  const userDetails = localStorage.getItem('userDetail');
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=addIntegration&companyid=${temp.companyid}&token=${temp.token}&productid=${product}&intname=${intName}&adminprop=${adminprop}`
    ).then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.data.error);
    });
  return Response;
}
export const deleteIntegrations = async (intid) => {
  const userDetails = localStorage.getItem('userDetail');
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .post(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=deleteIntegrations&companyid=${temp.companyid}&token=${temp.token}&intid=${intid}`,
      intid
    ).then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error.response.intid.error);
    });
  return Response;
}

export const getUSerRoles = async () => {
  const userDetails = localStorage.getItem('userDetail');
  const temp = JSON.parse(userDetails);
  const Response = await axios.get(
    `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getUSerRoles&companyid=${temp.companyid}&token=${temp.token}`
  ).then((response) => {
    return response;
  }).catch((error) => {
    console.log(error);
  });
  return Response;
}
export const updateUserType = async (userid,roleid) => {
  const userDetails = localStorage.getItem('userDetail');
  const temp = JSON.parse(userDetails);
  const Response = await axios.get(
    `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=UpdateUSerRole&companyid=${temp.companyid}&token=${temp.token}&userid=${userid}&roleid=${roleid}`
  ).then((response) => {
    return response;
  }).catch((error) => {
    console.log(error);
  });
  return Response;
}

export const updateModulePerm = async (moduleid,roleid,perm) => {
  const userDetails = localStorage.getItem('userDetail');
  const temp = JSON.parse(userDetails);
  const Response = await axios.get(
    `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=updateModulePermission&companyid=${temp.companyid}&token=${temp.token}&moduleid=${moduleid}&roleid=${roleid}&permission=${perm}`
  ).then((response) => {
    return response;
  }).catch((error) => {
    console.log(error);
  });
  return Response;
}

export const getModulePermissions = async (modid) => {
  const userDetails = localStorage.getItem('userDetail');
  const temp = JSON.parse(userDetails);
  const Response = await axios
    .get(
      `${API_BASE_PATH}${API_BASE_RELATIVE_PATH}?action=getModulePermission&companyid=${temp.companyid}&token=${temp.token}&moduleid=${modid}`
    ).then((response) => {
      return response;
    }).catch((error) => {
      console.log(error);
    });
  return Response;
}