import React from "react";

const TestCasesPassIcon = (props) => (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.9443 34C7.58033 34 0 26.4197 0 17.0557C0 7.6918 7.58033 0 16.9443 0C26.3082 0 34 7.6918 34 17.0557C34 26.4197 26.3082 34 16.9443 34ZM16.9443 1.67213C8.47213 1.67213 1.67213 8.58361 1.67213 17.0557C1.67213 25.5279 8.58361 32.4393 17.0557 32.4393C25.5279 32.4393 32.4393 25.5279 32.4393 17.0557C32.3279 8.58361 25.4164 1.67213 16.9443 1.67213Z" fill="#30CA17" />
        <path d="M16.946 29.9849C9.81161 29.9849 4.01489 24.1881 4.01489 17.0537C4.01489 9.91928 9.81161 4.12256 16.946 4.12256C24.0805 4.12256 29.8772 9.91928 29.8772 17.0537C29.8772 24.1881 24.0805 29.9849 16.946 29.9849ZM16.946 5.79469C10.7034 5.79469 5.68702 10.8111 5.68702 17.0537C5.68702 23.2963 10.7034 28.3127 16.946 28.3127C23.1887 28.3127 28.2051 23.2963 28.2051 17.0537C28.2051 10.8111 23.1887 5.79469 16.946 5.79469Z" fill="#30CA17" />
        <path d="M14.828 21.8484L11.2607 18.2811L12.487 17.1664L14.828 19.5074L21.405 12.8188L22.6312 14.0451L14.828 21.8484Z" fill="#30CA17" />
    </svg>


);

export default TestCasesPassIcon;
